import React, { useEffect, useRef, useState } from "react";
import ModalContainer from "../../../common/ModalContainer";
import Button from "../../../common/Button";
import TextareaInput from "../../../common/InputField/TextareaInput";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../common/Loader";
import CustomTable from "../../../common/CustomTable";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import VendorAvatar from "components/common/VendorAvatar";
import { truncate } from "lodash";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import { inventory } from "redux/Ldb/reducers";
import { productionActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { unitOfMeasurement } from "variables/unitOfMeasurement";
import { Tooltip } from "react-tooltip";
import useGetUserProfile from "hooks/useGetUserProfile";

type yieldLossesProps = {
    productData: Record<string, any>;
    calculateModal: boolean;
    setCalculateModal: (data: boolean) => void;
    setNoReload: (data: boolean) => void;
    qualityControlModal: boolean;
    setQualityControlModal: (data: boolean) => void;
    pendingQC: Record<string, any>;
    fetchingPendingQC: boolean;
    fetchingPendingQCLoadMore: boolean;
};

const YieldAndLosses = ({
    productData,
    calculateModal,
    setCalculateModal,
    setNoReload,
    qualityControlModal,
    setQualityControlModal,
    pendingQC,
    fetchingPendingQC,
    fetchingPendingQCLoadMore,
}: yieldLossesProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { workOrderId } = useParams();

    const { submittingYieldAndLoss, submitYieldAndLossSuccess } = useAppSelector(
        (state) => state.production
    );

    const [calculatingModal, setCalculatingModal] = useState<boolean>(false);
    const [summaryModal, setSummaryModal] = useState<boolean>(false);
    const [reportModal, setReportModal] = useState<boolean>(false);
    const [data, setData] = useState<{ [key: string]: any }>({});
    const [finalQuantity, setFinalQuantity] = useState<any>();
    const [sellableQuantity, setSellableQuantity] = useState<any>();
    const [yeildLossNotes, setYeildLossNotes] = useState<string>("");
    const [discrepancyCost, setDiscrepancyCost] = useState<number>();

    const [billOfMaterialIds, setBillOfMaterialIds] = useState<{ [key: string]: any }[]>([]);

    useEffect(() => {
        setBillOfMaterialIds(productData?.billOfMaterial);
    }, [productData]);

    useEffect(() => {
        const discrepancyCosts =
            billOfMaterialIds?.map((bom) => {
                const calcQuantity = bom?.quantity * productData?.plannedQuantity;

                const totalQuantity = bom?.scrap
                    ? calcTotalQuantity(calcQuantity, bom?.scrap)
                    : bom?.quantity
                    ? calcQuantity
                    : "";

                const finalQuantity = bom?.finalQuantity || 0;
                // const quantity = bom?.quantity || 0;
                const unitCost = bom?.inventory?.unitCost?.amount || 0;

                return finalQuantity > totalQuantity
                    ? (finalQuantity - Number(totalQuantity)) * unitCost
                    : 0;
            }) || [];

        const totalCost = discrepancyCosts.reduce((sum, cost) => sum + cost, 0);

        setDiscrepancyCost(totalCost);
    }, [billOfMaterialIds]);

    const handleSubmit = () => {
        const body = {
            finalQuantity: {
                amount: finalQuantity,
                unitOfMeasurement: productData?.productUnitOfMeasurement,
            },
            // sellableQuantity: {
            //     amount: sellableQuantity,
            //     unitOfMeasurement: productData?.productUnitOfMeasurement,
            // },
            billOfMaterialIds: billOfMaterialIds?.map((item) => {
                return {
                    inventoryId: item?.inventoryId,
                    finalQuantity: item?.finalQuantity,
                };
            }),
            ...(yeildLossNotes && { yeildLossNotes }),
        };
        // console.log(body)
        dispatch(productionActions?.submitYieldAndLoss(body, workOrderId));
    };

    useEffect(() => {
        if (Boolean(submitYieldAndLossSuccess)) {
            setSummaryModal(false);
            setReportModal(true);
            setNoReload(true);
            dispatch(productionActions?.resetSubmitYieldAndLoss());
            dispatch(productionActions?.fetchSingleWorkOrder(workOrderId));
        }
    }, [submitYieldAndLossSuccess]);

    const handleBOMData = (e, idx) => {
        const { name, value } = e.target;
        const copyArr = [...billOfMaterialIds];
        const copy = copyArr[idx];
        copy[name] = value;
        copyArr[idx] = copy;
        setBillOfMaterialIds(copyArr);
    };

    const calcTotalQuantity = (quantity, scrap, unitPrice?: number) => {
        const quantityNum = quantity;
        const scrapNum = scrap;
        const percentage = (quantityNum * scrapNum) / 100;

        const totalQuantity = quantity ? Number(percentage) + Number(quantity) : 0;
        if (unitPrice !== undefined) {
            return Number(totalQuantity * unitPrice);
        }
        return totalQuantity ? Number(totalQuantity) : 0;
    };

    const ProductionLossTableHeader = [
        { title: "Materials", widthClass: "w-[30%] !font-rocGroteskMedium !border-slate-200" },
        {
            title: "BOM-specified materials",
            widthClass: "w-[30%] !font-rocGroteskMedium !border-slate-200",
        },
        {
            title: "Actual quantity used ",
            widthClass: "w-[30%] !font-rocGroteskMedium !border-slate-200",
        },
    ];

    const ProductionLossTableBody = billOfMaterialIds?.map((bom, idx) => {
        const calcQuantity = bom?.quantity * productData?.plannedQuantity;

        const totalNeeded = bom?.scrap
            ? calcTotalQuantity(calcQuantity, bom?.scrap)
            : bom?.quantity
            ? calcQuantity
            : "";

        return [
            {
                content: (
                    <div key={idx} className={`w-full  `}>
                        <div className="flex items-center gap-2 py-2 px-2.5">
                            {bom?.inventory?.productImageDetails?.productAvatar ||
                            bom?.inventory?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        bom?.inventory?.productImageDetails?.productAvatar ||
                                        bom?.inventory?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={bom?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm">
                                <p className="text-g-75">
                                    {truncate(bom?.inventory?.productName, { length: 20 })}
                                </p>
                                <p className="text-[#64748B]">{bom?.inventory?.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-slate-500 flex items-center  font-rocGroteskMedium `}
                    >
                        <p className="px-4">
                            {/* {formatMoney()?.format(bom?.quantity * productData?.plannedQuantity)}{" "}
                            {``}
                            {bom?.inventory?.productStockDetails?.unitOfMeasurement} */}

                            {totalNeeded !== undefined
                                ? `${formatMoney()?.format(totalNeeded)} ${
                                      bom?.inventory?.productStockDetails?.unitOfMeasurement
                                  }`
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-slate-500 font-rocGroteskMedium `}
                    >
                        <div className="flex items-center justify-start">
                            <TextInput
                                value={bom?.finalQuantity}
                                name={"finalQuantity"}
                                type="number"
                                required={true}
                                onChange={(e) => handleBOMData(e, idx)}
                                onWheel={(e: any) => e.target.blur()}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                                containerClassname=""
                                inputClassName="!w-auto"
                                inputContainerClassname={
                                    "!border-0 !rounded-none !w-full !flex justify-start"
                                }
                                placeholder={!bom?.finalQuantity && "Enter value"}
                                focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                                rightIcon={bom?.inventory?.productStockDetails?.unitOfMeasurement}
                                leftIconClass="!relative left-0 cursor-default"
                                widthClass="!w-auto"
                            />
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    const ProductionSummaryTableHeader = [
        { title: "Materials", widthClass: "w-[20%] !font-rocGroteskMedium !border-slate-200" },
        {
            title: "BOM-specified Amount",
            widthClass: "w-[20%] !font-rocGroteskMedium !border-slate-200",
        },
        {
            title: "Actual Amount used ",
            widthClass: "w-[20%] !font-rocGroteskMedium !border-slate-200",
        },
        {
            title: "Discrepancy ",
            widthClass: "w-[20%] !font-rocGroteskMedium !border-slate-200",
        },
        {
            title: "Discrepancy Cost",
            widthClass: "w-[20%] !font-rocGroteskMedium !border-slate-200",
        },
    ];

    const ProductionSummaryTableBody = billOfMaterialIds?.map((bom, idx) => {
        const calcQuantity = bom?.quantity * productData?.plannedQuantity;

        const totalNeeded = bom?.scrap
            ? calcTotalQuantity(calcQuantity, bom?.scrap)
            : bom?.quantity
            ? calcQuantity
            : "";

        const discrepancy =
            bom?.finalQuantity > totalNeeded ? bom?.finalQuantity - Number(totalNeeded) : "";
        return [
            {
                content: (
                    <div key={idx} className={`w-full  `}>
                        <div className="flex items-center gap-2 py-2 px-2.5">
                            {bom?.inventory?.productImageDetails?.productAvatar ||
                            bom?.inventory?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        bom?.inventory?.productImageDetails?.productAvatar ||
                                        bom?.inventory?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={bom?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm">
                                <p className="text-g-75">
                                    {truncate(bom?.inventory?.productName, { length: 20 })}
                                </p>
                                <p className="text-[#64748B]">{bom?.inventory?.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm flex items-center  font-rocGroteskMedium `}
                    >
                        <p className="px-4 text-slate-950">
                            {/* {bom?.quantity !== undefined &&
                                formatMoney()?.format(
                                    bom?.quantity * productData?.plannedQuantity
                                )}{" "}
                            {bom?.quantity !== undefined &&
                                bom?.inventory?.productStockDetails?.unitOfMeasurement} */}

                            {totalNeeded !== undefined
                                ? `${formatMoney()?.format(totalNeeded)} ${
                                      bom?.inventory?.productStockDetails?.unitOfMeasurement
                                  }`
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm flex items-center  font-rocGroteskMedium `}
                    >
                        <p className="px-4 text-slate-950">
                            {bom?.finalQuantity !== undefined &&
                                formatMoney()?.format(bom?.finalQuantity)}
                            {` `}
                            {bom?.finalQuantity !== undefined &&
                                bom?.inventory?.productStockDetails?.unitOfMeasurement}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`${
                            discrepancy ? "text-r-50" : "text-[#56B648]"
                        } w-full border-slate-200 text-sm  flex items-center  font-rocGroteskMedium `}
                    >
                        <p className="px-4">
                            {discrepancy
                                ? `+${formatMoney().format(discrepancy)} ${
                                      bom?.inventory?.productStockDetails?.unitOfMeasurement
                                  }`
                                : "No discrepancy"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={` ${
                            discrepancy ? "text-r-50" : "text-[#56B648]"
                        } w-full border-slate-200 text-sm flex items-center  font-rocGroteskMedium `}
                    >
                        <p className="px-4">
                            {discrepancy &&
                                getCurrencyFromCurrencyCode(bom?.inventory?.unitCost?.currency)}
                            {discrepancy
                                ? formatMoney().format(
                                      discrepancy * bom?.inventory?.unitCost?.amount
                                  )
                                : "No discrepancy cost"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    const QualityControlTableHeader = [
        { title: "Product", widthClass: "w-[30%] !font-rocGroteskMedium !border-slate-200" },
        { title: "Assign to ", widthClass: "w-[30%] !font-rocGroteskMedium !border-slate-200" },
        { title: "Status", widthClass: "w-[30%] !font-rocGroteskMedium !border-slate-200" },
    ];

    const QualityControlTableBody = pendingQC?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full border-slate-200 text-sm text-gm-50 px-4 py-3 flex items-center  font-rocGroteskMedium `}
                    >
                        <p className="">{item?.task} </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-gm-50 px-4 py-3 flex items-center  font-rocGroteskMedium gap-2`}
                    >
                        <div className="">
                            {item?.assignee?.profile?.avatar ? (
                                <img
                                    src={item?.assignee?.profile?.avatar}
                                    alt="profile"
                                    className="w-5 h-5 rounded-full object-cover"
                                />
                            ) : (
                                <div className="bg-slate-100 rounded-full w-6 h-6 flex items-center justify-center">
                                    <i className="ri-user-follow-line text-gm-50"></i>
                                </div>
                            )}
                        </div>
                        <p className="text-sm">
                            {item?.assignee?.fullName || item?.assignee?.email}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-gm-50 px-4 py-3 gap-2 flex items-center   font-rocGroteskMedium `}
                    >
                        <div className={`bg-[#EAB308] rounded-full w-2 h-2`}></div>
                        <p className="">QC Pending</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    return (
        <div>
            <ModalContainer
                open={calculateModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[1089px]"
                showCloseIcon={false}
                closeModal={() => {
                    setCalculateModal(false);
                }}
            >
                <form
                    className="bg-white rounded-[8px] relative font-rocGroteskMedium"
                    onSubmit={(e) => {
                        e.preventDefault();
                        setCalculateModal(false);
                        setSummaryModal(true);
                    }}
                >
                    <div className="mt-4 ">
                        <div className=" sticky top-0 bg-white rounded-t-[8px] z-[1200] px-6 font-rocGroteskMedium border-b border-b-slate-200 flex items-center justify-between py-3">
                            <p className="mt-1 text-lg text-g-75 ">Enter Yield and Loss Details</p>
                            <i
                                className={`cursor-pointer ri-close-fill text-gm-50 `}
                                onClick={() => {
                                    setCalculateModal(false);
                                }}
                            ></i>
                        </div>

                        <div className="bg-slate-50 px-6 py-1.5 border-b border-b-slate-200">
                            <p className="text-slate-500 text-sm">
                                Please input the actual materials used, the quantities defined in
                                the Bill of Materials (BOM), and any unsellable units. This will
                                allow the system to calculate the production yield and discrepancies
                                {` `}
                                {productData?.product?.productName &&
                                    `for ${productData?.product?.productName}`}
                                .
                            </p>
                        </div>

                        <div className="px-6 pb-8 space-y-3 pt-6">
                            <div className="pb-10 border-b border-b-slate-200">
                                <p className="text-base text-[#000] mb-1">Production Yield</p>
                                <p className="text-slate-500 text-sm">
                                    Production Yield helps track the percentage of sellable units
                                    compared to the total units produced
                                </p>
                                <div className="grid grid-cols-2 gap-4 mt-2 ">
                                    <div>
                                        <div id="product-name">
                                            <SelectInput
                                                value={productData?.product?.productName}
                                                name="product"
                                                placeholder={"Product"}
                                                handleChange={(name, value) => {
                                                    // handleStepData({ target: { name, value } });
                                                }}
                                                isRequired={true}
                                                disabled={true}
                                                dropdownClassName="!max-h-24"
                                            />
                                        </div>

                                        {/* <Tooltip
                                            anchorSelect="#product-name"
                                            place={"top"}
                                            content="completion-ratecompletion-ratecompletion-rate"
                                            style={{ width: "180px", zIndex: 9999 }}
                                        /> */}
                                        <Tooltip
                                            anchorSelect={`#product-name`}
                                            place={"top"}
                                            content={productData?.product?.productName}
                                            style={{
                                                maxWidth: "250px",
                                                zIndex: 9999,
                                                borderRadius: "6px",
                                                border: "1px solid #E2E8F0",
                                                color: "#fff",
                                                boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                            }}
                                        />
                                    </div>

                                    <TextInput
                                        value={finalQuantity}
                                        name={"finalQuantity"}
                                        type={"number"}
                                        onChange={(e) => setFinalQuantity(e.target.value)}
                                        placeholder={`Total ${productData?.productUnitOfMeasurement} produced`}
                                        required={true}
                                        containerClassname=""
                                        onWheel={(e: any) => e.target.blur()}
                                        onKeyDown={(evt) =>
                                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                                evt.key
                                            ) && evt.preventDefault()
                                        }
                                    />

                                    {/* <TextInput
                                        value={sellableQuantity}
                                        name={"sellableQuantity"}
                                        type={"number"}
                                        onChange={(e) => setSellableQuantity(e.target.value)}
                                        placeholder={`Sellable ${productData?.productUnitOfMeasurement}`}
                                        required={true}
                                        containerClassname=""
                                        max={finalQuantity}
                                        onWheel={(e: any) => e.target.blur()}
                                        onKeyDown={(evt) =>
                                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                                evt.key
                                            ) && evt.preventDefault()
                                        }
                                    /> */}
                                </div>

                                <div className="bg-slate-50 border border-[#E2E8F0] px-4 py-2 rounded-md mt-3">
                                    <div className="flex items-center gap-1 mb-[6px]">
                                        <p className="text-slate-500 text-sm ">
                                            Total Planned Units{" "}
                                        </p>

                                        <div>
                                            <a id="planned-units">
                                                <i className="ri-information-fill text-slate-500 text-base"></i>
                                            </a>

                                            <Tooltip
                                                anchorSelect="#planned-units"
                                                place={"top"}
                                                content="The total number of units planned for this production run"
                                                style={{ width: "250px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>

                                    <p className="text-g-75 text-lg">
                                        {productData?.plannedQuantity !== undefined &&
                                            formatMoney()?.format(
                                                productData?.plannedQuantity
                                            )}{" "}
                                        {productData?.plannedQuantity !== undefined
                                            ? productData?.productUnitOfMeasurement
                                            : "N/A"}
                                    </p>
                                </div>
                            </div>

                            <div className="!mt-10">
                                <p className="text-base text-[#000] mb-1">Production Loss</p>
                                <p className="text-slate-500 text-sm">
                                    Production Loss measures how efficiently raw materials are
                                    converted into finished products
                                </p>
                                <CustomTable
                                    tableBody={ProductionLossTableBody}
                                    tableHeader={ProductionLossTableHeader}
                                    isScrollable={false}
                                    isCellBordered={true}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    scrollWithin={false}
                                    headerContainerClass="!bg-slate-50 !border-slate-200 "
                                    headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                    tableClass="!border-slate-200"
                                    bodyItemClass="hover:!bg-transparent !border-slate-200"
                                />
                            </div>
                        </div>
                        <div
                            className={`sticky bg-white bottom-0 border-t border-t-slate-200 py-4 px-6 flex items-center w-full justify-end space-x-4`}
                        >
                            <div className="flex items-center  justify-end gap-4">
                                <Button
                                    btnText={"Cancel"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                    onClick={() => setCalculateModal(false)}
                                />
                                <Button
                                    type="submit"
                                    btnText={`Calculate yield and losses`}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </ModalContainer>

            <ModalContainer
                open={calculatingModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setCalculatingModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            setCalculatingModal(false);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 flex items-center justify-center">
                                <Loader size={4} color={"[#CBD5E1]"} />
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[20px] text-gm-50">
                                    Calculating Yield and Loss
                                </span>
                                <p className={`px-8 mt-1 text-sm text-slate-500 text-center `}>
                                    Your submitted details are being processed to calculate the
                                    production yield and losses for Kōloa Kauaʻi Gold Rum. This will
                                    only take a moment.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={summaryModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[1089px] !max-h-[90vh] rounded-[8px] "
                showCloseIcon={false}
                closeModal={() => {
                    setSummaryModal(false);
                }}
            >
                <form
                    className="bg-white rounded-[8px] relative font-rocGroteskMedium"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <div className="">
                        <div className=" sticky top-0 rounded-t-[8px] bg-white px-6 font-rocGroteskMedium border-b border-b-slate-200 flex items-center justify-between py-3">
                            <p className="mt-1 text-lg text-g-75 ">
                                Production Yield and Loss Summary
                            </p>
                            <i
                                className={`cursor-pointer ri-close-fill text-gm-50 `}
                                onClick={() => {
                                    setSummaryModal(false);
                                }}
                            ></i>
                        </div>

                        <div className="bg-slate-50 px-6 py-1.5 border-b border-b-slate-200">
                            <p className="text-slate-500 text-sm">
                                The yield and loss calculations{" "}
                                {productData?.product?.productName &&
                                    `for ${productData?.product?.productName}`}{" "}
                                are complete. Below is a summary of the results for your review.
                                Please check for any discrepancies and leave comments if needed.
                            </p>
                        </div>

                        <div className="px-6 pb-8 space-y-3 pt-6">
                            <div className="pb-10 border-b border-b-slate-200">
                                <p className="text-base text-[#000] mb-4">
                                    Production Yield result
                                </p>

                                <div className="grid grid-cols-3 gap-2">
                                    <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                        <p className="text-slate-500 text-sm">
                                            Total Units Produced
                                        </p>

                                        <div
                                            className={` text-lg text-g-75 pt-2 flex items-center`}
                                        >
                                            <p className="text-g-75">
                                                {formatMoney()?.format(finalQuantity)}
                                                {` `}
                                                {productData?.productUnitOfMeasurement}
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                        <p className="text-slate-500 text-sm">Sellable Units</p>

                                        <div
                                            className={` text-lg text-g-75 pt-2 flex items-center`}
                                        >
                                            <p className="">
                                                {formatMoney()?.format(sellableQuantity)}
                                                {productData?.productUnitOfMeasurement}
                                            </p>
                                        </div>
                                    </div> */}
                                    <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                        <p className="text-slate-500 text-sm">
                                            Total Planned Units
                                        </p>

                                        <div
                                            className={` text-lg text-g-75 pt-2 flex items-center`}
                                        >
                                            <p className="">
                                                {productData?.plannedQuantity !== undefined &&
                                                    formatMoney()?.format(
                                                        productData?.plannedQuantity
                                                    )}{" "}
                                                {productData?.plannedQuantity !== undefined
                                                    ? productData?.productUnitOfMeasurement
                                                    : "N/A"}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                        <p className="text-slate-500 text-sm">Yield percentage</p>

                                        <div
                                            className={` text-lg text-g-75 pt-2 flex items-center`}
                                        >
                                            <p className="">
                                                {formatMoney().format(
                                                    (finalQuantity / productData?.plannedQuantity) *
                                                        100
                                                )}
                                                %
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="!mt-6 pb-10 border-b border-b-slate-200">
                                <p className="text-base text-[#000] mb-4">Production Loss result</p>
                                <div className="grid grid-cols-3 gap-2 mb-4">
                                    <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                        <p className="text-slate-500 text-sm">
                                            Material Discrepancy Cost
                                        </p>

                                        <div
                                            className={` text-lg text-g-75 pt-2 flex items-center`}
                                        >
                                            <p className="text-g-75">
                                                {discrepancyCost !== null
                                                    ? getCurrencyFromCurrencyCode(
                                                          productData?.product?.unitCost?.currency
                                                      )
                                                    : ""}
                                                {formatMoney().format(discrepancyCost)}
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                        <p className="text-slate-500 text-sm">
                                            Unsellable Goods Cost
                                        </p>

                                        <div
                                            className={` text-lg text-g-75 pt-2 flex items-center`}
                                        >
                                            <p className="">
                                                {discrepancyCost !== null
                                                    ? getCurrencyFromCurrencyCode(
                                                          productData?.product?.unitCost?.currency
                                                      )
                                                    : ""}
                                                {formatMoney().format(
                                                    (finalQuantity - sellableQuantity) *
                                                        productData?.product?.unitCost?.amount
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="border border-slate-200 p-4 rounded-md shadow-[0px_4px_8px_-4px_rgba(16,24,40,0.03)]">
                                        <p className="text-slate-500 text-sm">
                                            Overall Production Loss
                                        </p>

                                        <div
                                            className={` text-lg text-g-75 pt-2 flex items-center`}
                                        >
                                            <p className="">
                                                {discrepancyCost !== null
                                                    ? getCurrencyFromCurrencyCode(
                                                          productData?.product?.unitCost?.currency
                                                      )
                                                    : ""}
                                                {formatMoney().format(
                                                    (finalQuantity - sellableQuantity) *
                                                        productData?.product?.unitCost?.amount +
                                                        discrepancyCost
                                                )}
                                            </p>
                                        </div>
                                    </div> */}
                                </div>
                                <CustomTable
                                    tableBody={ProductionSummaryTableBody}
                                    tableHeader={ProductionSummaryTableHeader}
                                    isScrollable={false}
                                    isCellBordered={true}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    scrollWithin={false}
                                    headerContainerClass="!bg-slate-50 !border-slate-200 "
                                    headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                    tableClass="!border-slate-200"
                                    bodyItemClass="hover:!bg-transparent !border-slate-200"
                                />
                            </div>

                            <div className="!mt-6">
                                <p className="text-base text-[#000] mb-4">Any Additional info?</p>

                                <TextareaInput
                                    name={"yeildLossNotes"}
                                    value={yeildLossNotes}
                                    onChange={(e) => setYeildLossNotes(e?.target?.value)}
                                    rows={3}
                                    placeholder={
                                        "Leave a comment if you find any discrepancies or issues with the calculations."
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className={`sticky bg-white bottom-0 border-t border-t-slate-200 py-4 px-6 flex items-center w-full justify-end space-x-4`}
                        >
                            <div className="flex items-center  justify-end gap-4">
                                <Button
                                    btnText={"Go back"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                    onClick={() => {
                                        setCalculateModal(true);
                                        setSummaryModal(false);
                                    }}
                                    disabled={submittingYieldAndLoss}
                                />
                                <Button
                                    type="submit"
                                    btnText={`Approve results and add to Production report`}
                                    isLoading={submittingYieldAndLoss}
                                    disabled={submittingYieldAndLoss}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </ModalContainer>

            <ModalContainer
                open={reportModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setReportModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            setReportModal(false);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 w-12 h-12 bottom-0 right-[-13px] rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-[24px] text-white"></i>
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] text-gm-50">
                                    Yield and Loss Added to Production Report
                                </span>
                                <p className={`px-8 mt-1 text-base text-slate-500 text-center `}>
                                    The yield and loss data{" "}
                                    {productData?.product?.productName &&
                                        `for ${productData?.product?.productName}`}{" "}
                                    has been successfully added to the production report. You can
                                    now access the full report for further review or sharing with
                                    your team.
                                </p>
                            </div>
                        </div>

                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setReportModal(false);
                                }}
                            />
                            <Button
                                btnText="View Production Report"
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    navigate(`/dashboard/production/report/${workOrderId}`);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={qualityControlModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[800px] !max-h-[90vh]  rounded-[8px]"
                showCloseIcon={false}
                closeModal={() => {
                    setQualityControlModal(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            setQualityControlModal(false);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 flex items-center justify-center">
                                <i className="ri-list-check-3 text-3xl text-[#000]"></i>
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[20px] text-gm-50">
                                    Complete Pending Tasks Before Proceeding
                                </span>
                                <p className={`px-8 mt-1 text-sm text-slate-500 text-center `}>
                                    The following tasks must be completed before proceeding to the
                                    yield and losses stage. Please review the tasks and their
                                    assigned team members. Ensure the above tasks are completed
                                    promptly. Reach out to the assigned team members if necessary to
                                    avoid delays.
                                </p>
                            </div>
                        </div>

                        <div className="px-8 pb-10">
                            {fetchingPendingQC ? (
                                <div className="flex justify-center my-4">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            ) : (
                                <CustomTable
                                    tableBody={QualityControlTableBody}
                                    tableHeader={QualityControlTableHeader}
                                    isScrollable={false}
                                    isCellBordered={false}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    scrollWithin={false}
                                    headerContainerClass="!bg-slate-50 !border-slate-200 "
                                    headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                    tableClass="!border-slate-200"
                                    bodyItemClass="hover:!bg-transparent !border-slate-200 !cursor-default"
                                />
                            )}
                            {fetchingPendingQCLoadMore && (
                                <div className="flex justify-center my-4">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                        </div>

                        <div className="bg-white border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4 sticky bottom-0  rounded-b-[8px]">
                            <Button
                                btnText={"Skip Tasks to Calculate Yield and Loss"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setQualityControlModal(false);
                                    setCalculateModal(true);
                                }}
                                disabled={fetchingPendingQC}
                            />
                            <Button
                                btnText="View all pending tasks"
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    navigate(`/dashboard/task`);
                                }}
                                disabled={fetchingPendingQC}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default YieldAndLosses;
