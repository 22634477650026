import React, { useEffect, useReducer, useRef, useState } from "react";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import DateInput from "components/common/DateInput";
import SelectInput from "components/common/InputField/SelectInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import TextInput from "components/common/InputField/TextInput";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import ProductionModals from "../../../components/views/Synth2.0/Production/ProductionModals";
import moment, { duration } from "moment";
import { cloneDeep, debounce, map, truncate } from "lodash";
import { displayFileIcon, formatMoney, getCurrencyFromCurrencyCode, getFromStorage } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { authActions, productionActions } from "redux/Ldb/actions";
import useGetSingleProductionPlan from "hooks/ProductionHooks/useGetSingleProductionPLan";
import { calculateDateDifference } from "helpers/getTimeDiffInDaysWeeksMths";
import ProductionSteps from "./../../../components/views/Synth2.0/Production/ProductionSteps";
import Loader from "components/common/Loader";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import { inventory } from "redux/Ldb/reducers";
import { Tooltip } from "react-tooltip";
import useGetProductionPlanTabTwo from "hooks/ProductionHooks/useGetProductionPlanTabTwo";
import useGetTeamRoles from "hooks/teamHooks/useGetTeamRoles";
import { accessTypes } from "variables";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import useGetUserProfile from "hooks/useGetUserProfile";

const CreateProductionPlan = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { productionId } = useParams();
    const [param] = useSearchParams();
    const planTab = param.get("planTab");
    const duplicateId = param.get("duplicateId");
    const duplicate = param.get("duplicate");

    const location = useLocation();
    const { forecastDetails } = location.state || {};
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [insufficientModal, setInsufficientModal] = useState<boolean>(false);
    const [productionStepModal, setProductionStepModal] = useState<boolean>(false);
    const [productionPlanId, setProductionPlanId] = useState<string>("");
    const [productName, setProductName] = useState<string>("");
    const [category, setCategory] = useState<string>("");
    const [warning, setWarning] = useState<boolean>(false);
    const [bomList, setBomList] = useState<{ [key: string]: any }[]>([{}]);
    const [directCost, setDirectCost] = useState<{ [key: string]: any }[]>([{}]);
    const [indirectCost, setIndirectCost] = useState<{ [key: string]: any }[]>([{}]);
    const [step, setStep] = useState<{ [key: string]: any }>({});
    const [productionSteps, setProductionSteps] = useState<{ [key: string]: any }[]>([]);
    const [productionStepsModalData, setProductionStepsModalData] = useState<
        { [key: string]: any }[]
    >([]);
    const [stepIdx, setStepIdx] = useState<number>(-1);
    const [teamMembers, setTeamMembers] = useState<{ [key: string]: any }[]>([]);
    const [nonFinishedInventory, setNonFinishedInventory] = useState<{ [key: string]: any }[]>([]);
    const [productData, setProductData] = useState<{ [key: string]: any }>({
        duration: "days",
    });
    const [directCostSum, setDirectCostSum] = useState<number>();
    const [assignees, setAssignees] = useState<string[]>([]);
    const [indirectCostSum, setIndirectCostSum] = useState<number>();
    const [workDays, setWorkDays] = useState<string[]>([]);
    const [qcData, setQcData] = useState<{ [key: string]: any }[]>([{}]);
    const [savedStepId, setSavedStepId] = useState<string>("");
    const [showCheckList, setShowCheckList] = useState<number>(-1);
    const [showAttachments, setShowAttachments] = useState<number>(-1);
    const [bomCost, setBomCost] = useState<number>(0);
    const [debouncedSearchGoods, setDebouncedSearchGoods] = useState<string>("");
    const [debouncedSearchInventory, setDebouncedSearchInventory] = useState<string>("");
    const [debouncedSearchTeamMember, setDebouncedSearchTeamMember] = useState<string>("");
    const [attachments, setAttachments] = useState<any>([]);
    const [planDetails, setPlanDetails] = useState<{ [key: string]: any }>({});
    const [viewStep, setViewStep] = useState<number>(-1);
    const [expandedRowIdx, setExpandedRowIdx] = useState<number>(-1);
    const [stockList, setStockList] = useState<{ [key: string]: any }[]>([]);
    const [addNewMember, setAddNewMember] = useState(false);
    const [addCustomRole, setAddCustomRole] = useState(false);
    const [teamForm, setTeamForm] = useState({
        email: "",
        role: "",

        // brands: [],
    });
    const [role, setRole] = useState("");

    const [finishedProduct, setFinishedProduct] = useState<{ [key: string]: any }[]>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

    const [profile] = useGetUserProfile();
    const userProfile = getFromStorage("ally-user");
    const { fetchingTeamMember, fetchedTeamMemberSuccess } = useAppSelector((state) => state.auth);
    const { addingTeamMember, addTeamMemberSuccess, createRoles, createRolesSuccess } =
        useAppSelector((state) => state.auth);

    const {
        fetchingFinishedInventory,
        fetchedFinishedInventorySuccess,
        fetchingNonFinishedInventoryStock,
        fetchNonFinishedInventoryStockSuccess,
        creatingProductionPlan,
        createProductionPlanSuccess,
        updatingProductionPlan,
        updateProductionPlanSuccess,
        creatingPlanSteps,
        createPlanStepsSuccess,
        updatingPlanSteps,
        updatePlanStepsSuccess,
        fetchedProductionSettingsSuccess,
        fetchedSingleProductionPlanBasicSuccess,
        validatingBatchId,
        fetchBomStockSuccess,
    } = useAppSelector((state) => state.production);

    const {
        data: stepsDetails,
        isLoading: stepsLoading,
        setQuery: setTabTwoQuery,
        isLoadingMore: isLoadingMore,
        pagination: pagination,
    } = useGetProductionPlanTabTwo();

    const { data: userRoles, isFetching: isFetchingRoles } = useGetTeamRoles(
        profile?.isMultiBrandAccount || profile?.accountType === "brand"
            ? userProfile?.mainToken || userProfile?.token
            : profile?.token
    );

    useEffect(() => {
        setTabTwoQuery("steps");
    }, []);

    useEffect(() => {
        dispatch(productionActions?.fetchProductionSettings());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedProductionSettingsSuccess)) {
            setWorkDays(fetchedProductionSettingsSuccess?.productionSettings?.workDays);
        }
    }, [fetchedProductionSettingsSuccess]);

    useEffect(() => {
        if (productionId || duplicateId) {
            dispatch(
                productionActions?.fetchSingleProductionPlanBasic(productionId || duplicateId)
            );
        }
    }, [productionId, duplicateId, dispatch]);

    useEffect(() => {
        if (Boolean(fetchedSingleProductionPlanBasicSuccess)) {
            setPlanDetails(fetchedSingleProductionPlanBasicSuccess?.plan);
        }
    }, [fetchedSingleProductionPlanBasicSuccess]);

    useEffect(() => {
        if ((productionId || duplicateId) && planDetails) {
            if (planDetails) {
                setProductName(planDetails?.inventory?.productName);
                setCategory(planDetails?.inventory?.inventoryCategory);
                setProductData({
                    inventoryId: planDetails?.inventory?._id,
                    quantity: planDetails?.quantity?.amount,
                    unitOfMeasurement: planDetails?.quantity?.unitOfMeasurement,
                    lotNumber: planDetails?.batchId,
                    startDate: moment(planDetails?.startDate)?.format("YYYY-MM-DD"),
                    dueDate: moment(planDetails?.dueDate)?.format("YYYY-MM-DD"),
                    endDate: moment(planDetails?.endDate)?.format("YYYY-MM-DD"),
                    managerId: planDetails?.managerId,
                    ...(planDetails?.qualityControlId && {
                        qualityControlId: planDetails?.qualityControlId,
                    }),
                    ...(planDetails?.notes && { notes: planDetails?.notes }),
                    leadTime: calculateDateDifference(
                        planDetails?.startDate,
                        planDetails?.estimatedLeadTime
                    )?.time,
                    duration:
                        calculateDateDifference(
                            planDetails?.startDate,
                            planDetails?.estimatedLeadTime
                        )?.duration?.slice(-1) !== "s"
                            ? calculateDateDifference(
                                  planDetails?.startDate,
                                  planDetails?.estimatedLeadTime
                              )?.duration + "s"
                            : calculateDateDifference(
                                  planDetails?.startDate,
                                  planDetails?.estimatedLeadTime
                              )?.duration,
                });

                if (planDetails?.indirectCosts?.length >= 1) {
                    const newdirectCost = planDetails?.directCosts?.map((cost) => {
                        const { _id, ...rest } = cost;
                        return rest;
                    });
                    setDirectCost(newdirectCost);
                }
                if (planDetails?.indirectCosts?.length >= 1) {
                    const newIndirectCost = planDetails?.indirectCosts?.map((cost) => {
                        const { _id, ...rest } = cost;
                        return rest;
                    });
                    setIndirectCost(newIndirectCost);
                }

                const newBOM = planDetails?.billOfMaterialIds?.map((bom) => {
                    const { _id, ...rest } = bom;
                    return rest;
                });
                setBomList(newBOM);
            }
            if (stepsDetails) {
                const newSteps =
                    stepsDetails?.length >= 1
                        ? stepsDetails?.map((step) => {
                              return {
                                  name: step?.name,
                                  ...(step?.description && { description: step?.description }),
                                  ...(step?.plannedStart && {
                                      plannedStart: moment(step?.plannedStart)?.format(
                                          "YYYY-MM-DDTHH:mm"
                                      ),
                                  }),
                                  ...(step?.plannedEnd && {
                                      plannedEnd: moment(step?.plannedEnd)?.format(
                                          "YYYY-MM-DDTHH:mm"
                                      ),
                                  }),
                                  id: step?._id,
                                  assigneeIds:
                                      step?.assigneeIds?.length >= 1 ? step?.assigneeIds : [],
                                  qualityChecklist:
                                      step?.qualityChecklist?.length >= 1
                                          ? step?.qualityChecklist
                                          : [],
                                  actualStart: step?.actualStart,
                                  actualEnd: step?.actualEnd,
                                  attachments:
                                      step?.attachments?.length >= 1
                                          ? step?.attachments?.map((item) => {
                                                return {
                                                    path: item?.path,
                                                    filename: item?.filename,
                                                };
                                            })
                                          : [],
                              };
                          })
                        : [];
                setProductionSteps(newSteps);
            }
        }
    }, [productionId, duplicateId, planDetails, stepsDetails]);

    useEffect(() => {
        if (forecastDetails) {
            setProductData((prev) => ({
                ...prev,
                inventoryId: forecastDetails?.inventoryId,
                quantity: forecastDetails?.quantity,
            }));

            if (forecastDetails?.inventoryId) {
                const selectedProduct = finishedProduct?.find(
                    (item) => item?._id === forecastDetails?.inventoryId
                );
                setProductName(selectedProduct?.productName);
                setProductData((prev) => ({
                    ...prev,
                    unitOfMeasurement: selectedProduct?.productStockDetails?.unitOfMeasurement,
                }));
                // if (selectedProduct?.billOfMaterialIds?.length >= 1) {
                //     setBomList(selectedProduct?.billOfMaterialIds);
                // } else {
                //     setBomList([{}]);
                // }
            }
        }
    }, [forecastDetails, finishedProduct]);

    useEffect(() => {
        dispatch(
            authActions.getTeamMembers(
                40,
                debouncedSearchTeamMember,
                null,
                profile?.isMultiBrandAccount || profile?.accountType === "brand"
                    ? userProfile?.mainToken || userProfile?.token
                    : profile?.token
            )
        );
    }, [
        dispatch,
        debouncedSearchTeamMember,
        addTeamMemberSuccess,
        profile?.token,
        profile?.isMultiBrandAccount,
        profile?.accountType,
        userProfile?.mainToken,
        userProfile?.token,
    ]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        if (Boolean(addTeamMemberSuccess)) {
            setAddNewMember(false);
        }
    }, [addTeamMemberSuccess]);

    useEffect(() => {
        if (Boolean(createRolesSuccess)) {
            setSelectedPermissions([]);
            setAddCustomRole(false);
            dispatch(authActions.resetCreateRoles());
        }
    }, [createRolesSuccess, dispatch]);

    useEffect(() => {
        if (category === "Work-In-Progress (WIP)" || category === "Finished Goods") {
            dispatch(
                productionActions.getFinishedInventory(category, 40, 1, debouncedSearchInventory)
            );
        }
    }, [dispatch, debouncedSearchInventory, category]);

    useEffect(() => {
        if (Boolean(fetchedFinishedInventorySuccess)) {
            setFinishedProduct(fetchedFinishedInventorySuccess?.products);
        }
    }, [fetchedFinishedInventorySuccess]);

    useEffect(() => {
        setProductData((prev) => ({
            ...prev,
            inventoryId: "",
            unitOfMeasurement: "",
        }));
        setBomList([{}]);
        setProductName("");
    }, [category]);

    useEffect(() => {
        dispatch(productionActions.fetchNonFinishedInventoryStock(40, 1, debouncedSearchGoods));
        // dispatch(productionActions.fetchNonFinishedInventoryStock());
    }, [dispatch, debouncedSearchGoods]);

    useEffect(() => {
        if (Boolean(fetchNonFinishedInventoryStockSuccess)) {
            setNonFinishedInventory(fetchNonFinishedInventoryStockSuccess?.products);
        }
    }, [fetchNonFinishedInventoryStockSuccess]);

    useEffect(() => {
        if (productData?.inventoryId) {
            const inventoryIdStr = String(productData.inventoryId); // Convert to string if it's an ObjectId

            const pattern = /^[0-9a-fA-F]{24}$/;
            const isValid = pattern.test(inventoryIdStr);
            if (isValid) {
                dispatch(productionActions?.fetchBomStock(productData.inventoryId));
            }
        }
    }, [productData?.inventoryId]);

    useEffect(() => {
        if (Boolean(fetchBomStockSuccess)) {
            dispatch(productionActions?.resetFetchBomStock());

            fetchBomStockSuccess?.bom?.length >= 1
                ? addQuantityToBatches(fetchBomStockSuccess?.bom)
                : setBomList([{}]);
        }
    }, [fetchBomStockSuccess]);

    const handleTeamFormChange = (name: string, value: any) => {
        setTeamForm((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const userData = { ...userProfile };
        const body: { [key: string]: any } = {
            // brandIds: [],
            teamMembers: [
                {
                    email: teamForm?.email,
                    role: teamForm?.role,
                },
            ],
        };

        if (profile?.isMultiBrandAccount || profile?.accountType === "brand") {
            body.brandIds = [profile?.parentId || profile?._id];
        }

        dispatch(
            authActions.addTeamMember(
                body,
                profile?.isMultiBrandAccount || profile?.accountType === "brand"
                    ? userData?.mainToken || userData?.token
                    : profile?.token
            )
        );
    };

    const handlePermissionChange = (permission: string) => {
        const isSelected = selectedPermissions.includes(permission);

        if (isSelected) {
            const updatedPermissions = selectedPermissions.filter((item) => item !== permission);
            setSelectedPermissions(updatedPermissions);
        } else {
            setSelectedPermissions([...selectedPermissions, permission]);
        }
    };

    const handleAddRole = () => {
        const userData = { ...userProfile };

        const permission = selectedPermissions;
        if (role === "") {
            toast.custom((t) => (
                <CustomToast t={t} message={"Role name is required"} type="error" />
            ));
        }
        if (role !== "") {
            const teamMember = {
                role,
                ...(permission.length > 0 && { permission }),
            };
            dispatch(
                authActions.createRoles(
                    teamMember,
                    profile?.isMultiBrandAccount || profile?.accountType === "brand"
                        ? userData?.mainToken || userData?.token
                        : profile?.token
                )
            );
        }
    };

    const handleBOMData = (e, idx) => {
        const { name, value } = e.target;
        const copyArr = [...bomList];
        const copy = copyArr[idx];
        copy[name] = value;
        copyArr[idx] = copy;
        if (name === "quantity" || name === "scrap") {
            const quantityValue = name === "quantity" ? value : copyArr[idx]?.quantity;
            const scrapValue = name === "scrap" ? value : copyArr[idx]?.scrap;
            const bomQuantity = quantityValue * (productData?.quantity || 1);

            let qty = scrapValue
                ? Number(calcTotalQuantity(bomQuantity, scrapValue))
                : bomQuantity
                ? Number(bomQuantity)
                : 0;

            copyArr[idx].stocks =
                copyArr[idx]?.stocks?.length > 0 &&
                copyArr[idx]?.stocks?.map((item) => {
                    if (qty > 0 && item?.productStockDetails?.stockLevel > 0) {
                        let stockQty =
                            qty - item?.productStockDetails?.stockLevel < 0
                                ? qty
                                : item?.productStockDetails?.stockLevel;
                        qty = qty - stockQty;

                        return {
                            ...item,
                            quantity: stockQty,
                        };
                    } else {
                        const { quantity, ...rest } = item;
                        return rest;
                    }
                });

            copyArr[idx] = {
                ...copyArr[idx],
                batchesStockIsNotEnough: qty > 0 ? true : false,
                calcPlannedQuantity: quantityValue * (productData?.quantity || 1),
            };

            if (expandedRowIdx === idx) {
                setStockList(copyArr[idx].stocks?.length > 0 ? copyArr[idx].stocks : []);
            }
        }
        setBomList(copyArr);
    };

    const addQuantityToBatches = (data) => {
        const formatStockData = data?.map((item) => {
            // const bomQuantity = item?.selected ? item?.quantity : item?.quantity * (productData?.quantity || 1);
            const bomQuantity = item?.quantity * (productData?.quantity || 1);
            let qty = item?.scrap
                ? Number(calcTotalQuantity(bomQuantity, item?.scrap))
                : bomQuantity
                ? Number(bomQuantity)
                : 0;

            const updatedQty =
                item?.stocks?.length > 0 &&
                item?.stocks?.map((stock) => {
                    if (qty > 0 && stock?.productStockDetails?.stockLevel > 0) {
                        let stockQty =
                            qty - stock?.productStockDetails?.stockLevel < 0
                                ? qty
                                : stock?.productStockDetails?.stockLevel;
                        qty = qty - stockQty;
                        return {
                            ...stock,
                            quantity: stockQty,
                        };
                    } else {
                        const { quantity, ...rest } = stock;
                        return rest;
                    }
                });
            return {
                ...item,
                stocks: updatedQty,
                quantity: item?.quantity,
                calcPlannedQuantity:
                    item?.quantity !== undefined
                        ? item?.quantity * (productData?.quantity || 1)
                        : "",
                batchesStockIsNotEnough: qty > 0 ? true : false,
            };
        });
        expandedRowIdx >= 0 && formatStockData[expandedRowIdx]?.stocks?.length >= 1
            ? setStockList(formatStockData[expandedRowIdx]?.stocks)
            : setStockList([]);
        setBomList(formatStockData);
    };

    useEffect(() => {
        addQuantityToBatches(bomList);
    }, [productData?.quantity]);

    const calcTotalQuantity = (quantity, scrap, unitPrice?: number) => {
        const quantityNum = quantity;
        const scrapNum = scrap;
        const percentage = (quantityNum * scrapNum) / 100;

        const totalQuantity = quantity ? Number(percentage) + Number(quantity) : 0;
        if (unitPrice !== undefined) {
            return Number(totalQuantity * unitPrice);
        }
        return totalQuantity ? Number(totalQuantity) : 0;
    };

    useEffect(() => {
        // const sum = Array.isArray(bomList)
        //     ? bomList?.reduce((sum, bom) => {
        //           const quantityNum = bom?.quantity || 0;
        //           const calcQuantity =
        //               (productData?.quantity ? productData?.quantity : 1) * quantityNum;
        //           const scrapNum = bom?.scrap || 0;
        //           const percentage = (calcQuantity * scrapNum) / 100;
        //           const totalQuantity = calcQuantity + percentage;
        //           const totalCost =
        //               ( bom?.unitCost?.amount || 0) * totalQuantity;
        //           return sum + totalCost;
        //       }, 0)
        //     : 0;

        const sum = Array.isArray(bomList)
            ? bomList.reduce((total, item) => {
                  const addedSum =
                      item?.stocks?.length > 0
                          ? item?.stocks.reduce((sum, stock) => {
                                const unitCost =
                                    Number(stock?.unitCost?.amount || item?.unitCost?.amount) || 0;
                                const quantity = stock?.quantity || 0;
                                return sum + unitCost * quantity;
                            }, 0)
                          : 0;

                  return total + addedSum;
              }, 0)
            : 0;

        setBomCost(sum);
    }, [bomList, productData?.quantity]);

    const handleAddMoreBom = () => {
        setBomList((prev) => [...prev, {}]);
    };

    const handleBOMDelete = (idx) => {
        const copyBom = [...bomList];
        copyBom?.splice(idx, 1);
        setBomList(copyBom);
        setExpandedRowIdx(-1);
        setStockList([]);
    };

    const handleDirectCostDelete = (idx) => {
        const copyCost = [...directCost];
        copyCost?.splice(idx, 1);
        setDirectCost(copyCost);
    };
    const handleIndirectCostDelete = (idx) => {
        const copyCost = [...indirectCost];
        copyCost?.splice(idx, 1);
        setIndirectCost(copyCost);
    };

    useEffect(() => {
        const sum = Array.isArray(directCost)
            ? directCost.reduce((sum, cost) => sum + (Number(cost?.amount) || 0), 0)
            : 0;
        setDirectCostSum(sum);
    }, [directCost, bomList]);

    useEffect(() => {
        const sum = indirectCost?.reduce((sum, cost) => sum + (Number(cost?.amount) || 0), 0);
        setIndirectCostSum(sum);
    }, [indirectCost]);

    const handleDirectCostData = (e, idx) => {
        const { name, value } = e.target;
        let copyArr = [...directCost];
        copyArr[idx] = {
            ...copyArr[idx],
            [name]: value,
        };
        setDirectCost(copyArr);
    };
    const handleIndirectCostData = (e, idx) => {
        const { name, value } = e.target;
        let copyArr = [...indirectCost];
        copyArr[idx] = {
            ...copyArr[idx],
            [name]: value,
        };
        setIndirectCost(copyArr);
    };
    const handleProductData = (e) => {
        const { name, value } = e.target;

        setProductData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (productData?.leadTime && productData?.startDate) {
            function getNextXDaysWeeksMonths() {
                let startDate = moment(productData?.startDate);

                const includesSaturday = workDays.includes("Saturday");
                const includesSunday = workDays.includes("Sunday");

                function addDaysExcludingWeekends(startDate, numDays) {
                    let tempDate = moment(startDate);
                    while (numDays > 0) {
                        tempDate.add(1, "days"); // Add one day at a time

                        if (tempDate.isoWeekday() === 6 && !includesSaturday) {
                            continue;
                        }
                        if (tempDate.isoWeekday() === 7 && !includesSunday) {
                            continue;
                        }

                        numDays--;
                    }
                    return tempDate.format("YYYY-MM-DD");
                }

                function addWeeksExcludingWeekends(startDate, numWeeks) {
                    let tempDate = moment(startDate);
                    let daysToAdd = numWeeks * 7;
                    return addDaysExcludingWeekends(tempDate, daysToAdd);
                }

                function addMonthsExcludingWeekends(startDate, numMonths) {
                    let tempDate = moment(startDate).add(numMonths, "months");
                    let currentDayOfWeek = tempDate.isoWeekday();
                    if (includesSaturday && currentDayOfWeek === 6) {
                        tempDate.subtract(1, "days");
                    } else if (includesSunday && currentDayOfWeek === 7) {
                        tempDate.subtract(2, "days");
                    }

                    return tempDate.format("YYYY-MM-DD");
                }

                if (productData?.duration === "days") {
                    return addDaysExcludingWeekends(startDate, productData?.leadTime);
                } else if (productData?.duration === "hours") {
                    return addDaysExcludingWeekends(startDate, productData?.leadTime);
                } else if (productData?.duration === "weeks") {
                    return addWeeksExcludingWeekends(startDate, productData?.leadTime);
                } else if (productData?.duration === "months") {
                    return addMonthsExcludingWeekends(startDate, productData?.leadTime);
                } else {
                    throw new Error(
                        "Invalid duration type. Please use 'days', 'weeks', or 'months'."
                    );
                }
            }

            setProductData((prev) => ({
                ...prev,
                endDate: getNextXDaysWeeksMonths(),
            }));
        }
    }, [productData?.leadTime, productData?.startDate, productData?.duration]);

    const handleDebouncedBatchIdCheck = debounce((e) => {
        const { value } = e.target;

        if (value) {
            validateBatchNo(value);
        }
    }, 2000);

    const validateBatchNo = async (batchId) => {
        try {
            const res = await dispatch(productionActions.validateProductionBatchId(batchId));
            if (res) {
                setWarning(false);
            }
        } catch (err) {
            setWarning(true);
        }
    };

    const handleCreateProductionPlan = () => {
        const saveIndirect =
            indirectCost?.length >= 1
                ? indirectCost
                      ?.filter((item) => {
                          return item?.category && item?.amount;
                      })
                      ?.map((item) => {
                          return {
                              category: item?.category,
                              currency: "USD",
                              amount: item?.amount,
                          };
                      })
                : [];

        const body = {
            ...(!productionId && { inventoryId: productData?.inventoryId }),
            quantity: {
                amount: productData?.quantity,
                unitOfMeasurement: productData?.unitOfMeasurement,
            },
            ...(productData?.lotNumber && !productionId && { batchId: productData?.lotNumber }),
            startDate: moment(productData?.startDate)?.format("YYYY-MM-DD"),
            dueDate: moment(productData?.dueDate)?.format("YYYY-MM-DD"),
            endDate: moment(productData?.endDate)?.format("YYYY-MM-DD"),
            managerId: productData?.managerId,
            ...(productData?.qualityControlId && {
                qualityControlId: productData?.qualityControlId,
            }),
            ...(productData?.notes && { notes: productData?.notes }),
            ...(directCost?.length >= 1 && {
                directCosts: directCost
                    ?.filter((item) => {
                        return item?.category && item?.amount;
                    })
                    ?.map((item) => {
                        return {
                            category: item?.category,
                            currency: "USD",
                            amount: item?.amount,
                        };
                    }),
            }),
            ...(saveIndirect?.length >= 1 && { indirectCosts: saveIndirect }),
            ...(!productionId &&
                productionSteps?.length >= 1 && {
                    productionSteps: productionSteps?.map((item) => {
                        return {
                            name: item?.name,
                            ...(item?.description && {
                                description: item?.description,
                            }),
                            ...(item?.plannedStart && {
                                plannedStart: moment(item?.plannedStart)?.toISOString(),
                            }),
                            ...(item?.plannedEnd && {
                                plannedEnd: moment(item?.plannedEnd)?.toISOString(),
                            }),
                            assigneeIds: item?.assigneeIds?.length >= 1 ? item?.assigneeIds : [],
                            ...(item?.qualityChecklist?.length >= 1 && {
                                qualityChecklist: item?.qualityChecklist?.map((item) => {
                                    return {
                                        assigneeId: item?.assigneeId,
                                        task: item?.task,
                                    };
                                }),
                            }),

                            ...(item?.notes && { notes: item?.notes }),
                            attachments: item?.attachments?.length >= 1 ? item?.attachments : [],
                        };
                    }),
                }),

            billOfMaterialIds: bomList?.map((item) => {
                return {
                    inventoryId: item?.inventoryId,
                    quantity: item?.quantity,
                    ...(item?.scrap && { scrap: item?.scrap }),
                    ...(item?.instructions && { instructions: item?.instructions }),
                    ...(item?.stocks?.length >= 1 && {
                        batches: item?.stocks
                            ?.filter?.((stock) => stock?.quantity)
                            ?.map((stocks) => {
                                return {
                                    stockId: stocks?.stockId,
                                    quantity: stocks?.quantity,
                                };
                            }),
                    }),
                };
            }),
            estimatedLeadTime: moment(productData?.startDate).add(
                productData?.leadTime,
                productData?.duration
            ),
        };

        if (productionId) {
            dispatch(productionActions?.updateProductionPlan(body, productionId));
        } else {
            dispatch(productionActions?.createProductionPlan(body));
        }
    };

    const handleStepsSubmission = () => {
        const sortData = qcData?.filter((item) => item?.task && item?.assigneeId);
        if (productionId) {
            const modStep = {
                name: step?.name,
                ...(savedStepId
                    ? {
                          description: step?.description || "",
                      }
                    : step?.description && { description: step?.description }),
                ...(step?.plannedStart && {
                    plannedStart: moment(step?.plannedStart)?.toISOString(),
                }),
                ...(step?.plannedEnd && { plannedEnd: moment(step?.plannedEnd)?.toISOString() }),
                assigneeIds: assignees?.length >= 1 ? assignees : [],
                ...(sortData?.length >= 1 && {
                    qualityChecklist: sortData?.map((item) => {
                        return {
                            assigneeId: item?.assigneeId,
                            task: item?.task,
                        };
                    }),
                }),
                ...(step?.notes && { notes: step?.notes }),
                attachments: attachments?.length >= 1 ? attachments : [],
            };

            if (savedStepId) {
                dispatch(productionActions?.updateProductionPlanSteps(modStep, savedStepId));
            } else if (!duplicateId) {
                const save = {
                    steps: [modStep],
                };
                dispatch(productionActions?.createProductionPlanSteps(save, productionId));
            }
        }
    };

    useEffect(() => {
        if (!productionStepModal) {
            setStepIdx(-1);
            setSavedStepId("");
        }
    }, [productionStepModal]);

    useEffect(() => {
        if (Boolean(createPlanStepsSuccess)) {
            dispatch(productionActions?.resetCreateProductionPlanSteps());
            dispatch(productionActions.fetchProductionPlanTabTwo(productionId, "steps", 10, 1));
            setProductionStepModal(false);
        }
    }, [createPlanStepsSuccess]);

    useEffect(() => {
        if (Boolean(updatePlanStepsSuccess)) {
            dispatch(productionActions.fetchProductionPlanTabTwo(productionId, "steps", 10, 1));
            dispatch(productionActions?.resetUpdateProductionPlanSteps());
            setProductionStepModal(false);
        }
    }, [updatePlanStepsSuccess]);

    useEffect(() => {
        if (Boolean(createProductionPlanSuccess)) {
            setConfirmModal(false);
            setSuccessModal(true);
            dispatch(productionActions?.resetCreateProductionPlan());
            setProductionPlanId(createProductionPlanSuccess?.productionPlan?._id);
        }
    }, [createProductionPlanSuccess]);

    useEffect(() => {
        if (Boolean(updateProductionPlanSuccess)) {
            setConfirmModal(false);
            setSuccessModal(true);
            dispatch(productionActions?.resetUpdateProductionPlan());
            setProductionPlanId(updateProductionPlanSuccess?.productionPlan?._id);
        }
    }, [updateProductionPlanSuccess]);

    useEffect(() => {
        if (!productionStepModal) {
            setStepIdx(-1);
            setAssignees([]);
            setAttachments([]);
            setQcData([{}]);
            setStep({});
        }
    }, [productionStepModal]);

    const ModalTableHeader = [
        {
            title: "Material needed",
            widthClass: "w-[50%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Current Inventory",
            widthClass: "w-[25%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Needed inventory",
            widthClass: "w-[25%] !border !bg-slate-50 !border-slate-200",
        },
    ];

    const ModalTableBody = [1, 1, 1, 1]?.map((product, idx) => {
        return [
            {
                content: (
                    <div className={`w-full  px-4 `}>
                        <div className="flex items-center gap-2 py-2">
                            {!product ? (
                                <VendorAvatar
                                    // imageSrc={
                                    //     product?.productImageDetails?.productAvatar ||
                                    //     product?.productImageDetails?.productImages?.[0]
                                    // }
                                    size={32}
                                    // name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            {/* <span>{truncate(item?.inventory?.productName, { length: 20 })}</span> */}
                            <div className="text-sm">
                                <p className="text-g-75"> Cane Sugar</p>
                                <p className="text-[#64748B]">SKU-11223</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full  px-4`}>
                        <p className="text-[#E4281D] text-sm">500kg</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4`}>
                        <p className="text-g-75 text-sm">1000kg</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    const BOMTableHeader = [
        { title: "Material", widthClass: "pl-4 w-[30%] !font-rocGroteskMedium border-slate-200" },
        { title: "Category", widthClass: "w-[20%] !font-rocGroteskMedium border-slate-200" },
        // { title: "Description", widthClass: "w-[20%] !font-rocGroteskMedium border-slate-200" },
        {
            title: "Curr. stock level",
            widthClass: "w-[20%] !font-rocGroteskMedium border-slate-200",
        },
        { title: "Quantity", widthClass: "w-[20%] !font-rocGroteskMedium border-slate-200" },
        { title: "Plan quantity", widthClass: "w-[20%] !font-rocGroteskMedium border-slate-200" },
        {
            title: "Estimated scrap (%)",
            widthClass: "w-[12%] !font-rocGroteskMedium border-slate-200",
        },
        { title: "Actual quantity", widthClass: "w-[20%] !font-rocGroteskMedium border-slate-200" },
        // { title: "UOM", widthClass: "w-[12%] !font-rocGroteskMedium border-slate-200" },
        { title: "Unit price", widthClass: "w-[20%] !font-rocGroteskMedium border-slate-200" },
        { title: "Total price", widthClass: "w-[20%] !font-rocGroteskMedium border-slate-200" },
        { title: "Action", widthClass: "w-[160px] !font-rocGroteskMedium border-slate-200" },

        { title: "", widthClass: "w-[40px] !font-rocGroteskMedium border-slate-200" },
    ];

    const BOMTableBody = bomList?.map((item, idx) => {
        const calcQuantity = (productData?.quantity || 1) * item?.quantity;

        const totalStockAvailable =
            item?.stocks?.length > 0
                ? item?.stocks?.reduce((sum, stock) => {
                      return sum + (stock?.quantity || 0);
                  }, 0)
                : 0;

        const totalNeeded = item?.scrap
            ? calcTotalQuantity(calcQuantity, item?.scrap)
            : item?.quantity
            ? calcQuantity
            : 0;

        const totalUnitPrice =
            item?.stocks?.length > 0
                ? item?.stocks?.reduce((sum, stock) => {
                      return (
                          sum +
                          (stock?.quantity
                              ? Number(stock?.unitCost?.amount || item?.unitCost?.amount) || 0
                              : 0)
                      );
                  }, 0)
                : 0;

        const totalPrice =
            item?.stocks?.length > 0
                ? item?.stocks?.reduce((sum, stock) => {
                      return (
                          sum +
                          (stock?.quantity
                              ? Number(stock?.unitCost?.amount || item?.unitCost?.amount) || 0
                              : 0) *
                              (stock?.quantity || 0)
                      );
                  }, 0)
                : 0;

        const deficit = Number(totalNeeded) - Number(totalStockAvailable);

        const actualQty = item?.scrap
            ? formatMoney()?.format(calcTotalQuantity(item?.calcPlannedQuantity, item?.scrap))
            : item?.quantity
            ? formatMoney()?.format(item?.calcPlannedQuantity)
            : "";

        const rawActualQty = item?.scrap
            ? calcTotalQuantity(item?.calcPlannedQuantity, item?.scrap)
            : item?.quantity
            ? item?.calcPlannedQuantity
            : 0;

        return [
            {
                content: (
                    <div key={idx} className={`w-full text-sm text-slate-900 `}>
                        <div>
                            <div id={`product-${idx}`} className="relative">
                                <SelectInput
                                    value={item?.productName || item?.inventoryId || ""}
                                    name="inventoryId"
                                    inputPlaceholder={"Select product"}
                                    handleChange={(name, value) => {
                                        if (value) {
                                            setBomList((prevSelectedItems) => {
                                                const updatedItems = [...prevSelectedItems];
                                                const selectedItem = nonFinishedInventory.find(
                                                    (item) => item.inventoryId === value
                                                );

                                                const bomQuantity =
                                                    item?.quantity * (productData?.quantity || 1);
                                                let qty = item?.scrap
                                                    ? Number(
                                                          calcTotalQuantity(
                                                              bomQuantity,
                                                              item?.scrap
                                                          )
                                                      )
                                                    : bomQuantity
                                                    ? Number(bomQuantity)
                                                    : 0;

                                                const updatedStock =
                                                    selectedItem?.stocks?.length > 0
                                                        ? selectedItem?.stocks?.map((stock) => {
                                                              if (
                                                                  qty > 0 &&
                                                                  stock?.productStockDetails
                                                                      ?.stockLevel > 0
                                                              ) {
                                                                  let stockQty =
                                                                      qty -
                                                                          stock?.productStockDetails
                                                                              ?.stockLevel <
                                                                      0
                                                                          ? qty
                                                                          : stock
                                                                                ?.productStockDetails
                                                                                ?.stockLevel;
                                                                  qty = qty - stockQty;
                                                                  return {
                                                                      ...stock,
                                                                      quantity: stockQty,
                                                                  };
                                                              } else {
                                                                  const { quantity, ...rest } =
                                                                      stock;
                                                                  return rest;
                                                              }
                                                          })
                                                        : [];

                                                if (selectedItem) {
                                                    updatedItems[idx] = {
                                                        ...updatedItems[idx],
                                                        inventoryCategory:
                                                            selectedItem?.inventoryCategory,
                                                        unitCost: {
                                                            amount: selectedItem?.unitCost?.amount,
                                                            currency:
                                                                selectedItem?.unitCost?.currency,
                                                        },
                                                        productDescription:
                                                            selectedItem?.productDescription,
                                                        productAvatar:
                                                            selectedItem?.productImageDetails
                                                                ?.productAvatar ||
                                                            selectedItem?.productImageDetails
                                                                ?.productImages?.[0],
                                                        productSKu: selectedItem?.sku,
                                                        productStockDetails: {
                                                            stockLevel:
                                                                selectedItem?.productStockDetails
                                                                    ?.stockLevel,
                                                            unitOfMeasurement:
                                                                selectedItem?.productStockDetails
                                                                    ?.unitOfMeasurement,
                                                        },
                                                        productName: selectedItem?.productName,
                                                        inventoryId: selectedItem?.inventoryId,
                                                        selected: true,
                                                        stocks: updatedStock,
                                                        totalBatches: selectedItem?.totalBatches,
                                                        prefilledQty: item?.quantity || 1,
                                                    };
                                                    setStockList(updatedStock);
                                                    setExpandedRowIdx(idx);
                                                }
                                                return updatedItems;
                                            });
                                        }
                                    }}
                                    handleInputChange={debounce((evt) => {
                                        setDebouncedSearchGoods(evt.target.value);
                                    }, 800)}
                                    isRequired={true}
                                    className="!border-0 !rounded-none !pl-0"
                                    inputClassName=" !pl-0"
                                    inputContainerClassName="!mb-0"
                                    searchLoading={fetchingNonFinishedInventoryStock}
                                    clearValue={true}
                                    dropdownOptions={nonFinishedInventory?.map((inv) => ({
                                        label: (
                                            <div className="text-sm">
                                                <p className="text-g-75">
                                                    {truncate(inv?.productName, { length: 20 })}
                                                </p>
                                                {/* <p className="text-[#64748B]">{inv?.sku}</p> */}
                                            </div>
                                        ),

                                        icon: (
                                            <div className="flex items-center gap-2">
                                                {inv?.productImageDetails?.productAvatar ||
                                                inv?.productImageDetails?.productImages?.[0] ? (
                                                    <VendorAvatar
                                                        imageSrc={
                                                            inv?.productImageDetails
                                                                ?.productAvatar ||
                                                            inv?.productImageDetails
                                                                ?.productImages?.[0]
                                                        }
                                                        size={32}
                                                        name={inv?.productName}
                                                        containerClassname="!rounded-md"
                                                    />
                                                ) : (
                                                    <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                                        <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                                    </div>
                                                )}
                                            </div>
                                        ),
                                        value: inv.inventoryId,
                                    }))}
                                />
                                {(item?.productName || item?.inventoryId) && (
                                    <p
                                        className={`text-[#64748B] text-[13px]  absolute bottom-[-0px]  ${
                                            item?.selected ? "left-10" : "left-2"
                                        }`}
                                    >
                                        {`${item?.totalBatches} ${
                                            item?.totalBatches > 1 ? "batches" : "batch"
                                        }`}
                                    </p>
                                )}
                            </div>

                            <Tooltip
                                anchorSelect={`#product-${idx}`}
                                place={"right"}
                                content={item?.productName}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#000",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#fff",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0 !border-slate-200",
                expandedRowAction: () => {
                    idx === expandedRowIdx ? setExpandedRowIdx(-1) : setExpandedRowIdx(idx);
                    idx !== expandedRowIdx &&
                        setStockList(item?.stocks?.length > 0 ? item?.stocks : []);
                },
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full text-sm text-slate-900 `}>
                        <div>
                            <div id={`product-cateogry-${idx}`}>
                                <TextInput
                                    value={
                                        item?.inventoryCategory
                                            ? item?.inventoryCategory
                                            : item?.inventoryId && !item?.inventoryCategory
                                            ? "N/A"
                                            : ""
                                    }
                                    name="value"
                                    type={"text"}
                                    disabled={true}
                                    inputPlaceholder="Category"
                                    inputContainerClassname={
                                        "!border-none !rounded-none !bg-transparent"
                                    }
                                    inputClassName="w-full "
                                />
                            </div>

                            <Tooltip
                                anchorSelect={`#product-cateogry-${idx}`}
                                place={"right"}
                                content={item?.inventoryCategory || ""}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#000",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#fff",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            // {
            //     content: (
            //         <div
            //             className={`w-full  text-sm text-slate-900 flex items-center`}
            //         >
            //             <div>
            //                 <div id={`product-description-${idx}`}>
            //                     <TextInput
            //                         value={
            //                             item?.productDescription
            //                                 ? truncate(item?.productDescription, { length: 15 })
            //                                : item?.inventoryId && !item?.productDescription
            //                                ? "N/A"
            //                                : ""
            //                         }

            //                         name="productDescription"
            //                         type={"text"}
            //                         disabled={true}
            //                         inputPlaceholder="Description"
            //                         inputContainerClassname={
            //                             "!border-none !rounded-none !bg-transparent"
            //                         }
            //                         inputClassName="w-full "
            //                     />
            //                 </div>

            //                 <Tooltip
            //                     anchorSelect={`#product-description-${idx}`}
            //                     place={"right"}
            //                     content={
            //                         item?.productDescription
            //                             ? truncate(item?.productDescription, { length: 40 })
            //                             : ""
            //                     }
            //                     style={{
            //                         maxWidth: "250px",
            //                         zIndex: 9999,
            //                         background: "#000",
            //                         borderRadius: "6px",
            //                         border: "1px solid #E2E8F0",
            //                         color: "#fff",
            //                         boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
            //                     }}
            //                 />
            //             </div>
            //         </div>
            //     ),
            //     tableDataDivClass: "!pl-0",
            //     widthClass: "!border-slate-200",
            // },
            {
                content: (
                    <div
                        className={`w-full text-[13px] text-slate-900 !font-rocGroteskRegular px-4`}
                    >
                        <p className="">
                            {item?.productStockDetails?.stockLevel !== undefined
                                ? `${formatMoney()?.format(
                                      item?.productStockDetails?.stockLevel
                                  )} ${
                                      item?.productStockDetails?.unitOfMeasurement
                                          ? item?.productStockDetails?.unitOfMeasurement
                                          : ""
                                  }`
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full  text-sm text-slate-900 flex items-center justify-center`}
                    >
                        <div className="p-3">
                            <TextInput
                                value={item?.quantity || ""}
                                name={"quantity"}
                                type="number"
                                onChange={(e) => {
                                    handleBOMData(e, idx);
                                }}
                                inputPlaceholder="Quantity"
                                required={true}
                                containerClassname=""
                                inputContainerClassname={"!h-[36px] !border-g-75"}
                                inputClassName={"!border-g-75 !h-[36px]"}
                                autoCompleteOff={true}
                                onWheel={(e: any) => e.target.blur()}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                                rightIconClass="!right-[12px] bg-white z-2"
                                rightIcon={
                                    (item?.batchesStockIsNotEnough || item?.totalBatches === 0) &&
                                    item?.inventoryId &&
                                    item?.quantity ? (
                                        <i
                                            className="ri-information-line text-g-75 "
                                            id={`input-${idx}`}
                                        ></i>
                                    ) : (
                                        ""
                                    )
                                }
                            />

                            <div>
                                <Tooltip
                                    anchorSelect={`#input-${idx}`}
                                    place={"top"}
                                    content="Quantity needed is less than stock level available for this production run"
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        background: "#000",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#fff",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full  text-sm text-slate-500 px-2.5`}>
                        <p className="!font-rocGroteskRegular text-gm-50">
                            {item?.calcPlannedQuantity !== undefined
                                ? formatMoney()?.format(item?.calcPlannedQuantity)
                                : ""}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full  text-sm text-slate-900 `}>
                        <TextInput
                            value={item?.scrap || ""}
                            name="scrap"
                            type="number"
                            onChange={(e) => handleBOMData(e, idx)}
                            inputPlaceholder="Scrap"
                            containerClassname=""
                            inputClassName="w-full"
                            inputContainerClassname={"!border-0 !rounded-none "}
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full  text-sm text-slate-500 px-2.5`}>
                        <p
                            className={`!font-rocGroteskRegular ${
                                Number(rawActualQty) > Number(item?.productStockDetails?.stockLevel)
                                    ? "text-r-50"
                                    : "text-gm-50"
                            }`}
                        >
                            {actualQty}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            // {
            //     content: (
            //         <div className={`w-full  text-sm text-slate-900`}>
            //             <div>
            //                 <div id={`product-uom-${idx}`}>
            //                     <TextInput
            //                          value={item?.productStockDetails
            //                                       ?.unitOfMeasurement
            //                                 || ""
            //                         }
            //                         name="unitOfMeasurement"
            //                         type="text"
            //                         disabled={true}
            //                         inputPlaceholder="UOM"
            //                         inputContainerClassname={
            //                             "!border-none !rounded-none !bg-transparent !px-0"
            //                         }
            //                         inputClassName="w-full "
            //                     />
            //                 </div>

            //                 <Tooltip
            //                     anchorSelect={`#product-uom-${idx}`}
            //                     place={"right"}
            //                     content={item?.productStockDetails?.unitOfMeasurement
            //                             ? item?.productStockDetails?.unitOfMeasurement
            //                             : ""
            //                     }
            //                     style={{
            //                         maxWidth: "250px",
            //                         zIndex: 9999,
            //                         background: "#000",
            //                         borderRadius: "6px",
            //                         border: "1px solid #E2E8F0",
            //                         color: "#fff",
            //                         boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
            //                     }}
            //                 />
            //             </div>
            //         </div>
            //     ),
            //     tableDataDivClass: "!pl-0",
            //     widthClass: "!border-slate-200",
            // },
            {
                content: (
                    <div className={`w-full text-sm text-slate-900 px-2.5 flex items-center`}>
                        <p className="text-slate-500">
                            {getCurrencyFromCurrencyCode(item?.unitCost?.currency)}
                        </p>

                        <div className="!font-rocGroteskRegular text-slate-900">
                            <p className="">
                                {totalUnitPrice !== undefined
                                    ? formatMoney()?.format(totalUnitPrice)
                                    : "0"}
                            </p>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full text-sm text-slate-900 px-2.5 flex items-center`}>
                        <p className="text-slate-500">
                            {getCurrencyFromCurrencyCode(item?.unitCost?.currency)}
                        </p>

                        <div className="!font-rocGroteskRegular text-slate-900">
                            <p className="">
                                {totalPrice !== undefined ? formatMoney()?.format(totalPrice) : "0"}
                            </p>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`pr-3 `}>
                        {(item?.batchesStockIsNotEnough || item?.totalBatches === 0) &&
                        item?.inventoryId &&
                        item?.quantity ? (
                            <Button
                                btnText="Create PO"
                                btnClassname="!px-4 !bg-transparent !py-2 !text-g-60 !w-fit text-[13px] !border !border-slate-200 !shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
                                onClick={() =>
                                    navigate(
                                        `/dashboard/purchase/create-purchase-request?inventoryId=${
                                            item?.inventoryId
                                        }&productName=${item?.productName}&qty=${Math.ceil(
                                            deficit
                                        )}&productionPO="true"`
                                    )
                                }
                                icon=<i className="ri-file-list-3-line"></i>
                            />
                        ) : (
                            ""
                        )}
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },
            {
                content: (
                    <div
                        className={`w-full text-sm text-slate-900 px-2.5 flex items-center`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <i
                            className={`ri-delete-bin-line text-slate-500 text-base ${
                                bomList?.length > 1 && "cursor-pointer"
                            } 
                        `}
                            onClick={() => {
                                bomList?.length > 1 && handleBOMDelete(idx);
                            }}
                        ></i>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200 !border-b",
            },
        ];
    });

    const tableHeader = [
        { title: "", widthClass: "w-[50%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[12%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[160px] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[40px] !font-rocGroteskMedium" },
    ];

    const expandedTableBody = stockList?.map((stock, idx) => {
        return [
            {
                content: (
                    <div
                        className={`py-2 px-3 flex font-rocGroteskMedium items-center h-full text-sm `}
                    >
                        <div className="flex items-center space-x-3">
                            {stock?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        stock?.productImageDetails?.productAvatar ||
                                        stock?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={stock?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-4 h-4 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-sm text-slate-500"></i>
                                </div>
                            )}
                            <span className="text-[#B18000]">
                                {truncate(bomList[expandedRowIdx]?.productName, {
                                    length: 40,
                                })}
                                - {stock?.batchId}
                            </span>
                        </div>
                    </div>
                ),

                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200 !border-b",
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                        <p className="">
                            {" "}
                            {stock?.productStockDetails?.stockLevel !== undefined
                                ? `${formatMoney()?.format(
                                      stock?.productStockDetails?.stockLevel
                                  )} ${
                                      bomList[expandedRowIdx]?.productStockDetails
                                          ?.unitOfMeasurement
                                  }`
                                : ""}
                        </p>
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b",
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                        {stock?.quantity !== undefined
                            ? `${formatMoney()?.format(stock?.quantity)}`
                            : ""}
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b",
            },

            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                        <p className=""></p>
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                        <p className=""></p>
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                        <p className=""></p>
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                        <p className="">
                            {stock?.unitCost?.currency !== undefined
                                ? `${getCurrencyFromCurrencyCode(
                                      stock?.unitCost?.currency
                                  )} ${formatMoney()?.format(
                                      stock?.unitCost?.amount || bomList[idx]?.unitCost?.amount
                                  )}`
                                : ""}
                        </p>
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}>
                        <p className="">
                            {stock?.unitCost?.currency !== undefined &&
                            stock?.quantity !== undefined
                                ? `${getCurrencyFromCurrencyCode(
                                      String(stock?.unitCost?.currency)
                                  )} ${formatMoney()?.format(
                                      Number(
                                          stock?.unitCost?.amount || bomList[idx]?.unitCost?.amount
                                      ) * Number(stock?.quantity)
                                  )}`
                                : ""}
                        </p>
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },

            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}
                    ></div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium text-sm text-[#B18000] `}
                    ></div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },
        ];
    });

    const DirectCostTableHeader = [
        { title: "Category", widthClass: "w-[45%] !font-rocGroteskMedium !border-slate-200" },

        {
            title: "Total Estimated Cost",
            widthClass: "w-[45%] !font-rocGroteskMedium !border-slate-200",
        },
        {
            title: "",
            widthClass: "w-[40px] !font-rocGroteskMedium !border-slate-200",
        },
    ];

    const DirectCostTableBody = directCost?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full border-slate-200 text-sm text-slate-500 flex items-center  font-rocGroteskMedium `}
                    >
                        <TextInput
                            value={item?.category ?? ""}
                            name={"category"}
                            type="text"
                            required={item?.amount && true}
                            onChange={(e) => handleDirectCostData(e, idx)}
                            inputPlaceholder="Enter category"
                            containerClassname=""
                            inputClassName="w-full"
                            inputContainerClassname={"!border-0 !rounded-none "}
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-[13px] text-slate-500 flex items-center font-rocGroteskMedium `}
                    >
                        <TextInput
                            value={item?.amount ?? ""}
                            name={"amount"}
                            type="number"
                            required={item?.category && true}
                            onChange={(e) => handleDirectCostData(e, idx)}
                            inputPlaceholder="0.00"
                            containerClassname=""
                            inputClassName="w-full"
                            inputContainerClassname={"!border-0 !rounded-none "}
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-slate-500 px-2.5 flex items-center justify-center`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <i
                            className={`ri-delete-bin-line text-slate-500 text-base ${
                                directCost?.length > 1 && "cursor-pointer"
                            } 
                        `}
                            onClick={() => {
                                directCost?.length > 1 && handleDirectCostDelete(idx);
                            }}
                        ></i>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
        ];
    });

    const DirectCostTableFooter = () => {
        return (
            <div className="flex rounded-b-md border-x border-b border-slate-200 bg-[#F8FAFC]">
                <div className=" border-slate-200 w-[45%]"></div>
                {/* <div className="w-[33%]"></div> */}
                <div className="w-[45%] border-l border-slate-200">
                    <p className="text-g-75 text-md py-[14px] px-2.5">
                        ${formatMoney().format(directCostSum)}
                    </p>
                </div>
                <div className=" border-slate-200  border-l w-[40px]"></div>
            </div>
        );
    };

    const IndirectCostTableHeader = [
        { title: "Category", widthClass: "w-[45%] !font-rocGroteskMedium !border-slate-200" },

        {
            title: "Total Estimated Cost",
            widthClass: "w-[45%] !font-rocGroteskMedium !border-slate-200",
        },
        {
            title: "",
            widthClass: "w-[40px] !font-rocGroteskMedium !border-slate-200",
        },
    ];

    const IndirectCostTableBody = indirectCost?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full border-slate-200 text-sm text-slate-500 flex items-center font-rocGroteskMedium `}
                    >
                        <TextInput
                            value={item?.category ?? ""}
                            name={"category"}
                            type="text"
                            required={item?.amount && true}
                            onChange={(e) => handleIndirectCostData(e, idx)}
                            inputPlaceholder="Enter category"
                            inputClassName="w-full"
                            inputContainerClassname={"!border-0 !rounded-none "}
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-[13px] text-slate-500 flex items-center font-rocGroteskMedium `}
                    >
                        <TextInput
                            value={item?.amount ?? ""}
                            name={"amount"}
                            type="number"
                            required={item?.category && true}
                            onChange={(e) => handleIndirectCostData(e, idx)}
                            inputPlaceholder="0.00"
                            containerClassname=""
                            inputClassName="w-full !placeholder:text-slate-500"
                            inputContainerClassname={"!border-0 !rounded-none "}
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-slate-500 px-2.5 flex items-center justify-center`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <i
                            className={`ri-delete-bin-line text-slate-500 text-base cursor-pointer`}
                            onClick={() => {
                                handleIndirectCostDelete(idx);
                            }}
                        ></i>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
        ];
    });
    const IndirectCostTableFooter = () => {
        return (
            <div className="flex rounded-b-md border-x border-b border-slate-200 bg-[#F8FAFC]">
                <div className=" border-slate-200 w-[45%]"></div>
                {/* <div className="w-[33%]"></div> */}
                <div className="w-[45%]  border-l border-slate-200">
                    <p className="text-g-75 text-md py-[14px] px-2.5">
                        ${formatMoney().format(indirectCostSum)}
                    </p>
                </div>
                <div className=" border-slate-200  border-l w-[40px]"></div>
            </div>
        );
    };

    return (
        <div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    if (
                        !validatingBatchId &&
                        !warning &&
                        (duplicateId ? planDetails?.batchId !== productData?.lotNumber : true)
                    ) {
                        setConfirmModal(true);
                    }
                }}
                className="bg-white rounded-lg border border-n-20 font-rocGroteskMedium pb-12"
            >
                <div className="sticky top-[-20px] bg-white z-[1200]">
                    <div className="flex items-center justify-between px-8 py-[15px] border-b border-n-20 ">
                        <div className="flex items-center space-x-1.5">
                            <i
                                onClick={() => navigate(`/dashboard/production?planTab=1`)}
                                className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                            ></i>
                            <span className="text-lg font-rocGroteskMedium">
                                {productionId
                                    ? "Edit production plan"
                                    : "Create a new production plan"}
                            </span>
                        </div>
                        <div className="flex items-center space-x-3">
                            <Button
                                btnText="Cancel"
                                btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                                disabled={creatingProductionPlan || updatingProductionPlan}
                                onClick={() => navigate(`/dashboard/production?planTab=1`)}
                            />
                            <Button
                                btnText={productionId ? "Save changes" : "Create production plan"}
                                type="submit"
                                isLoading={creatingProductionPlan || updatingProductionPlan}
                                disabled={creatingProductionPlan || updatingProductionPlan}
                                btnClassname="!px-4 !py-2.5 !w-fit"
                            />
                        </div>
                    </div>
                    {warning && (
                        <div
                            className={`h-[36px] bg-[rgba(228,40,29,0.05)] flex items-center justify-center gap-2 w-full text-[#E4281D] text-sm transition-opacity`}
                        >
                            <i className="ri-alarm-warning-fill text-[#E4281D]"></i>
                            Duplicate LOT Number Detected: The LOT number you entered already exists
                            in Synth. Please enter a different LOT number to proceed.
                        </div>
                    )}
                    {!warning && duplicateId && planDetails?.batchId === productData?.lotNumber && (
                        <div
                            className={`h-[36px] bg-[rgba(228,40,29,0.05)] flex items-center justify-center gap-2 w-full text-[#E4281D] text-sm transition-opacity`}
                        >
                            <i className="ri-alarm-warning-fill text-[#E4281D]"></i>
                            Edit Lot number to save as a new production plan
                        </div>
                    )}
                </div>

                <div className="px-8">
                    <div className="py-6 ">
                        <div className="grid grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1 items-center gap-4">
                            <SelectInput
                                value={category}
                                name="category"
                                placeholder={"Category"}
                                handleChange={(name, value) => {
                                    setCategory(value);
                                    setExpandedRowIdx(-1);
                                    setBomList([{}]);
                                }}
                                clearValue={true}
                                isRequired={true}
                                dropdownOptions={[
                                    {
                                        label: "Finished Goods",
                                        value: "Finished Goods",
                                    },
                                    {
                                        label: "Work-In-Progress (WIP)",
                                        value: "Work-In-Progress (WIP)",
                                    },
                                ]}
                                disabled={productionId && true}
                            />

                            <div>
                                <div id="product-name">
                                    <SelectInput
                                        value={productData?.inventoryId || ""}
                                        name="inventoryId"
                                        placeholder={"Product"}
                                        handleChange={(name, value) => {
                                            handleProductData({ target: { name, value } });
                                            if (value) {
                                                const selectedProduct = finishedProduct?.find(
                                                    (item) => item?._id === value
                                                );
                                                setProductName(selectedProduct?.productName);
                                                setProductData((prev) => ({
                                                    ...prev,
                                                    unitOfMeasurement:
                                                        selectedProduct?.productStockDetails
                                                            ?.unitOfMeasurement,
                                                }));
                                                setStockList([]);
                                                setExpandedRowIdx(-1);
                                                setBomList([{}]);
                                            }
                                        }}
                                        handleInputChange={debounce((evt) => {
                                            setDebouncedSearchInventory(evt.target.value);
                                        }, 800)}
                                        searchLoading={fetchingFinishedInventory}
                                        clearValue={true}
                                        isRequired={true}
                                        dropdownOptions={finishedProduct?.map((product) => ({
                                            label: product.productName,
                                            value: product._id,
                                            icon: (
                                                <div className="flex items-center gap-2">
                                                    {product?.productImageDetails?.productAvatar ||
                                                    product?.productImageDetails
                                                        ?.productImages?.[0] ? (
                                                        <VendorAvatar
                                                            imageSrc={
                                                                product?.productImageDetails
                                                                    ?.productAvatar ||
                                                                product?.productImageDetails
                                                                    ?.productImages?.[0]
                                                            }
                                                            size={24}
                                                            name={product?.productName}
                                                            containerClassname="!rounded-md"
                                                        />
                                                    ) : (
                                                        <div className="w-6 h-6 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                                            <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                                        </div>
                                                    )}
                                                </div>
                                            ),
                                        }))}
                                        disabled={productionId && true}
                                    />
                                </div>
                                <Tooltip
                                    anchorSelect={"#product-name"}
                                    place={"top"}
                                    content={
                                        productName ||
                                        "The item being manufactured in this production run."
                                    }
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#fff",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>

                            <div className="">
                                <div>
                                    <div id="uom">
                                        <TextInput
                                            value={productData.unitOfMeasurement || ""}
                                            name={"unitOfMeasurement"}
                                            type="text"
                                            onChange={(e) => handleProductData(e)}
                                            placeholder={"UOM"}
                                            required={true}
                                            containerClassname=""
                                            disabled
                                        />
                                    </div>
                                    <Tooltip
                                        anchorSelect={"#uom"}
                                        place={"top"}
                                        content="The standardized unit used to track quantities (e.g., kg, liters, pieces)"
                                        style={{
                                            maxWidth: "250px",
                                            zIndex: 9999,
                                            borderRadius: "6px",
                                            border: "1px solid #E2E8F0",
                                            color: "#fff",
                                            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div id="quantity">
                                    <TextInput
                                        value={productData.quantity}
                                        name={"quantity"}
                                        type="number"
                                        onChange={(e) => handleProductData(e)}
                                        placeholder={"Quantity"}
                                        required={true}
                                        containerClassname=""
                                        onWheel={(e: any) => e.target.blur()}
                                        onKeyDown={(evt) =>
                                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                                evt.key
                                            ) && evt.preventDefault()
                                        }
                                    />
                                </div>
                                <Tooltip
                                    anchorSelect={"#quantity"}
                                    place={"top"}
                                    content="The total number of units to be produced."
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#fff",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>

                            <div>
                                <div id="lotNumber">
                                    <TextInput
                                        value={productData.lotNumber}
                                        name={"lotNumber"}
                                        type="text"
                                        onChange={(e) => handleProductData(e)}
                                        onInput={(e) => handleDebouncedBatchIdCheck(e)}
                                        placeholder={"Lot Number"}
                                        required={true}
                                        disabled={productionId && !duplicate ? true : false}
                                        autoCompleteOff={true}
                                        inputContainerClassname={`${
                                            (warning ||
                                                (duplicateId &&
                                                    planDetails?.batchId ===
                                                        productData?.lotNumber)) &&
                                            "!border !border-[#E4281D] rounded"
                                        }`}
                                        inputClassName={`${
                                            (warning ||
                                                (duplicateId &&
                                                    planDetails?.batchId ===
                                                        productData?.lotNumber)) &&
                                            "!text-[#E4281D]"
                                        }`}
                                    />
                                </div>
                                <Tooltip
                                    anchorSelect={"#lotNumber"}
                                    place={"top"}
                                    content=" A unique identifier for tracking batches of products."
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        // background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#fff",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>

                            <div>
                                <div id="leadTime">
                                    <div className="gap-4 flex w-full">
                                        <div className="w-[70%]">
                                            <TextInput
                                                value={productData.leadTime}
                                                name={"leadTime"}
                                                type="text"
                                                onChange={(e) => handleProductData(e)}
                                                placeholder={"Production Lead Time"}
                                                required={true}
                                                containerClassname=""
                                            />
                                        </div>
                                        <div className="w-[30%]">
                                            <SelectInput
                                                value={productData.duration}
                                                name="duration"
                                                placeholder={"Duration"}
                                                handleChange={(name, value) => {
                                                    handleProductData({ target: { name, value } });
                                                }}
                                                isRequired={true}
                                                dropdownOptions={[
                                                    {
                                                        label: "Days",
                                                        value: "days",
                                                    },
                                                    {
                                                        label: "Weeks",
                                                        value: "weeks",
                                                    },
                                                    {
                                                        label: "Months",
                                                        value: "months",
                                                    },
                                                ]}
                                                dropdownClassName="!pb-0"
                                                rightDropdownContainerClassName="!sticky bottom-0"
                                            />
                                        </div>
                                    </div>
                                    <Tooltip
                                        anchorSelect={"#leadTime"}
                                        place={"top"}
                                        content="The total time required to complete a production cycle."
                                        style={{
                                            maxWidth: "250px",
                                            zIndex: 9999,
                                            // background: "#fff",
                                            borderRadius: "6px",
                                            border: "1px solid #E2E8F0",
                                            color: "#fff",
                                            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                        }}
                                    />
                                </div>
                            </div>

                            <div>
                                <div id="startDate">
                                    <TextInput
                                        value={productData.startDate}
                                        name="startDate"
                                        type="date"
                                        onChange={(e) => {
                                            handleProductData(e);
                                        }}
                                        placeholder={"Start Date"}
                                        required={true}
                                        min={
                                            !productionId && new Date().toISOString().split("T")[0]
                                        }
                                    />
                                </div>
                                <Tooltip
                                    anchorSelect={"#startDate"}
                                    place={"top"}
                                    content="The scheduled date production begins"
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        // background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#fff",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>

                            <div>
                                <div id="endDate">
                                    <TextInput
                                        value={productData.endDate}
                                        name="endDate"
                                        type="date"
                                        onChange={(e) => handleProductData(e)}
                                        placeholder={"End Date"}
                                        required={true}
                                        min={
                                            !productionId && new Date().toISOString().split("T")[0]
                                        }
                                        disabled
                                    />
                                </div>
                                <Tooltip
                                    anchorSelect={"#endDate"}
                                    place={"top"}
                                    content="The planned date production is completed."
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        // background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#fff",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-4 pt-6">
                            <div>
                                <div id="dueDate">
                                    <TextInput
                                        value={productData.dueDate}
                                        name="dueDate"
                                        type="date"
                                        onChange={(e) => handleProductData(e)}
                                        placeholder={"Due Date"}
                                        required={true}
                                        min={
                                            !productionId && productData.endDate
                                                ? new Date(productData.endDate)
                                                      .toISOString()
                                                      .split("T")[0]
                                                : !productionId &&
                                                  new Date().toISOString().split("T")[0]
                                        }
                                    />
                                </div>
                                <Tooltip
                                    anchorSelect={"#dueDate"}
                                    place={"top"}
                                    content="The deadline for completing the production process."
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        // background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#fff",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>

                            <div>
                                <div id="manager">
                                    <SelectInput
                                        value={productData.managerId}
                                        name="managerId"
                                        placeholder={"Production Manager"}
                                        handleChange={(name, value) => {
                                            handleProductData({ target: { name, value } });
                                        }}
                                        handleInputChange={debounce((evt) => {
                                            setDebouncedSearchTeamMember(evt.target.value);
                                        }, 800)}
                                        isRequired={true}
                                        searchLoading={fetchingTeamMember}
                                        dropdownOptions={teamMembers?.map((member) => ({
                                            label: member?.fullName
                                                ? member?.fullName
                                                : member?.email,
                                            value: member?._id,
                                        }))}
                                        // customRightDropdownContainer={}
                                        customDropdownContainer={
                                            <div className="py-2 px-3  flex items-center bg-white border-t-slate-200 border-t !h-[48px] sticky w-full bottom-0">
                                                <div
                                                    className="flex items-center gap-3 w-fit cursor-pointer"
                                                    onClick={() => setAddNewMember(true)}
                                                >
                                                    <div className="w-8 h-8 bg-[#FFF5DA] rounded-full flex items-center justify-center">
                                                        <i className="ri-add-fill text-[#FFB902]"></i>
                                                    </div>

                                                    <span className="text-sm font-rocGroteskMedium text-[#D39901] underline">
                                                        Add new
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                                <Tooltip
                                    anchorSelect={"#manager"}
                                    place={"top"}
                                    content="The person overseeing the entire production process."
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        // background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#fff",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>

                            <div>
                                <div id="qualityControl">
                                    <SelectInput
                                        value={productData.qualityControl}
                                        name="qualityControlId"
                                        placeholder={"Quality control officer"}
                                        handleChange={(name, value) => {
                                            handleProductData({ target: { name, value } });
                                        }}
                                        handleInputChange={debounce((evt) => {
                                            setDebouncedSearchTeamMember(evt.target.value);
                                        }, 800)}
                                        searchLoading={fetchingTeamMember}
                                        dropdownOptions={teamMembers?.map((member) => ({
                                            label: member?.fullName
                                                ? member?.fullName
                                                : member?.email,
                                            value: member?._id,
                                        }))}
                                        // clearValue={true}
                                        customDropdownContainer={
                                            <div className="py-2 px-3  flex items-center bg-white border-t-slate-200 border-t !h-[48px] sticky w-full bottom-0">
                                                <div
                                                    className="flex items-center gap-3 w-fit cursor-pointer"
                                                    onClick={() => setAddNewMember(true)}
                                                >
                                                    <div className="w-8 h-8 bg-[#FFF5DA] rounded-full flex items-center justify-center">
                                                        <i className="ri-add-fill text-[#FFB902]"></i>
                                                    </div>

                                                    <span className="text-sm font-rocGroteskMedium text-[#D39901] underline">
                                                        Add new
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                                <Tooltip
                                    anchorSelect={"#qualityControl"}
                                    place={"top"}
                                    content="The individual ensuring all products meet required standards"
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        // background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#fff",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <TextareaInput
                                name={"notes"}
                                value={productData?.notes || ""}
                                onChange={handleProductData}
                                rows={5}
                                placeholder={"Additional info"}
                                required={false}
                            />
                        </div>
                    </div>
                    <div className="mt-12  ">
                        <div className=" p-4 border-x border-t  border-slate-200 rounded-t-md">
                            <div className="flex items-center gap-1 ">
                                <p className="text-gm-75 text-base ">
                                    Bill of materials (BOM) {productName && `for ${productName}`}
                                </p>

                                <div>
                                    <a id="bom">
                                        <i className="ri-information-fill text-slate-500 text-base"></i>
                                    </a>

                                    <Tooltip
                                        anchorSelect="#bom"
                                        place={"top"}
                                        content="A comprehensive list of raw materials, components, and quantities required for production."
                                        style={{ width: "250px", zIndex: 9999 }}
                                    />
                                </div>
                            </div>

                            <p className="text-slate-500 text-[13px] ">
                                Synth automatically selects batches using FIFO to determine the
                                quantities for this production plan.
                            </p>
                        </div>

                        <div>
                            <CustomTable
                                tableBody={BOMTableBody}
                                tableHeader={BOMTableHeader}
                                isScrollable={false}
                                isCellBordered={true}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                scrollWithin={false}
                                headerContainerClass="!bg-slate-50 !border-slate-200"
                                headerItemClass="font-rocGroteskMedium !text-[#334155]"
                                tableClass="!border-slate-200"
                                isCollapseable={true}
                                allCellClick={false}
                                expandedRowIdx={expandedRowIdx}
                                bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                                rowExpanded={
                                    <div className=" bg-[#FFFBF0]">
                                        {stockList?.length === 0 ? (
                                            <div className="overflow-x-scroll">
                                                <p className="py-2 px-4 text-sm text-slate-900">
                                                    No batch available
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="overflow-x-scroll">
                                                <CustomTable
                                                    tableBody={expandedTableBody}
                                                    tableHeader={tableHeader}
                                                    scrollWithin={false}
                                                    isCellBordered={false}
                                                    isInifiniteScroll={true}
                                                    tableClass="!bg-[#FFFBF0] !border-t-slate-200 !border-b-0 !border-x-0 !rounded-none mt-[-30px]"
                                                    isScrollable={true}
                                                    tableContainerClass=""
                                                    bodyItemClass="hover:!bg-[#FFFBF0]  !bg-[#FFFBF0]  !cursor-default !border-slate-200"
                                                    expandedRowTClass="!z-[0] !relative"
                                                />
                                            </div>
                                        )}
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div className="flex items-center gap-3 mt-6">
                        <Button
                            btnText="Add material from inventory"
                            btnClassname="!text-[13px] !py-2 !px-3 !bg-n-20 !w-fit text-gm-50 !rounded-[4px] "
                            onClick={handleAddMoreBom}
                        />
                    </div>
                    <div className="mt-12  ">
                        <div className="flex items-center gap-1">
                            <p className="text-gm-75 text-base py-4">
                                Cost breakdown {productName && `for ${productName}`}
                            </p>
                            <div>
                                <a id="breakdown">
                                    <i className="ri-information-fill text-slate-500 text-base"></i>
                                </a>

                                <Tooltip
                                    anchorSelect="#breakdown"
                                    place={"top"}
                                    content="A detailed division of costs across different components of production."
                                    style={{ width: "250px", zIndex: 9999 }}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-4 gap-2">
                            <div className="border border-slate-200 p-4 rounded-md">
                                <div className="flex items-center gap-1">
                                    <p className="text-slate-500 text-sm">Direct cost</p>

                                    <div>
                                        <a id="direct-cost">
                                            <i className="ri-information-fill text-slate-500 text-base"></i>
                                        </a>

                                        <Tooltip
                                            anchorSelect="#direct-cost"
                                            place={"top"}
                                            content="Costs directly attributable to production, such as raw materials and labor"
                                            style={{ width: "250px", zIndex: 9999 }}
                                        />
                                    </div>
                                </div>
                                <div className={` text-lg text-g-75 py-2 flex items-center`}>
                                    <p className="text-g-75">
                                        ${formatMoney().format(directCostSum + bomCost)}
                                    </p>
                                </div>
                            </div>
                            <div className="border border-slate-200 p-4 rounded-md">
                                <div className="flex items-center gap-1">
                                    <p className="text-slate-500 text-sm">Indirect cost</p>

                                    <div>
                                        <a id="indirect-cost">
                                            <i className="ri-information-fill text-slate-500 text-base"></i>
                                        </a>

                                        <Tooltip
                                            anchorSelect="#indirect-cost"
                                            place={"top"}
                                            content="Overhead expenses not directly tied to production, like utilities and rent"
                                            style={{ width: "250px", zIndex: 9999 }}
                                        />
                                    </div>
                                </div>
                                <div className={` text-lg text-g-75 py-2 flex items-center`}>
                                    <p className="">${formatMoney().format(indirectCostSum)}</p>
                                </div>
                            </div>{" "}
                            <div className="border border-slate-200 p-4 rounded-md">
                                <div className="flex items-center gap-1">
                                    <p className="text-slate-500 text-sm">Total production cost</p>

                                    <div>
                                        <a id="production-cost">
                                            <i className="ri-information-fill text-slate-500 text-base"></i>
                                        </a>

                                        <Tooltip
                                            anchorSelect="#production-cost"
                                            place={"top"}
                                            content="The total expense incurred to manufacture products, including materials, labor, and overhead"
                                            style={{ width: "250px", zIndex: 9999 }}
                                        />
                                    </div>
                                </div>
                                <div className={` text-lg text-g-75 py-2 flex items-center`}>
                                    <p className="">
                                        $
                                        {formatMoney().format(
                                            indirectCostSum + directCostSum + bomCost
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-8  ">
                        <div className="grid grid-cols-2 gap-6">
                            <div className="">
                                <p className="text-gm-75 text-base p-4 border-x border-t  border-slate-200 rounded-t-md">
                                    Direct costs
                                </p>
                                <CustomTable
                                    tableBody={DirectCostTableBody}
                                    tableHeader={DirectCostTableHeader}
                                    isScrollable={false}
                                    isCellBordered={true}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    scrollWithin={false}
                                    headerContainerClass="!bg-slate-50 !border-slate-200 "
                                    headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                    tableClass="!border-slate-200"
                                    bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                                />
                                <DirectCostTableFooter />
                                <Button
                                    btnText="Add more direct cost"
                                    btnClassname=" mt-6 !text-[13px] !py-2 !px-3 !bg-n-20 !w-fit text-gm-50 !rounded-[4px] "
                                    onClick={() => {
                                        setDirectCost((prev) => [...prev, {}]);
                                    }}
                                />
                            </div>

                            <div className="">
                                <p className="text-gm-75 text-base p-4 border-x border-t  border-slate-200 rounded-t-md">
                                    Indirect costs
                                </p>
                                <CustomTable
                                    tableBody={IndirectCostTableBody}
                                    tableHeader={IndirectCostTableHeader}
                                    isScrollable={false}
                                    isCellBordered={true}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    scrollWithin={false}
                                    headerContainerClass="!bg-slate-50 !border-slate-200 "
                                    headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                    tableClass="!border-slate-200"
                                    bodyItemClass="hover:!bg-transparent"
                                />
                                <IndirectCostTableFooter />
                                <Button
                                    btnText="Add more indirect cost"
                                    btnClassname=" mt-6 !text-[13px] !py-2 !px-3 !bg-n-20 !w-fit text-gm-50 !rounded-[4px] "
                                    onClick={() => setIndirectCost((prev) => [...prev, {}])}
                                />
                            </div>
                        </div>
                    </div>

                    {!stepsLoading ? (
                        <div className="mt-12  ">
                            <div className="flex items-center justify-between gap-2">
                                <div className="flex items-center gap-1">
                                    <p className="text-gm-75 text-base py-4">
                                        Production process {productName && `for ${productName}`}
                                    </p>
                                    <div>
                                        <a id="process">
                                            <i className="ri-information-fill text-slate-500 text-base"></i>
                                        </a>

                                        <Tooltip
                                            anchorSelect="#process"
                                            place={"top"}
                                            content="The timeline of all production activities and milestones."
                                            style={{ width: "250px", zIndex: 9999 }}
                                        />
                                    </div>
                                </div>

                                {productionSteps?.length >= 1 && (
                                    <div className="flex gap-2.5">
                                        <Button
                                            btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px]"
                                            btnText={"Add production step"}
                                            onClick={() => {
                                                setProductionStepModal(true);
                                                const copyArr = cloneDeep(productionSteps);
                                                copyArr[productionSteps?.length] = {};
                                                setProductionStepsModalData(copyArr);
                                                copyArr?.length >= 1
                                                    ? setViewStep(copyArr?.length - 1)
                                                    : setViewStep(0);
                                            }}
                                        />
                                        {/* <Button
                                            btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px] !bg-transparent !border !border-slate-200"
                                            btnText={"Import steps from file"}
                                            // onClick={}
                                        /> */}
                                    </div>
                                )}
                            </div>
                            {productionSteps?.length === 0 ? (
                                <div className="border border-slate-300 border-dashed mx-auto text-center pt-6 pb-8 rounded-md">
                                    <div className=" flex items-center flex-col ">
                                        <i className="ri-flow-chart text-g-75 text-[40px]"></i>
                                        <h6 className="mb-2 text-base font-rocGroteskMedium text-g-75 ">
                                            No production schedule found
                                        </h6>
                                        <p className="text-sm  mb-4 text-slate-500 max-w-[570px]">
                                            You haven't added any production steps or plans yet.
                                            Start organizing your production schedule to streamline
                                            your processes and improve efficiency.
                                        </p>

                                        <div className="flex gap-2.5">
                                            <Button
                                                btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px]"
                                                btnText={"Add production step"}
                                                onClick={() => {
                                                    setProductionStepModal(true);
                                                    const copyArr = cloneDeep(productionSteps);
                                                    copyArr[productionSteps?.length] = {};
                                                    setProductionStepsModalData(copyArr);
                                                    copyArr?.length >= 1
                                                        ? setViewStep(copyArr?.length - 1)
                                                        : setViewStep(0);
                                                }}
                                            />
                                            {/* <Button
                                                btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px] !bg-transparent !border !border-slate-200"
                                                btnText={"Import from document"}
                                                // onClick={}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                productionSteps?.map((step, idx) => {
                                    const duration = moment.duration(
                                        moment(step?.actualEnd).diff(moment(step?.actualStart))
                                    );
                                    const timeSpent = `${duration.minutes()} hrs ${duration.hours()} mins`;

                                    return (
                                        <div className="font-rocGroteskMedium" key={idx}>
                                            <div className="">
                                                {idx === 0 ? (
                                                    <div className="w-6 h-6 rounded-full bg-[#00DB8F] flex items-center justify-center relative z-1">
                                                        <div className="w-3 h-3 rounded-full bg-white flex items-center justify-center">
                                                            <i className="ri-check-line text-[#00DB8F] text-[10px]"></i>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="w-6 h-6 rounded-full bg-[#FEF3C7] flex items-center justify-center relative z-1">
                                                        <i className="ri-time-line text-[#D97706] text-xs"></i>
                                                    </div>
                                                )}
                                                <div
                                                    className={` pb-4 pl-[26px] ml-2.5 border-l border-slate-200 -mt-4`}
                                                >
                                                    <div className="border border-slate-200 rounded-[6px] font-rocGroteskMedium   px-6 py-4 space-y-4 shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                                        <div className="">
                                                            <div className="flex items-start justify-between">
                                                                <div className="space-y-2 w-auto">
                                                                    <div className="flex gap-2">
                                                                        {/* <div className="py-1 px-3 border border-slate-200 bg-slate-100 rounded text-xs text-gm-50 w-fit">
                                                                            Day {step?.step}
                                                                        </div> */}
                                                                        {/* {step?.isDaily && (
                                                                            <div className="py-1 px-3 border border-[#16A34A] bg-[#DCFCE7] rounded text-xs text-[#16A34A] w-fit">
                                                                                Daily
                                                                            </div>
                                                                        )} */}
                                                                    </div>
                                                                    <p className="text-sm text-g-75">
                                                                        Step {idx + 1}: {step?.name}
                                                                    </p>
                                                                    <p className="text-sm text-slate-500">
                                                                        {step?.description}
                                                                    </p>
                                                                </div>

                                                                <div className="space-y-2 min-w-[650px]">
                                                                    <div className="flex gap-2 justify-end">
                                                                        <p className="text-g-75 text-sm">
                                                                            Planned Start:{" "}
                                                                            <span className="text-slate-500 text-sm ">
                                                                                {step?.plannedStart
                                                                                    ? moment(
                                                                                          step?.plannedStart
                                                                                      )?.format(
                                                                                          "YYYY-MM-DD HH:MM:SS A"
                                                                                      )
                                                                                    : "N/A"}
                                                                            </span>
                                                                        </p>
                                                                        <p className="text-g-75 text-sm">
                                                                            Planned End:{" "}
                                                                            <span className="text-slate-500 text-sm ">
                                                                                {step?.plannedEnd
                                                                                    ? moment(
                                                                                          step?.plannedEnd
                                                                                      )?.format(
                                                                                          "YYYY-MM-DD HH:MM:SS A"
                                                                                      )
                                                                                    : "N/A"}
                                                                            </span>
                                                                        </p>
                                                                        {/* <p className="text-g-75 text-sm">
                                                                            Quantity: {` `}
                                                                            <span className="text-slate-500 text-sm ">
                                                                                {step?.amount}
                                                                                {` `}
                                                                                {
                                                                                    step?.unitOfMeasurement
                                                                                }
                                                                            </span>
                                                                        </p> */}
                                                                    </div>

                                                                    <div className="flex gap-2 justify-end">
                                                                        <p className="text-g-75 text-sm">
                                                                            Actual Start:{` `}
                                                                            <span className="text-slate-500 text-sm ">
                                                                                {step?.actualStart
                                                                                    ? moment(
                                                                                          step?.actualStart
                                                                                      )?.format(
                                                                                          "YYYY-MM-DD HH:MM:SS A"
                                                                                      )
                                                                                    : "N/A"}
                                                                            </span>
                                                                        </p>

                                                                        <p className="text-g-75 text-sm">
                                                                            Actual End:{" "}
                                                                            <span className="text-slate-500 text-sm ">
                                                                                {step?.actualEnd
                                                                                    ? moment(
                                                                                          step?.actualEnd
                                                                                      )?.format(
                                                                                          "YYYY-MM-DD HH:MM:SS A"
                                                                                      )
                                                                                    : "N/A"}
                                                                            </span>
                                                                        </p>

                                                                        {/* <p className="text-g-75 text-sm">
                                                                            Quantity: {` `}
                                                                            <span className="text-slate-500 text-sm ">
                                                                                {step?.actualStart
                                                                                    ? `${step?.amount} ${step?.unitOfMeasurement}`
                                                                                    : "N/A"}
                                                                            </span>
                                                                        </p> */}
                                                                    </div>

                                                                    <div className="flex gap-2 justify-end">
                                                                        <p className="text-g-75 text-sm">
                                                                            Total time spent:{" "}
                                                                            <span className="text-slate-500 text-sm ">
                                                                                {step?.actualStart &&
                                                                                step?.actualEnd ? (
                                                                                    <span className="text-slate-500 text-sm ">
                                                                                        {
                                                                                            step?.amount
                                                                                        }
                                                                                        {` `}
                                                                                        {
                                                                                            step?.unitOfMeasurement
                                                                                        }
                                                                                    </span>
                                                                                ) : (
                                                                                    "N/A"
                                                                                )}
                                                                            </span>
                                                                        </p>
                                                                        {/* <p className="text-g-75 text-sm">
                                                                            Total Quantity:{" "}
                                                                            <span className="text-slate-500 text-sm ">
                                                                                {step?.actualStart &&
                                                                                step?.actualEnd
                                                                                    ? "525kg"
                                                                                    : "N/A"}
                                                                            </span>
                                                                        </p> */}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="flex gap-2 pt-2">
                                                                <Button
                                                                    btnText="Edit step"
                                                                    btnClassname="!px-4 !py-2 !bg-transparent !w-fit !border !border-slate-200 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !text-[13px]"
                                                                    icon={
                                                                        <i className="ri-edit-2-line text-gm-50 text-xs"></i>
                                                                    }
                                                                    onClick={() => {
                                                                        if (!productionId) {
                                                                            setViewStep(idx);
                                                                            setProductionStepModal(
                                                                                true
                                                                            );
                                                                            const copyArr =
                                                                                cloneDeep(
                                                                                    productionSteps
                                                                                );
                                                                            setProductionStepsModalData(
                                                                                copyArr
                                                                            );
                                                                        } else {
                                                                            setStepIdx(idx);
                                                                            setStep(
                                                                                productionSteps[idx]
                                                                            );
                                                                            setProductionStepModal(
                                                                                true
                                                                            );
                                                                            productionId &&
                                                                                setSavedStepId(
                                                                                    step?.id
                                                                                );
                                                                            setAssignees(
                                                                                productionSteps[idx]
                                                                                    ?.assigneeIds
                                                                            );
                                                                            setAttachments(
                                                                                productionSteps[idx]
                                                                                    ?.attachments ||
                                                                                    []
                                                                            );
                                                                            setQcData(
                                                                                productionSteps[idx]
                                                                                    ?.qualityChecklist
                                                                                    ?.length >= 1
                                                                                    ? productionSteps[
                                                                                          idx
                                                                                      ]
                                                                                          ?.qualityChecklist
                                                                                    : [{}]
                                                                            );
                                                                        }
                                                                    }}
                                                                />

                                                                {/* <div className="flex items-center gap-2">
                                                                    <div className="bg-slate-100 rounded-full w-6 h-6 flex items-center justify-center">
                                                                        <i className="ri-user-follow-line text-gm-50"></i>
                                                                    </div>
                                                                    <p className="text-sm">
                                                                        Assigned to
                                                                        <span className="">
                                                                         {step?.assigneeIds?.} 
                                                                        </span>{" "}
                                                                    </p>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        {step?.qualityChecklist?.length >= 1 && (
                                                            <div className="border border-slate-200 rounded-[6px] shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                                                <div
                                                                    className={`flex justify-between items-center py-3 px-4 cursor-pointer ${
                                                                        showCheckList === idx
                                                                            ? "border-b border-b-slate-200"
                                                                            : ""
                                                                    }`}
                                                                    onClick={() =>
                                                                        showCheckList === idx
                                                                            ? setShowCheckList(-1)
                                                                            : setShowCheckList(idx)
                                                                    }
                                                                >
                                                                    <div className="flex items-center gap-2">
                                                                        <i className="ri-list-check text-[#000]"></i>
                                                                        <p className="text-g-75 text-sm">
                                                                            Quality Control
                                                                            checklists
                                                                        </p>
                                                                    </div>
                                                                    <i
                                                                        className={`${
                                                                            showCheckList === idx
                                                                                ? "ri-arrow-up-s-line"
                                                                                : "ri-arrow-down-s-line"
                                                                        } text-gm-50 cursor-pointer`}
                                                                        onClick={() =>
                                                                            showCheckList === idx
                                                                                ? setShowCheckList(
                                                                                      -1
                                                                                  )
                                                                                : setShowCheckList(
                                                                                      idx
                                                                                  )
                                                                        }
                                                                    ></i>
                                                                </div>
                                                                {showCheckList === idx && (
                                                                    <div className="py-3 px-4">
                                                                        <ul className="list-disc py-3 px-4">
                                                                            {step?.qualityChecklist?.map(
                                                                                (qc, idx) => (
                                                                                    <li
                                                                                        key={idx}
                                                                                        className="text-[#142837] text-sm"
                                                                                    >
                                                                                        {qc?.task}
                                                                                    </li>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                        {/* {step?.qualityChecklist?.filter(
                                                            (item) => item?.defect
                                                        )?.length >= 1 && (
                                                            <div className="border border-slate-200 rounded-[6px] shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                                                <div
                                                                    className={`flex justify-between items-center py-3 px-4  cursor-pointer  ${
                                                                        showDefect === idx
                                                                            ? "border-b border-b-slate-200"
                                                                            : ""
                                                                    }`}
                                                                    onClick={() =>
                                                                        showDefect === idx
                                                                            ? setShowDefects(-1)
                                                                            : setShowDefects(idx)
                                                                    }
                                                                >
                                                                    <div className="flex items-center gap-2">
                                                                        <i className="ri-information-line text-gm-50"></i>
                                                                        <p className="text-g-75 text-sm">
                                                                            Defects identified
                                                                        </p>
                                                                    </div>
                                                                    <i
                                                                        className={`${
                                                                            showDefect === idx
                                                                                ? "ri-arrow-up-s-line"
                                                                                : "ri-arrow-down-s-line"
                                                                        } text-gm-50 cursor-pointer`}
                                                                    ></i>
                                                                </div>
                                                                {showDefect === idx && (
                                                                    <div className="py-3 px-4">
                                                                        <ul className="list-disc py-3 px-4">
                                                                            {step?.qualityChecklist
                                                                                ?.filter(
                                                                                    (item) =>
                                                                                        item?.defect
                                                                                )
                                                                                ?.map((qc, idx) => (
                                                                                    <li
                                                                                        key={idx}
                                                                                        className="text-[#142837] text-sm"
                                                                                    >
                                                                                        {qc?.defect}
                                                                                    </li>
                                                                                ))}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )} */}

                                                        {step?.attachments?.length >= 1 && (
                                                            <div className="border border-slate-200 rounded-[6px] shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                                                <div
                                                                    className={`flex justify-between items-center py-3 px-4  cursor-pointer  ${
                                                                        showAttachments === idx
                                                                            ? "border-b border-b-slate-200"
                                                                            : ""
                                                                    }`}
                                                                    onClick={() =>
                                                                        showAttachments === idx
                                                                            ? setShowAttachments(-1)
                                                                            : setShowAttachments(
                                                                                  idx
                                                                              )
                                                                    }
                                                                >
                                                                    <div className="flex items-center gap-2">
                                                                        <i className="ri-file-line text-gm-50"></i>
                                                                        <p className="text-g-75 text-sm">
                                                                            Documents attached
                                                                        </p>
                                                                    </div>
                                                                    <i
                                                                        className={`${
                                                                            showAttachments === idx
                                                                                ? "ri-arrow-up-s-line"
                                                                                : "ri-arrow-down-s-line"
                                                                        } text-gm-50 cursor-pointer`}
                                                                    ></i>
                                                                </div>
                                                                {showAttachments === idx && (
                                                                    <div className="py-3 px-4 grid grid-cols-2 gap-8">
                                                                        {step?.attachments?.map(
                                                                            (item, idx) => {
                                                                                return (
                                                                                    <div
                                                                                        className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
                                                                                        key={idx}
                                                                                    >
                                                                                        <div
                                                                                            className="flex items-center gap-2 cursor-pointer"
                                                                                            onClick={() => {
                                                                                                const a =
                                                                                                    document.createElement(
                                                                                                        "a"
                                                                                                    );
                                                                                                a.href =
                                                                                                    item?.url;
                                                                                                a.target =
                                                                                                    "_blank";
                                                                                                document.body.appendChild(
                                                                                                    a
                                                                                                );
                                                                                                a.click();
                                                                                                document.body.removeChild(
                                                                                                    a
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {displayFileIcon(
                                                                                                getFileTypeFromUrl(
                                                                                                    item?.path
                                                                                                ),
                                                                                                false,
                                                                                                "!w-[20px] !h-[20px]"
                                                                                            )}
                                                                                            <div className="font-rocGroteskMedium">
                                                                                                <p className="text-sm">
                                                                                                    {
                                                                                                        item?.filename
                                                                                                    }
                                                                                                    {` `}
                                                                                                    {/* <span className="text-xs text-slate-500">
                                                                                                        |
                                                                                                        4mb
                                                                                                    </span> */}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    ) : (
                        <div className="flex justify-center  items-center h-[100px]">
                            <Loader color="gm-25" size={6} />
                        </div>
                    )}
                </div>
            </form>

            <ProductionModals
                successModal={successModal}
                setSuccessModal={setSuccessModal}
                productionPlanId={productionPlanId}
                confirmModal={confirmModal}
                setConfirmModal={setConfirmModal}
                handleCreateProductionPlan={handleCreateProductionPlan}
                creatingProductionPlan={creatingProductionPlan}
                updatingProductionPlan={updatingProductionPlan}
            />

            <ModalContainer
                open={addNewMember}
                showCloseIcon={false}
                modalClassName={
                    "md:py-10 bg-white !items-start !top-0 !left-0 md:!items-center md:bg-gm-50/20"
                }
                tailwindClassName="w-full md:w-[553px] !overflow-y-visible"
                closeModal={() => {
                    setAddNewMember(false);
                    setTeamForm({
                        // brands: [],
                        email: "",
                        role: "",
                    });
                }}
            >
                <div className="bg-white shadow-cardShadow relative rounded-lg">
                    <div className="w-full md:flex md:justify-center ">
                        <div className="w-full md:w-[553px]">
                            <div className="w-full   md:flex md:text-center items-center md:justify-center">
                                <div className="w-full flex py-4 border-b border-slate-200 text-center items-center justify-center relative">
                                    <span className="text-base font-rocGroteskMedium">
                                        Add a team-mate
                                    </span>
                                    <div className="absolute right-[24px]">
                                        <i
                                            className="ri-close-fill text-2xl cursor-pointer"
                                            onClick={() => {
                                                setAddNewMember(false);
                                                setTeamForm({
                                                    email: "",
                                                    role: "",
                                                });
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            </div>
                            <form
                                onSubmit={(e) => {
                                    handleSubmit(e);
                                }}
                                className="w-full  "
                            >
                                <div className="space-y-6 p-6">
                                    <TextInput
                                        value={teamForm?.email}
                                        name={"email"}
                                        type={"email"}
                                        onChange={(e) => {
                                            handleTeamFormChange(e.target.name, e.target.value);
                                        }}
                                        placeholder={"Email"}
                                        required={true}
                                        containerClassname=""
                                    />

                                    <SelectInput
                                        value={teamForm.role}
                                        name="role"
                                        placeholder="Select role"
                                        handleChange={(name, value) => {
                                            handleTeamFormChange(name, value);
                                        }}
                                        isRequired={true}
                                        searchLoading={isFetchingRoles}
                                        customDropdownContainer={
                                            <div className="w-full py-1.5 flex flex-col gap-3.5">
                                                <div
                                                    onClick={() => setAddCustomRole(true)}
                                                    className="flex items-center gap-1 px-3 font-rocGroteskMedium cursor-pointer w-fit"
                                                >
                                                    <i className="ri-add-fill before:content-['\ea12'] text-p-55 text-xl mt-[-2px]"></i>
                                                    <span className="text-sm text-p-50">
                                                        Add custom role
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                        dropdownOptions={userRoles?.map((role) => {
                                            return {
                                                label: role?.label,
                                                value: role?.role,
                                            };
                                        })}
                                    />
                                </div>

                                <div className="w-full mt-1 py-4 px-6 border-t border-slate-200 flex items-center space-x-2">
                                    <Button
                                        btnText={"Cancel"}
                                        onClick={() => {
                                            setAddNewMember(false);
                                            setTeamForm({
                                                // accessLevel: [],
                                                // brands: [],
                                                email: "",
                                                role: "",
                                            });
                                        }}
                                        disabled={addingTeamMember}
                                        btnClassname={"!bg-tradeally-neutral-20 !text-gm-75 "}
                                    />
                                    <Button
                                        btnText={"Add team-mate"}
                                        isLoading={addingTeamMember}
                                        type={"submit"}
                                        btnClassname=""
                                        btnTextClassName="!text-md"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={addCustomRole}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[513px] xl:w-[600px]"
                closeModal={() => {
                    setAddCustomRole(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-modalShadow relative pt-5 pb-10">
                    <div className="flex justify-end px-10">
                        <span
                            onClick={() => setAddCustomRole(false)}
                            className="material-icons text-gm-50 text-xl cursor-pointer"
                        >
                            close
                        </span>
                    </div>
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center text-center mb-[45px]">
                            <p className="text-2xl mb-2.5 font-rocGroteskMedium text-g-75">
                                Create a custom role
                            </p>
                            <p className="text-sm font-rocGroteskMedium text-gm-40">
                                Define what the new role’s responsibilities are
                            </p>
                        </div>
                        <form className="w-full">
                            <TextInput
                                name={`role`}
                                type={"text"}
                                placeholder={"Enter role name"}
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                                required={true}
                                containerClassname={"mb-6"}
                            />
                            <div>
                                <p className="text-base font-rocGroteskMedium mb-6">
                                    Select access levels
                                </p>
                                <div className="flex flex-wrap gap-4 relative overflow-auto">
                                    {accessTypes.map((access, idx) => (
                                        <div
                                            key={idx}
                                            onClick={() => handlePermissionChange(access.value)}
                                            className={`px-2.5 w-fit h-[30px] ${
                                                selectedPermissions.includes(access.value)
                                                    ? "!bg-carribean-green !text-white !border-carribean-green"
                                                    : ""
                                            } border flex items-center gap-2 rounded-[8px] font-rocGroteskMedium bg-white hover:!bg-g-65 hover:text-white hover:!border-g-65 border-slate-300 text-[13px] cursor-pointer`}
                                        >
                                            {selectedPermissions.includes(access.value) ? (
                                                <i className="ri-checkbox-circle-fill text-base"></i>
                                            ) : (
                                                <i className="ri-add-fill text-base"></i>
                                            )}
                                            {access.label}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Button
                                onClick={handleAddRole}
                                btnText={"Add role"}
                                isLoading={createRoles}
                                btnClassname={"my-10"}
                            />
                            <p
                                onClick={() => setAddCustomRole(false)}
                                className="text-sm font-rocGroteskMedium text-center cursor-pointer"
                            >
                                Close
                            </p>
                        </form>
                    </div>
                </div>
            </ModalContainer>

            {/* <ModalContainer
                open={successModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[700px]"
                showCloseIcon={false}
                closeModal={() => {
                    setSuccessModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative font-rocGroteskMedium">
                    
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 w-12 h-12 bottom-0 right-[-13px] rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-[24px] text-white"></i>
                            </div>
                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Purchase orders created for materials
                                </span>
                                <p className="mt-1 text-sm text-slate-500 text-center ">
                                    All purchase orders for {productName && `for ${productName}`} have been
                                    successfully created. The raw materials from different suppliers
                                    will be replenished soon. You can track the order status for
                                    each supplier in the 'Purchase' section to ensure timely
                                    deliveries.
                                </p>
                            </div>
                        </div>
                        <div className="px-6 pb-8">
                            <p className="text-gm-50 text-sm mb-2">Summary</p>

                            <div className="border border-[#E2E8F0] rounded-md px-3 py-4 space-y-3">
                                <button className="border-b border-slate-200 text-g-75 text-sm underline pb-2">
                                    PO #KGR-2024-1223 (Molasses Supplier): Expected Delivery Date:
                                    August 25, 2024
                                </button>

                                <button className="border-b border-slate-200 text-g-75 text-sm underline pb-2">
                                    PO #KGR-2024-1223 (Molasses Supplier): Expected Delivery Date:
                                    August 25, 2024
                                </button>
                                <button className="border-b border-slate-200 text-g-75 text-sm underline pb-2">
                                    PO #KGR-2024-1223 (Molasses Supplier): Expected Delivery Date:
                                    August 25, 2024
                                </button>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Return to production plan"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setSuccessModal(false);
                                }}
                            />
                            <Button
                                btnText={"View purchase orders"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {}}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer> */}
            <ModalContainer
                open={insufficientModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[700px]"
                showCloseIcon={false}
                closeModal={() => {
                    setInsufficientModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative font-rocGroteskMedium">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <i className="ri-box-3-line text-[32px] mb-4 text-[#E4281D]"></i>

                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Insufficient materials for production
                                </span>
                                <p className="mt-1 text-sm text-slate-500 text-center ">
                                    The current inventory levels for the following raw materials are
                                    insufficient to meet the production plan for Kōloa Kauaʻi Gold
                                    Rum. You can create a purchase order (PO) to fulfill these
                                    shortages and ensure production can proceed.
                                </p>
                            </div>
                        </div>
                        <div className="px-6 pb-8">
                            <CustomTable
                                tableBody={ModalTableBody}
                                tableHeader={ModalTableHeader}
                                isScrollable={false}
                                isCellBordered={true}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                scrollWithin={false}
                                headerContainerClass=""
                                headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                tableClass=""
                                bodyItemClass="hover:bg-transparent"
                            />
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Not now"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setInsufficientModal(false);
                                }}
                            />
                            <Button
                                btnText={"View purchase orders"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {}}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            {productionStepModal && (
                <ProductionSteps
                    productionStepModal={productionStepModal}
                    setProductionStepModal={setProductionStepModal}
                    productionSteps={productionSteps}
                    setProductionSteps={setProductionSteps}
                    productionStepsModalData={productionStepsModalData}
                    setProductionStepsModalData={setProductionStepsModalData}
                    step={step}
                    setStep={setStep}
                    stepIdx={stepIdx}
                    setStepIdx={setStepIdx}
                    handleSubmit={handleStepsSubmission}
                    isSaving={creatingPlanSteps || updatingPlanSteps}
                    productionId={productionId}
                    assignees={assignees}
                    setAssignees={setAssignees}
                    setQcData={setQcData}
                    qcData={qcData}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    viewStep={viewStep}
                    setViewStep={setViewStep}
                    type="planForm"
                />
            )}
        </div>
    );
};

export default CreateProductionPlan;
