import React, { useEffect, useState } from "react";
import VendorAvatar from "../../../../common/VendorAvatar";
import CustomTable from "../../../../common/CustomTable";
import moment from "moment";
import CustomTableLoader from "components/common/CustomTableLoader";
import { truncate } from "lodash";
import { Tooltip } from "react-tooltip";
import { format } from "path";
import { formatMoney } from "helpers";

type poTypes = {
    poDetails: any;
    productName: string;
    isLoading: boolean;
};

const LinkedPurchaseOrder = ({ poDetails, productName, isLoading }: poTypes) => {
    const [linkedPo, setLinkedPo] = useState<{ [key: string]: any }[]>([]);

    useEffect(() => {
        if (poDetails?.length >= 1) {
            const parsedData = poDetails
                ?.map((poData) => {
                    return poData?.itemsOrServices?.map((prod) => ({
                        taId: poData?.taId,
                        product: prod,
                        supplier: poData?.vendor?.profile?.businessName,
                        createdBy:
                            poData?.createdBy?.profile?.businessName ||
                            `${poData?.createdBy?.firstName} ${poData?.createdBy?.lastName}`,
                        totalShipments: poData?.totalShipments,
                        currentStatus: poData?.currentStatus,
                    }));
                })
                .flat();

            setLinkedPo(parsedData);
        } else {
            setLinkedPo([]);
        }
    }, [poDetails]);

    const PurchaseOrderTableHeader = [
        { title: "PO Number", widthClass: "w-[8%] !border !bg-slate-50 !border-slate-200" },
        {
            title: "Material Ordered",
            widthClass: "w-[15%]  !border !bg-slate-50 !border-slate-200",
            sortIcon: true,
        },
        { title: "Categories", widthClass: "w-[10%] !border !bg-slate-50 !border-slate-200" },
        { title: "Supplier", widthClass: "w-[12%] !border !bg-slate-50 !border-slate-200" },
        { title: "Created By", widthClass: "w-[12%] !border !bg-slate-50 !border-slate-200" },
        {
            title: "Est. delivery date",
            widthClass: "w-[10%] !border !bg-slate-50 !border-slate-200",
        },
        { title: "Quantity", widthClass: "w-[8%] !border !bg-slate-50 !border-slate-200" },
        { title: "No. of shipments", widthClass: "w-[8%] !border !bg-slate-50 !border-slate-200" },
        { title: "Status", widthClass: "w-[8%] !border !bg-slate-50 !border-slate-200" },
    ];

    const PurchaseOrderTableBody = linkedPo?.map((order, idx) => {
        return [
            {
                content: (
                    <div className={`w-full  px-4 `}>
                        <p className="text-g-75 text-sm">{`PO-${order?.taId}`}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full  `}>
                        <div className="flex items-center gap-2 py-2 px-2.5">
                            {order?.product?.inventory?.productImageDetails?.productAvatar ||
                            order?.product?.inventory?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        order?.product?.inventory?.productImageDetails
                                            ?.productAvatar ||
                                        order?.product?.inventory?.productImageDetails
                                            ?.productImages?.[0]
                                    }
                                    size={32}
                                    name={order?.product?.inventory?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm">
                                <p className="text-g-75">
                                    <span>
                                        {truncate(order?.product?.inventory?.productName || order?.product?.name, {
                                            length: 20,
                                        })}
                                    </span>
                                </p>
                                <p className="text-[#64748B]">{order?.product?.inventory?.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <div className={`${order?.product?.inventory?.inventoryCategory && "bg-[#F1F5F9] border border-slate-200"} text-g-75 py-0.5 px-2 rounded-[3px] text-sm   w-fit`}>
                            {order?.product?.inventory?.inventoryCategory}
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <div className="flex items-center gap-2 text-g-75 text-sm">
                            {/* {order ? (
                                <VendorAvatar
                                    // imageSrc={
                                    //     product?.productImageDetails?.productAvatar ||
                                    //     product?.productImageDetails?.productImages?.[0]
                                    // }
                                    size={32}
                                    // name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-5 h-5 rounded-full shrink-0 flex items-center justify-center bg-slate-100 ">
                                    <i className="ri-user-follow-line text-sm text-gm-50"></i>
                                </div>
                            )}

                            <span>Synth</span> */}
                            <p className="">
                                {truncate(order?.supplier, {
                                    length: 40,
                                })}
                            </p>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <div className="flex items-center gap-2 text-slate-950 text-sm">
                            <div className="w-5 h-5 rounded-full shrink-0 flex items-center justify-center bg-slate-100 ">
                                <i className="ri-user-follow-line text-sm text-gm-50"></i>
                            </div>

                            <span>
                                {truncate(order?.createdBy, {
                                    length: 40,
                                })}
                            </span>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-g-75 text-sm">
                            {order?.product?.deliveryDate
                                ? moment(order?.product?.deliveryDate)?.format("MMM DD, YYYY")
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-900 text-sm">{order?.product?.qty!== undefined ? formatMoney()?.format(order?.product?.qty) : "" }</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-g-75 text-sm">{order?.totalShipments}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <div className="flex items-center gap-2">
                            <div
                                className={`w-2 h-2 text-sm rounded-full shrink-0 flex items-center justify-center
                                   
                                    ${
                                        order?.currentStatus === "pending"
                                            ? "bg-[#F59E0B]"
                                            : order?.currentStatus === "approved"
                                            ? "bg-[#16C6A4]"
                                            : order?.currentStatus === "cancelled"
                                            ? "bg-[#FF3D1D]"
                                            : "bg-[#6B7280]"
                                    }`}
                            ></div>

                            {/* {idx === 0
                                ? "bg-[#16C6A4]"
                                : idx === 1
                                ? "bg-[#FF3D1D]"
                                : "bg-[#F59E0B]"} */}
                            <span className="capitalize text-sm">
                                {order?.currentStatus}
                                {/* {idx === 0 ? "Approved" : idx === 1 ? "Cancelled" : "Pending"} */}
                            </span>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border !border-slate-200",
            },
        ];
    });
    return (
        <div>
            <div className="flex items-center gap-1  pb-6">
                <p className="text-gm-50 text-base">
                    Linked purchase orders (POs) {productName && `for ${productName}`}
                </p>
                <div>
                    <a id="linked-po">
                        <i className="ri-information-fill text-slate-500 text-base"></i>
                    </a>

                    <Tooltip
                        anchorSelect="#linked-po"
                        place={"top"}
                        content="Purchase orders associated with the materials or components required for this production."
                        style={{ width: "250px", zIndex: 9999 }}
                    />
                </div>
            </div>

            {isLoading && linkedPo?.length === 0 ? (
                <div className="overflow-x-hidden">
                    <CustomTableLoader tableHeader={PurchaseOrderTableHeader} />
                </div>
            ) : linkedPo?.length >= 1 ? (
                <>
                    <CustomTable
                        tableBody={PurchaseOrderTableBody}
                        tableHeader={PurchaseOrderTableHeader}
                        isScrollable={false}
                        isCellBordered={true}
                        isCellSelectable={false}
                        isAllSelectable={false}
                        scrollWithin={false}
                        headerContainerClass=""
                        headerItemClass="font-rocGroteskMedium !text-[#334155] "
                        tableClass=""
                    />
                </>
            ) : (
                <div className="h-[100px] flex items-center justify-center ">
                    <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                        No linked purchase orders
                    </p>
                </div>
            )}
        </div>
    );
};

export default LinkedPurchaseOrder;
