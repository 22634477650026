import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";

const useGetNavBrandList = (token?: string) => {
    const dispatch = useAppDispatch();
    const [brandList, setBrandList] = useState<{ [key: string]: any }[]>([]);
    // const [brandListSearch, setBrandListSearch] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    // const [searchTwo, setSearchTwo] = useState<string>("");
    // const [debouncedSearchTwo, setDebouncedSearchTwo] = useState<string>("");

    const {
        // fetchingBrandList,
        // fetchBrandListSuccess,
        // fetchBrandListFailure,
        // fetchingBrandListSearch,
        // fetchBrandListSearchSuccess,

        fetchingNavBrandList,
        fetchNavBrandListSuccess,
        fetchNavBrandListFailure,
    } = useAppSelector((state) => state.multibrand);

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setDebouncedSearch(e.target.value);
    }, 800);

    // const handleSearchTwo = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setSearchTwo(e.target.value);
    // };

    // const handleDebouncedChangeTwo = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    //     setDebouncedSearchTwo(e.target.value);
    // }, 800);

    useEffect(() => {
        if (Boolean(fetchNavBrandListSuccess)) {
            setBrandList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchNavBrandListSuccess?.brands], "_id")
                    : uniqBy([...prev, ...fetchNavBrandListSuccess?.brands], "_id")
            );
            setPagination({
                currentPage: fetchNavBrandListSuccess.pagination?.current,
                noOfPages: fetchNavBrandListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchNavBrandListSuccess]);

    useEffect(() => {
        dispatch(multibrandActions?.getNavBrandList(updatedPage, debouncedSearch, token));
    }, [dispatch, debouncedSearch, updatedPage, token]);

    // useEffect(() => {
    //     if (Boolean(fetchBrandListSearchSuccess)) {
    //         setBrandListSearch(uniqBy([...fetchBrandListSearchSuccess?.brands], "brandId"));
    //     }
    // }, [fetchBrandListSearchSuccess]);

    // useEffect(() => {
    //     dispatch(multibrandActions?.getBrandListSearch(1, debouncedSearchTwo));
    // }, [dispatch, debouncedSearchTwo]);

    return {
        data: brandList,
        isFetching: fetchingNavBrandList,
        error: fetchNavBrandListFailure,
        isLoadingMore,
        pagination,
        search,
        handleSearch,
        handleDebouncedChange,
        // brandListSearch,
        // searchTwo,
        // setSearchTwo,
        // setDebouncedSearchTwo,
        // handleSearchTwo,
        // handleDebouncedChangeTwo,
        setUpdatedPage,
    };
};

export default useGetNavBrandList;
