import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { taskActions } from "../../redux/Ldb/actions/taskActions";
import { debounce, uniqBy } from "lodash";

const useGetCompletedHumanTask = () => {
    const dispatch = useAppDispatch();

    const [completedHumanTaskList, setCompletedHumanTaskList] = useState<{ [key: string]: any }[]>(
        []
    );
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [taskStatus, setTaskStatus] = useState<string>("passed");
    const [taskType, setTaskType] = useState<string>("");
    const {
        fetchingCompletedHumanTask,
        fetchCompletedHumanTaskSuccess,
        fetchCompletedHumanTaskFailure,
    } = useAppSelector((state) => state.task);

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            {
                current: pagination.currentPage,
                number_of_pages: pagination.noOfPages,
            },
            10
        );

    useEffect(() => {
        setUpdatedPage(1);
    }, [debouncedSearch, taskStatus]);

    useEffect(() => {
        taskStatus === "" && setCompletedHumanTaskList([]);
    }, [taskStatus]);

    useEffect(() => {
        if (taskStatus) {
            dispatch(
                taskActions.fetchCompletedHumanTaskList(
                    updatedLimit,
                    updatedPage,
                    taskStatus,
                    debouncedSearch,
                    taskType
                )
            );
        }
    }, [dispatch, debouncedSearch, updatedPage, taskStatus, taskType]);

    useEffect(() => {
        if (Boolean(fetchCompletedHumanTaskSuccess)) {
            setCompletedHumanTaskList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchCompletedHumanTaskSuccess?.tasks], "_id")
                    : uniqBy([...prev, ...fetchCompletedHumanTaskSuccess?.tasks], "_id")
            );

            setPagination({
                currentPage: fetchCompletedHumanTaskSuccess.pagination?.current,
                noOfPages: fetchCompletedHumanTaskSuccess.pagination?.number_of_pages,
            });

            setLoadMore(false);
        }
    }, [fetchCompletedHumanTaskSuccess]);

    return {
        isFetching: fetchingCompletedHumanTask,
        error: fetchCompletedHumanTaskFailure,
        data: completedHumanTaskList,
        setTaskStatus,
        setTaskType,
        setCompletedHumanTaskList,
        isLoadingMore,
        pagination,
        setUpdatedPage,
        setLoadMore,
        setDebouncedSearch,
    };
};

export default useGetCompletedHumanTask;
