export const productionTypes = {
    FETCH_BOM_LIST_REQUEST: "FETCH_BOM_LIST_REQUEST",
    FETCH_BOM_LIST_SUCCESS: "FETCH_BOM_LIST_SUCCESS",
    FETCH_BOM_LIST_FAILURE: "FETCH_BOM_LIST_FAILURE",
    FETCH_SINGLE_BOM_REQUEST: "FETCH_SINGLE_BOM_REQUEST",
    FETCH_SINGLE_BOM_SUCCESS: "FETCH_SINGLE_BOM_SUCCESS",
    FETCH_SINGLE_BOM_FAILURE: "FETCH_SINGLE_BOM_FAILURE",
    CREATE_BOM_REQUEST: "CREATE_BOM_REQUEST",
    CREATE_BOM_SUCCESS: "CREATE_BOM_SUCCESS",
    CREATE_BOM_FAILURE: "CREATE_BOM_FAILURE",
    UPDATE_BOM_REQUEST: "UPDATE_BOM_REQUEST",
    UPDATE_BOM_SUCCESS: "UPDATE_BOM_SUCCESS",
    UPDATE_BOM_FAILURE: "UPDATE_BOM_FAILURE",
    FETCH_PRODUCT_WITHOUT_BOM_REQUEST: "FETCH_PRODUCT_WITHOUT_BOM_REQUEST",
    FETCH_PRODUCT_WITHOUT_BOM_SUCCESS: "FETCH_PRODUCT_WITHOUT_BOM_SUCCESS",
    FETCH_PRODUCT_WITHOUT_BOM_FAILURE: "FETCH_PRODUCT_WITHOUT_BOM_FAILURE",
    FETCH_NON_FINISHED_INVENTORY_REQUEST: "FETCH_NON_FINISHED_INVENTORY_REQUEST",
    FETCH_NON_FINISHED_INVENTORY_SUCCESS: "FETCH_NON_FINISHED_INVENTORY_SUCCESS",
    FETCH_NON_FINISHED_INVENTORY_FAILURE: "FETCH_NON_FINISHED_INVENTORY_FAILURE",
    CREATE_PRODUCTION_PLAN_REQUEST: "CREATE_PRODUCTION_PLAN_REQUEST",
    CREATE_PRODUCTION_PLAN_SUCCESS: "CREATE_PRODUCTION_PLAN_SUCCESS",
    CREATE_PRODUCTION_PLAN_FAILURE: "CREATE_PRODUCTION_PLAN_FAILURE",
    UPDATE_PRODUCTION_PLAN_REQUEST: "UPDATE_PRODUCTION_PLAN_REQUEST",
    UPDATE_PRODUCTION_PLAN_SUCCESS: "UPDATE_PRODUCTION_PLAN_SUCCESS",
    UPDATE_PRODUCTION_PLAN_FAILURE: "UPDATE_PRODUCTION_PLAN_FAILURE",
    FETCH_FINISHED_INVENTORY_REQUEST: "FETCH_FINISHED_INVENTORY_REQUEST",
    FETCH_FINISHED_INVENTORY_SUCCESS: "FETCH_FINISHED_INVENTORY_SUCCESS",
    FETCH_FINISHED_INVENTORY_FAILURE: "FETCH_FINISHED_INVENTORY_FAILURE",
    FETCH_PRODUCTION_PLAN_LIST_REQUEST: "FETCH_PRODUCTION_PLAN_LIST_REQUEST",
    FETCH_PRODUCTION_PLAN_LIST_SUCCESS: "FETCH_PRODUCTION_PLAN_LIST_SUCCESS",
    FETCH_PRODUCTION_PLAN_LIST_FAILURE: "FETCH_PRODUCTION_PLAN_LIST_FAILURE",
    FETCH_PRODUCTION_PLAN_LIST_SEARCH_REQUEST: "FETCH_PRODUCTION_PLAN_LIST_SEARCH_REQUEST",
    FETCH_PRODUCTION_PLAN_LIST_SEARCH_SUCCESS: "FETCH_PRODUCTION_PLAN_LIST_SEARCH_SUCCESS",
    FETCH_PRODUCTION_PLAN_LIST_SEARCH_FAILURE: "FETCH_PRODUCTION_PLAN_LIST_SEARCH_FAILURE",
    FETCH_SINGLE_PRODUCTION_PLAN_REQUEST: "FETCH_SINGLE_PRODUCTION_PLAN_REQUEST",
    FETCH_SINGLE_PRODUCTION_PLAN_SUCCESS: "FETCH_SINGLE_PRODUCTION_PLAN_SUCCESS",
    FETCH_SINGLE_PRODUCTION_PLAN_FAILURE: "FETCH_SINGLE_PRODUCTION_PLAN_FAILURE",
    FETCH_SINGLE_PRODUCTION_PLAN_BASIC_REQUEST: "FETCH_SINGLE_PRODUCTION_PLAN_BASIC_REQUEST",
    FETCH_SINGLE_PRODUCTION_PLAN_BASIC_SUCCESS: "FETCH_SINGLE_PRODUCTION_PLAN_BASIC_SUCCESS",
    FETCH_SINGLE_PRODUCTION_PLAN_BASIC_FAILURE: "FETCH_SINGLE_PRODUCTION_PLAN_BASIC_FAILURE",
    FETCH_PRODUCTION_PLAN_TAB_REQUEST: "FETCH_PRODUCTION_PLAN_TAB_REQUEST",
    FETCH_PRODUCTION_PLAN_TAB_SUCCESS: "FETCH_PRODUCTION_PLAN_TAB_SUCCESS",
    FETCH_PRODUCTION_PLAN_TAB_FAILURE: "FETCH_PRODUCTION_PLAN_TAB_FAILURE",
    FETCH_PRODUCTION_PLAN_TAB_TWO_REQUEST: "FETCH_PRODUCTION_PLAN_TAB_TWO_REQUEST",
    FETCH_PRODUCTION_PLAN_TAB_TWO_SUCCESS: "FETCH_PRODUCTION_PLAN_TAB_TWO_SUCCESS",
    FETCH_PRODUCTION_PLAN_TAB_TWO_FAILURE: "FETCH_PRODUCTION_PLAN_TAB_TWO_FAILURE",
    UPDATE_PLAN_STEPS_REQUEST: "UPDATE_PLAN_STEPS_REQUEST",
    UPDATE_PLAN_STEPS_SUCCESS: "UPDATE_PLAN_STEPS_SUCCESS",
    UPDATE_PLAN_STEPS_FAILURE: "UPDATE_PLAN_STEPS_FAILURE",
    FETCH_PRODUCTION_ANALYTICS_REQUEST: "FETCH_PRODUCTION_ANALYTICS_REQUEST",
    FETCH_PRODUCTION_ANALYTICS_SUCCESS: "FETCH_PRODUCTION_ANALYTICS_SUCCESS",
    FETCH_PRODUCTION_ANALYTICS_FAILURE: "FETCH_PRODUCTION_ANALYTICS_FAILURE",
    CREATE_PRODUCTION_STEPS_REQUEST: "CREATE_PRODUCTION_STEPS_REQUEST",
    CREATE_PRODUCTION_STEPS_SUCCESS: "CREATE_PRODUCTION_STEPS_SUCCESS",
    CREATE_PRODUCTION_STEPS_FAILURE: "CREATE_PRODUCTION_STEPS_FAILURE",
    CREATE_PRODUCTION_SETTINGS_REQUEST: "CREATE_PRODUCTION_SETTINGS_REQUEST",
    CREATE_PRODUCTION_SETTINGS_SUCCESS: "CREATE_PRODUCTION_SETTINGS_SUCCESS",
    CREATE_PRODUCTION_SETTINGS_FAILURE: "CREATE_PRODUCTION_SETTINGS_FAILURE",
    FETCH_PRODUCTION_SETTINGS_REQUEST: "FETCH_PRODUCTION_SETTINGS_REQUEST",
    FETCH_PRODUCTION_SETTINGS_SUCCESS: "FETCH_PRODUCTION_SETTINGS_SUCCESS",
    FETCH_PRODUCTION_SETTINGS_FAILURE: "FETCH_PRODUCTION_SETTINGS_FAILURE",
    UPDATE_PRODUCTION_SETTINGS_REQUEST: "UPDATE_PRODUCTION_SETTINGS_REQUEST",
    UPDATE_PRODUCTION_SETTINGS_SUCCESS: "UPDATE_PRODUCTION_SETTINGS_SUCCESS",
    UPDATE_PRODUCTION_SETTINGS_FAILURE: "UPDATE_PRODUCTION_SETTINGS_FAILURE",
    UPDATE_APPROVAL_STATUS_REQUEST: "UPDATE_APPROVAL_STATUS_REQUEST",
    UPDATE_APPROVAL_STATUS_SUCCESS: "UPDATE_APPROVAL_STATUS_SUCCESS",
    UPDATE_APPROVAL_STATUS_FAILURE: "UPDATE_APPROVAL_STATUS_FAILURE",
    VALIDATE_PRODUCTION_BATCHID_REQUEST: "VALIDATE_PRODUCTION_BATCHID_REQUEST",
    VALIDATE_PRODUCTION_BATCHID_SUCCESS: "VALIDATE_PRODUCTION_BATCHID_SUCCESS",
    VALIDATE_PRODUCTION_BATCHID_FAILURE: "VALIDATE_PRODUCTION_BATCHID_FAILURE",
    FETCH_WORK_ORDER_LIST_REQUEST: "FETCH_WORK_ORDER_LIST_REQUEST",
    FETCH_WORK_ORDER_LIST_SUCCESS: "FETCH_WORK_ORDER_LIST_SUCCESS",
    FETCH_WORK_ORDER_LIST_FAILURE: "FETCH_WORK_ORDER_LIST_FAILURE",
    FETCH_SINGLE_WORK_ORDER_REQUEST: "FETCH_SINGLE_WORK_ORDER_REQUEST",
    FETCH_SINGLE_WORK_ORDER_SUCCESS: "FETCH_SINGLE_WORK_ORDER_SUCCESS",
    FETCH_SINGLE_WORK_ORDER_FAILURE: "FETCH_SINGLE_WORK_ORDER_FAILURE",
    FETCH_WORK_ORDER_TAB_REQUEST: "FETCH_WORK_ORDER_TAB_REQUEST",
    FETCH_WORK_ORDER_TAB_SUCCESS: "FETCH_WORK_ORDER_TAB_SUCCESS",
    FETCH_WORK_ORDER_TAB_FAILURE: "FETCH_WORK_ORDER_TAB_FAILURE",
    FETCH_WORK_ORDER_TAB_TWO_REQUEST: "FETCH_WORK_ORDER_TAB_TWO_REQUEST",
    FETCH_WORK_ORDER_TAB_TWO_SUCCESS: "FETCH_WORK_ORDER_TAB_TWO_SUCCESS",
    FETCH_WORK_ORDER_TAB_TWO_FAILURE: "FETCH_WORK_ORDER_TAB_TWO_FAILURE",
    UPDATE_PRODUCTION_RUN_REQUEST: "UPDATE_PRODUCTION_RUN_REQUEST",
    UPDATE_PRODUCTION_RUN_SUCCESS: "UPDATE_PRODUCTION_RUN_SUCCESS",
    UPDATE_PRODUCTION_RUN_FAILURE: "UPDATE_PRODUCTION_RUN_FAILURE",
    UPDATE_WORK_ORDER_STATUS_REQUEST: "UPDATE_WORK_ORDER_STATUS_REQUEST",
    UPDATE_WORK_ORDER_STATUS_SUCCESS: "UPDATE_WORK_ORDER_STATUS_SUCCESS",
    UPDATE_WORK_ORDER_STATUS_FAILURE: "UPDATE_WORK_ORDER_STATUS_FAILURE",
    UPDATE_WORK_ORDER_APPROVAL_STATUS_REQUEST: "UPDATE_WORK_ORDER_APPROVAL_STATUS_REQUEST",
    UPDATE_WORK_ORDER_APPROVAL_STATUS_SUCCESS: "UPDATE_WORK_ORDER_APPROVAL_STATUS_SUCCESS",
    UPDATE_WORK_ORDER_APPROVAL_STATUS_FAILURE: "UPDATE_WORK_ORDER_APPROVAL_STATUS_FAILURE",
    FETCH_PENDING_WORK_ORDER_LIST_REQUEST: "FETCH_PENDING_WORK_ORDER_LIST_REQUEST",
    FETCH_PENDING_WORK_ORDER_LIST_SUCCESS: "FETCH_PENDING_WORK_ORDER_LIST_SUCCESS",
    FETCH_PENDING_WORK_ORDER_LIST_FAILURE: "FETCH_PENDING_WORK_ORDER_LIST_FAILURE",
    FETCH_REJECTED_WORK_ORDER_LIST_REQUEST: "FETCH_REJECTED_WORK_ORDER_LIST_REQUEST",
    FETCH_REJECTED_WORK_ORDER_LIST_SUCCESS: "FETCH_REJECTED_WORK_ORDER_LIST_SUCCESS",
    FETCH_REJECTED_WORK_ORDER_LIST_FAILURE: "FETCH_REJECTED_WORK_ORDER_LIST_FAILURE",
    SUBMIT_YIELD_AND_LOSS_REQUEST: "SUBMIT_YIELD_AND_LOSS_REQUEST",
    SUBMIT_YIELD_AND_LOSS_SUCCESS: "SUBMIT_YIELD_AND_LOSS_SUCCESS",
    SUBMIT_YIELD_AND_LOSS_FAILURE: "SUBMIT_YIELD_AND_LOSS_FAILURE",
    UPDATE_PRODUCTION_STEP_REQUEST: "UPDATE_PRODUCTION_STEP_REQUEST",
    UPDATE_PRODUCTION_STEP_SUCCESS: "UPDATE_PRODUCTION_STEP_SUCCESS",
    UPDATE_PRODUCTION_STEP_FAILURE: "UPDATE_PRODUCTION_STEP_FAILURE",
    FETCH_REPORT_LIST_REQUEST: "FETCH_REPORT_LIST_REQUEST",
    FETCH_REPORT_LIST_SUCCESS: "FETCH_REPORT_LIST_SUCCESS",
    FETCH_REPORT_LIST_FAILURE: "FETCH_REPORT_LIST_FAILURE",
    FETCH_SINGLE_REPORT_REQUEST: "FETCH_SINGLE_REPORT_REQUEST",
    FETCH_SINGLE_REPORT_SUCCESS: "FETCH_SINGLE_REPORT_SUCCESS",
    FETCH_SINGLE_REPORT_FAILURE: "FETCH_SINGLE_REPORT_FAILURE",
    SAVE_REPORT_REQUEST: "SAVE_REPORT_REQUEST",
    SAVE_REPORT_SUCCESS: "SAVE_REPORT_SUCCESS",
    SAVE_REPORT_FAILURE: "SAVE_REPORT_FAILURE",
    ADD_TO_INVENTORY_REQUEST: "ADD_TO_INVENTORY_REQUEST",
    ADD_TO_INVENTORY_SUCCESS: "ADD_TO_INVENTORY_SUCCESS",
    ADD_TO_INVENTORY_FAILURE: "ADD_TO_INVENTORY_FAILURE",
    FETCH_PRODUCTION_FORECAST_LIST_REQUEST: "FETCH_PRODUCTION_FORECAST_LIST_REQUEST",
    FETCH_PRODUCTION_FORECAST_LIST_SUCCESS: "FETCH_PRODUCTION_FORECAST_LIST_SUCCESS",
    FETCH_PRODUCTION_FORECAST_LIST_FAILURE: "FETCH_PRODUCTION_FORECAST_LIST_FAILURE",
    DELETE_PRODUCTION_PLAN_REQUEST: "DELETE_PRODUCTION_PLAN_REQUEST",
    DELETE_PRODUCTION_PLAN_SUCCESS: "DELETE_PRODUCTION_PLAN_SUCCESS",
    DELETE_PRODUCTION_PLAN_FAILURE: "DELETE_PRODUCTION_PLAN_FAILURE",
    CREATE_WORK_ORDER_REQUEST: "CREATE_WORK_ORDER_REQUEST",
    CREATE_WORK_ORDER_SUCCESS: "CREATE_WORK_ORDER_SUCCESS",
    CREATE_WORK_ORDER_FAILURE: "CREATE_WORK_ORDER_FAILURE",
    FETCH_BOM_STOCK_REQUEST: "FETCH_BOM_STOCK_REQUEST",
    FETCH_BOM_STOCK_SUCCESS: "FETCH_BOM_STOCK_SUCCESS",
    FETCH_BOM_STOCK_FAILURE: "FETCH_BOM_STOCK_FAILURE",
    FETCH_NON_FINISHED_INVENTORY_STOCK_REQUEST: "FETCH_NON_FINISHED_INVENTORY_STOCK_REQUEST",
    FETCH_NON_FINISHED_INVENTORY_STOCK_SUCCESS: "FETCH_NON_FINISHED_INVENTORY_STOCK_SUCCESS",
    FETCH_NON_FINISHED_INVENTORY_STOCK_FAILURE: "FETCH_NON_FINISHED_INVENTORY_STOCK_FAILURE",
    START_WORK_ORDER_REQUEST: "START_WORK_ORDER_REQUEST",
    START_WORK_ORDER_SUCCESS: "START_WORK_ORDER_SUCCESS",
    START_WORK_ORDER_FAILURE: "START_WORK_ORDER_FAILURE",
    END_WORK_ORDER_REQUEST: "END_WORK_ORDER_REQUEST",
    END_WORK_ORDER_SUCCESS: "END_WORK_ORDER_SUCCESS",
    END_WORK_ORDER_FAILURE: "END_WORK_ORDER_FAILURE",
    FETCH_PENDING_QC_REQUEST: "FETCH_PENDING_QC_REQUEST",
    FETCH_PENDING_QC_SUCCESS: "FETCH_PENDING_QC_SUCCESS",
    FETCH_PENDING_QC_FAILURE: "FETCH_PENDING_QC_FAILURE",
    DUPLICATE_PRODUCTION_PLAN_REQUEST: "DUPLICATE_PRODUCTION_PLAN_REQUEST",
    DUPLICATE_PRODUCTION_PLAN_SUCCESS: "DUPLICATE_PRODUCTION_PLAN_SUCCESS",
    DUPLICATE_PRODUCTION_PLAN_FAILURE: "DUPLICATE_PRODUCTION_PLAN_FAILURE",
    DUPLICATE_WORK_ORDER_REQUEST: "DUPLICATE_WORK_ORDER_REQUEST",
    DUPLICATE_WORK_ORDER_SUCCESS: "DUPLICATE_WORK_ORDER_SUCCESS",
    DUPLICATE_WORK_ORDER_FAILURE: "DUPLICATE_WORK_ORDER_FAILURE",
};
