import Dropdown from "components/common/Dropdown";
import TrackTraceMap from "components/common/TrackTraceMap";
import PageTitleSection from "components/common/PageTitleSection";
import TabsContainer from "components/common/TabsContainer";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Dashboard from "components/views/Synth2.0/Inventory/Tabs/Dashboard";
import ProductList from "components/views/Synth2.0/Inventory/Tabs/ProductList";
import Warehouse from "components/views/Synth2.0/Inventory/Tabs/Warehouse";
import Alerts from "components/views/Synth2.0/Inventory/Tabs/Alerts";
import StockControl from "components/views/Synth2.0/Inventory/Tabs/StockControl";
import ExpirationTracking from "components/views/Synth2.0/Inventory/Tabs/ExpirationTracking";
import SkuMapping from "components/views/Synth2.0/Inventory/Tabs/SkuMapping";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import Button from "components/common/Button";
import useGetInventoryIncidents from "hooks/inventoryHooks/useGetInventoryIncidents";
import useGetAllWarehouses from "hooks/useGetAllWarehouses";
import { truncate } from "lodash";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import Snapshots from "components/views/Synth2.0/Inventory/Tabs/InventorySnapshots";
import AddWarehouse from "pages/Ldb/Inventory/Warehouse/AddWarehouse";
import TextareaInput from "components/common/InputField/TextareaInput";
import AddressInput from "components/common/InputField/AddressInput";
import CustomTable from "components/common/CustomTable";
import { formatMoney } from "helpers";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";

type YourProductType = {
    _id?: string;
    inventoryId?: string;
    productName?: string;
    minimumStockLevel: number;
    maximumStockLevel: number;
    bufferStock: number;
};

const Inventory = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [incidentType, setIncidentType] = useState("");
    const [addWarehouseModal, setAddWarehouseModal] = useState(false);
    const [productDetails, setProductDetails] = useState({
        warehouseName: "",
        contactPersonName: "",
        additionalNote: "",
        contactPersonEmail: "",
    });
    const [selectProduct, setSelectProduct] = useState<{ [key: string]: any }[]>([]);
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [products, setProducts] = useState<YourProductType[]>([]);
    const [productData, setProductData] = useState<YourProductType[]>([]);
    const [addProduct, setAddProduct] = useState<boolean>(false);
    const [searchProduct, setSearchProduct] = useState("");
    const [addWarehouseSuccess, setAddWarehouseSuccess] = useState<boolean>(false);
    const [searchInventoryList, setSearchInventoryList] = useState<{ [key: string]: any }[]>([]);
    const [loadMoreSearchInventory, setLoadMoreSearchInventory] = useState(false);
    const [searchInventoryPagination, setSearchInventoryPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [inputValues, setInputValues] = useState({});
    const [disabledRows, setDisabledRows] = useState({});
    const [clickCount, setClickCount] = useState({});

    const tabs = [
        // "Dashboard",
        "All Products",
        "Stock Control",
        "Incidents",
        "Warehouse",
        "Expiration Tracking",
        "SKU Mapping",
        "Inventory Snapshot",
    ];
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const currTab = param.get("currTab");
    const dispatch = useAppDispatch();
    const inputRef = useRef(null);

    const {
        data,
        isFetching,
        pagination,
        isLoadingMore,
        handleClearFilter,
        handleIncidentTypeChange,
        handleWarehouseChange,
    } = useGetInventoryIncidents();

    const {
        data: warehouseList,
        warehouseValue: warehouseDataValue,
        searchTwo,
        handleDebouncedChangeTwo,
        handleSearchTwo,
        searchResult,
        handleFilterChange: filterChange,
        handleClearFilter: clearFilter,
    } = useGetAllWarehouses();

    const {
        fetchingSearchProductListing,
        fetchedSearchProductListingSuccess,
        createWarehouse,
        createWarehouseSuccess,
    } = useAppSelector((state) => state.inventory);

    const getSelectedInputValues = () => {
        const selectedInputValues = selectProduct.map((selectedItem) => ({
            inventoryId: selectedItem?._id,
            minimumStockLevel: inputValues[selectedItem._id]?.minimumStockLevel || 0,
            maximumStockLevel: inputValues[selectedItem._id]?.maximumStockLevel || 0,
            bufferStock: inputValues[selectedItem._id]?.bufferStock || 0,
        }));

        return selectedInputValues;
    };

    const getSelectedProductValues = () => {
        const selectedProductValues = selectProduct?.map((selectedItem) => ({
            productName: selectedItem?.productName,
            minimumStockLevel: inputValues[selectedItem._id]?.minimumStockLevel || 0,
            maximumStockLevel: inputValues[selectedItem._id]?.maximumStockLevel || 0,
            bufferStock: inputValues[selectedItem._id]?.bufferStock || 0,
        }));

        return selectedProductValues;
    };

    const handleSelectAllProducts = (deselect = false) => {
        const selectedProducts = deselect ? [] : searchInventoryList;
        setSelectProduct(selectedProducts);
    };

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setProductDetails((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleAddProductToWarehouse = () => {
        const selectedValues = getSelectedInputValues();
        const selectedProductValues = getSelectedProductValues();
        setProducts(selectedValues);
        setProductData(selectedProductValues);
        setAddProduct(false);
    };

    const handleStockChange = (item, inputType, actionType) => {
        setInputValues((prevInputValues) => {
            const currentValue = prevInputValues[item?._id] || {
                minimumStockLevel: 0,
                maximumStockLevel: 0,
                bufferStock: 0,
            };
            let newValue;
            switch (actionType) {
                case "decrease":
                    newValue = {
                        ...currentValue,
                        [inputType]: currentValue[inputType] - 1,
                    };
                    break;
                case "increase":
                    newValue = {
                        ...currentValue,
                        [inputType]: currentValue[inputType] + 1,
                    };
                    break;
                default:
                    newValue = currentValue;
            }

            return {
                ...prevInputValues,
                [item?._id]: newValue,
            };
        });
    };

    const handleCheckboxClick = (itemSelected) => {
        const clickCountCopy = { ...clickCount };
        const disabledRowsCopy = { ...disabledRows };

        if (!clickCountCopy[itemSelected?._id]) {
            clickCountCopy[itemSelected?._id] = 1;
        } else {
            clickCountCopy[itemSelected?._id] += 1;
            if (clickCountCopy[itemSelected?._id] % 2 === 0) {
                disabledRowsCopy[itemSelected?._id] = !disabledRowsCopy[itemSelected?._id];
            }
        }

        setClickCount(clickCountCopy);
        setDisabledRows(disabledRowsCopy);
        const check = selectProduct.some((product) => product._id === itemSelected?._id);

        const updatedSelectProduct = check
            ? selectProduct.filter((product) => product?._id !== itemSelected?._id)
            : [...selectProduct, itemSelected];

        setSelectProduct(updatedSelectProduct);
    };

    const handleSelectEachProduct = (itemSelected, values) => {
        const selectedProductsCopy = { ...inputValues };

        if (!selectedProductsCopy[itemSelected?._id]) {
            selectedProductsCopy[itemSelected?._id] = {};
        }

        selectedProductsCopy[itemSelected?._id] = {
            ...selectedProductsCopy[itemSelected?._id],
            ...values,
        };

        setInputValues(selectedProductsCopy);
    };

    const tableProductHeader = [
        { title: "Product name", widthClass: "w-[50%] !py-2 !text-slate-700" },
        { title: "Minimum Qty", widthClass: "w-[16%] !text-slate-700" },
        { title: "Maximum Qty", widthClass: "w-[17%] !text-slate-700" },
        { title: "Buffer Stock", widthClass: "w-[17%] !text-slate-700" },
    ];

    const tableProductBody = productData?.map((item) => [
        {
            content: (
                <div className={`py-3 h-full border-slate-100 text-sm font-rocGroteskMedium`}>
                    {item?.productName}
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div className={`py-3 h-full border-slate-100 text-sm font-rocGroteskMedium`}>
                    {formatMoney().format(item?.minimumStockLevel)}
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div className={`py-3 h-full border-slate-100 text-sm font-rocGroteskMedium`}>
                    {formatMoney().format(item?.maximumStockLevel)}
                </div>
            ),
            widthClass: "!border-t",
        },
        {
            content: (
                <div className={`py-3 h-full border-slate-100 text-sm font-rocGroteskMedium`}>
                    {formatMoney().format(item?.bufferStock)}
                </div>
            ),
            widthClass: "!border-t",
        },
    ]);

    const tableHeader = [
        {
            title: "",
            widthClass: "w-[5%] !py-2 !text-slate-700",
            onSelectAll: () => {
                handleSelectAllProducts(selectProduct?.length > 0);
            },
            isAllChecked: selectProduct?.length === searchInventoryList?.length,
        },
        { title: "Product name", widthClass: "w-[48%] !py-2 !text-slate-700" },
        { title: "Minimum Qty", widthClass: "w-[18%] !text-slate-700" },
        { title: "Maximum Qty", widthClass: "w-[18%] !text-slate-700" },
        { title: "Buffer Stock", widthClass: "w-[18%] !text-slate-700" },
    ];

    const tableBody = searchInventoryList?.map((item) => {
        const minimumStockLevelInputId = `minimumStockLevel_${item?._id}`;
        const maximumStockLevelInputId = `maximumStockLevel_${item?._id}`;
        const bufferStockInputId = `bufferStock_${item?._id}`;
        const hasValue = selectProduct?.find((prod) => prod?._id === item?._id);
        if (!inputValues[item?._id]) {
            setInputValues((prevInputValues) => ({
                ...prevInputValues,
                [item?._id]: {
                    minimumStockLevel: hasValue
                        ? hasValue?.productStockSettings?.[0].minimumStockLevel
                        : 0,
                    maximumStockLevel: hasValue
                        ? hasValue?.productStockSettings?.[0]?.maximumStockLevel
                        : 0,
                    bufferStock: hasValue ? hasValue?.productStockSettings?.[0].bufferStock : 0,
                },
            }));
        }

        const isChecked = selectProduct.some((product) => product._id === item._id);
        return [
            {
                content: (
                    <div className={` h-full border-slate-100 text-sm font-rocGroteskMedium`}></div>
                ),
                widthClass: "!border-t",
                isItemChecked: isChecked,
                onCheckBoxClick: () => {
                    handleCheckboxClick(item);
                },
            },
            {
                content: (
                    <div
                        className={`py-2 pr-3 h-full flex space-x-2 items-center border-slate-100 text-sm font-rocGroteskMedium`}
                    >
                        <VendorAvatar
                            size={32}
                            imageSrc={item?.productImageDetails?.productAvatar}
                            containerClassname="!bg-none border !rounded"
                        />
                        <div>
                            <p className="text-slate-900 ">{item?.sku}</p>
                            <p className="text-slate-500 ">{item?.productName}</p>
                        </div>
                    </div>
                ),
                widthClass: "!border-t",
            },

            {
                content: (
                    <div
                        className={`py-3  pr-3  flex justify-center w-full items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50`}
                    >
                        <span
                            className="bg-[#166534]  px-[5px] rounded-l text-center py-1"
                            onClick={() => {
                                if (inputValues[item?._id]?.minimumStockLevel?.toString() > 0) {
                                    handleStockChange(item, "minimumStockLevel", "decrease");
                                }
                            }}
                        >
                            <i className="ri-subtract-line text-white text-sm font-rocGroteskMedium"></i>
                        </span>
                        <div className="text-center">
                            <TextInput
                                name="minimumStockLevel"
                                type="number"
                                id={minimumStockLevelInputId}
                                value={inputValues[item?._id]?.minimumStockLevel?.toString() || ""}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value, 10) || 0;
                                    handleSelectEachProduct(item, {
                                        ...inputValues[item?._id],
                                        minimumStockLevel: value,
                                    });
                                }}
                                inputClassName={"!text-center "}
                                disabled={!isChecked}
                                inputContainerClassname={
                                    "!rounded-none text-sm text-gun-metal px-2 py-1 uppercase !bg-[#F0FDF4] !min-w-[84px] !w-[84px]  !text-center !px-0 !py-0 !w-auto !h-[30px] !border-0"
                                }
                            />
                        </div>

                        <span
                            className="bg-[#166534] px-[5px]  rounded-r text-center py-1"
                            onClick={() => {
                                handleStockChange(item, "minimumStockLevel", "increase");
                            }}
                        >
                            <i className="ri-add-line text-white text-sm"></i>
                        </span>
                    </div>
                ),
                widthClass: "!border-t",
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex justify-center w-full items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50`}
                    >
                        <span
                            className="bg-[#166534] px-[5px]  rounded-l text-center py-1"
                            onClick={() => {
                                if (inputValues[item?._id]?.maximumStockLevel?.toString() > 0) {
                                    handleStockChange(item, "maximumStockLevel", "decrease");
                                }
                            }}
                        >
                            <i className="ri-subtract-line text-white text-sm font-rocGroteskMedium"></i>
                        </span>
                        <div className="text-center">
                            <TextInput
                                name="maximumStockLevel"
                                type="number"
                                id={maximumStockLevelInputId}
                                value={inputValues[item?._id]?.maximumStockLevel?.toString() || ""}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value, 10) || 0;
                                    handleSelectEachProduct(item, {
                                        ...inputValues[item?._id],
                                        maximumStockLevel: value,
                                    });
                                }}
                                inputClassName={"!text-center "}
                                disabled={!isChecked}
                                inputContainerClassname={
                                    "!rounded-none text-sm text-gun-metal px-2 py-1 uppercase !bg-[#F0FDF4] !min-w-[84px] !w-[84px]  !text-center !px-0 !py-0 !w-auto !h-[30px] !border-0"
                                }
                            />
                        </div>

                        <span
                            className="bg-[#166534] px-[5px]  rounded-r text-center py-1"
                            onClick={() => {
                                handleStockChange(item, "maximumStockLevel", "increase");
                            }}
                        >
                            <i className="ri-add-line text-white text-sm"></i>
                        </span>
                    </div>
                ),
                widthClass: "!border-t",
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex justify-center w-full items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50`}
                    >
                        <span
                            className="bg-[#166534] px-[5px]  rounded-l text-center py-1"
                            onClick={() => {
                                if (inputValues[item?._id]?.bufferStock?.toString() > 0) {
                                    handleStockChange(item, "bufferStock", "decrease");
                                }
                            }}
                        >
                            <i className="ri-subtract-line text-white text-sm font-rocGroteskMedium"></i>
                        </span>
                        <div className="text-center">
                            <TextInput
                                name="bufferStock"
                                type="number"
                                id={bufferStockInputId}
                                value={inputValues[item?._id]?.bufferStock?.toString() || ""}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value, 10) || 0;
                                    handleSelectEachProduct(item, {
                                        ...inputValues[item?._id],
                                        bufferStock: value,
                                    });
                                }}
                                inputClassName={"!text-center "}
                                disabled={!isChecked}
                                inputContainerClassname={
                                    "!rounded-none text-sm text-gun-metal px-2 py-1 uppercase !bg-[#F0FDF4] !min-w-[84px] !w-[84px]  !text-center !px-0 !py-0 !w-auto !h-[30px] !border-0"
                                }
                            />
                        </div>

                        <span
                            className="bg-[#166534] px-[5px]  rounded-r text-center py-1"
                            onClick={() => {
                                handleStockChange(item, "bufferStock", "increase");
                            }}
                        >
                            <i className="ri-add-line text-white text-sm"></i>
                        </span>
                    </div>
                ),
                widthClass: "!border-t",
            },
        ];
    });

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return <ProductList />;

            case 1:
                return <StockControl />;

            case 2:
                return (
                    <Alerts
                        data={data}
                        isFetching={isFetching}
                        pagination={pagination}
                        isLoadingMore={isLoadingMore}
                    />
                );

            case 3:
                return <Warehouse />;

            case 4:
                return <ExpirationTracking />;

            case 5:
                return <SkuMapping />;
            case 6:
                return <Snapshots />;

            default:
                return <ProductList />;
        }
    };

    useEffect(() => {
        setActiveTab(Number(currTab));
    }, [currTab]);

    interface WarehouseBody {
        address?: {
            lat: any;
            lng: any;
            address: any;
            details: any[];
            country: any;
        };
        contactPersonName: string;
        warehouseName?: string;
        products: YourProductType[];
        additionalNotes?: string;
        contactPersonEmail?: string;
    }

    const countryComponent = address?.Location?.details?.[0]?.address_components?.find(
        (component) => component.types.includes("country")
    );

    const onAddWareHouse = (e) => {
        e.preventDefault();

        const body: WarehouseBody = {
            contactPersonName: productDetails?.contactPersonName,
            contactPersonEmail: productDetails?.contactPersonEmail,
            products,
            ...(address?.Location?.address && {
                address: {
                    lat: address?.Location?.lng,
                    lng: address?.Location?.lat,
                    address: address?.Location?.address,
                    details: [address?.Location?.details?.[0]?.formatted_address],
                    country: countryComponent?.long_name,
                },
            }),
            ...(productDetails?.additionalNote && {
                additionalNotes: productDetails?.additionalNote,
            }),

            warehouseName: productDetails?.warehouseName,

            // ...(warehouseId &&
            //     channel === "Synth" &&
            //     fetchedSingleWarehouseListSuccess?.warehouse?.warehouseName !==
            //         productDetails?.warehouseName && {
            //         warehouseName: productDetails?.warehouseName,
            //     }),
        };

        dispatch(inventoryActions.createWarehouse(body));
    };

    useEffect(() => {
        if (Boolean(createWarehouseSuccess)) {
            setAddWarehouseSuccess(true);
            setAddWarehouseModal(false);
            dispatch(inventoryActions.resetCreateWarehouse());
            // navigate("/dashboard/inventory?currTab=3");
        }
    }, [createWarehouseSuccess]);

    return (
        <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
            <div className="mb-6">
                <PageTitleSection
                    leftComp={
                        <div className="flex items-center space-x-3">
                            <h3 className="text-xl font-rocGroteskMedium">Inventory</h3>
                        </div>
                    }
                    rightComp={
                        <div className="flex items-center space-x-1.5 bg-white border border-slate-200 rounded py-2 px-3 h-[33px]">
                            <i className="ri-signal-tower-fill text-base text-[#15803D] "></i>
                            <span className="text-xs text-[#15803DDD] font-rocGroteskMedium">
                                Real time monitoring
                            </span>
                        </div>
                    }
                />
            </div>

            <div className="relative">
                <TabsContainer
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={(idx) => {
                        setActiveTab(idx);
                        dispatch(inventoryActions.resetFetchInventoryList());
                        navigate(`/dashboard/inventory?currTab=${idx}`, { replace: true });
                    }}
                    component={displayActiveTab()}
                    className="!px-0"
                    showButtonClassName
                    itemClassName="!pb-2 !whitespace-nowrap"
                    borderLineClase={"!text-slate-100 w-[67%] max-lg:w-full mb-6"}
                    showButton={
                        <div className="">
                            {activeTab === 3 && (
                                <div className="flex  items-center space-x-3 max-lg:mb-4">
                                    {/* <Button
                                btnText={"Initiate return"}
                                type={"button"}
                                btnClassname="!py-2 !px-4 !w-fit max-sm:w-full !whitespace-nowrap !bg-slate-100"
                                icon={<i className="ri-arrow-turn-back-fill text-base"></i>}
                                onClick={() => navigate("/dashboard/purchase")}
                            /> */}
                                    <Button
                                        btnText={"Add Warehouses"}
                                        type={"button"}
                                        btnClassname="!py-2 !px-4 !w-fit !whitespace-nowrap max-sm:w-full"
                                        onClick={() =>
                                            // navigate("/dashboard/inventory/add-warehouse")
                                            setAddWarehouseModal(true)
                                        }
                                    />
                                </div>
                            )}
                            {activeTab === 2 && (
                                <div className="flex max-lg:mb-4 max-sm:space-x-0 max-sm:flex-col max-sm:space-y-3 items-center space-x-3">
                                    <Dropdown
                                        name="filter"
                                        value={""}
                                        dropdownTriggerClassName={" "}
                                        handleChange={(name, value) => {
                                            setIncidentType(value);
                                            handleIncidentTypeChange(value);
                                        }}
                                        dropdown={
                                            <div className="bg-white flex items-center max-sm:justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    {incidentType || "All Incidents types"}
                                                </span>

                                                <div className="flex items-center space-x-2">
                                                    {incidentType !== "" && (
                                                        <i
                                                            onClick={(e) => {
                                                                e?.stopPropagation();
                                                                setIncidentType("");
                                                                handleClearFilter();
                                                            }}
                                                            className="ri-close-circle-fill text-sm text-slate-500"
                                                        ></i>
                                                    )}
                                                    <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                                </div>
                                            </div>
                                        }
                                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                        dropdownContainerClasses={`shadow-cardShadow-4  !mt-0  !w-[159px] max-sm:!w-full  rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                        wholeContainerClass={`max-sm:!w-full`}
                                        dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                        dropdownOptions={[
                                            {
                                                label: "Overstocking",
                                                value: "overstocking",
                                            },
                                            {
                                                label: "Stock Out",
                                                value: "stockout",
                                            },
                                        ]}
                                    />

                                    <div className="border rounded border-slate-200 max-sm:w-full">
                                        <Dropdown
                                            handleChange={(name, value) => {
                                                handleWarehouseChange(value);
                                            }}
                                            dropdown={
                                                <div className="flex items-center space-x-2 whitespace-nowrap h-[40px] py-2 px-4 justify-between ">
                                                    <div className="flex items-center space-x-1">
                                                        <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                                        <span
                                                            className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                                        >
                                                            {warehouseDataValue?.name ||
                                                                "All Warehouses / Outlets"}
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center space-x-2">
                                                        {warehouseDataValue?.name && (
                                                            <i
                                                                onClick={() => {
                                                                    handleWarehouseChange("");
                                                                    clearFilter();
                                                                }}
                                                                className="ri-close-circle-line text-slate-500"
                                                            ></i>
                                                        )}
                                                        <i className="ri-arrow-down-s-line text-g-75"></i>
                                                    </div>
                                                </div>
                                            }
                                            search={
                                                <div>
                                                    <TextInput
                                                        value={searchTwo}
                                                        name={"search"}
                                                        type={"text"}
                                                        required={false}
                                                        onChange={handleSearchTwo}
                                                        onInput={handleDebouncedChangeTwo}
                                                        inputPlaceholder="Search warehouse"
                                                        containerClassname=""
                                                        // inputContainerClassname={"!border-none !rounded-none "}
                                                        // inputClassName="w-[50%]"
                                                    />
                                                </div>
                                            }
                                            dropdownContainerClasses={`shadow-cardShadow-4 !right-0 max-sm:!w-full  border border-slate-100  rounded `}
                                            wholeContainerClass={`max-sm:!w-full`}
                                            dropdownOptions={[
                                                {
                                                    customChild: (
                                                        <div>
                                                            <div>
                                                                {searchResult?.map((warehouse) => {
                                                                    return (
                                                                        <div
                                                                            key={warehouse?._id}
                                                                            onClick={() => {
                                                                                filterChange(
                                                                                    warehouse?.warehouseName,
                                                                                    warehouse?._id
                                                                                );

                                                                                handleWarehouseChange(
                                                                                    warehouse?._id
                                                                                );
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className={`flex items-center space-x-3 max-w-[300px] px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                                                    warehouseDataValue?.value ===
                                                                                        warehouse?._id &&
                                                                                    "bg-slate-100"
                                                                                }`}
                                                                            >
                                                                                <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                                                <div>
                                                                                    <span
                                                                                        className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                                            warehouse
                                                                                                ?.address
                                                                                                ?.address &&
                                                                                            "mb-[2px]"
                                                                                        }`}
                                                                                    >
                                                                                        {
                                                                                            warehouse?.warehouseName
                                                                                        }
                                                                                    </span>
                                                                                    <span className="block  text-g-75 text-sm font-rocGroteskMedium ">
                                                                                        {
                                                                                            warehouse
                                                                                                ?.address
                                                                                                ?.address
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    ),
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                />
            </div>

            <ModalContainer
                open={addWarehouseModal}
                modalClassName={"p-6 !rounded-[8px]"}
                tailwindClassName="w-[55%]"
                showCloseIcon={false}
                closeModal={() => {
                    setAddWarehouseModal(false);
                }}
            >
                <form
                    onSubmit={(e) => onAddWareHouse(e)}
                    className="bg-white rounded-[8px] max-h-[70vh] overflow-y-scroll relative"
                >
                    <div className="flex items-center justify-between pb-[14px] px-6 pt-6 border-b border-slate-200">
                        <div className="flex items-center space-x-3">
                            <div className="w-10 h-10 rounded bg-[#F3E8FF] flex items-center justify-center">
                                <i className="ri-store-2-line text-[#9333EA] text-[22px]"></i>
                            </div>
                            <p className="text-xl font-rocGroteskMedium">Add warehouse</p>
                        </div>

                        <i
                            className="ri-close-fill text-2xl cursor-pointer"
                            onClick={() => {
                                setAddWarehouseModal(false);
                            }}
                        ></i>
                    </div>

                    <div className="p-6 h-[48vh] overflow-y-auto">
                        <div className="w-full mb-6">
                            <div className="mb-6">
                                <div className="flex items-center w-full justify-between mb-4">
                                    <p className="text-sm font-rocGroteskMedium">
                                        Warehouse name <span className="text-r-50">*</span>
                                    </p>
                                </div>
                                <div className="border-b-[1px] border-b-[#E2E8F0] pb-5">
                                    <input
                                        type="text"
                                        placeholder="Enter a warehouse name"
                                        className="!bg-white !text-2xl font-rocGroteskMedium  placeholder:text-slate-300 !border-none outline-none w-full placeholder:pl-1 caret-slate-700 !leading-6"
                                        ref={inputRef}
                                        name="warehouseName"
                                        value={productDetails?.warehouseName ?? ""}
                                        onChange={handleChange}
                                        required
                                        // disabled={warehouseId && channel !== "Synth"}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col space-y-6">
                                <AddressInput
                                    setData={setAddress}
                                    savedAddress={
                                        address?.streetAddress
                                            ? address?.streetAddress?.address
                                            : ""
                                    }
                                    placeholder="Warehouse location"
                                    required={true}
                                    name={"Location"}
                                    // disabled={warehouseId && channel !== "Synth"}
                                />
                                <div className="flex items-center space-x-4">
                                    <div className="w-1/2">
                                        <TextInput
                                            name={"contactPersonName"}
                                            value={productDetails?.contactPersonName ?? ""}
                                            onChange={handleChange}
                                            placeholder={"Contact person's full name"}
                                            containerClassname=""
                                            required
                                        />
                                    </div>
                                    <div className="w-1/2">
                                        <TextInput
                                            name={"contactPersonEmail"}
                                            value={productDetails?.contactPersonEmail ?? ""}
                                            onChange={handleChange}
                                            placeholder={"Contact person's email address"}
                                            containerClassname=""
                                            type="email"
                                            required
                                        />
                                    </div>
                                </div>
                                <div>
                                    <TextareaInput
                                        name={"additionalNote"}
                                        value={productDetails?.additionalNote ?? ""}
                                        onChange={handleChange}
                                        rows={7}
                                        placeholder={"Additional Note"}
                                        containerClassname=""
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="">
                                {products?.length > 0 ? (
                                    <p className="text-gm-50 mb-4 font-rocGroteskMedium text-base">
                                        Products Added
                                    </p>
                                ) : (
                                    <p className="text-gm-50 mb-4 font-rocGroteskMedium text-base">
                                        Add products to warehouse
                                    </p>
                                )}
                                {products?.length > 0 ? (
                                    <div className="">
                                        <div className=" w-full mt-5 ">
                                            <CustomTable
                                                tableBody={tableProductBody}
                                                tableHeader={tableProductHeader}
                                                isAllSelectable={false}
                                                isScrollable={true}
                                                isCellBordered={true}
                                                headerContainerClass="!border-t-none !bg-slate-50 !py-3 "
                                                bodyItemClass="hover:border-slate-100 hover:bg-slate-50 "
                                                headerItemClass="font-rocGroteskMedium !py-3 !text-slate-700"
                                            />
                                        </div>
                                        {/* {channel !== "Synth" && !warehouseId && ( */}
                                        <div className=" my-6">
                                            <Button
                                                btnText={"View products"}
                                                type={"button"}
                                                btnClassname={"!py-2 !w-fit !text-gm-50"}
                                                disabled={createWarehouse}
                                                onClick={() => setAddProduct(true)}
                                            />
                                        </div>
                                        {/* )} */}
                                    </div>
                                ) : (
                                    <div className="pt-4 mb-10">
                                        <div className="space-y-2.5 border-[1.5px] rounded border-dashed py-[43px] flex flex-col justify-center items-center">
                                            <i className="ri-box-3-line text-slate-500 text-[40px]"></i>
                                            <div className="flex items-center flex-col">
                                                <p className="text-gm-50 text-sm font-rocGroteskMedium">
                                                    No products found
                                                </p>
                                                <p className="text-[#6B778C] font-rocGroteskMedium text-sm text-center">
                                                    Store all products in the warehouse for precise
                                                    tracking and efficient management.
                                                </p>
                                            </div>
                                            <Button
                                                btnText="Add products"
                                                btnClassname="!bg-[#F4F5F7] !py-2 !w-fit"
                                                onClick={() => setAddProduct(true)}
                                                disabled={false}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>

                            <ModalContainer
                                open={addProduct}
                                showCloseIcon={false}
                                closeModal={() => setAddProduct(false)}
                                tailwindClassName="w-[90%] lg:w-[1000px]"
                            >
                                <div className="bg-white  rounded-lg max-h-[90vh] overflow-y-scroll">
                                    <div className=" px-6 py-4 flex gap-4 items-center justify-between border-b border-b-slate-200">
                                        <p className="text-lg text-gm-50 font-rocGroteskMedium">
                                            Select products and add to warehouse
                                        </p>
                                        <i
                                            onClick={() => setAddProduct(false)}
                                            className="ri-close-fill cursor-pointer text-gm-50 text-2xl "
                                        ></i>
                                    </div>
                                    <div className="my-8 mx-8  ">
                                        <div className="">
                                            <TextInput
                                                name="search"
                                                type="text"
                                                value={searchProduct}
                                                onChange={(e) => setSearchProduct(e.target.value)}
                                                inputPlaceholder="Search for product"
                                                inputClassName={
                                                    "!h-[50px] pl-[0px] !mb-0  !font-rocGroteskMedium !text-slate-400"
                                                }
                                                leftIcon={
                                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                                }
                                                inputContainerClassname={`!rounded-tl-md !rounded-tr-md !rounded-bl-none !rounded-br-none !h-[50px] !pl-[40px] !bg-white !border !border-slate-200 ${
                                                    searchInventoryList?.length > 0 &&
                                                    "!border-b-[0.5px]"
                                                }`}
                                            />
                                        </div>
                                        {searchInventoryList?.length > 0 ? (
                                            <div className="">
                                                <div
                                                    className="max-h-[300px] overflow-y-auto"
                                                    id="custom-modal"
                                                >
                                                    <CustomTable
                                                        tableBody={tableBody}
                                                        tableHeader={tableHeader}
                                                        isAllSelectable={true}
                                                        isCellBordered={true}
                                                        headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                                        headerContainerClass="!bg-slate-50"
                                                        isScrollable={false}
                                                    />

                                                    {loadMoreSearchInventory &&
                                                        fetchingSearchProductListing && (
                                                            <div className="flex my-4 justify-center">
                                                                <Loader color="gm-25" size={4} />
                                                            </div>
                                                        )}
                                                    {searchInventoryPagination?.current ===
                                                        searchInventoryPagination?.number_of_pages && (
                                                        <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                                            End of list
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex justify-center w-full my-[5%]">
                                                <div className="border w-[635px] border-slate-100 shadow-tableNoDataShadow rounded-[8px] bg-white p-10">
                                                    <div className="flex space-x-6">
                                                        <i className="ri-box-3-line text-slate-500 text-6xl"></i>
                                                        <div className="space-y-6">
                                                            <div className="font-rocGroteskMedium space-y-2">
                                                                <p className="text-base text-gm-50 ">
                                                                    You haven't added any products
                                                                    yet
                                                                </p>
                                                                <p className="text-slate-500 text-sm">
                                                                    Start building your inventory by
                                                                    adding products and their
                                                                    details. Streamline your stock
                                                                    management effortlessly.
                                                                </p>
                                                            </div>
                                                            <Button
                                                                btnClassname="!w-fit"
                                                                btnText="Add product"
                                                                onClick={() =>
                                                                    navigate(
                                                                        "/dashboard/inventory/add-product"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {searchInventoryList?.length > 0 && (
                                        <div className="flex   px-6 border-t py-3 justify-between items-center">
                                            <div className=" ">
                                                <Button
                                                    btnText={"Add new product"}
                                                    icon={<i className="ri-add-fill"></i>}
                                                    type={"button"}
                                                    btnClassname={
                                                        "!bg-n-20 !py-3 !w-fit !text-gm-50"
                                                    }
                                                    onClick={() =>
                                                        navigate("/dashboard/inventory/add-product")
                                                    }
                                                />
                                            </div>
                                            <div className="flex  items-center space-x-2 ">
                                                <Button
                                                    btnText={"Cancel"}
                                                    type={"button"}
                                                    btnClassname={
                                                        "!bg-n-20  !py-3 !w-fit !text-gm-50"
                                                    }
                                                    disabled={false}
                                                    onClick={() => setAddProduct(false)}
                                                />
                                                <Button
                                                    btnClassname={"!py-3 !text-gm-50 !w-fit !px-8"}
                                                    onClick={handleAddProductToWarehouse}
                                                    btnText={"Add product to warehouse"}
                                                    type={"button"}
                                                    isLoading={false}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </ModalContainer>

                            {addWarehouseSuccess && (
                                <ModalContainer
                                    open={addWarehouseSuccess}
                                    showCloseIcon={false}
                                    modalClassName={"py-10 px-10"}
                                    tailwindClassName="w-[600px]"
                                    closeModal={() => {
                                        setProductData([]);
                                        setProducts([]);
                                        setProductDetails({
                                            warehouseName: "",
                                            contactPersonName: "",
                                            additionalNote: "",
                                            contactPersonEmail: "",
                                        });
                                        setAddress({});
                                        setAddWarehouseSuccess(false);
                                        navigate("/dashboard/inventory/warehouse");
                                    }}
                                >
                                    <div className="bg-white py-7 px-6  shadow-cardShadow relative rounded-[8px]">
                                        <div className="mb-5">
                                            <div className="w-10 h-10 flex justify-center mb-4 items-center bg-[#00DB8F] rounded-full  shadow-lightGreenShadow">
                                                <i className="ri-checkbox-circle-fill text-[29px] text-white "></i>
                                            </div>
                                            <h1 className="text-lg mb-2  font-rocGroteskMedium">
                                                Warehouse Added Successfully
                                            </h1>
                                            <p className="text-slate-500 font-rocGroteskMedium  text-base">
                                                The new warehouse has been added to the system
                                                successfully. Ensure all details are accurate for
                                                seamless operations.
                                            </p>
                                        </div>
                                        <div className="flex justify-end items-center ">
                                            <div className="flex items-center !whitespace-nowrap space-x-2 ">
                                                <Button
                                                    btnText={"No, don't transfer"}
                                                    type={"button"}
                                                    btnClassname={
                                                        "!bg-white !border !border-[#F4F5F7]!w-fit !text-gm-50"
                                                    }
                                                    disabled={false}
                                                    onClick={() => {
                                                        setProductData([]);
                                                        setProducts([]);
                                                        setProductDetails({
                                                            warehouseName: "",
                                                            contactPersonName: "",
                                                            additionalNote: "",
                                                            contactPersonEmail: "",
                                                        });
                                                        setAddress({});
                                                        setAddWarehouseSuccess(false);
                                                        navigate("/dashboard/inventory/warehouse");
                                                    }}
                                                />
                                                <Button
                                                    btnClassname={"!py-3 !text-gm-50  !w-fit"}
                                                    onClick={() =>
                                                        navigate("/dashboard/inventory/warehouse")
                                                    }
                                                    btnText={"View warehouse"}
                                                    type={"button"}
                                                    isLoading={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ModalContainer>
                            )}
                        </div>
                    </div>

                    <div className="flex items-center space-x-4 py-4 px-6 border-t border-slate-200">
                        <Button
                            btnText={"Close"}
                            type={"button"}
                            btnClassname={"!bg-n-20  !text-gm-50"}
                            disabled={createWarehouse}
                            onClick={() => setAddWarehouseModal(false)}
                        />
                        <Button
                            btnClassname={"!py-3 "}
                            btnText={"Save warehouse"}
                            type={"submit"}
                            isLoading={createWarehouse}
                        />
                    </div>
                </form>
            </ModalContainer>
        </div>
    );
};

export default Inventory;
