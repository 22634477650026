import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { taskActions } from "../../redux/Ldb/actions/taskActions";
import { debounce, uniqBy } from "lodash";

const useGetAllHumanTask = () => {
    const dispatch = useAppDispatch();

    const [allHumanTaskList, setAllHumanTaskList] = useState<{ [key: string]: any }[]>([]);
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [totalHumanTask, setTotalHumanTask] = useState<number>(0);

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [taskStatus, setTaskStatus] = useState<string>("");
    const [taskType, setTaskType] = useState<string>("");
    const { fetchingAllHumanTask, fetchAllHumanTaskSuccess, fetchAllHumanTaskFailure } =
        useAppSelector((state) => state.task);

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            {
                current: pagination.currentPage,
                number_of_pages: pagination.noOfPages,
            },
            10
        );

    useEffect(() => {
        setUpdatedPage(1);
    }, [debouncedSearch, taskStatus, taskType]);

    useEffect(() => {
        dispatch(
            taskActions.fetchAllHumanTaskList(
                updatedLimit,
                updatedPage,
                taskStatus,
                debouncedSearch,
                taskType
            )
        );
    }, [dispatch, debouncedSearch, updatedPage, taskStatus, taskType]);

    useEffect(() => {
        if (Boolean(fetchAllHumanTaskSuccess)) {
            setAllHumanTaskList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchAllHumanTaskSuccess?.tasks], "_id")
                    : uniqBy([...prev, ...fetchAllHumanTaskSuccess?.tasks], "_id")
            );

            setPagination({
                currentPage: fetchAllHumanTaskSuccess.pagination?.current,
                noOfPages: fetchAllHumanTaskSuccess.pagination?.number_of_pages,
            });
            setTotalHumanTask(fetchAllHumanTaskSuccess?.total);

            setLoadMore(false);
        }
    }, [fetchAllHumanTaskSuccess]);

    return {
        isFetching: fetchingAllHumanTask,
        error: fetchAllHumanTaskFailure,
        data: allHumanTaskList,
        setTaskStatus,
        setTaskType,
        isLoadingMore,
        pagination,
        setUpdatedPage,
        setLoadMore,
        setDebouncedSearch,
        totalHumanTask,
    };
};

export default useGetAllHumanTask;
