import React from "react";
import { Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
import moment from "moment";
import { formatMoney, getCurrencyFromCurrencyCode } from "../../../helpers";
import VendorAvatar from "components/common/VendorAvatar";

Font.register({
    family: "Roboto",
    fonts: [
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
            fontWeight: 400,
        },
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
            fontWeight: 500,
        },
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
            fontWeight: 600,
        },
    ],
});

const signFix = StyleSheet.create({
    page: {
        fontFamily: "Roboto",
        fontWeight: 400,
    },
});

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#fff",
        fontWeight: 500,
        fontFamily: "Roboto",
    },
    pageLayout: {
        padding: 20,
    },
});

const tableStyles = StyleSheet.create({
    section: {
        marginTop: 20,
        marginBottom: 20,
    },
    headingSection: {
        marginBottom: 2,
        color: "#334155",
        fontWeight: "medium",
    },
    heading: {
        fontSize: 16,
        marginBottom: "16px",
        color: "#1E293B",
        borderBottom: "1px solid #F1F5F9",
    },
    container: {
        width: "100%",
        backgroundColor: "white",
    },
    tableBody: {
        borderLeft: "1 solid #E2E8F0",
        borderRight: "1 solid #E2E8F0",
        borderBottom: "1 solid #E2E8F0",
    },
    header: {
        backgroundColor: "#F8FAFC",
        flexDirection: "row",
        justifyContent: "space-between",
        borderLeft: "1 solid #E2E8F0",
        borderTop: "1 solid #E2E8F0",
        borderRight: "1 solid #E2E8F0",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 16,
        textAlign: "left",
        fontSize: 10,
        color: "#334155",
        fontWeight: "medium",
        alignItems: "center",
    },
    header2: {
        backgroundColor: "#F8FAFC",
        flexDirection: "row",
        justifyContent: "space-between",
        borderLeft: "1 solid #E2E8F0",
        borderRight: "1 solid #E2E8F0",
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 16,
        textAlign: "left",
        fontSize: 10,
        color: "#334155",
        fontWeight: "medium",
        alignItems: "center",
    },
    footer: {
        backgroundColor: "#F8FAFC",
        flexDirection: "row",
        justifyContent: "space-between",
        borderLeft: "1 solid #E2E8F0",
        borderRight: "1 solid #E2E8F0",
        borderBottom: "1 solid #E2E8F0",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        textAlign: "left",
        fontSize: 10,
        color: "#334155",
        fontWeight: "medium",
        alignItems: "center",
    },
    row: {
        flexDirection: "row",
        borderTop: "1 solid #E2E8F0",
        justifyContent: "space-between",
        fontSize: 10,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 16,
        color: "#3B4C58",
        fontWeight: "medium",
    },
    row2: {
        flexDirection: "row",
        borderTop: "1 solid #E2E8F0",
        justifyContent: "space-between",
        fontSize: 10,
        color: "#3B4C58",
        fontWeight: "medium",
    },
    cellWdith: {
        width: "23%",
    },
    cellWdith2: {
        width: "10%",
    },
    cellWdith3: {
        width: "12%",
    },
    rounded: {
        borderRadius: 9999,
        width: 7,
        height: 7,
    },
    pending: {
        backgroundColor: "#F59E0B",
    },
    approved: {
        backgroundColor: "#16C6A4",
    },
    rejected: {
        backgroundColor: "#ff3347",
    },
    statusFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 6,
    },
    imgMargin: {
        marginTop: -4,
    },
    cellFlex: {
        flexDirection: "row",
        gap: 4,
        alignItems: "center",
    },
    profileImg: {
        width: 16,
        marginTop: -4,
    },
});

type ReportPDFProps = {
    taId?: string;
    product?: string;
    reportDate?: string;
    startDate?: string;
    completionDate?: string;
    shift?: any;
    quantityProduced?: any;
    // sellableUnits?: any;
    batches?: any;
    targetQuantity?: any;
    completionRate?: any;
    finishedGoods?: any;
    yieldPercentage?: any;
    discrepancyCost?: any;
    // unsellableGoodsCost?: any;
    // productionLoss?: any;
    materialsUsed?: any;
    directCosts?: any;
    indirectCosts?: any;
    directCostSum?: any;
    indirectCostSum?: any;
    checkList?: any;
    notes?: any;
};

const ReportPDF = ({
    taId,
    product,
    reportDate,
    startDate,
    completionDate,
    shift,
    quantityProduced,
    // sellableUnits,
    batches,
    targetQuantity,
    completionRate,
    finishedGoods,
    yieldPercentage,
    discrepancyCost,
    // unsellableGoodsCost,
    // productionLoss,
    materialsUsed,
    directCosts,
    indirectCosts,
    directCostSum,
    indirectCostSum,
    checkList,
    notes,
}: ReportPDFProps) => {
    return (
        <Document>
            <Page size="A4" style={[styles.page, styles.pageLayout]} orientation="landscape">
                <View style={{ padding: "12px 0" }}>
                    <View
                        style={{
                            paddingVertical: 6,
                            borderBottomWidth: 1,
                            borderBottomColor: "#E2E8F0",
                        }}
                    >
                        <Text style={{ color: "#142837", fontSize: 11, marginBottom: 4 }}>
                            Production report ID
                        </Text>
                        <Text style={{ color: "#04080B", fontSize: 20 }}>
                            {taId && `PR-${taId}`}
                        </Text>
                    </View>
                    <View
                        style={{
                            display: "flex",
                            padding: "10px 0",
                            borderBottom: "1px solid #E2E8F0",
                            gap: "10px",
                            flexDirection: "row",
                        }}
                    >
                        <View style={{ width: "20%" }}>
                            <Text style={{ color: "#64748B", fontSize: 11 }}>Product</Text>
                            <Text style={{ color: "#142837", fontSize: 11 }}>{product}</Text>
                        </View>
                        <View style={{ width: "20%" }}>
                            <Text style={{ color: "#64748B", fontSize: 11 }}>Report Date</Text>
                            <Text style={{ color: "#142837", fontSize: 11 }}>
                                {reportDate ? moment(reportDate)?.format("MMM DD, YYYY") : "N/A"}
                            </Text>
                        </View>
                        <View style={{ width: "20%" }}>
                            <Text style={{ color: "#64748B", fontSize: 11 }}>
                                Production Start Date
                            </Text>
                            <Text style={{ color: "#142837", fontSize: 11 }}>
                                {startDate ? moment(startDate)?.format("MMM DD, YYYY") : "N/A"}
                            </Text>
                        </View>
                        <View style={{ width: "20%" }}>
                            <Text style={{ color: "#64748B", fontSize: 11 }}>
                                Production Completion Date
                            </Text>
                            <Text style={{ color: "#142837", fontSize: 11 }}>
                                {completionDate
                                    ? moment(completionDate)?.format("MMM DD, YYYY")
                                    : "N/A"}
                            </Text>
                        </View>
                        <View style={{ width: "20%" }}>
                            <Text style={{ color: "#64748B", fontSize: 11 }}>Shift</Text>
                            <Text style={{ color: "#142837", fontSize: 11 }}>{shift}</Text>
                        </View>
                    </View>
                </View>

                <View style={{ padding: "14px 0" }}>
                    <View style={{ paddingBottom: "14px" }}>
                        <Text
                            style={{
                                fontSize: "14px",
                                marginBottom: "14px",
                                color: "#142837",
                                fontWeight: 500,
                            }}
                        >
                            Production summary
                        </Text>

                        <View
                            style={{
                                display: "flex",
                                gap: "8px",
                                flexDirection: "row",
                            }}
                        >
                            <View
                                style={{
                                    border: "1px solid #E2E8F0",
                                    padding: "8px",
                                    borderRadius: "6px",
                                    backgroundColor: "#E6FFF0",
                                    width: "20%",
                                }}
                            >
                                <Text
                                    style={{
                                        color: "#042821",
                                        fontSize: "10px",
                                    }}
                                >
                                    Total quantity produced
                                </Text>

                                <Text
                                    style={{
                                        color: "#00A643",
                                        fontSize: "11px",
                                        paddingTop: "8px",
                                        textAlign: "left",
                                        fontWeight: 500,
                                        width: "100%",
                                    }}
                                >
                                    {quantityProduced}
                                </Text>
                            </View>
                            {/* <View
                                style={{
                                    border: "1px solid #E2E8F0",
                                    padding: "8px",
                                    borderRadius: "6px",
                                    width: "20%",
                                }}
                            >
                                textAlign: "left"
                                <Text style={{ color: "#64748B", fontSize: "10px" }}>
                                    Sellable Units
                                </Text>
                                <Text
                                    style={{
                                        fontSize: "11px",
                                        color: "#042821",
                                        paddingTop: "8px",
                                        textAlign: "left",
                                    }}
                                >
                                    {sellableUnits}
                                </Text>
                            </View> */}
                            <View
                                style={{
                                    border: "1px solid #E2E8F0",
                                    padding: "8px",
                                    borderRadius: "6px",
                                    width: "20%",
                                }}
                            >
                                <Text style={{ color: "#64748B", fontSize: "10px" }}>
                                    Number of batches
                                </Text>

                                <Text
                                    style={{
                                        fontSize: "11px",
                                        color: "#042821",
                                        paddingTop: "8px",
                                        textAlign: "left",
                                    }}
                                >
                                    {batches}
                                </Text>
                            </View>
                            <View
                                style={{
                                    border: "1px solid #E2E8F0",
                                    padding: "8px",
                                    borderRadius: "6px",
                                    width: "20%",
                                }}
                            >
                                <Text style={{ color: "#64748B", fontSize: "10px" }}>
                                    Production target quantity
                                </Text>

                                <Text
                                    style={{
                                        fontSize: "11px",
                                        color: "#042821",
                                        paddingTop: "8px",
                                        textAlign: "left",
                                    }}
                                >
                                    {targetQuantity}
                                </Text>
                            </View>
                            <View
                                style={{
                                    border: "1px solid #E2E8F0",
                                    padding: "8px",
                                    borderRadius: "6px",
                                    width: "20%",
                                }}
                            >
                                <Text style={{ color: "#64748B", fontSize: "10px" }}>
                                    Completion rate
                                </Text>

                                <Text
                                    style={{
                                        fontSize: "11px",
                                        color: "#042821",
                                        paddingTop: "8px",
                                        textAlign: "left",
                                    }}
                                >
                                    {completionRate}
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View>
                        <View style={{ paddingBottom: "14px" }}>
                            {finishedGoods && (
                                <View style={tableStyles.section}>
                                    <View style={tableStyles.headingSection}>
                                        <Text
                                            style={{
                                                fontSize: "14px",
                                                color: "#142837",
                                                marginBottom: "14px",
                                            }}
                                        >
                                            Finished good to be added to inventory
                                        </Text>
                                    </View>
                                    <View style={tableStyles.container}>
                                        <View style={tableStyles.header}>
                                            <Text
                                                style={{
                                                    width: "50%",
                                                }}
                                            >
                                                Product
                                            </Text>
                                            <Text
                                                style={{
                                                    width: "12%",
                                                }}
                                            >
                                                Previous Stock
                                            </Text>
                                            <Text
                                                style={{
                                                    width: "12%",
                                                }}
                                            >
                                                Added
                                            </Text>
                                            <Text
                                                style={{
                                                    width: "12%",
                                                }}
                                            >
                                                New stock
                                            </Text>
                                            <Text
                                                style={{
                                                    width: "12%",
                                                }}
                                            >
                                                LOT Number
                                            </Text>
                                        </View>
                                        <View style={tableStyles.tableBody}>
                                            {finishedGoods.map((product, idx) => (
                                                <View key={idx} style={tableStyles.row2}>
                                                    <View
                                                        style={{
                                                            width: "50%",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            paddingTop: 6,
                                                            paddingBottom: 6,
                                                            paddingLeft: 16,
                                                        }}
                                                    >
                                                        <Text style={{ color: "#042821" }}>
                                                            {product?.inventory?.productName}
                                                        </Text>
                                                        <Text style={{ color: "#64748B" }}>
                                                            {product?.inventory?.sku}
                                                        </Text>
                                                    </View>

                                                    <Text
                                                        style={{
                                                            width: "12%",
                                                            paddingTop: 6,
                                                            paddingBottom: 6,
                                                        }}
                                                    >
                                                        {`${formatMoney()?.format(
                                                            product?.previousStockLevel?.amount
                                                        )} ${
                                                            product?.previousStockLevel
                                                                ?.unitOfMeasurement
                                                        }`}
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            width: "12%",
                                                            backgroundColor: "#E6FFF0",
                                                            color: "#00A643",
                                                            paddingTop: 6,
                                                            paddingBottom: 6,
                                                            paddingLeft: 6,
                                                        }}
                                                    >
                                                        {`${formatMoney()?.format(
                                                            product?.quantity?.amount
                                                        )} ${product?.quantity?.unitOfMeasurement}`}
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            width: "12%",
                                                            paddingTop: 6,
                                                            paddingBottom: 6,
                                                        }}
                                                    >
                                                        {`${formatMoney()?.format(
                                                            product?.previousStockLevel?.amount +
                                                                product?.quantity?.amount
                                                        )} ${
                                                            product?.previousStockLevel
                                                                ?.unitOfMeasurement
                                                        }`}
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            width: "12%",
                                                            paddingTop: 6,
                                                            paddingBottom: 6,
                                                        }}
                                                    >
                                                        {product?.batchId}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                    </View>
                                </View>
                            )}
                        </View>

                        <View style={{ paddingBottom: "40px" }}>
                            <Text
                                style={{
                                    fontSize: "14px",
                                    color: "#142837",
                                    marginBottom: "14px",
                                }}
                            >
                                Production yield
                            </Text>

                            <View
                                style={{
                                    display: "flex",
                                    gap: "8px",
                                    flexDirection: "row",
                                }}
                            >
                                <View
                                    style={{
                                        border: "1px solid #E2E8F0",
                                        padding: "8px",
                                        borderRadius: "6px",
                                        width: "30%",
                                    }}
                                >
                                    <Text style={{ color: "#64748B", fontSize: "10px" }}>
                                        Total Units produced
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: "11px",
                                            color: "#042821",
                                            paddingTop: "8px",
                                            textAlign: "left",
                                        }}
                                    >
                                        {quantityProduced}
                                    </Text>
                                </View>
                                {/* <View
                                    style={{
                                        border: "1px solid #E2E8F0",
                                        padding: "8px",
                                        borderRadius: "6px",
                                        width: "30%",
                                    }}
                                >
                                    <Text style={{ color: "#64748B", fontSize: "10px" }}>
                                        Sellable Units
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: "11px",
                                            color: "#042821",
                                            paddingTop: "8px",
                                            textAlign: "left",
                                        }}
                                    >
                                        {sellableUnits}
                                    </Text>
                                </View> */}
                                <View
                                    style={{
                                        border: "1px solid #E2E8F0",
                                        padding: "8px",
                                        borderRadius: "6px",
                                        width: "30%",
                                    }}
                                >
                                    <Text style={{ color: "#64748B", fontSize: "10px" }}>
                                        Yield percentage
                                    </Text>

                                    <Text
                                        style={{
                                            fontSize: "11px",
                                            color: "#042821",
                                            paddingTop: "8px",
                                            textAlign: "left",
                                        }}
                                    >
                                        {yieldPercentage}%
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={{ paddingBottom: "14px" }}>
                            <Text
                                style={{
                                    fontSize: "14px",
                                    color: "#142837",
                                    marginBottom: "14px",
                                }}
                            >
                                Production Loss result
                            </Text>

                            <View
                                style={{
                                    display: "flex",
                                    gap: "8px",
                                    flexDirection: "row",
                                }}
                            >
                                <View
                                    style={{
                                        border: "1px solid #E2E8F0",
                                        padding: "8px",
                                        borderRadius: "6px",
                                        width: "30%",
                                    }}
                                >
                                    <Text style={{ color: "#64748B", fontSize: "10px" }}>
                                        Material Discrepancy Cost
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: "11px",
                                            color: "#042821",
                                            paddingTop: "8px",
                                            textAlign: "left",
                                        }}
                                    >
                                        {discrepancyCost}
                                    </Text>
                                </View>
                                {/* <View
                                    style={{
                                        border: "1px solid #E2E8F0",
                                        padding: "8px",
                                        borderRadius: "6px",
                                        width: "30%",
                                    }}
                                >
                                    <Text style={{ color: "#64748B", fontSize: "10px" }}>
                                        Unsellable Goods Cost
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: "11px",
                                            color: "#042821",
                                            paddingTop: "8px",
                                            textAlign: "left",
                                        }}
                                    >
                                        {unsellableGoodsCost}
                                    </Text>
                                </View> */}
                                {/* <View
                                    style={{
                                        border: "1px solid #E2E8F0",
                                        padding: "8px",
                                        borderRadius: "6px",
                                        width: "30%",
                                    }}
                                >
                                    <Text style={{ color: "#64748B", fontSize: "10px" }}>
                                        Overall Production Loss
                                    </Text>

                                    <Text
                                        style={{
                                            fontSize: "11px",
                                            color: "#042821",
                                            paddingTop: "8px",
                                            textAlign: "left",
                                        }}
                                    >
                                        {productionLoss}
                                    </Text>
                                </View> */}
                            </View>
                        </View>

                        <View style={{ paddingBottom: "14px" }}>
                            {materialsUsed && (
                                <View style={tableStyles.section}>
                                    <View style={tableStyles.headingSection}>
                                        <Text
                                            style={{
                                                fontSize: "14px",
                                                color: "#142837",
                                                marginBottom: "14px",
                                            }}
                                        >
                                            Materials used for production
                                        </Text>
                                    </View>
                                    <View style={tableStyles.container}>
                                        <View style={tableStyles.header}>
                                            <Text
                                                style={{
                                                    width: "20%",
                                                }}
                                            >
                                                Materials
                                            </Text>
                                            <Text
                                                style={{
                                                    width: "20%",
                                                }}
                                            >
                                                BOM-specified Amount
                                            </Text>
                                            <Text
                                                style={{
                                                    width: "20%",
                                                }}
                                            >
                                                Actual Amount used
                                            </Text>
                                            <Text
                                                style={{
                                                    width: "20%",
                                                }}
                                            >
                                                Discrepancy
                                            </Text>
                                            <Text
                                                style={{
                                                    width: "20%",
                                                }}
                                            >
                                                Discrepancy Cost
                                            </Text>
                                        </View>
                                        <View style={tableStyles.tableBody}>
                                            {materialsUsed?.map((bom, idx) => (
                                                <View key={idx} style={tableStyles.row}>
                                                    <View
                                                        style={{
                                                            width: "20%",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            gap: 2,
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {bom?.inventory?.productImageDetails
                                                            ?.productAvatar ? (
                                                            <Image
                                                                src={
                                                                    bom?.inventory
                                                                        ?.productImageDetails
                                                                        ?.productAvatar
                                                                }
                                                                style={{
                                                                    width: 24,
                                                                    height: 24,
                                                                }}
                                                            />
                                                        ) : (
                                                            <Image
                                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1731065817/Avatar_nmypbd.png"
                                                                style={{
                                                                    width: 24,
                                                                    height: 24,
                                                                }}
                                                            />
                                                        )}

                                                        <View
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                            }}
                                                        >
                                                            <Text style={{ color: "#042821" }}>
                                                                {bom?.inventory?.productName}
                                                            </Text>
                                                            <Text style={{ color: "#64748B" }}>
                                                                {bom?.inventory?.sku}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <Text
                                                        style={{
                                                            width: "20%",
                                                        }}
                                                    >
                                                        {bom?.quantity} {` `}
                                                        {
                                                            bom?.inventory?.productStockDetails
                                                                ?.unitOfMeasurement
                                                        }
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            width: "20%",
                                                        }}
                                                    >
                                                        {bom?.finalQuantity}
                                                        {` `}
                                                        {
                                                            bom?.inventory?.productStockDetails
                                                                ?.unitOfMeasurement
                                                        }
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            width: "20%",
                                                            ...(bom?.finalQuantity > bom?.quantity
                                                                ? { color: "#FF3D1D" }
                                                                : { color: "#56B648" }),
                                                        }}
                                                    >
                                                        {bom?.finalQuantity > bom?.quantity
                                                            ? bom?.materialLoss
                                                            : "No discrepancy"}
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            width: "20%",
                                                            ...(bom?.finalQuantity > bom?.quantity
                                                                ? { color: "#FF3D1D" }
                                                                : { color: "#56B648" }),
                                                        }}
                                                    >
                                                        {bom?.finalQuantity > bom?.quantity
                                                            ? getCurrencyFromCurrencyCode(
                                                                  bom?.costMaterialLoss?.currency
                                                              )
                                                            : ""}
                                                        {bom?.finalQuantity > bom?.quantity
                                                            ? bom?.costMaterialLoss?.amount
                                                            : "No discrepancy cost"}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                    </View>
                                </View>
                            )}
                        </View>

                        <View style={{ paddingBottom: "14px" }}>
                            {directCosts?.length >= 1 && (
                                <View style={tableStyles.section}>
                                    <View
                                        style={{
                                            color: "",
                                            fontSize: 12,
                                            padding: "4px",
                                            borderTopLeftRadius: "6px",
                                            borderTopRightRadius: "6px",
                                            borderWidth: "1px 1px 0px",
                                            borderColor: "#E2E8F0",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: "12px",
                                                color: "#142837",
                                                margin: "6px",
                                            }}
                                        >
                                            Direct cost
                                        </Text>
                                    </View>
                                    <View style={tableStyles.container}>
                                        <View style={tableStyles.header2}>
                                            <Text
                                                style={{
                                                    width: "30%",
                                                }}
                                            >
                                                Category
                                            </Text>
                                            <Text
                                                style={{
                                                    width: "30%",
                                                }}
                                            >
                                                Currency
                                            </Text>
                                            <Text
                                                style={{
                                                    width: "30%",
                                                }}
                                            >
                                                Total Estimated Cost
                                            </Text>
                                        </View>
                                        <View style={tableStyles.tableBody}>
                                            {directCosts?.map((item, idx) => (
                                                <View key={idx} style={tableStyles.row}>
                                                    <Text
                                                        style={{ color: "#042821", width: "30%" }}
                                                    >
                                                        {item?.category}
                                                    </Text>

                                                    <Text
                                                        style={{ color: "#042821", width: "30%" }}
                                                    >
                                                        USD
                                                    </Text>
                                                    <Text
                                                        style={{ color: "#042821", width: "30%" }}
                                                    >
                                                        {formatMoney()?.format(item?.amount)}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                        <View style={tableStyles.footer}>
                                            <View style={{ width: "30%" }}>
                                                <Text></Text>
                                            </View>

                                            <View style={{ width: "30%" }}>
                                                <Text></Text>
                                            </View>
                                            <Text
                                                style={{
                                                    color: "#042821",
                                                    width: "30%",
                                                    fontSize: 10,
                                                }}
                                            >
                                                {directCostSum}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            )}
                        </View>

                        <View style={{ paddingBottom: "14px" }}>
                            {indirectCosts?.length >= 1 && (
                                <View style={tableStyles.section}>
                                    <View
                                        style={{
                                            color: "",
                                            fontSize: 12,
                                            padding: "4px",
                                            borderTopLeftRadius: "6px",
                                            borderTopRightRadius: "6px",
                                            borderWidth: "1px 1px 0px",
                                            borderColor: "#E2E8F0",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: "12px",
                                                color: "#142837",
                                                margin: "6px",
                                            }}
                                        >
                                            Indirect cost
                                        </Text>
                                    </View>
                                    <View style={tableStyles.container}>
                                        <View style={tableStyles.header2}>
                                            <Text
                                                style={{
                                                    width: "30%",
                                                }}
                                            >
                                                Category
                                            </Text>
                                            <Text
                                                style={{
                                                    width: "30%",
                                                }}
                                            >
                                                Currency
                                            </Text>
                                            <Text
                                                style={{
                                                    width: "30%",
                                                }}
                                            >
                                                Total Estimated Cost
                                            </Text>
                                        </View>
                                        <View style={tableStyles.tableBody}>
                                            {indirectCosts?.map((item, idx) => (
                                                <View key={idx} style={tableStyles.row}>
                                                    <Text
                                                        style={{ color: "#042821", width: "30%" }}
                                                    >
                                                        {item?.category}
                                                    </Text>

                                                    <Text
                                                        style={{ color: "#042821", width: "30%" }}
                                                    >
                                                        USD
                                                    </Text>
                                                    <Text
                                                        style={{ color: "#042821", width: "30%" }}
                                                    >
                                                        {formatMoney()?.format(item?.amount)}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                        <View style={tableStyles.footer}>
                                            <View style={{ width: "30%" }}>
                                                <Text></Text>
                                            </View>

                                            <View style={{ width: "30%" }}>
                                                <Text></Text>
                                            </View>
                                            <Text
                                                style={{
                                                    color: "#042821",
                                                    width: "30%",
                                                    fontSize: 10,
                                                }}
                                            >
                                                {indirectCostSum}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            )}
                        </View>

                        <View style={{ paddingBottom: "20px" }}>
                            <Text
                                style={{ fontSize: "14px", marginBottom: "14px", color: "#142837" }}
                            >
                                Discrepancies and corrective actions
                            </Text>
                            <View
                                style={{
                                    border: "1px solid #E2E8F0",
                                    borderRadius: "6px",
                                }}
                            >
                                <View
                                    style={{
                                        padding: "12px 16px",
                                        borderBottom: "1px solid #E2E8F0",
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: "#801F0F",
                                            fontSize: "10px",
                                        }}
                                    >
                                        Quality Control checklists
                                    </Text>
                                </View>

                                <View style={{ padding: "12px 16px" }}>
                                    <View style={{}}>
                                        {checkList?.length >= 1 ? (
                                            checkList?.map((qc, idx) => (
                                                <Text
                                                    key={idx}
                                                    style={{ color: "#142837", fontSize: 10 }}
                                                >
                                                    {qc?.task}
                                                </Text>
                                            ))
                                        ) : (
                                            <Text style={{ color: "#142837", fontSize: 10 }}>
                                                N/A
                                            </Text>
                                        )}
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={{ paddingBottom: "14px" }}>
                            <Text
                                style={{ fontSize: "14px", marginBottom: "14px", color: "#142837" }}
                            >
                                Notes and recommendations
                            </Text>
                            <View
                                style={{
                                    border: "1px solid #E2E8F0",
                                    borderRadius: "6px",
                                }}
                            >
                                <View
                                    style={{
                                        padding: "12px 16px",
                                        borderBottom: "1px solid #E2E8F0",
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: "#04080B",
                                            fontSize: 10,
                                        }}
                                    >
                                        Your Recommendations {`for ${product}`}
                                    </Text>
                                </View>

                                <View style={{ padding: "12px 16px" }}>
                                    <Text style={{ color: "#142837", fontSize: 10 }}>
                                        {notes || "N/A"}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};
export default ReportPDF;
