import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";

const useGetProfitAnalytics = (
    generalBrand?: string,
    generalDuration?: string,
    startAndEndDate?: string[]
) => {
    const dispatch = useAppDispatch();
    const [profitAnalytics, setProfitAnalytics] = useState<{ [key: string]: any }>();
    const [dateRange, setDateRange] = useState<{ label: string; value: string }>({
        label: "",
        value: "yearly",
    });
    const [brand, setBrand] = useState<{ label: string; value: string }>({
        label: "",
        value: "",
    });

    const { fetchingProfitAnalytics, fetchProfitAnalyticsSuccess, fetchProfitAnalyticsFailure } =
        useAppSelector((state) => state.multibrand);

    const handleDurationChange = (value: { label: string; value: string }) => {
        setDateRange(value);
    };

    const handleBrandChangeFilter = (value: { label: string; value: string }) => {
        setBrand(value);
    };

    useEffect(() => {
        if (Boolean(fetchProfitAnalyticsSuccess)) {
            setProfitAnalytics(fetchProfitAnalyticsSuccess?.analytics);
        }
    }, [fetchProfitAnalyticsSuccess]);

    useEffect(() => {
        dispatch(
            multibrandActions?.getProfitAnalytics(
                typeof dateRange?.value === "number"
                    ? "custom"
                    : dateRange?.value !== generalDuration && dateRange?.value !== "yearly"
                    ? dateRange?.value
                    : generalDuration,
                brand?.value !== generalBrand && brand?.value !== "" ? brand?.value : generalBrand,
                startAndEndDate[0],
                startAndEndDate[1]
            )
        );
    }, [dispatch, dateRange?.value, brand?.value, generalBrand, generalDuration, startAndEndDate]);

    return {
        data: profitAnalytics,
        isFetching: fetchingProfitAnalytics,
        error: fetchProfitAnalyticsFailure,
        dateRange,
        handleDurationChange,
        brand,
        handleBrandChangeFilter,
    };
};

export default useGetProfitAnalytics;
