import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";

const useGetCostSummary = (
    generalBrand?: string,
    generalDuration?: string,
    startAndEndDate?: string[]
) => {
    const dispatch = useAppDispatch();
    const [costSummary, setCostSummary] = useState<{ [key: string]: any }>();
    const [dateRange, setDateRange] = useState<{ label: string; value: string }>({
        label: "",
        value: "yearly",
    });
    const [brand, setBrand] = useState<{ label: string; value: string }>({
        label: "",
        value: "",
    });
    const [costType, setCostType] = useState<string>("sales");

    const { fetchingCostSummary, fetchCostSummarySuccess, fetchCostSummaryFailure } =
        useAppSelector((state) => state.multibrand);

    const handleDurationChange = (value: { label: string; value: string }) => {
        setDateRange(value);
    };

    const handleBrandChangeFilter = (value: { label: string; value: string }) => {
        setBrand(value);
    };

    const handleCostTypeFilter = (value: string) => {
        setCostType(value);
    };

    useEffect(() => {
        if (Boolean(fetchCostSummarySuccess)) {
            setCostSummary(fetchCostSummarySuccess?.analytics);
        }
    }, [fetchCostSummarySuccess]);

    useEffect(() => {
        dispatch(
            multibrandActions?.getCostSummary(
                typeof dateRange?.value === "number"
                    ? "custom"
                    : dateRange?.value !== generalDuration && dateRange?.value !== "yearly"
                    ? dateRange?.value
                    : generalDuration,
                brand?.value !== generalBrand && brand?.value !== "" ? brand?.value : generalBrand,
                costType,
                startAndEndDate[0],
                startAndEndDate[1]
            )
        );
    }, [
        dispatch,
        dateRange?.value,
        brand?.value,
        generalBrand,
        generalDuration,
        costType,
        startAndEndDate,
    ]);

    return {
        data: costSummary,
        isFetching: fetchingCostSummary,
        error: fetchCostSummaryFailure,
        handleDurationChange,
        dateRange,
        brand,
        handleBrandChangeFilter,
        handleCostTypeFilter,
        costType,
    };
};

export default useGetCostSummary;
