import VendorAvatar from "components/common/VendorAvatar";
import React, { useEffect, useState } from "react";
import Dropdown from "components/common/Dropdown";
import ReactCountryFlag from "react-country-flag";
import ReactECharts from "echarts-for-react";
import GeoMap from "components/common/GeoMap";
import HexagonBackground from "components/common/HexagonBackground";
import CustomTable from "components/common/CustomTable";
import { useNavigate } from "react-router-dom";
import ProgressBar from "components/common/ProgressBar";
import SalesCard from "components/common/SalesCard";
import useGetSalesDashboardStats from "hooks/salesHooks/useGetSalesDashboardStats";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import Skeleton from "react-loading-skeleton";
import useGetSalesTopAndLeastProducts from "hooks/salesHooks/useGetSalesTopAndLeastProducts";
import { truncate } from "lodash";
import millify from "millify";
import useGetSalesVelocityStats from "hooks/salesHooks/useGetSalesVelocityStats";
import useGetSalesChannelStats from "hooks/salesHooks/useGetSalesChannelStats";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import TextInput from "components/common/InputField/TextInput";
import useGetSalesOrderList from "hooks/salesHooks/useGetSalesOrderList";
import { getCurrentStartAndEndDate } from "helpers/getCurrentStartAndEndDate";
import useGetSalesTopAndLeastRegions from "hooks/salesHooks/useGetSalesTopAndLeastRegions";
import { COLORS } from "variables/chartColors";
import { yearDropdown } from "helpers/yearDropdown";

type SalesDashboardProps = {
    durationValue?: string;
    dateRange?: Date[];
};

const SalesDashboard = ({ durationValue, dateRange }: SalesDashboardProps) => {
    const navigate = useNavigate();
    const piechartColors = [
        "#84CC16",
        "#22D3EE",
        "#7C3AED",
        "#22C55E",
        "#FC1D8B",
        "#2563EB",
        "#EE0B0B",
        "#FFB902",
    ];
    const [topLeastProductTab, setTopLeastProductTab] = useState(0);
    const [topLeastRegionsTab, setTopLeastRegionsTab] = useState(0);
    const [topLeastSellingProductsLabel, setTopLeastSellingProductsLabel] = useState("This month");
    const [topLeastSellingRegionsLabel, setTopLeastSellingRegionsLabel] = useState("This month");
    const [salesVelocityDurationLabel, setSalesVelocityDurationLabel] = useState("This month");
    const [salesChannelDurationLabel, setSalesChannelDurationLabel] = useState("This month");
    const [years, setYears] = useState<number[]>([]);

    useEffect(() => {
        setYears(yearDropdown());
    }, []);

    const {
        searchTwo,
        handleSearchTwo,
        handleDebouncedChangeTwo,
        handleFilterChangeTwo,
        handleClearFilterTwo,
        selectedProduct,
        productSearchList,
    } = useGetProductList();

    const {
        data: statsData,
        isFetching: isFetchingStatsData,
        setStartEndDate,
    } = useGetSalesDashboardStats();
    const {
        data: salesTopAndLeastProductsData,
        isFetching: isFetchingSalesTopAndLeastProducts,
        handleDurationChange: handleDurationProductsChange,
    } = useGetSalesTopAndLeastProducts();

    const {
        data: salesTopAndLeastData,
        isFetching: isFetchingSalesTopAndLeastRegions,
        handleDurationChange: handleDurationRegionsChange,
    } = useGetSalesTopAndLeastRegions();
    const {
        data: salesVelocityData,
        isFetching: fetchingSalesVelocityData,
        handleGroupByChange,
        groupBy: salesVelocityGroupBy,
        handleDurationChange: handleSalesVelocityDurationChange,
    } = useGetSalesVelocityStats(selectedProduct?.value);
    const {
        data: salesChannelData,
        isFetching: fetchingSalesChannelData,
        handleDurationChange: handleChannelDurationChange,
    } = useGetSalesChannelStats();

    const totalTopAndLeastSellingQuantiy = salesTopAndLeastProductsData?.[
        topLeastProductTab === 0 ? "topSelling" : "leastSelling"
    ]?.reduce((acc, curr) => acc + curr?.totalRevenue, 0);

    const determineTrendDirection = (value: string | number) => {
        return String(value)?.[0] === "-" ? "down" : "up";
    };

    const totalRegionalRevenue = salesTopAndLeastData?.[
        topLeastRegionsTab === 0 ? "topRegion" : "leastRegion"
    ]?.reduce((acc, curr) => acc + Number(curr?.totalRevenue), 0);

    const salesCardData: {
        icon: JSX.Element;
        title: string;
        value: string | number;
        trendDirection?: "up" | "down";
        trendValue?: string | number;
    }[] = [
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-carribean-green">
                    <i className="ri-file-line text-lg text-white"></i>
                </div>
            ),
            title: "Total orders",
            value: formatMoney()?.format(statsData?.totalOrders?.value),
            trendDirection: determineTrendDirection(statsData?.totalOrders?.percentIncrease),
            trendValue: statsData?.totalOrders?.percentIncrease?.toFixed(2),
        },
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-p-50">
                    <i className="ri-bar-chart-2-fill text-lg text-white"></i>
                </div>
            ),
            title: "Total fulfilled orders",
            value: formatMoney()?.format(statsData?.totalFulfilledOrders?.value),
            trendDirection: determineTrendDirection(
                statsData?.totalFulfilledOrders?.percentIncrease
            ),
            trendValue: statsData?.totalFulfilledOrders?.percentIncrease?.toFixed(2),
        },
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-[#E8A621]">
                    <i className="ri-shopping-basket-2-line text-lg text-white"></i>
                </div>
            ),
            title: "New units sold",
            value: formatMoney()?.format(statsData?.totalUnits?.value),
            trendDirection: determineTrendDirection(statsData?.totalUnits?.percentIncrease),
            trendValue: statsData?.totalUnits?.percentIncrease?.toFixed(2),
        },
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-r-50">
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1726602779/line_vector_k6cwfy.svg"
                        className="w-[17px] h-[9px]"
                    />
                </div>
            ),
            title: "Total revenue",
            value: "$" + formatMoney()?.format(statsData?.totalRevenue?.value),
            trendDirection: determineTrendDirection(statsData?.totalRevenue?.percentIncrease),
            trendValue: statsData?.totalRevenue?.percentIncrease?.toFixed(2),
        },
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-y-10">
                    <i className="ri-money-dollar-circle-line text-lg text-white"></i>
                </div>
            ),
            title: "Gross margin",
            value: formatMoney()?.format(statsData?.grossMargin?.value) + "%",
            trendDirection: determineTrendDirection(statsData?.grossMargin?.percentIncrease),
            trendValue: statsData?.grossMargin?.percentIncrease?.toFixed(2),
        },
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-slate-400">
                    <i className="ri-box-3-line text-lg text-white"></i>
                </div>
            ),
            title: "Av. revenue per SKU",
            value: "$" + formatMoney()?.format(statsData?.averageRevenuePerSKU?.value),
            trendDirection: determineTrendDirection(
                statsData?.averageRevenuePerSKU?.percentIncrease
            ),
            trendValue: statsData?.averageRevenuePerSKU?.percentIncrease?.toFixed(2),
        },
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-[#A243DC]">
                    <i className="ri-line-chart-line text-lg text-white"></i>
                </div>
            ),
            title: "Av. revenue growth rate",
            value: formatMoney()?.format(statsData?.revenueGrowthRate?.value) + "%",
            trendDirection: determineTrendDirection(statsData?.revenueGrowthRate?.percentIncrease),
            trendValue: statsData?.revenueGrowthRate?.percentIncrease?.toFixed(2),
        },
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-y-10">
                    <i className="ri-money-dollar-circle-line text-lg text-white"></i>
                </div>
            ),
            title: "Sell-through rate",
            value: formatMoney()?.format(statsData?.sellThroughRate?.value) + "%",
            trendDirection: determineTrendDirection(statsData?.sellThroughRate?.percentIncrease),
            trendValue: statsData?.sellThroughRate?.percentIncrease?.toFixed(2),
        },
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-slate-400">
                    <i className="ri-box-3-line text-lg text-white"></i>
                </div>
            ),
            title: "Av. order value",
            value: "$" + formatMoney()?.format(statsData?.averageOrderValue?.value),
            trendDirection: determineTrendDirection(statsData?.averageOrderValue?.percentIncrease),
            trendValue: statsData?.averageOrderValue?.percentIncrease?.toFixed(2),
        },
    ];

    const pieChartOptions = {
        tooltip: {
            trigger: "item",
            position: function (point, params, dom, rect, size) {
                // point: [x, y] coordinate of the cursor
                return [point[0] + 10, point[1]]; // Offset tooltip 10px to the right
            },
            formatter: function (params) {
                return `
                <div>
                    <p style="margin-bottom: 10px">${params?.seriesName}</p>
                    <div style="display:flex ; align-items: center; gap: 10px;">
                        <div style="display:flex ; align-items: center; gap: 5px;">
                            <span style="width: 10px; height: 10px; background-color:${
                                params?.color
                            }; border-radius: 100%; "></span>
                            <span>${params?.data?.name}</span>
                        </div> 

                        <span style="font-weight: 600;">$${formatMoney().format(
                            params?.data?.value
                        )}</span>
                    </div>
                </div>
                `;
            },
        },
        color: piechartColors,
        series: [
            {
                name: topLeastProductTab === 0 ? "Top selling product" : "Least selling product",
                type: "pie",
                radius: ["50%", "70%"],
                avoidLabelOverlap: false,
                padAngle: 2,
                label: {
                    show: false,
                    position: "center",
                },
                itemStyle: {
                    borderRadius: 5,
                    borderWidth: 2,
                    borderColor: "#fff",
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 40,
                        fontWeight: "bold",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: salesTopAndLeastProductsData?.[
                    topLeastProductTab === 0 ? "topSelling" : "leastSelling"
                ]?.map((salesInfo) => {
                    return {
                        value: salesInfo?.totalRevenue,
                        name: salesInfo?.productName,
                    };
                }),
            },
        ],
    };

    const bubbleCoordinates = [
        [
            salesTopAndLeastData?.[topLeastRegionsTab === 0 ? "topRegion" : "leastRegion"]
                ?.length === 1
                ? 150
                : 95,
            60,
        ], // Coordinates adjusted to center the bubbles
        [190, 40],
        [150, 100],
        [240, 110],
        [95, 130],
    ];

    const bubbleChartOption = {
        tooltip: {
            show: true,
            formatter: function (params) {
                return `<div class="chart"><span style="font-size: 14px;">$${millify(
                    params.value[2],
                    {
                        precision: 2,
                    }
                )}</span></div>`;
            },
        },
        xAxis: {
            show: false, // Hide the x-axis
            type: "value",
            min: 0,
            max: 300, // Adjust the range to ensure space for the bubbles
        },
        yAxis: {
            show: false, // Hide the y-axis
            type: "value",
            min: 0,
            max: 150, // Adjust the range to center the bubbles vertically
        },
        series: [
            {
                type: "scatter",
                symbolSize: (data) => {
                    return (data[2] / totalRegionalRevenue) * 100 * 2.5;
                },
                data: salesTopAndLeastData?.[
                    topLeastRegionsTab === 0 ? "topRegion" : "leastRegion"
                ]?.map((salesRegion, idx) => {
                    const coordinateItem: any[] = bubbleCoordinates?.[idx];

                    coordinateItem.push(salesRegion?.totalRevenue);

                    return {
                        value: coordinateItem,
                        itemStyle: { color: COLORS[idx] },
                        label: { color: idx === 0 || idx === 3 ? "white" : "black" },
                    };
                }),
                label: {
                    show: true,
                    formatter: (params) =>
                        `$${millify(params.value[2], {
                            precision: 2,
                        })}`,
                    // color: "white",
                    fontWeight: "bold",
                    fontSize: 12,
                },
                itemStyle: {
                    color: "#6562E5", // Adjust bubble color (or use gradient if needed)
                    opacity: 0.8,
                    shadowBlur: 10,
                    shadowColor: "rgba(0, 0, 0, 0.2)",
                },
                emphasis: {
                    itemStyle: {
                        opacity: 1,
                        shadowBlur: 15,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                },
            },
        ],
    };

    const multiBarChartOption = {
        legend: { show: false },
        tooltip: {
            formatter: function (params) {
                return `
                <div>
                    <p style="margin-bottom: 10px">${params?.seriesName}</p>
                    <div style="display:flex ; align-items: center; gap: 10px;">
                        <div style="display:flex ; align-items: center; gap: 5px;">
                            <span style="width: 10px; height: 10px; background-color:${
                                params?.color
                            }; border-radius: 100%; "></span>
                            <span>${params?.value?.[0]}</span>
                        </div> 

                        <span style="font-weight: 600;">$${formatMoney().format(
                            params?.value?.[1] || params?.value?.[2]
                        )}</span>
                    </div>
                </div>
                `;
            },
        },
        color: ["#6562E5", "#56B648"],
        dataset: {
            source: [
                ["product", "Sales velocity"],

                ...salesVelocityData?.map((dataset) => {
                    return [dataset?.field, dataset?.totalRevenue?.toFixed(2)];
                }),
            ],
        },
        xAxis: { type: "category" },
        yAxis: {
            axisLabel: {
                formatter: "${value}",
            },
        },
        series: [
            {
                type: "bar",
                barWidth: "13%",
                itemStyle: {
                    borderRadius: [6, 6, 0, 0],
                },
            },
            // { type: "bar", barWidth: "13%" },
        ],
    };

    const channelColors = ["#56B648", "#1E293B", "#0054C2", ...piechartColors];

    const totalSellThroughRate = salesChannelData?.salesByChannel?.reduce((acc, curr) => {
        return acc + curr?.sellthroughRate;
    }, 0);

    const avgSellThroughRate = totalSellThroughRate / salesChannelData?.salesByChannel?.length;

    const totalChannelRevenue = salesChannelData?.salesByChannel?.reduce((acc, curr) => {
        return acc + curr?.totalRevenue;
    }, 0);

    const totalRevenueGrowthRate = salesChannelData?.salesByChannel?.reduce((acc, curr) => {
        return acc + curr?.revenueGrowthRate;
    }, 0);

    const revenueGrowthRate = totalRevenueGrowthRate / salesChannelData?.salesByChannel?.length;

    const channelBreakdownData = [
        {
            title: "Av. sell-through rate",
            value: `${avgSellThroughRate?.toFixed(2)}%`,
        },
        {
            title: "Number of sales channels",
            value: salesChannelData?.totalChannels,
        },
        {
            title: "Top sales channel",
            value: salesChannelData?.topChannel?.channel?.includes("microsoft")
                ? "Microsoft"
                : salesChannelData?.topChannel?.channel?.includes("gmail")
                ? "Gmail"
                : salesChannelData?.topChannel?.channel.includes("synth")
                ? "Synth email"
                : salesChannelData?.topChannel?.channel,
        },
        {
            title: "Revenue growth rate",
            value: `${revenueGrowthRate?.toFixed(2)}%`,
        },
    ];

    const durationOpts = [
        {
            label: "This week",
            value: "This week-weekly",
        },
        {
            label: "This month",
            value: "This month-monthly",
        },
        ...(years && Array.isArray(years)
            ? years.map((item) => ({
                  label:
                      item === new Date().getFullYear() ? `This year (${item})` : item?.toString(),
                  value: item,
              }))
            : []),
    ];

    useEffect(() => {
        setStartEndDate(
            getCurrentStartAndEndDate(
                durationValue === "custom" && !dateRange?.[0] && !dateRange?.[1]
                    ? "monthly"
                    : durationValue,
                dateRange
            )
        );
    }, [durationValue, dateRange]);

    return (
        <div>
            <div className="mb-6">
                {isFetchingStatsData ? (
                    <div className="grid grid-cols-4 gap-x-2.5 gap-y-6">
                        {salesCardData?.map((_, idx) => (
                            <Skeleton
                                key={idx}
                                width={"100%"}
                                height={134}
                                highlightColor="#E2E8F0"
                                borderRadius={8}
                                baseColor="#F1F5F9"
                                className="custom-skeleton"
                            />
                        ))}
                    </div>
                ) : (
                    <div className="grid grid-cols-4 gap-x-2.5 gap-y-6">
                        {salesCardData?.map((cardData, idx) => {
                            return (
                                <SalesCard
                                    key={cardData.title + String(idx)}
                                    title={cardData?.title}
                                    value={cardData.value}
                                    icon={cardData?.icon}
                                    bottomComp={
                                        cardData?.trendValue ? (
                                            <span
                                                className={`block font-rocGroteskMedium text-sm space-x-1 ${
                                                    cardData?.trendDirection === "up"
                                                        ? "text-g-60 bg-g-25"
                                                        : "text-r-55 bg-r-25"
                                                } px-1.5 h-6 rounded-[3px] w-fit`}
                                            >
                                                <i
                                                    className={`${
                                                        cardData?.trendDirection === "up"
                                                            ? "ri-arrow-up-line"
                                                            : "ri-arrow-down-line"
                                                    } text-sm`}
                                                ></i>{" "}
                                                {cardData?.trendValue}%
                                            </span>
                                        ) : null
                                    }
                                />
                            );
                        })}
                    </div>
                )}
            </div>

            <div className="flex space-x-6 mb-6">
                <div className="w-1/2 rounded-lg border border-slate-200 py-4 px-[13px]">
                    <div className="flex items-center justify-between pb-[13px] border-b border-slate-200 mb-6">
                        <div className="w-fit bg-slate-50 rounded p-1 flex items-center space-x-2.5 ">
                            {["Top selling products", "Least selling products"].map(
                                (tab, index) => (
                                    <div
                                        key={index}
                                        className={`px-4 py-[7px] cursor-pointer ${
                                            topLeastProductTab === index &&
                                            "bg-white rounded shadow-[0px_4px_10px_0px_#0000000D]"
                                        }`}
                                        onClick={() => {
                                            setTopLeastProductTab(index);
                                        }}
                                    >
                                        <span className="text-sm font-rocGroteskMedium">{tab}</span>
                                    </div>
                                )
                            )}
                        </div>

                        <Dropdown
                            handleChange={(name, value) => {
                                setTopLeastSellingProductsLabel(
                                    typeof value === "number" ? value : value?.split("-")?.[0]
                                );
                                handleDurationProductsChange(
                                    typeof value === "number" ? value : value?.split("-")?.[1]
                                );
                            }}
                            dropdown={
                                <div className="flex whitespace-nowrap border border-slate-200 items-center rounded h-[46px] px-4 py-[14.5px]">
                                    <span className={` font-rocGroteskMedium text-xs`}>
                                        {topLeastSellingProductsLabel}
                                    </span>
                                    <i className="ri-arrow-down-s-line text-slate-600"></i>
                                </div>
                            }
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full max-sm:z-[9999] rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                            dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                            dropdownOptions={durationOpts}
                        />
                    </div>

                    {salesTopAndLeastProductsData?.[
                        topLeastProductTab === 0 ? "topSelling" : "leastSelling"
                    ]?.length > 0 ? (
                        <div className="flex items-center">
                            <div className="w-1/2">
                                <div className="relative">
                                    <ReactECharts option={pieChartOptions} />
                                    <div className="absolute top-[30%] left-0 right-0 m-auto w-[120px] h-[120px] rounded-full font-rocGroteskMedium flex flex-col items-center justify-center bg-white shadow-[0px_4px_15.6px_-4px_#1018281A]">
                                        <span className="block text-[12px] text-slate-500">
                                            Total sales
                                        </span>
                                        <span className="block text-[20px] text-g-75 leading-[40px]">
                                            $
                                            {millify(totalTopAndLeastSellingQuantiy, {
                                                precision: 2,
                                            })}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/2 space-y-5">
                                {salesTopAndLeastProductsData?.[
                                    topLeastProductTab === 0 ? "topSelling" : "leastSelling"
                                ]?.map((data, idx) => {
                                    return (
                                        <div
                                            key={data?._id}
                                            className="flex items-start justify-between"
                                        >
                                            <div className="flex items-center space-x-2.5">
                                                <span
                                                    className={`w-2.5 h-2.5 rounded-full bg-[${piechartColors?.[idx]}]`}
                                                    style={{
                                                        backgroundColor: piechartColors?.[idx],
                                                    }}
                                                ></span>
                                                <div className="font-rocGroteskMedium">
                                                    <span className="block text-sm ">
                                                        {truncate(data?.productName, {
                                                            length: 25,
                                                        })}
                                                    </span>
                                                    <span className="block text-xs text-slate-500">
                                                        SKU {truncate(data?.sku, { length: 25 })}
                                                    </span>
                                                </div>
                                            </div>

                                            <span className="font-rocGroteskMedium text-slate-700 text-sm">
                                                {getCurrencyFromCurrencyCode(data?.currency)}{" "}
                                                {millify(data?.totalRevenue, {
                                                    precision: 2,
                                                })}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center items-center my-16">
                            <span className="text-xl font-rocGroteskMedium text-center ">
                                No Data available
                            </span>
                        </div>
                    )}
                </div>

                <div className="w-1/2 rounded-lg border border-slate-200 py-4 px-[13px]">
                    <div className="flex items-center justify-between pb-[13px] border-b border-slate-200 mb-6">
                        <div className="w-fit bg-slate-50 rounded p-1 flex items-center space-x-2.5 ">
                            {["Top performing regions", "Least performing regions"].map(
                                (tab, index) => (
                                    <div
                                        key={index}
                                        className={`px-4 py-[7px] cursor-pointer ${
                                            topLeastRegionsTab === index &&
                                            "bg-white rounded shadow-[0px_4px_10px_0px_#0000000D]"
                                        }`}
                                        onClick={() => {
                                            setTopLeastRegionsTab(index);
                                        }}
                                    >
                                        <span className="text-sm font-rocGroteskMedium">{tab}</span>
                                    </div>
                                )
                            )}
                        </div>

                        <Dropdown
                            handleChange={(name, value) => {
                                setTopLeastSellingRegionsLabel(
                                    typeof value === "number" ? value : value?.split("-")?.[0]
                                );
                                handleDurationRegionsChange(
                                    typeof value === "number" ? value : value?.split("-")?.[1]
                                );
                            }}
                            dropdown={
                                <div className="flex whitespace-nowrap border border-slate-200 items-center rounded h-[46px] px-4 py-[14.5px]">
                                    <span className={`font-rocGroteskMedium text-xs`}>
                                        {topLeastSellingRegionsLabel}
                                    </span>
                                    <i className="ri-arrow-down-s-line text-slate-600"></i>
                                </div>
                            }
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full max-sm:z-[9999] rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                            dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                            dropdownOptions={durationOpts}
                        />
                    </div>

                    {salesTopAndLeastData?.[topLeastRegionsTab === 0 ? "topRegion" : "leastRegion"]
                        ?.length > 0 ? (
                        <div className="flex items-center">
                            <div className="w-[60%]">
                                <div>
                                    <ReactECharts option={bubbleChartOption} />
                                </div>
                            </div>
                            <div className="w-[40%] space-y-2">
                                {salesTopAndLeastData?.[
                                    topLeastRegionsTab === 0 ? "topRegion" : "leastRegion"
                                ]?.map((region, idx) => {
                                    return (
                                        <div key={region?.region + String(idx)} className="">
                                            <span className="block text-base font-rocGroteskMedium">
                                                {region?.region}
                                            </span>

                                            <div className="flex items-center space-x-2">
                                                <div className="w-1/2">
                                                    <ProgressBar
                                                        className={`w-full !h-1.5 !rounded-[5px]`}
                                                        containerClass="!bg-[#F2F4F7] !h-1.5"
                                                        styling={{ background: COLORS[idx] }}
                                                        progress={Number(
                                                            (
                                                                (region?.totalRevenue /
                                                                    totalRegionalRevenue) *
                                                                100
                                                            )?.toFixed(0)
                                                        )}
                                                    />
                                                </div>

                                                <span className="text-base font-rocGroteskMedium text-slate-500">
                                                    {getCurrencyFromCurrencyCode(region?.currency)}
                                                    {formatMoney()?.format(region?.totalRevenue)}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center items-center my-16">
                            <span className="text-xl font-rocGroteskMedium text-center ">
                                No Data available
                            </span>
                        </div>
                    )}
                </div>
            </div>

            <div className="flex space-x-6 mb-6">
                <div className="w-[65%] flex-col justify-between space-y-4">
                    <div className="w-full rounded-lg border border-slate-200 py-4 px-[13px]">
                        <div className="flex items-center justify-between pb-[13px] mb-6">
                            <span className="text-base font-rocGroteskMedium ">Sales velocity</span>

                            <div className="flex items-center">
                                <Dropdown
                                    handleChange={(name, value) => {
                                        console.log("first", name, value);
                                    }}
                                    dropdown={
                                        <div className="flex space-x-1.5 whitespace-nowrap border-l border-y border-slate-200 items-center rounded-tl rounded-bl h-[32px] px-1.5 py-2">
                                            <span className={` font-rocGroteskMedium text-xs`}>
                                                {truncate(selectedProduct?.name, { length: 25 }) ||
                                                    "All SKUs"}
                                            </span>
                                            <div className="flex items-center space-x-1">
                                                {selectedProduct?.name && (
                                                    <i
                                                        onClick={handleClearFilterTwo}
                                                        className="ri-close-circle-fill text-slate-400 text-sm"
                                                    ></i>
                                                )}
                                                <i className="ri-arrow-down-s-line text-slate-600"></i>
                                            </div>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full max-sm:z-[9999] rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    search={
                                        <div>
                                            <TextInput
                                                value={searchTwo}
                                                name={"search"}
                                                type={"text"}
                                                required={false}
                                                onChange={handleSearchTwo}
                                                onInput={handleDebouncedChangeTwo}
                                                inputPlaceholder="Search products"
                                                containerClassname=""
                                                // inputContainerClassname={"!border-none !rounded-none "}
                                                inputClassName="w-full"
                                            />
                                        </div>
                                    }
                                    dropdownOptions={[
                                        {
                                            customChild: (
                                                <div>
                                                    <div className="max-h-[280px]">
                                                        {productSearchList?.map((product) => {
                                                            return (
                                                                <div
                                                                    key={product?._id}
                                                                    onClick={() =>
                                                                        handleFilterChangeTwo(
                                                                            product?.productName,
                                                                            product?._id
                                                                        )
                                                                    }
                                                                >
                                                                    <div
                                                                        className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-100 cursor-pointer `}
                                                                    >
                                                                        <div>
                                                                            <div className="flex items-center space-x-3">
                                                                                {product
                                                                                    ?.productImageDetails
                                                                                    ?.productImages?.[0] ? (
                                                                                    <VendorAvatar
                                                                                        imageSrc={
                                                                                            product
                                                                                                ?.productImageDetails
                                                                                                ?.productImages?.[0]
                                                                                        }
                                                                                        size={32}
                                                                                        name={
                                                                                            product?.productName
                                                                                        }
                                                                                        containerClassname="!rounded-md"
                                                                                    />
                                                                                ) : (
                                                                                    <div className="w-8 h-8 rounded flex shrink-0 items-center justify-center bg-slate-200 ">
                                                                                        <i className="ri-box-2-fill text-sm text-slate-500"></i>
                                                                                    </div>
                                                                                )}
                                                                                <div>
                                                                                    <span className="block text-xs font-rocGroteskMedium">
                                                                                        {truncate(
                                                                                            product?.sku,
                                                                                            {
                                                                                                length: 30,
                                                                                            }
                                                                                        )}
                                                                                    </span>
                                                                                    <span className="block text-[10px] text-slate-500 font-rocGroteskMedium">
                                                                                        {truncate(
                                                                                            product?.productName,
                                                                                            {
                                                                                                length: 50,
                                                                                            }
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            ),
                                        },
                                    ]}
                                />
                                <Dropdown
                                    handleChange={(name, value) => {
                                        handleGroupByChange(value as "warehouse" | "channel");
                                    }}
                                    dropdown={
                                        <div className="flex whitespace-nowrap border border-slate-200 items-center h-[32px] px-1.5 py-2">
                                            <span className={` font-rocGroteskMedium text-xs`}>
                                                Per {salesVelocityGroupBy}
                                            </span>
                                            <i className="ri-arrow-down-s-line text-slate-600"></i>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full max-sm:z-[9999] rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={[
                                        { label: "Per warehouse", value: "warehouse" },
                                        { label: "Per channel", value: "channel" },
                                    ]}
                                />
                                <Dropdown
                                    handleChange={(name, value) => {
                                        setSalesVelocityDurationLabel(
                                            typeof value === "number"
                                                ? value
                                                : value?.split("-")?.[0]
                                        );
                                        handleSalesVelocityDurationChange(
                                            typeof value === "number"
                                                ? value
                                                : value?.split("-")?.[1]
                                        );
                                    }}
                                    dropdown={
                                        <div className="flex whitespace-nowrap border-r border-y border-slate-200 items-center rounded-tr rounded-br h-[32px] px-1.5 py-2">
                                            <span className={` font-rocGroteskMedium text-xs`}>
                                                {salesVelocityDurationLabel}
                                            </span>
                                            <i className="ri-arrow-down-s-line text-slate-600"></i>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full max-sm:z-[9999] rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={durationOpts}
                                />
                            </div>
                        </div>

                        {salesVelocityData?.length > 0 ? (
                            <div>
                                <div className="mb-[30px]">
                                    <span className="block text-sm text-slate-400 mb-2">
                                        Average sales velocity per {salesVelocityGroupBy}
                                    </span>
                                </div>
                                <div className="w-full h-full">
                                    <ReactECharts
                                        option={multiBarChartOption}
                                        style={{ height: 500 }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="flex justify-center items-center my-16 ">
                                <span className="text-xl font-rocGroteskMedium text-center ">
                                    No Data available
                                </span>
                            </div>
                        )}
                    </div>
                    {/* <div className="grid grid-cols-2 space-x-2.5">
                        {salesCardData?.slice(7)?.map((cardData, idx) => {
                            return (
                                <SalesCard
                                    key={cardData.title + String(idx)}
                                    title={cardData?.title}
                                    value={cardData.value}
                                    icon={cardData?.icon}
                                    bottomComp={
                                        cardData?.trendValue ? (
                                            <span
                                                className={`block font-rocGroteskMedium text-sm space-x-1 ${
                                                    cardData?.trendDirection === "up"
                                                        ? "text-g-60 bg-g-25"
                                                        : "text-r-55 bg-r-25"
                                                } px-1.5 h-6 rounded-[3px] w-fit`}
                                            >
                                                <i
                                                    className={`${
                                                        cardData?.trendDirection === "up"
                                                            ? "ri-arrow-up-line"
                                                            : "ri-arrow-down-line"
                                                    } text-sm`}
                                                ></i>{" "}
                                                {cardData?.trendValue}%
                                            </span>
                                        ) : null
                                    }
                                />
                            );
                        })}
                    </div> */}
                </div>

                <div
                    className={`w-[35%] ${
                        salesChannelData?.salesByChannel?.length > 0 ? "h-[653px]" : "h-auto"
                    } rounded-lg border border-slate-200 py-4 px-[13px]`}
                >
                    <div className="flex items-center justify-between pb-[10px] border-b border-slate-200 mb-10">
                        <span className="text-base font-rocGroteskMedium ">Channel breakdown</span>

                        <div className="flex items-center">
                            <Dropdown
                                handleChange={(name, value) => {
                                    setSalesChannelDurationLabel(
                                        typeof value === "number" ? value : value?.split("-")?.[0]
                                    );
                                    handleChannelDurationChange(
                                        typeof value === "number" ? value : value?.split("-")?.[1]
                                    );
                                }}
                                dropdown={
                                    <div className="flex whitespace-nowrap border border-slate-200 items-center rounded h-[32px] px-1.5 py-2">
                                        <span className={` font-rocGroteskMedium text-xs`}>
                                            {salesChannelDurationLabel}
                                        </span>
                                        <i className="ri-arrow-down-s-line text-slate-600"></i>
                                    </div>
                                }
                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full max-sm:z-[9999] rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                dropdownOptions={durationOpts}
                            />
                        </div>
                    </div>

                    {salesChannelData?.salesByChannel?.length > 0 ? (
                        <div className="flex space-x-6">
                            <div className="w-1/2 flex justify-end">
                                <div className="w-[37px] h-[480px] space-y-1">
                                    {salesChannelData?.salesByChannel?.map((channelData, idx) => {
                                        const calcHeight =
                                            (channelData?.totalRevenue / totalChannelRevenue) * 100;

                                        const useOtherSide =
                                            calcHeight < 30 &&
                                            salesChannelData?.salesByChannel?.length - 1 === idx;

                                        return (
                                            <div
                                                key={idx}
                                                className="relative w-full"
                                                style={{ height: `${calcHeight}%` }}
                                            >
                                                <div
                                                    className={`absolute top-[-29px] flex items-center space-x-1 ${
                                                        useOtherSide
                                                            ? "right-[-110px]"
                                                            : "left-[-80px]"
                                                    }`}
                                                >
                                                    <div className="space-y-1 font-rocGroteskMedium">
                                                        <div className="flex items-center space-x-1">
                                                            <span
                                                                className="w-2 h-2 rounded-full"
                                                                style={{
                                                                    backgroundColor:
                                                                        channelColors?.[idx],
                                                                }}
                                                            />
                                                            <span className="text-slate-500  text-[10px]">
                                                                {channelData?.channel?.includes(
                                                                    "microsoft"
                                                                )
                                                                    ? "Microsoft"
                                                                    : channelData?.channel?.includes(
                                                                          "gmail"
                                                                      )
                                                                    ? "Gmail"
                                                                    : channelData?.channel?.includes(
                                                                          "synth"
                                                                      )
                                                                    ? "Synth email"
                                                                    : channelData?.channel}
                                                            </span>
                                                        </div>
                                                        {/* <span className="block text-xs text-right">
                                                        10-d%
                                                    </span> */}
                                                        <span className="block text-sm text-right">
                                                            $
                                                            {millify(channelData?.totalRevenue, {
                                                                precision: 2,
                                                            })}
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={`${
                                                            useOtherSide && "relative left-[-80px]"
                                                        }`}
                                                    >
                                                        <div className="w-[18px] border-b-[1.5px] border-p-25" />
                                                        {!useOtherSide ? (
                                                            <div className="w-[22px] border-b-[1.5px] border-p-25 rotate-45 mt-[7px] ml-[14.5px]" />
                                                        ) : (
                                                            <div className="w-[22px] border-b-[1.5px] border-p-25 rotate-[135deg] mt-[7px] ml-[-18.5px]" />
                                                        )}
                                                    </div>
                                                </div>

                                                <div
                                                    className={`${idx === 0 && "rounded-t-md"}  ${
                                                        idx ===
                                                            salesChannelData?.salesByChannel
                                                                ?.length -
                                                                1 && "rounded-b-md"
                                                    } w-full h-full`}
                                                    style={{
                                                        backgroundColor: channelColors?.[idx],
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="w-1/2 space-y-4">
                                {channelBreakdownData?.map((breakdowndata, idx) => {
                                    return (
                                        <div
                                            key={breakdowndata?.title + String(idx)}
                                            className="w-fit rounded-[16px] bg-slate-100 p-2 font-rocGroteskMedium"
                                        >
                                            <span className="block text-xs text-slate-500">
                                                {breakdowndata?.title}
                                            </span>
                                            <span className="block text-sm">
                                                {breakdowndata?.value}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center items-center my-16">
                            <span className="text-xl font-rocGroteskMedium text-center ">
                                No Data available
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SalesDashboard;
