import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { productionActions } from "redux/Ldb/actions";

const useGetRejectedWorkOrderList = () => {
    const dispatch = useAppDispatch();
    const [rejectecList, setRejectecList] = useState<{ [key: string]: any }[]>([]);
    const [settings, setSettings] = useState<{ [key: string]: any }>({});

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { fetchingRejectedWorkOrderList, fetchedRejectedWorkOrderListSuccess } = useAppSelector(
        (state) => state.production
    );

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    useEffect(() => {
        dispatch(productionActions.fetchRejectedWorkOrder(10, updatedPage));
    }, [dispatch, updatedPage]);

    useEffect(() => {
        if (Boolean(fetchedRejectedWorkOrderListSuccess)) {
            setRejectecList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchedRejectedWorkOrderListSuccess?.workOrders], "_id")
                    : uniqBy([...prev, ...fetchedRejectedWorkOrderListSuccess?.workOrders], "_id")
            );
            setSettings(fetchedRejectedWorkOrderListSuccess?.settings);

            setPagination({
                currentPage: fetchedRejectedWorkOrderListSuccess.pagination?.current,
                noOfPages: fetchedRejectedWorkOrderListSuccess.pagination?.number_of_pages,
            });
            setSettings(fetchedRejectedWorkOrderListSuccess?.settings);
            setLoadMore(false);
            dispatch(productionActions.resetRejectedWorkOrderList());
        }
    }, [dispatch, fetchedRejectedWorkOrderListSuccess, updatedPage]);

    return {
        data: rejectecList,
        settings,
        isLoading: fetchingRejectedWorkOrderList,
        isLoadingMore,
        pagination,
    };
};

export default useGetRejectedWorkOrderList;
