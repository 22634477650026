import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { productionActions } from "redux/Ldb/actions";

const useGetWorkOrderList = () => {
    const dispatch = useAppDispatch();
    // const [search, setSearch] = useState<string>("");
    const [assigneeId, setAssigneeId] = useState<string>("");
    const [id, setId] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [workOrderList, setWorkOrderList] = useState<{ [key: string]: any }[]>([]);

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { fetchingWorkOrderList, fetchedWorkOrderListSuccess, duplicateWorkOrderSuccess } =
        useAppSelector((state) => state.production);

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    useEffect(() => {
        setUpdatedPage(1);
    }, [status, debouncedSearch]);

    const handleAssigneeIdChange = (e) => {
        setUpdatedPage(1);
        setAssigneeId(e.target.value);
        setId(e.target.value?.split("-")[0]);
    };

    useEffect(() => {
        if (assigneeId === "") {
            setId("");
        }
    }, [assigneeId]);

    useEffect(() => {
        dispatch(
            productionActions.fetchWorkOrderList(10, updatedPage, debouncedSearch, status, id)
        );
    }, [dispatch, debouncedSearch, updatedPage, status, id, duplicateWorkOrderSuccess]);

    useEffect(() => {
        if (Boolean(fetchedWorkOrderListSuccess)) {
            setWorkOrderList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchedWorkOrderListSuccess?.workOrders], "_id")
                    : uniqBy([...prev, ...fetchedWorkOrderListSuccess?.workOrders], "_id")
            );
            setPagination({
                currentPage: fetchedWorkOrderListSuccess.pagination?.current,
                noOfPages: fetchedWorkOrderListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
            dispatch(productionActions.resetFetchWorkOrderList());
        }
    }, [dispatch, fetchedWorkOrderListSuccess, updatedPage, debouncedSearch, status, assigneeId]);

    return {
        data: workOrderList,
        isLoading: fetchingWorkOrderList,
        isLoadingMore,
        pagination,
        debouncedSearch,
        setDebouncedSearch,
        // search,
        // setSearch,
        handleAssigneeIdChange,
        assigneeId,
        setAssigneeId,
        status,
        setStatus,
    };
};

export default useGetWorkOrderList;
