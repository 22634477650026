export const yearDropdown = () => {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = currentYear; year >= 2021; year--) {
        years.push(year);
    }

    return years;
};
