import React, { useEffect, useMemo, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "./useInfinteScroll";
import { debounce } from "lodash";

const useGetAllWarehouses = (
    deleteId?: string,
    setDeleteWarehouseShow?: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const dispatch = useAppDispatch();
    const {
        fetchingWarehouseList,
        fetchedWarehouseListSuccess,
        fetchedWarehouseListFailure,
        fetchingWarehouseSearchList,
        fetchedWarehouseSearchListSuccess,
        fetchedWarehouseSearchListFailure,
        deleteWarehouse,
        deleteWarehouseSuccess,
    } = useAppSelector((state) => state.inventory);
    const { createWarehouseSuccess } = useAppSelector((state) => state.inventory);

    const [warehouseList, setWarehouseList] = useState<{ [key: string]: any }[]>([]);
    const [warehouseSearchResult, setWarehouseSearchResult] = useState<{ [key: string]: any }[]>(
        []
    );
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [warehouseSearchResultPagination, setWarehouseSearchResultPagination] = useState<{
        currentPage: string;
        noOfPages: string;
    }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const [debouncedSearchTwo, setDebouncedSearchTwo] = useState<string>("");
    const [searchTwo, setSearchTwo] = useState<string>("");
    const [warehouseValue, setWarehouseValue] = useState<{ name?: string; value: string }>({
        name: "",
        value: "",
    });
    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            {
                current: pagination.currentPage,
                number_of_pages: pagination.noOfPages,
            },
            24
        );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setDebouncedSearch(e.target.value);
    }, 1000);

    const handleSearchTwo = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTwo(e.target.value);
    };

    const handleDebouncedChangeTwo = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setDebouncedSearchTwo(e.target.value);
    }, 1000);

    const handleFilterChange = (name: string, value: string) => {
        setUpdatedPage(1);
        setWarehouseValue({
            name,
            value,
        });
    };

    const handleClearFilter = () => {
        setUpdatedPage(1);
        setWarehouseValue({
            name: "",
            value: "",
        });
    };

    const deleteProductWarehouse = () => {
        dispatch(inventoryActions.deleteWarehouse(deleteId));
    };

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouseList(15, updatedPage, debouncedSearch));

        return () => {
            dispatch(inventoryActions.resetFetchWarehouseList());
        };
    }, [dispatch, deleteWarehouseSuccess, updatedPage, debouncedSearch, createWarehouseSuccess]);

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouseSearchList(15, debouncedSearchTwo));
    }, [dispatch, deleteWarehouseSuccess, , debouncedSearchTwo, createWarehouseSuccess]);

    useEffect(() => {
        if (Boolean(fetchedWarehouseSearchListSuccess)) {
            setWarehouseSearchResult(fetchedWarehouseSearchListSuccess?.warehouses?.data);
            setWarehouseSearchResultPagination({
                currentPage: fetchedWarehouseSearchListSuccess.pagination?.current,
                noOfPages: fetchedWarehouseSearchListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }

        if (Boolean(fetchedWarehouseListSuccess)) {
            setWarehouseList((prev) =>
                updatedPage === 1
                    ? [...fetchedWarehouseListSuccess?.warehouses?.data]
                    : [...prev, ...fetchedWarehouseListSuccess?.warehouses?.data]
            );
            setPagination({
                currentPage: fetchedWarehouseListSuccess.pagination?.current,
                noOfPages: fetchedWarehouseListSuccess.pagination?.number_of_pages,
            });

            setLoadMore(false);
        }
    }, [fetchedWarehouseListSuccess, fetchedWarehouseSearchListSuccess, updatedPage]);

    useEffect(() => {
        if (Boolean(deleteWarehouseSuccess)) {
            dispatch(inventoryActions.resetDeleteWarehouse());

            setDeleteWarehouseShow?.(false);
        }
    }, [dispatch, deleteWarehouseSuccess]);

    return {
        isFetching: fetchingWarehouseList,
        error: fetchedWarehouseListFailure,
        searchError: fetchedWarehouseSearchListFailure,
        isSearching: fetchingWarehouseSearchList,
        data: warehouseList,
        searchResult: warehouseSearchResult,
        deleteWarehouseFunc: deleteProductWarehouse,
        isDeletingWarehouse: deleteWarehouse,
        pagination,
        searchPagination: warehouseSearchResultPagination,
        isLoadingMore,
        handleSearch,
        handleSearchTwo,
        handleDebouncedChange,
        handleDebouncedChangeTwo,
        handleFilterChange,
        handleClearFilter,
        warehouseValue,
        search,
        searchTwo,
        setSearch,
        setDebouncedSearch,
    };
};

export default useGetAllWarehouses;
