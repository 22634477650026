import {taskTypes} from "redux/Ldb/types";

const initialState = {
    createTask: false,
    createTaskSuccess: null,
    createTaskFailure: null,
    updateTask: false,
    updateTaskSuccess: null,
    updateTaskFailure: null,
    fetchingTaskList: false,
    fetchTaskListSuccess: null,
    fetchTaskListFailure: null,
    fetchingSingleTask: false,
    fetchingSingleTaskSuccess: null,
    fetchingSingleTaskFailure: null,
    fetchingAllTaskList: false,
    fetchAllTaskSuccess: null,
    fetchAllTaskFailure: null,
    fetchingPendingTaskList: false,
    fetchPendingTaskSuccess: null,
    fetchPendingTaskFailure: null,
    fetchingCompletedTaskList: false,
    fetchCompletedTaskSuccess: null,
    fetchCompletedTaskFailure: null,
    fetchingOverdueTaskList: false,
    fetchOverdueTaskSuccess: null,
    fetchOverdueTaskFailure: null,
    fetchingActiveTaskList: false,
    fetchActiveTaskSuccess: null,
    fetchActiveTaskFailure: null,
    fetchingMyTaskList: false,
    fetchMyTaskSuccess: null,
    fetchMyTaskFailure: null,



    fetchAllTaskTwoSuccess: null,
    fetchAllTaskTwoFailure: null,
    fetchingPendingTaskTwoList: false,
    fetchPendingTaskTwoSuccess: null,
    fetchPendingTaskTwoFailure: null,
    fetchingCompletedTaskTwoList: false,
    fetchCompletedTaskTwoSuccess: null,
    fetchCompletedTaskTwoFailure: null,
    fetchingOverdueTaskTwoList: false,
    fetchOverdueTaskTwoSuccess: null,
    fetchOverdueTaskTwoFailure: null,
    fetchingActiveTaskTwoList: false,
    fetchActiveTaskTwoSuccess: null,
    fetchActiveTaskTwoFailure: null,
    fetchingMyTaskTwoList: false,
    fetchMyTaskTwoSuccess: null,
    fetchMyTaskTwoFailure: null,
    fetchingPendingHumanTask: false,
    fetchPendingHumanTaskSuccess: null,
    fetchPendingHumanTaskFailure: null,
    fetchingAllHumanTask: false,
    fetchAllHumanTaskSuccess: null,
    fetchAllHumanTaskFailure: null,
    fetchingCompletedHumanTask: false,
    fetchCompletedHumanTaskSuccess: null,
    fetchCompletedHumanTaskFailure: null,
    fetchingFailedHumanTask: false,
    fetchFailedHumanTaskSuccess: null,
    fetchFailedHumanTaskFailure: null,
    fetchingSingleHumanTask: false,
    fetchSingleHumanTaskSuccess: null,     
    fetchSingleHumanTaskFailure: null,
    creatingHumanTask: false,
    createHumanTaskSuccess: null,
    createHumanTaskFailure: null,
    updatingHumanTask: false,
    updateHumanTaskSuccess: null,
    updateHumanTaskFailure: null,
    completingHumanTask: false,
    completeHumanTaskSuccess: null,
    completeHumanTaskFailure: null,
};

export const task = (state = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {
        case taskTypes.CREATE_TASK_REQUEST:
            return {
                ...state,
                createTask: true,
                createTaskSuccess: null,
                createTaskFailure: null,
            };
        case taskTypes.CREATE_TASK_SUCCESS:
            return {
                ...state,
                createTask: false,
                createTaskSuccess: action.payload,
                createTaskFailure: null,
            };
        case taskTypes.CREATE_TASK_FAILURE:
            return {
                ...state,
                createTask: false,
                createTaskSuccess: null,
                createTaskFailure: action.payload,
            };

        case taskTypes.UPDATE_TASK_REQUEST:
            return {
                ...state,
                updateTask: true,
                updateTaskSuccess: null,
                updateTaskFailure: null,
            };
        case taskTypes.UPDATE_TASK_SUCCESS:
            return {
                ...state,
                updateTask: false,
                updateTaskSuccess: action.payload,
                updateTaskFailure: null,
            };
        case taskTypes.UPDATE_TASK_FAILURE:
            return {
                ...state,
                updateTask: false,
                updateTaskSuccess: null,
                updateTaskFailure: action.payload,
            };
        case taskTypes.FETCH_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingTaskList: true,
                fetchTaskListSuccess: null,
                fetchTaskListFailure: null,
            };
        case taskTypes.FETCH_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingTaskList: false,
                fetchTaskListSuccess: action.payload,
                fetchTaskListFailure: null,
            };
        case taskTypes.FETCH_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingTaskList: false,
                fetchTaskListSuccess: null,
                fetchTaskListFailure: action.payload,
            };
        case taskTypes.FETCH_SINGLE_TASK_REQUEST:
            return {
                ...state,
                fetchingSingleTask: true,
                fetchingSingleTaskSuccess: null,
                fetchingSingleTaskFailure: null,
            };
        case taskTypes.FETCH_SINGLE_TASK_SUCCESS:
            return {
                ...state,
                fetchingSingleTask: false,
                fetchingSingleTaskSuccess: action.payload,
                fetchingSingleTaskFailure: null,
            };
        case taskTypes.FETCH_SINGLE_TASK_FAILURE:
            return {
                ...state,
                fetchingSingleTask: false,
                fetchingSingleTaskSuccess: null,
                fetchingSingleTaskFailure: action.payload,
            };
        case taskTypes.FETCH__PENDING_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingPendingTaskList: true,
                fetchPendingTaskSuccess: null,
                fetchPendingTaskFailure: null,
            };
        case taskTypes.FETCH__PENDING_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingPendingTaskList: false,
                fetchPendingTaskSuccess: action.payload,
                fetchPendingTaskFailure: null,
            };
        case taskTypes.FETCH__PENDING_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingPendingTaskList: false,
                fetchPendingTaskSuccess: null,
                fetchPendingTaskFailure: action.payload,
            };
        case taskTypes.FETCH__COMPLETED_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingCompletedTaskList: true,
                fetchCompletedTaskSuccess: null,
                fetchCompletedTaskFailure: null,
            };
        case taskTypes.FETCH__COMPLETED_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingCompletedTaskList: false,
                fetchCompletedTaskSuccess: action.payload,
                fetchCompletedTaskFailure: null,
            };
        case taskTypes.FETCH__COMPLETED_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingCompletedTaskList: false,
                fetchCompletedTaskSuccess: null,
                fetchCompletedTaskFailure: action.payload,
            };
        case taskTypes.FETCH__OVERDUE_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingOverdueTaskList: true,
                fetchOverdueTaskSuccess: null,
                fetchOverdueTaskFailure: null,
            };
        case taskTypes.FETCH__OVERDUE_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingOverdueTaskList: false,
                fetchOverdueTaskSuccess: action.payload,
                fetchOverdueTaskFailure: null,
            };
        case taskTypes.FETCH__OVERDUE_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingOverdueTaskList: false,
                fetchOverdueTaskSuccess: null,
                fetchOverdueTaskFailure: action.payload,
            };
        case taskTypes.FETCH__ACTIVE_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingActiveTaskList: true,
                fetchActiveTaskSuccess: null,
                fetchActiveTaskFailure: null,
            };
        case taskTypes.FETCH__ACTIVE_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingActiveTaskList: false,
                fetchActiveTaskSuccess: action.payload,
                fetchActiveTaskFailure: null,
            };
        case taskTypes.FETCH__ACTIVE_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingActiveTaskList: false,
                fetchActiveTaskSuccess: null,
                fetchActiveTaskFailure: action.payload,
            };
        case taskTypes.FETCH__ALL_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingAllTaskList: true,
                fetchAllTaskSuccess: null,
                fetchAllTaskFailure: null,
            };
        case taskTypes.FETCH__ALL_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingAllTaskList: false,
                fetchAllTaskSuccess: action.payload,
                fetchAllTaskFailure: null,
            };
        case taskTypes.FETCH__ALL_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingAllTaskList: false,
                fetchAllTaskSuccess: null,
                fetchAllTaskFailure: action.payload,
            };

        case taskTypes.FETCH__MY_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingMyTaskList: true,
                fetchMyTaskSuccess: null,
                fetchMyTaskFailure: null,
            };
        case taskTypes.FETCH__MY_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingMyTaskList: false,
                fetchMyTaskSuccess: action.payload,
                fetchMyTaskFailure: null,
            };
        case taskTypes.FETCH__MY_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingMyTaskList: false,
                fetchMyTaskSuccess: null,
                fetchMyTaskFailure: action.payload,
            };

            case taskTypes.FETCH__PENDING_TASK_TWO_LIST_REQUEST:
                return {
                    ...state,
                    fetchingPendingTaskTwoList: true,
                    fetchPendingTaskTwoSuccess: null,
                    fetchPendingTaskTwoFailure: null,
                };
            case taskTypes.FETCH__PENDING_TASK_TWO_LIST_SUCCESS:
                return {
                    ...state,
                    fetchingPendingTaskTwoList: false,
                    fetchPendingTaskTwoSuccess: action.payload,
                    fetchPendingTaskTwoFailure: null,
                };
            case taskTypes.FETCH__PENDING_TASK_TWO_LIST_FAILURE:
                return {
                    ...state,
                    fetchingPendingTaskTwoList: false,
                    fetchPendingTaskTwoSuccess: null,
                    fetchPendingTaskTwoFailure: action.payload,
                };
            case taskTypes.FETCH__COMPLETED_TASK_TWO_LIST_REQUEST:
                return {
                    ...state,
                    fetchingCompletedTaskTwoList: true,
                    fetchCompletedTaskTwoSuccess: null,
                    fetchCompletedTaskTwoFailure: null,
                };
            case taskTypes.FETCH__COMPLETED_TASK_TWO_LIST_SUCCESS:
                return {
                    ...state,
                    fetchingCompletedTaskTwoList: false,
                    fetchCompletedTaskTwoSuccess: action.payload,
                    fetchCompletedTaskTwoFailure: null,
                };
            case taskTypes.FETCH__COMPLETED_TASK_TWO_LIST_FAILURE:
                return {
                    ...state,
                    fetchingCompletedTaskTwoList: false,
                    fetchCompletedTaskTwoSuccess: null,
                    fetchCompletedTaskTwoFailure: action.payload,
                };
            case taskTypes.FETCH__OVERDUE_TASK_TWO_LIST_REQUEST:
                return {
                    ...state,
                    fetchingOverdueTaskTwoList: true,
                    fetchOverdueTaskTwoSuccess: null,
                    fetchOverdueTaskTwoFailure: null,
                };
            case taskTypes.FETCH__OVERDUE_TASK_TWO_LIST_SUCCESS:
                return {
                    ...state,
                    fetchingOverdueTaskTwoList: false,
                    fetchOverdueTaskTwoSuccess: action.payload,
                    fetchOverdueTaskTwoFailure: null,
                };
            case taskTypes.FETCH__OVERDUE_TASK_TWO_LIST_FAILURE:
                return {
                    ...state,
                    fetchingOverdueTaskTwoList: false,
                    fetchOverdueTaskTwoSuccess: null,
                    fetchOverdueTaskTwoFailure: action.payload,
                };
            case taskTypes.FETCH__ACTIVE_TASK_TWO_LIST_REQUEST:
                return {
                    ...state,
                    fetchingActiveTaskTwoList: true,
                    fetchActiveTaskTwoSuccess: null,
                    fetchActiveTaskTwoFailure: null,
                };
            case taskTypes.FETCH__ACTIVE_TASK_TWO_LIST_SUCCESS:
                return {
                    ...state,
                    fetchingActiveTaskTwoList: false,
                    fetchActiveTaskTwoSuccess: action.payload,
                    fetchActiveTaskTwoFailure: null,
                };
            case taskTypes.FETCH__ACTIVE_TASK_TWO_LIST_FAILURE:
                return {
                    ...state,
                    fetchingActiveTaskTwoList: false,
                    fetchActiveTaskTwoSuccess: null,
                    fetchActiveTaskTwoFailure: action.payload,
                };
            case taskTypes.FETCH__ALL_TASK_TWO_LIST_REQUEST:
                return {
                    ...state,
                    fetchingAllTaskTwoList: true,
                    fetchAllTaskTwoSuccess: null,
                    fetchAllTaskTwoFailure: null,
                };
            case taskTypes.FETCH__ALL_TASK_TWO_LIST_SUCCESS:
                return {
                    ...state,
                    fetchingAllTaskTwoList: false,
                    fetchAllTaskTwoSuccess: action.payload,
                    fetchAllTaskTwoFailure: null,
                };
            case taskTypes.FETCH__ALL_TASK_TWO_LIST_FAILURE:
                return {
                    ...state,
                    fetchingAllTaskTwoList: false,
                    fetchAllTaskTwoSuccess: null,
                    fetchAllTaskTwoFailure: action.payload,
                };
    
            case taskTypes.FETCH__MY_TASK_TWO_LIST_REQUEST:
                return {
                    ...state,
                    fetchingMyTaskTwoList: true,
                    fetchMyTaskTwoSuccess: null,
                    fetchMyTaskTwoFailure: null,
                };
            case taskTypes.FETCH__MY_TASK_TWO_LIST_SUCCESS:
                return {
                    ...state,
                    fetchingMyTaskTwoList: false,
                    fetchMyTaskTwoSuccess: action.payload,
                    fetchMyTaskTwoFailure: null,
                };
            case taskTypes.FETCH__MY_TASK_TWO_LIST_FAILURE:
                return {
                    ...state,
                    fetchingMyTaskTwoList: false,
                    fetchMyTaskTwoSuccess: null,
                    fetchMyTaskTwoFailure: action.payload,
                };
            case taskTypes.FETCH_PENDING_HUMAN_TASK_REQUEST:
                return {
                    ...state,
                    fetchingPendingHumanTask: true,
                    fetchPendingHumanTaskSuccess: null,
                    fetchPendingHumanTaskFailure: null,
                };
            case taskTypes.FETCH_PENDING_HUMAN_TASK_SUCCESS:
                return {
                    ...state,
                    fetchingPendingHumanTask: false,
                    fetchPendingHumanTaskSuccess: action.payload,
                    fetchPendingHumanTaskFailure: null,
                };
            case taskTypes.FETCH_PENDING_HUMAN_TASK_FAILURE:
                return {
                    ...state,
                    fetchingPendingHumanTask: false,
                    fetchPendingHumanTaskSuccess: null,
                    fetchPendingHumanTaskFailure: action.payload,
                };
                case taskTypes.FETCH_ALL_HUMAN_TASK_REQUEST:
                return {
                    ...state,
                    fetchingAllHumanTask: true,
                    fetchAllHumanTaskSuccess: null,
                    fetchAllHumanTaskFailure: null,
                };
            case taskTypes.FETCH_ALL_HUMAN_TASK_SUCCESS:
                return {
                    ...state,
                    fetchingAllHumanTask: false,
                    fetchAllHumanTaskSuccess: action.payload,
                    fetchAllHumanTaskFailure: null,
                };
            case taskTypes.FETCH_ALL_HUMAN_TASK_FAILURE:
                return {
                    ...state,
                    fetchingAllHumanTask: false,
                    fetchAllHumanTaskSuccess: null,
                    fetchAllHumanTaskFailure: action.payload,
                };
            case taskTypes.FETCH_COMPLETED_HUMAN_TASK_REQUEST:
                return {
                    ...state,
                    fetchingCompletedHumanTask: true,
                    fetchCompletedHumanTaskSuccess: null,
                    fetchCompletedHumanTaskFailure: null,
                };
            case taskTypes.FETCH_COMPLETED_HUMAN_TASK_SUCCESS:
                return {
                    ...state,
                    fetchingCompletedHumanTask: false,
                    fetchCompletedHumanTaskSuccess: action.payload,
                    fetchCompletedHumanTaskFailure: null,
                };
            case taskTypes.FETCH_COMPLETED_HUMAN_TASK_FAILURE:
                return {
                    ...state,
                    fetchingCompletedHumanTask: false,
                    fetchCompletedHumanTaskSuccess: null,
                    fetchCompletedHumanTaskFailure: action.payload,
                };
            case taskTypes.FETCH_FAILED_HUMAN_TASK_REQUEST:
                return {
                    ...state,
                    fetchingFailedHumanTask: true,
                    fetchFailedHumanTaskSuccess: null,
                    fetchFailedHumanTaskFailure: null,
                };
            case taskTypes.FETCH_FAILED_HUMAN_TASK_SUCCESS:
                return {
                    ...state,
                    fetchingFailedHumanTask: false,
                    fetchFailedHumanTaskSuccess: action.payload,
                    fetchFailedHumanTaskFailure: null,
                };
            case taskTypes.FETCH_FAILED_HUMAN_TASK_FAILURE:
                return {
                    ...state,
                    fetchingFailedHumanTask: false,
                    fetchFailedHumanTaskSuccess: null,
                    fetchFailedHumanTaskFailure: action.payload,
                };
             case taskTypes.FETCH_SINGLE_HUMAN_TASK_REQUEST:
            return {
                ...state,
                fetchingSingleHumanTask: true,
                fetchSingleHumanTaskSuccess: null,
                fetchSingleHumanTaskFailure: null,
            };
            case taskTypes.FETCH_SINGLE_HUMAN_TASK_SUCCESS:
            return {
                ...state,
                fetchingSingleHumanTask: false,
                fetchSingleHumanTaskSuccess: action.payload,
                fetchSingleHumanTaskFailure: null,
            };
            case taskTypes.FETCH_SINGLE_HUMAN_TASK_FAILURE:
            return {
                ...state,
                fetchingSingleHumanTask: false,
                fetchSingleHumanTaskSuccess: null,
                fetchSingleHumanTaskFailure: action.payload,
            };
            case taskTypes.CREATE_HUMAN_TASK_REQUEST:
                return {
                    ...state,
                    creatingHumanTask: true,
                    createHumanTaskSuccess: null,
                    createHumanTaskFailure: null,
                };
            case taskTypes.CREATE_HUMAN_TASK_SUCCESS:
                return {
                    ...state,
                    creatingHumanTask: false,
                    createHumanTaskSuccess: action.payload,
                    createHumanTaskFailure: null,
                };
            case taskTypes.CREATE_HUMAN_TASK_FAILURE:
                return {
                    ...state,
                    creatingHumanTask: false,
                    createHumanTaskSuccess: null,
                    createHumanTaskFailure: action.payload,
                };
            case taskTypes.UPDATE_HUMAN_TASK_REQUEST:
                return {
                    ...state,
                    updatingHumanTask: true,
                    updateHumanTaskSuccess: null,
                    updateHumanTaskFailure: null,
                };
            case taskTypes.UPDATE_HUMAN_TASK_SUCCESS:
                return {
                    ...state,
                    updatingHumanTask: false,
                    updateHumanTaskSuccess: action.payload,
                    updateHumanTaskFailure: null,
                };
            case taskTypes.UPDATE_HUMAN_TASK_FAILURE:
                return {
                    ...state,
                    updatingHumanTask: false,
                    updateHumanTaskSuccess: null,
                    updateHumanTaskFailure: action.payload,
                };
            case taskTypes.COMPLETE_HUMAN_TASK_REQUEST:
                return {
                    ...state,
                    completingHumanTask: true,
                    completeHumanTaskSuccess: null,
                    completeHumanTaskFailure: null,
                };
            case taskTypes.COMPLETE_HUMAN_TASK_SUCCESS:
                return {
                    ...state,
                    completingHumanTask: false,
                    completeHumanTaskSuccess: action.payload,
                    completeHumanTaskFailure: null,
                };
            case taskTypes.COMPLETE_HUMAN_TASK_FAILURE:
                return {
                    ...state,
                    completingHumanTask: false,
                    completeHumanTaskSuccess: null,
                    completeHumanTaskFailure: action.payload,
                };


        default:
            return state;
    }
};
