import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/common/Button";
import TabsContainer from "../../../components/common/TabsContainer";
import TextInput from "../../../components/common/InputField/TextInput";
import CustomTable from "../../../components/common/CustomTable";
import BOM from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/BOM";
import CostBreakdown from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/CostBreakdown";
import AdditionalInfo from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/AdditionalInfo";
import ApprovalWorkflow from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/ApprovalWorkflow";
import LinkedPurchaseOrder from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/LinkedPurchaseOrder";
import ProductionWorkflow from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/ProductionWorkflow";
import ModalContainer from "components/common/ModalContainer";
import TextareaInput from "components/common/InputField/TextareaInput";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import PageFrame from "components/layout/PageFrame";
import { productionActions } from "redux/Ldb/actions";
import moment from "moment";
import useGetSingleProductionPlan from "hooks/ProductionHooks/useGetSingleProductionPLan";
import useGetProductionPlanTab from "hooks/ProductionHooks/useGetProductionPlanTab";
import useGetUserProfile from "hooks/useGetUserProfile";
import { formatMoney } from "helpers";
import useGetProductionPlanTabTwo from "hooks/ProductionHooks/useGetProductionPlanTabTwo";

const ProductionPlanDetails = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { productionId } = useParams();
    const [rejectPlan, setRejectPlan] = useState<boolean>(false);
    const [approvePlan, setApprovePlan] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [approve, setApprove] = useState<boolean>(false);
    const [reason, setReason] = useState<string>("");
    // const [approversId, setApproversId] = useState<string[]>([]);
    const [canApprove, setCanApprove] = useState<boolean>(false);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [noReload, setNoReload] = useState<boolean>(false);
    const [settings, setSettings] = useState<{ [key: string]: any }>({});
    const [rawMaterialsSum, setRawMaterialsSum] = useState<number>(0);

    const tabs = [
        "Bill of Materials (BOM)",
        "Linked purchase orders",
        "Cost breakdown",
        "Production workflow",
        "Additional info",
        "Approval workflow",
    ];

    const {
        updateApprovalStatusSuccess,
        updatingApprovalStatus,
        fetchingProductionSettings,
        fetchedProductionSettingsSuccess,
    } = useAppSelector((state) => state?.production);

    const [profile] = useGetUserProfile();
    const { creatingWorkOrder, createWorkOrderSuccess } = useAppSelector(
        (state) => state?.production
    );

    const { data: planDetails, isLoading } = useGetSingleProductionPlan();

    const {
        data: tabDetails,
        setPlanDetails: setTabDetails,
        isLoading: tabLoading,
        setQuery,
        query,
        debouncedSearch,
        setDebouncedSearch,
        handleDebouncedSearch,
        search,
        setSearch,
        handleSearch,
        isLoadingMore,
        pagination,
    } = useGetProductionPlanTab();

    const {
        data: tabDetailsTwo,
        isLoading: tabTwoLoading,
        setQuery: setTabTwoQuery,
        isLoadingMore: tabTwoLoadingMore,
        pagination: tabTwoPagination,
    } = useGetProductionPlanTabTwo();

    useEffect(() => {
        dispatch(productionActions?.fetchProductionSettings());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedProductionSettingsSuccess)) {
            setSettings(fetchedProductionSettingsSuccess?.productionSettings);
        }
    }, [fetchedProductionSettingsSuccess]);

    const handleUpdateStatus = () => {
        const body = {
            approve: approve,
            ...(!approve && reason ? { reason } : reason && { comment: reason }),
        };
        setNoReload(true);
        dispatch(productionActions?.updateApprovalStatus(body, productionId));
    };

    useEffect(() => {
        if (!approvePlan && !rejectPlan) {
            setReason("");
        }
        if (approvePlan) {
            setApprove(true);
        } else {
            setApprove(false);
        }
    }, [approvePlan, rejectPlan]);

    useEffect(() => {
        if (Boolean(updateApprovalStatusSuccess)) {
            setApprovePlan(false);
            setRejectPlan(false);
            if (activeTab === 5) {
                setQuery("");
                setQuery("approval_workflow");
            }
            dispatch(productionActions.resetUpdateApprovalStatus());
            dispatch(productionActions.fetchSingleProductionPlan(productionId));
        }
    }, [updateApprovalStatusSuccess]);

    useEffect(() => {
        dispatch(productionActions?.fetchProductionSettings());
    }, [dispatch]);

    useEffect(() => {
        const canEditBasedOnProductionStatus =
            planDetails?.productionStatus === "planned" ||
            planDetails?.productionStatus === "pending";

        setCanEdit(
            fetchedProductionSettingsSuccess?.productionSettings &&
                fetchedProductionSettingsSuccess?.productionSettings?.editRole === profile?.role &&
                canEditBasedOnProductionStatus
                ? true
                : !fetchedProductionSettingsSuccess?.productionSettings &&
                  canEditBasedOnProductionStatus
                ? true
                : false
        );
    }, [fetchedProductionSettingsSuccess, planDetails]);

    useEffect(() => {
        const approve =
            planDetails?.approvalStatus !== "pending"
                ? false
                : planDetails?.allowedApproverIds?.length >= 1 && settings?.planApproval
                ? planDetails?.allowedApproverIds?.includes(profile?._id)
                : false;
        setCanApprove(approve);
    }, [planDetails, profile, settings]);

    const handleCreateWorkOrder = () => {
        planDetails?.workOrderId
            ? navigate(`/dashboard/production/work-order/${planDetails?.workOrderId}`)
            : dispatch(productionActions?.createWorkOrder(productionId));
    };

    useEffect(() => {
        if (Boolean(createWorkOrderSuccess)) {
            dispatch(productionActions?.resetCreateWorkOrder());
            navigate(`/dashboard/production/work-order/${createWorkOrderSuccess?.workOrder?._id}`);
        }
    }, [createWorkOrderSuccess]);
    useEffect(() => {
        if (activeTab === 0) {
            setQuery("bom");
        }
        if (activeTab === 1) {
            setTabTwoQuery("purchase_orders");
        } else if (activeTab === 3) {
            setTabTwoQuery("steps");
        } else if (activeTab === 5) {
            setQuery("approval_workflow");
        } else if (activeTab === 2) {
            setQuery("bom");
        }
    }, [activeTab]);

    useEffect(() => {
        if (query === "bom") {
            const sum = Array.isArray(tabDetails?.billOfMaterialIds)
                ? tabDetails?.billOfMaterialIds.reduce((total, item) => {
                      const addedSum =
                          item?.batches?.length > 0
                              ? item?.batches.reduce((sum, stock) => {
                                    const unitCost =
                                        Number(
                                            stock?.stock?.cost?.amount ||
                                                item?.inventory?.unitCost?.amount
                                        ) || 0;
                                    const quantity = stock?.quantity || 0;
                                    return sum + unitCost * quantity;
                                }, 0)
                              : 0;

                      return total + addedSum;
                  }, 0)
                : 0;
            // const sum = Array.isArray(tabDetails?.billOfMaterialIds)
            //     ? tabDetails?.billOfMaterialIds?.reduce((sum, bom) => {
            //           const quantityNum = bom?.quantity || 0;
            //           const calcQuantity = (planDetails?.quantity?.amount || 1) * quantityNum;
            //           const scrapNum = bom?.scrap || 0;
            //           const percentage = (calcQuantity * scrapNum) / 100;
            //           const totalQuantity = calcQuantity + percentage;
            //           const totalCost =
            //               (parseFloat(bom?.unitPrice || bom?.inventory?.unitCost?.amount) || 0) *
            //               totalQuantity;
            //           return sum + totalCost;
            //       }, 0)
            //     : 0;
            setRawMaterialsSum(sum);
        }
    }, [tabDetails]);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <BOM
                        bomDetails={tabDetails}
                        productName={planDetails?.inventory?.productName}
                        isLoading={tabLoading}
                        debouncedSearch={debouncedSearch}
                        handleDebouncedSearch={handleDebouncedSearch}
                        handleSearch={handleSearch}
                        search={search}
                        isLoadingMore={isLoadingMore}
                        pagination={pagination}
                        showShortageButton={true}
                        plannedQuantity={planDetails?.quantity?.amount}
                    />
                );
            case 1:
                return (
                    <LinkedPurchaseOrder
                        poDetails={tabDetailsTwo}
                        productName={planDetails?.inventory?.productName}
                        isLoading={tabTwoLoading}
                    />
                );
            case 2:
                return (
                    <CostBreakdown
                        directCost={planDetails?.directCosts}
                        inDirectCost={planDetails?.indirectCosts}
                        productName={planDetails?.inventory?.productName}
                        rawMaterialsSum={rawMaterialsSum}
                        isLoading={tabLoading}
                    />
                );
            case 3:
                return (
                    <ProductionWorkflow
                        steps={tabDetailsTwo}
                        productName={planDetails?.inventory?.productName}
                        isLoading={tabTwoLoading}
                    />
                );
            case 4:
                return (
                    <AdditionalInfo
                        notes={planDetails?.notes}
                        productName={planDetails?.inventory?.productName}
                    />
                );
            case 5:
                return (
                    <ApprovalWorkflow
                        approvalWorkflow={tabDetails}
                        productName={planDetails?.inventory?.productName}
                        // approversId={approversId}
                    />
                );
            default:
                return (
                    <BOM
                        bomDetails={tabDetails}
                        productName={planDetails?.inventory?.productName}
                        isLoading={tabLoading}
                        debouncedSearch={debouncedSearch}
                        handleDebouncedSearch={handleDebouncedSearch}
                        handleSearch={handleSearch}
                        search={search}
                        isLoadingMore={isLoadingMore}
                        pagination={pagination}
                        plannedQuantity={planDetails?.quantity?.amount}
                    />
                );
        }
    };

    return (
        <PageFrame isLoading={!noReload && isLoading}>
            <div className="bg-white rounded-lg border border-slate-100 font-rocGroteskMedium pb-12">
                <div className="flex items-center justify-between px-8 py-6 border-b border-n-20">
                    <div
                        className="flex items-center space-x-1.5 w-fit cursor-pointer"
                        onClick={() => navigate(`/dashboard/production?planTab=1`)}
                    >
                        <i className="ri-arrow-left-line text-[20px]"></i>
                        <p className="text-sm text-g-75 font-rocGroteskMedium underline">Back</p>
                    </div>

                    <div className="flex items-center  gap-3">
                        <div className="flex items-center gap-2">
                            <div
                                className={`w-2 h-2 rounded-full shrink-0 flex items-center justify-center captialize
                                   ${
                                       planDetails?.productionStatus === "pending"
                                           ? "bg-[#6366F1]"
                                           : planDetails?.productionStatus === "completed"
                                           ? "bg-[#22C55E]"
                                           : planDetails?.productionStatus === "in-progress"
                                           ? "bg-[#3B82F6]"
                                           : planDetails?.productionStatus === "cancelled"
                                           ? "bg-[#EF4444]"
                                           : planDetails?.productionStatus === "on-hold"
                                           ? "bg-[#EAB308]"
                                           : planDetails?.productionStatus === "rescheduled"
                                           ? "bg-[#8B5CF6]"
                                           : planDetails?.productionStatus === "planned"
                                           ? "bg-[#14B8A6]"
                                           : planDetails?.productionStatus === "delayed"
                                           ? "bg-[#F97316]"
                                           : planDetails?.productionStatus === "partial-completed"
                                           ? "bg-[#0EA5E9]"
                                           : planDetails?.productionStatus && "bg-[#6B7280]"
                                   }
                                    `}
                            ></div>

                            <span className="capitalize text-sm">
                                {planDetails?.productionStatus === "in-progress"
                                    ? "In progress"
                                    : planDetails?.productionStatus === "on-hold"
                                    ? "On hold"
                                    : planDetails?.productionStatus}
                            </span>
                        </div>
                        <Button
                            btnText="Close"
                            btnClassname="!px-4 !py-2 !bg-slate-100 !text-g-75 !w-fit text-[13px]"
                            onClick={() => navigate(`/dashboard/production?planTab=1`)}
                        />
                        {canEdit && (
                            <Button
                                btnText="Edit"
                                type="button"
                                onClick={() =>
                                    navigate(`/dashboard/production/edit-plan/${productionId}`)
                                }
                                btnClassname="!px-4 !py-2 !text-g-75 !w-fit text-[13px]"
                                disabled={!canEdit}
                            />
                        )}
                    </div>
                </div>

                {planDetails?.productionStatus === "pending" && (
                    <div
                        className={`bg-[rgba(34,197,94,0.1)] flex items-center justify-center gap-2 w-full text-[#22C55E] text-sm transition-opacity px-4 py-2`}
                    >
                        <i className="ri-checkbox-circle-fill  text-[#22C55E]"></i>
                        Your production plan for {planDetails?.inventory?.productName} has been
                        successfully created! Review the details to ensure everything is aligned
                        with your operational goals.
                    </div>
                )}

                <div className="px-6">
                    <div className="py-6 border-b border-slate-200">
                        <p className="text-gm-50 text-sm">Production Plan ID</p>
                        <h6 className="text-g-75 text-2xl">
                            {planDetails?.taId && `PP-${planDetails?.taId}`}
                        </h6>
                        <div className="flex gap-2 mt-4">
                            {canApprove && (
                                <div className="flex gap-2">
                                    <Button
                                        btnText="Approve production plan"
                                        btnClassname="!px-4 !py-2 !text-000 !w-fit text-[13px]"
                                        onClick={() => setApprovePlan(true)}
                                    />
                                    <Button
                                        btnText="Reject production plan"
                                        type="submit"
                                        onClick={() => setRejectPlan(true)}
                                        btnClassname="!px-4 !py-2 !bg-[#FEF2F2] !text-[#FF3D1D] !w-fit text-[13px]"
                                    />
                                </div>
                            )}

                            {((planDetails?.allowedApproverIds?.length === 0 &&
                                planDetails?.productionStatus === "pending") ||
                                planDetails?.productionStatus !== "pending" ||
                                (planDetails?.productionStatus === "pending" &&
                                    !settings?.planApproval)) && (
                                <Button
                                    btnText="Proceed to view Work Order"
                                    btnClassname="!px-4 !py-2 !text-000 !w-fit text-[13px]"
                                    isLoading={creatingWorkOrder}
                                    disabled={creatingWorkOrder}
                                    onClick={handleCreateWorkOrder}
                                />
                            )}
                        </div>
                    </div>

                    <div className="">
                        <div className="grid grid-cols-5 py-6 border-b border-slate-200">
                            <div className="">
                                <p className="text-slate-500 text-sm">Product</p>
                                <p className="text-gm-50 text-sm">
                                    {planDetails?.inventory?.productName}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-slate-500 text-sm">Production Quantity</p>
                                <p className="text-gm-50 text-sm">
                                    {formatMoney()?.format(planDetails?.quantity?.amount)}{" "}
                                    {planDetails?.quantity?.amount !== undefined
                                        ? planDetails?.quantity?.unitOfMeasurement
                                        : "N/A"}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-slate-500 text-sm">Production Start Date:</p>
                                <p className="text-gm-50 text-sm">
                                    {planDetails?.startDate
                                        ? moment(planDetails?.startDate)?.format("MMM DD, YYYY")
                                        : "N/A"}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-slate-500 text-sm">Estimated Completion Date</p>
                                <p className="text-gm-50 text-sm">
                                    {" "}
                                    {planDetails?.endDate
                                        ? moment(planDetails?.endDate)?.format("MMM DD, YYYY")
                                        : "N/A"}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-slate-500 text-sm">Quality Control Supervisor</p>
                                <p className="text-gm-50 text-sm">
                                    {planDetails?.qualityController?.email || "N/A"}
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-5 py-6 ">
                            <div className="">
                                <p className="text-slate-500 text-sm">Due Date</p>
                                <p className="text-gm-50 text-sm">
                                    {planDetails?.dueDate
                                        ? moment(planDetails?.dueDate)?.format("MMM DD, YYYY")
                                        : "N/A"}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-slate-500 text-sm">Created by</p>
                                <p className="text-gm-50 text-sm">
                                    {planDetails?.createdBy?.fullName ||
                                        planDetails?.createdBy?.email}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-slate-500 text-sm">Production Manager</p>
                                <p className="text-gm-50 text-sm">
                                    {planDetails?.manager?.email || planDetails?.manager?.name}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-slate-500 text-sm">Batch Number</p>
                                <p className="text-gm-50 text-sm">{planDetails?.batchId}</p>
                            </div>
                        </div>
                    </div>

                    <TabsContainer
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={(idx) => {
                            setActiveTab(idx);
                        }}
                        component={displayActiveTab()}
                        className="!px-0"
                        showButtonClassName
                        itemClassName="!pb-2 !whitespace-nowrap !border-y-10"
                        borderLineClase={"!text-slate-100 w-full mb-6"}
                    />
                </div>

                <ModalContainer
                    open={approvePlan}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[600px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        setApprovePlan(true);
                    }}
                >
                    <div className="bg-white rounded-[8px] relative">
                        <i
                            className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                            onClick={() => {
                                setApprovePlan(false);
                            }}
                        ></i>
                        <div className="mt-4 ">
                            <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                                <div className="mb-1">
                                    <i className="ri-chat-check-line text-gm-50 text-[40px]"></i>
                                </div>
                                <div className="px-4 font-rocGroteskMedium">
                                    <span className="block mb-2 text-center text-[22px] text-gm-50">
                                        Approve Production Plan?
                                    </span>
                                    <p className="mt-1 text-base text-slate-500 text-center ">
                                        Your approval will move this plan to the next level for
                                        review. The next approver will be notified.
                                    </p>
                                </div>
                            </div>

                            <div className="px-6 pb-6">
                                <TextareaInput
                                    name={"reason"}
                                    value={reason}
                                    onChange={(e) => setReason(e?.target.value)}
                                    rows={5}
                                    placeholder={"Comment"}
                                />
                            </div>
                            <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                                <Button
                                    btnText={"Cancel"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                    onClick={() => {
                                        setApprovePlan(false);
                                    }}
                                />
                                <Button
                                    btnText={"Approve Production Plan"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                    onClick={() => {
                                        handleUpdateStatus();
                                    }}
                                    isLoading={updatingApprovalStatus}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
                <ModalContainer
                    open={rejectPlan}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[600px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        setRejectPlan(true);
                    }}
                >
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleUpdateStatus();
                        }}
                        className="bg-white rounded-[8px] relative"
                    >
                        <i
                            className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                            onClick={() => {
                                setRejectPlan(false);
                            }}
                        ></i>
                        <div className="mt-4 ">
                            <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                                <div className="mb-1">
                                    <i className="ri-feedback-line text-gm-50 text-[40px]"></i>
                                </div>
                                <div className="px-4 font-rocGroteskMedium">
                                    <span className="block mb-2 text-center text-[22px] text-gm-50">
                                        Production Plan Rejected
                                    </span>
                                    <p className="mt-1 text-base text-slate-500 text-center ">
                                        The production plan for{" "}
                                        {planDetails?.inventory?.productName} has been rejected.
                                        Please review the feedback provided to understand the
                                        reasons.
                                    </p>
                                </div>
                            </div>

                            <div className="px-6 pb-6">
                                <TextareaInput
                                    name={"reason"}
                                    value={reason}
                                    onChange={(e) => setReason(e?.target.value)}
                                    rows={5}
                                    placeholder={"Comment"}
                                    required={true}
                                />
                            </div>
                            <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                                <Button
                                    btnText={"Cancel"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                    onClick={() => {
                                        setRejectPlan(false);
                                    }}
                                />
                                <Button
                                    btnText={"Reject Production Plan"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !text-white !border border-n-20 !font-rocGroteskMedium !px-4 !bg-[#FF3D1D]"
                                    type="submit"
                                    isLoading={updatingApprovalStatus}
                                />
                            </div>
                        </div>
                    </form>
                </ModalContainer>
            </div>
        </PageFrame>
    );
};

export default ProductionPlanDetails;
