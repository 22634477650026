import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import PageTitleSection from "components/common/PageTitleSection";
import TabsContainer from "components/common/TabsContainer";
import VendorAvatar from "components/common/VendorAvatar";
import useGetTeamRoles from "hooks/teamHooks/useGetTeamRoles";
import useGetTeam from "hooks/teamHooks/useGetTeamList";
import React, { useEffect, useState } from "react";
import { accessTypes } from "variables";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import Loader from "components/common/Loader";
import useGetBrandList from "hooks/multibrandHooks/useGetBrandList";
import { cloneDeep, isEqual } from "lodash";
import { authActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { getFromStorage } from "helpers";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";

const TeamManagement = () => {
    const [profile, setProfile] = useState(() => getFromStorage("ally-user"));
    const [activeTab, setActiveTab] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [shouldDeactivateModal, setShouldDeactivateModal] = useState(false);
    const [shouldDeleteMemberModal, setShouldDeleteMemberModal] = useState(false);
    const [teamForm, setTeamForm] = useState({
        email: "",
        role: "",
        accessLevel: [],
        brands: [],
    });
    const [selectedMember, setSelectedMember] = useState<{ [key: string]: any }>({});
    const [addCustomRole, setAddCustomRole] = useState(false);
    const [role, setRole] = useState("");
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
    const [selectedBrands, setSelectedBrands] = useState<any[]>([]);

    const dispatch = useAppDispatch();
    const {
        addingTeamMember,
        addTeamMemberSuccess,
        deleteTeamMember,
        deleteTeamMemberSuccess,
        updateTeamMember,
        updateTeamMemberSuccess,
        createRoles,
        createRolesSuccess,
    } = useAppSelector((state) => state.auth);

    const {
        data: brandList,
        isFetching,
        handleDebouncedChange: debouncehandleChange,
    } = useGetBrandList();
    const {
        data: teamList,
        handleSearch,
        handleDebouncedChange,
        search,
        isFetching: isFetchingTeamList,
        isLoadingMore,
        pagination,
        setTeamList,
    } = useGetTeam();
    const { data: userRoles, isFetching: isFetchingRoles } = useGetTeamRoles();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: { [key: string]: any } = {
            brandIds: teamForm?.brands,
            teamMembers: [
                {
                    email: teamForm?.email,
                    role: teamForm?.role,
                },
            ],
        };

        dispatch(authActions.addTeamMember(body, profile?.token));
    };

    const handleChange = (name: string, value: any) => {
        setTeamForm((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleMultipleChange = (name: string, value: any) => {
        // const parseValue = JSON.parse(value);
        const teamFormCopy = { ...teamForm };
        const valueIndex = teamFormCopy?.[name]?.findIndex((item) => isEqual(item, value));

        if (valueIndex !== -1) {
            setTeamForm((prev) => {
                return {
                    ...prev,
                    [name]: teamFormCopy?.[name]?.filter((item) => !isEqual(item, value)),
                };
            });
        } else {
            setTeamForm((prev) => {
                return {
                    ...prev,
                    [name]: [...teamFormCopy?.[name], value],
                };
            });
        }
    };

    const handleSelectBrand = (data: any) => {
        // const parseValue = JSON.parse(value);
        const selectedBrandsCopy = [...selectedBrands];
        const valueIndex = selectedBrandsCopy?.findIndex((item) => isEqual(item, data));

        if (valueIndex !== -1) {
            setSelectedBrands((prev) => {
                return selectedBrandsCopy?.filter((item) => !isEqual(item, data));
            });
        } else {
            setSelectedBrands((prev) => {
                return [...prev, data];
            });
        }
    };

    const handleUpdateMember = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body = {
            memberId: selectedMember?._id,
            role: teamForm?.role,
            active: selectedMember?.active,
            assignedBrandsId: teamForm?.brands,
        };

        dispatch(authActions.updateTeamMember(body, profile?.token));
    };

    const handleDeactivateMember = () => {
        const body = {
            active: selectedMember?.active ? false : true,
            memberId: selectedMember?._id,
        };
        dispatch(authActions.updateTeamMember(body, profile?.token));
    };

    const handleDeleteMember = () => {
        if (selectedMember?._id) {
            dispatch(authActions.deleteTeamMember(selectedMember?._id, profile?.token));
        }
    };

    const handlePermissionChange = (permission: string) => {
        const isSelected = selectedPermissions.includes(permission);

        if (isSelected) {
            const updatedPermissions = selectedPermissions.filter((item) => item !== permission);
            setSelectedPermissions(updatedPermissions);
        } else {
            setSelectedPermissions([...selectedPermissions, permission]);
        }
    };

    const handleAddRole = () => {
        // e.preventDefault();
        const permission = selectedPermissions;
        if (role === "") {
            toast.custom((t) => (
                <CustomToast t={t} message={"Role name is required"} type="error" />
            ));
        }
        if (role !== "") {
            const teamMember = {
                role,
                ...(permission.length > 0 && { permission }),
            };
            dispatch(authActions.createRoles(teamMember, profile?.token));
        }
    };

    const tableHeader = [
        { title: "Teammate", widthClass: "w-[35%] font-rocGroteskMedium" },
        { title: "Brands managed", widthClass: "w-[25%] font-rocGroteskMedium" },
        { title: "Date added", widthClass: "w-[19%] font-rocGroteskMedium" },
        { title: "Status", widthClass: "w-[16%] font-rocGroteskMedium" },
        { title: "", widthClass: "w-[4.3%] font-rocGroteskMedium" },
    ];

    const tableBody = teamList?.map((teammate, idx) => [
        {
            content: (
                <div className={`w-full border-slate-100 text-sm font-rocGroteskMedium py-1.5`}>
                    <div className="flex items-center space-x-3">
                        <VendorAvatar name="Cameron Horace" size={32} />
                        <div>
                            <p className="text-sm">{teammate?.fullName || teammate?.email}</p>
                            <p className="text-sm text-slate-500 capitalize">{teammate?.role}</p>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-1.5`}
                >
                    {teammate?.assignedBrands && teammate?.assignedBrands?.length > 0 ? (
                        <div className="w-fit">
                            <a id={`brands-column-${idx}`}>
                                <div className="flex items-center space-x-1">
                                    {teammate?.assignedBrands?.slice(0, 4)?.map((brand, idx) => {
                                        return brand?.profile?.avatar ? (
                                            <img
                                                key={idx}
                                                src={brand?.profile?.avatar}
                                                alt={`${brand?.brandName} Logo`}
                                                className="w-6 h-6 rounded-full object-cover"
                                            />
                                        ) : (
                                            <i
                                                key={idx}
                                                className="ri-store-2-line text-2xl rounded-full"
                                            ></i>
                                        );
                                    })}

                                    {teammate?.assignedBrands?.slice(4)?.length > 0 && (
                                        <div className="w-6 h-6 rounded-lg bg-slate-200 flex items-center justify-center">
                                            <span className="text-xs font-rocGroteskMedium">
                                                +{teammate?.assignedBrands?.slice(4)?.length}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </a>
                            <Tooltip
                                anchorSelect={`#brands-column-${idx}`}
                                place={"right"}
                                content={teammate?.assignedBrands
                                    ?.map((brand) => brand?.brandName)
                                    ?.join(", ")}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    // background: "#fff",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "white",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-1.5`}
                >
                    <p className="text-sm">{moment(teammate?.createdAt)?.format("MMM DD, YYYY")}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-1.5`}
                >
                    {teammate?.active ? (
                        <p className="w-fit text-xs bg-g-25 py-[2px] px-2 rounded-[16px] flex items-center text-g-60">
                            Active
                        </p>
                    ) : (
                        <p className="w-fit text-xs bg-transparent py-[2px] px-2 rounded-[16px] flex items-center text-[#BEC5CE]">
                            Inactive
                        </p>
                    )}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-1.5`}
                >
                    {profile?.user?.role === "owner" &&
                        !profile?.user?.parentId &&
                        teammate?.role?.toLowerCase() !== "owner" && (
                            <Dropdown
                                name="filter"
                                value={""}
                                dropdownTriggerClassName={" "}
                                // handleChange={(name, value) => handleFilterChange(value)}
                                dropdown={<i className="ri-more-2-fill"></i>}
                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                dropdownContainerClasses={`shadow-cardShadow-4 left-[-150px] !mt-0 !w-[159px] max-sm:!w-full rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100 hover:!bg-[#D0F4ED66]"
                                dropdownOptions={[
                                    {
                                        label: "Edit user details",
                                        value: "edit",
                                        action: () => {
                                            setSelectedMember(teammate);
                                            setTeamForm({
                                                accessLevel: accessTypes
                                                    ?.filter((item) =>
                                                        teammate?.permission?.some(
                                                            (elem) => elem === item?.value
                                                        )
                                                    )
                                                    ?.map((item) => JSON.stringify(item)),
                                                // brands: teammate?.assignedBrands?.map((item) =>
                                                //     JSON.stringify(item)
                                                // ),
                                                brands: teammate?.assignedBrands?.map(
                                                    (item) => item?._id
                                                ),
                                                email: teammate?.email,
                                                role: teammate?.role,
                                            });
                                            setSelectedBrands(
                                                teammate?.assignedBrands?.map((item) =>
                                                    JSON.stringify(item)
                                                )
                                            );
                                            setOpenModal(true);
                                        },
                                    },
                                    {
                                        label: teammate?.active
                                            ? "Deactivate user"
                                            : "Activate user",
                                        value: teammate?.active ? "deactivate" : "activate",
                                        action: () => {
                                            setSelectedMember(teammate);
                                            setShouldDeactivateModal(true);
                                        },
                                    },
                                    {
                                        label: "Delete user",
                                        value: "delete",
                                        action: () => {
                                            setSelectedMember(teammate);
                                            setShouldDeleteMemberModal(true);
                                        },
                                    },
                                ]}
                            />
                        )}
                </div>
            ),
        },
    ]);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <div>
                        <div className="">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                tableContainerClass="!pb-[120px] !mb-[-120px]"
                                tableClass=" w-full"
                                isScrollable={false}
                                isCellBordered={false}
                            />
                        </div>

                        {isFetchingTeamList && isLoadingMore && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {pagination?.currentPage === pagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                );

            default:
                break;
        }
    };

    useEffect(() => {
        if (Boolean(addTeamMemberSuccess)) {
            setTeamForm({
                email: "",
                role: "",
                accessLevel: [],
                brands: [],
            });
            setSelectedBrands([]);
            setOpenModal(false);

            dispatch(authActions.resetAddTeamMemberSuccess());
        }
    }, [dispatch, addTeamMemberSuccess]);

    useEffect(() => {
        if (Boolean(updateTeamMemberSuccess)) {
            const teamListCopy = cloneDeep([...teamList]);
            const memberIndex = teamListCopy?.findIndex(
                (item) => item?._id === updateTeamMemberSuccess?.user?._id
            );

            if (memberIndex !== -1) {
                teamListCopy[memberIndex] = updateTeamMemberSuccess?.user;
                setTeamList(teamListCopy);
            }

            setTeamForm({
                email: "",
                role: "",
                accessLevel: [],
                brands: [],
            });
            setSelectedBrands([]);
            setOpenModal(false);
            setSelectedMember({});
            dispatch(authActions.resetUpdateTeamMemberSuccess());
        }
    }, [dispatch, updateTeamMemberSuccess, selectedMember?._id, teamList?.length]);

    useEffect(() => {
        if (Boolean(deleteTeamMemberSuccess)) {
            const teamListCopy = cloneDeep([...teamList]);

            setTeamList((prev) => prev?.filter((item) => item?._id !== selectedMember?._id));

            setShouldDeleteMemberModal(false);
            setSelectedMember({});
            dispatch(authActions.resetDeleteTeamMemberSuccess());
        }
    }, [dispatch, deleteTeamMemberSuccess, selectedMember?._id, teamList?.length]);

    useEffect(() => {
        if (Boolean(createRolesSuccess)) {
            setSelectedPermissions([]);
            setAddCustomRole(false);
            dispatch(authActions.resetCreateRoles());
            dispatch(authActions.getRoles(20, profile?.token));
        }
    }, [createRolesSuccess, dispatch]);

    return (
        <div className="px-4 pt-4 pb-6 border border-slate-100 rounded-[8px] bg-white">
            <div className="mb-6">
                <PageTitleSection
                    leftComp={
                        <div className="flex items-center space-x-3">
                            <h3 className="text-xl font-rocGroteskMedium">Team management</h3>
                        </div>
                    }
                    rightComp={
                        profile?.user?.role === "owner" &&
                        !profile?.user?.parentId && (
                            <Button
                                btnText="Add a team-mate"
                                btnClassname="!px-[8px] !py-2 !w-fit !h-[35px] !text-[13px] !whitespace-nowrap"
                                icon={<i className="ri-user-add-fill"></i>}
                                onClick={() => {
                                    setSelectedMember({});
                                    setOpenModal(true);
                                }}
                            />
                        )
                    }
                />
            </div>

            <div>
                <TabsContainer
                    tabs={["Existing team members"]}
                    activeTab={activeTab}
                    setActiveTab={(idx) => {
                        setActiveTab(idx);
                    }}
                    component={displayActiveTab()}
                    className="!px-0"
                    showButtonClassName
                    itemClassName="!pb-2 max-lg:whitespace-nowrap"
                    borderLineClase={"!text-slate-100 w-[32%] max-lg:w-full mb-6"}
                    showButton={
                        <div className="flex items-center space-x-2 mb-6 max-sm:grid max-sm:space-x-0 max-sm:space-y-2 max-sm:grid-cols-1">
                            <TextInput
                                name={"search"}
                                value={search}
                                type={"text"}
                                inputPlaceholder={"Search team members"}
                                inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                onChange={(e) => handleSearch(e.target.value)}
                                onInput={handleDebouncedChange}
                                containerClassname={"!w-[300px] max-sm:!w-full"}
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px] max-sm:!w-full !pl-[25px] !h-[40px] !bg-white !border !border-slate-200"
                                }
                            />

                            {/* <Button
                                btnText="Settings"
                                btnClassname="!px-[8px] !py-2 !w-fit !h-[40px] !text-[13px] !bg-transparent !border !border-slate-200 !whitespace-nowrap"
                                icon={<i className="ri-settings-5-line"></i>}
                            /> */}
                        </div>
                    }
                />
            </div>

            <ModalContainer
                open={openModal}
                showCloseIcon={false}
                modalClassName={
                    "md:py-10 bg-white !items-start !top-0 !left-0 md:!items-center md:bg-gm-50/20"
                }
                tailwindClassName="w-full md:w-[553px] !overflow-y-visible"
                closeModal={() => {
                    setOpenModal(false);
                    setTeamForm({
                        accessLevel: [],
                        brands: [],
                        email: "",
                        role: "",
                    });
                    setSelectedBrands([]);
                }}
            >
                <div className="bg-white shadow-cardShadow relative rounded-lg">
                    <div className="w-full md:flex md:justify-center ">
                        <div className="w-full md:w-[553px]">
                            <div className="w-full   md:flex md:text-center items-center md:justify-center">
                                <div className="w-full flex py-4 border-b border-slate-200 text-center items-center justify-center relative">
                                    <span className="text-base font-rocGroteskMedium">
                                        Add a team-mate
                                    </span>
                                    <div className="absolute right-[24px]">
                                        <i
                                            className="ri-close-fill text-2xl cursor-pointer"
                                            onClick={() => {
                                                setOpenModal(false);
                                                setTeamForm({
                                                    accessLevel: [],
                                                    brands: [],
                                                    email: "",
                                                    role: "",
                                                });
                                                setSelectedBrands([]);
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            </div>
                            <form
                                onSubmit={(e) => {
                                    if (selectedMember?._id) {
                                        handleUpdateMember(e);
                                    } else {
                                        handleSubmit(e);
                                    }
                                }}
                                className="w-full  "
                            >
                                <div className="space-y-6 p-6">
                                    <TextInput
                                        value={teamForm?.email}
                                        name={"email"}
                                        type={"email"}
                                        onChange={(e) => {
                                            if (!Boolean(selectedMember?._id)) {
                                                handleChange(e.target.name, e.target.value);
                                            }
                                        }}
                                        placeholder={"Email"}
                                        disabled={Boolean(selectedMember?._id)}
                                        required={true}
                                        containerClassname=""
                                    />

                                    <SelectInput
                                        value={teamForm.role}
                                        name="role"
                                        placeholder="Select role"
                                        handleChange={(name, value) => {
                                            handleChange(name, value);
                                        }}
                                        isRequired={true}
                                        searchLoading={isFetchingRoles}
                                        customDropdownContainer={
                                            <div className="w-full py-1.5 flex flex-col gap-3.5">
                                                <div
                                                    onClick={() => setAddCustomRole(true)}
                                                    className="flex items-center gap-1 px-3 font-rocGroteskMedium cursor-pointer w-fit"
                                                >
                                                    <i className="ri-add-fill before:content-['\ea12'] text-p-55 text-xl mt-[-2px]"></i>
                                                    <span className="text-sm text-p-50">
                                                        Add custom role
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                        dropdownOptions={userRoles?.map((role) => {
                                            return {
                                                label: role?.label,
                                                value: role?.role,
                                            };
                                        })}
                                    />

                                    <div className="flex items-center space-x-3">
                                        <hr className="text-slate-500 w-[40%]" />
                                        <span className="text-sm font-rocGroteskMedium text-slate-500 ">
                                            Assign brands
                                        </span>
                                        <hr className="text-slate-500 w-[35%]" />
                                    </div>

                                    <div>
                                        <div className="mb-2.5">
                                            <SelectInput
                                                value={""}
                                                name="brands"
                                                // placeholder={"Select category"}
                                                placeholder="Select brands (can be multiple)"
                                                handleChange={(name, value, rawData) => {
                                                    if (Boolean(rawData)) {
                                                        handleMultipleChange(name, value);
                                                        handleSelectBrand(rawData);
                                                    }
                                                }}
                                                handleInputChange={debouncehandleChange}
                                                searchLoading={isFetching}
                                                isRequired={
                                                    selectedBrands?.length > 0 ? false : true
                                                }
                                                dropdownOptions={brandList?.map((item) => {
                                                    return {
                                                        label: item?.brand?.brandName,
                                                        value: item?.brand?._id,
                                                        // value: JSON.stringify(item?.brand),
                                                        rawData: JSON.stringify(item?.brand),
                                                    };
                                                })}
                                            />
                                        </div>

                                        <div className="flex items-center gap-2.5 flex-wrap ">
                                            {selectedBrands?.map((item, idx) => {
                                                const parseItem = JSON.parse(item);
                                                return (
                                                    <div key={parseItem?._id} className="w-fit">
                                                        <a id={`brands-row-${idx}`}>
                                                            <div className="bg-white h-[52px] w-[52px] flex items-center shadow-[0px_-4px_8px_0px_#00000008] justify-center rounded-full relative">
                                                                <div className="w-8 h-8 rounded-full  flex items-center justify-center">
                                                                    {parseItem?.profile?.avatar ? (
                                                                        <img
                                                                            src={
                                                                                parseItem?.profile
                                                                                    ?.avatar
                                                                            }
                                                                            alt={`${parseItem?.brandName} logo`}
                                                                            className="w-8 h-8 rounded-full object-cover"
                                                                        />
                                                                    ) : (
                                                                        <i className="ri-store-2-line text-[32px] rounded-full"></i>
                                                                    )}
                                                                </div>
                                                                <div
                                                                    onClick={() => {
                                                                        handleMultipleChange(
                                                                            "brands",
                                                                            parseItem?._id
                                                                        );
                                                                        handleSelectBrand(item);
                                                                    }}
                                                                    className="w-fit absolute top-[-10px] right-[-4px] cursor-pointer"
                                                                >
                                                                    <i className="ri-close-fill text-r-55"></i>
                                                                </div>
                                                            </div>
                                                        </a>

                                                        <Tooltip
                                                            anchorSelect={`#brands-row-${idx}`}
                                                            place={"right"}
                                                            content={parseItem?.brandName}
                                                            style={{
                                                                maxWidth: "250px",
                                                                zIndex: 9999,
                                                                // background: "#fff",
                                                                borderRadius: "6px",
                                                                border: "1px solid #E2E8F0",
                                                                color: "white",
                                                                boxShadow:
                                                                    "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full mt-1 py-4 px-6 border-t border-slate-200 flex items-center space-x-2">
                                    <Button
                                        btnText={"Cancel"}
                                        onClick={() => {
                                            setOpenModal(false);
                                            setTeamForm({
                                                accessLevel: [],
                                                brands: [],
                                                email: "",
                                                role: "",
                                            });
                                            setSelectedBrands([]);
                                        }}
                                        disabled={addingTeamMember || updateTeamMember}
                                        btnClassname={"!bg-tradeally-neutral-20 !text-gm-75 "}
                                    />
                                    <Button
                                        btnText={"Add team-mate"}
                                        isLoading={addingTeamMember || updateTeamMember}
                                        type={"submit"}
                                        btnClassname=""
                                        btnTextClassName="!text-md"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={addCustomRole}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[513px] xl:w-[600px]"
                closeModal={() => {
                    setAddCustomRole(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-modalShadow relative pt-5 pb-10">
                    <div className="flex justify-end px-10">
                        <span
                            onClick={() => setAddCustomRole(false)}
                            className="material-icons text-gm-50 text-xl cursor-pointer"
                        >
                            close
                        </span>
                    </div>
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center text-center mb-[45px]">
                            <p className="text-2xl mb-2.5 font-rocGroteskMedium text-g-75">
                                Create a custom role
                            </p>
                            <p className="text-sm font-rocGroteskMedium text-gm-40">
                                Define what the new role’s responsibilities are
                            </p>
                        </div>
                        <form className="w-full">
                            <TextInput
                                name={`role`}
                                type={"text"}
                                placeholder={"Enter role name"}
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                                required={true}
                                containerClassname={"mb-6"}
                            />
                            <div>
                                <p className="text-base font-rocGroteskMedium mb-6">
                                    Select access levels
                                </p>
                                <div className="flex flex-wrap gap-4 relative overflow-auto">
                                    {accessTypes.map((access, idx) => (
                                        <div
                                            key={idx}
                                            onClick={() => handlePermissionChange(access.value)}
                                            className={`px-2.5 w-fit h-[30px] ${
                                                selectedPermissions.includes(access.value)
                                                    ? "!bg-carribean-green !text-white !border-carribean-green"
                                                    : ""
                                            } border flex items-center gap-2 rounded-[8px] font-rocGroteskMedium bg-white hover:!bg-g-65 hover:text-white hover:!border-g-65 border-slate-300 text-[13px] cursor-pointer`}
                                        >
                                            {selectedPermissions.includes(access.value) ? (
                                                <i className="ri-checkbox-circle-fill text-base"></i>
                                            ) : (
                                                <i className="ri-add-fill text-base"></i>
                                            )}
                                            {access.label}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Button
                                onClick={handleAddRole}
                                btnText={"Add role"}
                                isLoading={createRoles}
                                btnClassname={"my-10"}
                            />
                            <p
                                onClick={() => setAddCustomRole(false)}
                                className="text-sm font-rocGroteskMedium text-center cursor-pointer"
                            >
                                Close
                            </p>
                        </form>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={shouldDeactivateModal}
                showCloseIcon={false}
                modalClassName={
                    "md:py-10 bg-white !items-start !top-0 !left-0 md:!items-center md:bg-gm-50/20"
                }
                tailwindClassName="w-full md:w-[553px] !overflow-y-visible"
                closeModal={() => {
                    setShouldDeactivateModal(false);
                }}
            >
                <div className="bg-white shadow-cardShadow relative rounded-lg">
                    <div className="w-full md:flex md:justify-center ">
                        <div className="w-full md:w-[553px]">
                            <div className="w-full text-center py-5 px-4">
                                <h5 className="text-xl font-rocGroteskMedium mb-4">
                                    {selectedMember?.active ? "Deactivate" : "Activate"} Member
                                </h5>
                                <p className="text-sm font-rocGroteskMedium text-slate-500">
                                    Are you sure you want to{" "}
                                    {selectedMember?.active ? "deactivate" : "activate"} team member{" "}
                                    {selectedMember?.email}?
                                </p>
                            </div>
                            <div className="w-full  ">
                                <div className="w-full mt-1 py-4 px-6 border-t border-slate-200 flex items-center space-x-2">
                                    <Button
                                        btnText={"No, cancel"}
                                        onClick={() => {
                                            setShouldDeactivateModal(false);
                                        }}
                                        disabled={updateTeamMember}
                                        btnClassname={"!bg-tradeally-neutral-20 !text-gm-75 "}
                                    />
                                    <Button
                                        btnText={"Yes, proceed"}
                                        isLoading={updateTeamMember}
                                        onClick={handleDeactivateMember}
                                        btnClassname=""
                                        btnTextClassName="!text-md"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={shouldDeleteMemberModal}
                showCloseIcon={false}
                modalClassName={
                    "md:py-10 bg-white !items-start !top-0 !left-0 md:!items-center md:bg-gm-50/20"
                }
                tailwindClassName="w-full md:w-[553px] !overflow-y-visible"
                closeModal={() => {
                    setShouldDeleteMemberModal(false);
                }}
            >
                <div className="bg-white shadow-cardShadow relative rounded-lg">
                    <div className="w-full md:flex md:justify-center ">
                        <div className="w-full md:w-[553px]">
                            <div className="w-full text-center py-5 px-4">
                                <h5 className="text-xl font-rocGroteskMedium mb-4">
                                    Delete Member
                                </h5>
                                <p className="text-sm font-rocGroteskMedium text-slate-500">
                                    Are you sure you want to delete this team member{" "}
                                    {selectedMember?.email}?
                                </p>
                            </div>
                            <div className="w-full  ">
                                <div className="w-full mt-1 py-4 px-6 border-t border-slate-200 flex items-center space-x-2">
                                    <Button
                                        btnText={"No, cancel"}
                                        onClick={() => {
                                            setShouldDeleteMemberModal(false);
                                        }}
                                        disabled={deleteTeamMember}
                                        btnClassname={"!bg-tradeally-neutral-20 !text-gm-75 "}
                                    />
                                    <Button
                                        btnText={"Yes, proceed"}
                                        isLoading={deleteTeamMember}
                                        onClick={handleDeleteMember}
                                        btnClassname=""
                                        btnTextClassName="!text-md"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default TeamManagement;
