import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "../../../components/common/Button";
import TabsContainer from "../../../components/common/TabsContainer";
import TextInput from "../../../components/common/InputField/TextInput";
import CustomTable from "../../../components/common/CustomTable";
import BOM from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/BOM";
import CostBreakdown from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/CostBreakdown";
import AdditionalInfo from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/AdditionalInfo";
import ApprovalWorkflow from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/ApprovalWorkflow";
import LinkedPurchaseOrder from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/LinkedPurchaseOrder";
import ProductionWorkflow from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/ProductionWorkflow";
import ModalContainer from "components/common/ModalContainer";
import TextareaInput from "components/common/InputField/TextareaInput";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import PageFrame from "components/layout/PageFrame";
import { productionActions } from "redux/Ldb/actions";
import moment from "moment";
import useGetSingleProductionPlan from "hooks/ProductionHooks/useGetSingleProductionPLan";
import useGetProductionPlanTab from "hooks/ProductionHooks/useGetProductionPlanTab";
import useGetUserProfile from "hooks/useGetUserProfile";
import useGetSingleWorkOrder from "hooks/ProductionHooks/useGetSingleWorkOrder";
import useGetWorkOrderTabDetails from "hooks/ProductionHooks/useGetWorkOrderTabDetails";
import SelectInput from "components/common/InputField/SelectInput";
import WorkOrderModal from "components/views/Synth2.0/Production/WorkOrderModal";
import YieldAndLosses from "components/views/Synth2.0/Production/YieldAndLosses";
import BillOfMaterial from "components/views/Synth2.0/Production/BillOfMaterial";
import { formatMoney } from "helpers";
import useGetWorkOrderTabTwoDetails from "hooks/ProductionHooks/useGetWorkOrderTabTwoDetails";
import useGetAllWarehouses from "hooks/useGetAllWarehouses";
import { debounce, truncate } from "lodash";
import { Tooltip } from "react-tooltip";
import useGetPendingWorkOrderQC from "hooks/ProductionHooks/useGetPendingWorkOrderQC";

const WorkOrderDetails = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { workOrderId } = useParams();
    const [params] = useSearchParams();
    const currTab = params.get("currTab");

    const yieldLoss = params.get("yieldLoss");
    const updateStatus = params.get("updateStatus");

    const [rejectPlan, setRejectPlan] = useState<boolean>(false);
    const [approvePlan, setApprovePlan] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState(0);
    const [approve, setApprove] = useState<boolean>(false);
    const [reason, setReason] = useState<string>("");
    const [workOrderStarted, setWorkOrderStarted] = useState<boolean>(false);
    const [workOrderFinished, setWorkOrderFinished] = useState<boolean>(false);
    const [confirmProductionStopModal, setConfirmProductionStopModal] = useState<boolean>(false);
    const [completedModal, setCompletedModal] = useState<boolean>(false);
    const [rejected, setRejected] = useState<boolean>(false);
    const [workOrderRejected, setWorkOrderRejected] = useState<boolean>(false);
    const [productionStopped, setProductionStopped] = useState<boolean>(false);
    const [productionStopModal, setProductionStopModal] = useState<boolean>(false);
    const [toggle, setToggle] = useState<string>("");
    const [updateWorkOrderStatusModal, setUpdateWorkOrderStatusModal] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("");
    const [noReload, setNoReload] = useState<boolean>(false);
    const [stoppageReason, setStoppageReason] = useState<string>("");
    const [productName, setProductName] = useState<string>("");
    const [productData, setProductData] = useState<{ [key: string]: any }>({});
    const [bomDetails, setBomDetails] = useState<{ [key: string]: any }>([]);
    const [calculateModal, setCalculateModal] = useState<boolean>(false);
    const [secButton, setSecButton] = useState<boolean>(false);
    const [warehouse, setWarehouse] = useState<boolean>(false);
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [warehouseDetails, setWarehouseDetails] = useState<{ [key: string]: any }>({});
    const [finishedGoodsAdded, setFinishedGoodsAdded] = useState<boolean>(false);
    const [inventory, setInventory] = useState<any>([]);
    const [rawMaterialsSum, setRawMaterialsSum] = useState<number>(0);
    const [bomList, setBomList] = useState<{ [key: string]: any }[]>([{}]);
    const [rawMaterialModal, setRawMaterialModal] = useState<boolean>(false);
    const [plannedQuantity, setPlannedQuantity] = useState<number>(0);
    // const [pendingQC, setPendingQC] = useState<{ [key: string]: any }[]>([]);
    const [qualityControlModal, setQualityControlModal] = useState<boolean>(false);

    const [profile] = useGetUserProfile();

    const {
        // updatingProductionRun,
        // updateProductionRunSuccess,
        updatingWorkOrderStatus,
        updateWorkOrderStatusSuccess,
        addingToInventory,
        addToInventorySuccess,
        startingWorkOrder,
        startWorkOrderSuccess,
        endingWorkOrder,
        endWorkOrderSuccess,
    } = useAppSelector((state) => state.production);

    const { data: warehouseList, isFetching, handleDebouncedChange } = useGetAllWarehouses();

    const { data: workOrderDetails, isLoading } = useGetSingleWorkOrder();

    const {
        data: tabDetails,
        isLoading: tabLoading,
        setQuery,
        debouncedSearch,
        setDebouncedSearch,
        handleDebouncedSearch,
        search,
        handleSearch,
        isLoadingMore,
        pagination,
        query,
    } = useGetWorkOrderTabDetails();

    const {
        data: tabDetailsTwo,
        isLoading: tabTwoLoading,
        setQuery: setTabTwoQuery,
        isLoadingMore: tabTwoLoadingMore,
        pagination: tabTwoPagination,
    } = useGetWorkOrderTabTwoDetails();

    const {
        data: pendingQC,
        isLoading: fetchingPendingQC,
        isLoadingMore: fetchingPendingQCLoadMore,
    } = useGetPendingWorkOrderQC();

    const tabs = [
        "Bill of Materials (BOM)",
        "Linked purchase orders",
        "Cost breakdown",
        "Production workflow",
        "Additional info",
        "Approval workflow",
    ];

    const handleStartWorkOrder = () => {
        setNoReload(true);
        const body = {
            billOfMaterialIds: bomList?.map((item) => {
                return {
                    inventoryId: item?.inventoryId,
                    quantity: item?.quantity,
                    ...(item?.scrap && { scrap: item?.scrap }),
                    ...(item?.instructions && { instructions: item?.instructions }),
                    ...(item?.batches?.length >= 1 && {
                        batches: item?.batches
                            ?.filter?.((stock) => stock?.quantity)
                            ?.map((batch) => {
                                return {
                                    stockId: batch?.stockId,
                                    quantity: batch?.quantity,
                                };
                            }),
                    }),
                };
            }),
        };
        dispatch(productionActions?.startWorkOrder(workOrderId, body));
    };

    useEffect(() => {
        if (Boolean(startWorkOrderSuccess)) {
            dispatch(productionActions?.resetStartWorkOrder());
            dispatch(productionActions.fetchSingleWorkOrder(workOrderId));
            dispatch(productionActions.fetchWorkOrderTabDetails(workOrderId, "bom", 10));
            setRawMaterialModal(false);
        }
    }, [startWorkOrderSuccess]);

    const handleAddToInventory = () => {
        const body = {
            warehouseId,
        };
        setNoReload(true);
        dispatch(productionActions?.addToInventory(body, workOrderId));
    };

    useEffect(() => {
        if (Boolean(addToInventorySuccess)) {
            setInventory([addToInventorySuccess?.stockDetail]);
            dispatch(productionActions.fetchSingleWorkOrder(workOrderId));
            dispatch(productionActions?.resetAddToInventory());
            setWarehouse(false);
            setFinishedGoodsAdded(true);
        }
    }, [addToInventorySuccess]);

    useEffect(() => {
        if (!warehouse) {
            setWarehouseId("");
        }
    }, [warehouse]);

    useEffect(() => {
        if (!approvePlan && !rejectPlan) {
            setReason("");
        }
        if (approvePlan) {
            setApprove(true);
        } else {
            setApprove(false);
        }
    }, [approvePlan, rejectPlan]);

    useEffect(() => {
        setActiveTab(+currTab);
    }, [currTab]);

    useEffect(() => {
        if (activeTab === 0) {
            setQuery("bom");
            setBomDetails(tabDetails?.billOfMaterialIds);
        }
        if (activeTab === 1) {
            setTabTwoQuery("purchase_orders");
        } else if (activeTab === 3) {
            setTabTwoQuery("steps");
        } else if (activeTab === 5) {
            setQuery("approval_workflow");
        } else {
            setQuery("bom");
            setBomDetails(tabDetails?.billOfMaterialIds);
        }
    }, [activeTab]);

    useEffect(() => {
        if (query === "bom") {
            // const sum = Array.isArray(tabDetails?.billOfMaterialIds)
            //     ? tabDetails?.billOfMaterialIds?.reduce((sum, bom) => {
            //           const quantityNum = bom?.quantity || 0;
            //           const calcQuantity = (workOrderDetails?.quantity?.amount || 1) * quantityNum;
            //           const scrapNum = bom?.scrap || 0;
            //           const percentage = (calcQuantity * scrapNum) / 100;
            //           const totalQuantity = calcQuantity + percentage;
            //           const totalCost =
            //               (parseFloat(bom?.unitPrice || bom?.inventory?.unitCost?.amount) || 0) *
            //               totalQuantity;
            //           return sum + totalCost;
            //       }, 0)
            //     : 0;

            const sum = Array.isArray(tabDetails?.billOfMaterialIds)
                ? tabDetails?.billOfMaterialIds.reduce((total, item) => {
                      const addedSum =
                          item?.batches?.length > 0
                              ? item?.batches.reduce((sum, stock) => {
                                    const unitCost =
                                        Number(
                                            stock?.stock?.cost?.amount ||
                                                item?.inventory?.unitCost?.amount
                                        ) || 0;
                                    const quantity = stock?.quantity || 0;
                                    return sum + unitCost * quantity;
                                }, 0)
                              : 0;

                      return total + addedSum;
                  }, 0)
                : 0;
            setRawMaterialsSum(sum);
        }
    }, [tabDetails]);

    useEffect(() => {
        if (
            yieldLoss &&
            bomDetails?.length >= 1 &&
            workOrderDetails?.inventory &&
            !workOrderDetails?.yieldLossComplete
        ) {
            setProductData({
                product: workOrderDetails?.inventory,
                billOfMaterial: bomDetails,
                productUnitOfMeasurement: workOrderDetails?.quantity?.unitOfMeasurement,
                plannedQuantity: workOrderDetails?.quantity?.amount,
            });

            pendingQC?.length >= 1 ? setQualityControlModal(true) : setCalculateModal(true);
        }
    }, [yieldLoss, workOrderDetails, bomDetails, pendingQC]);

    useEffect(() => {
        if (calculateModal) {
            setProductData({
                product: workOrderDetails?.inventory,
                billOfMaterial: bomDetails,
                productUnitOfMeasurement: workOrderDetails?.quantity?.unitOfMeasurement,
                plannedQuantity: workOrderDetails?.quantity?.amount,
            });
        }
    }, [bomDetails, workOrderDetails]);

    useEffect(() => {
        if (calculateModal) {
            setQuery("bom");
            setBomDetails(tabDetails?.billOfMaterialIds);
            setPlannedQuantity(workOrderDetails?.quantity?.amount);
        }
    }, [calculateModal]);

    useEffect(() => {
        if (updateStatus === "true") {
            setUpdateWorkOrderStatusModal(true);
        }
    }, [updateStatus]);
    useEffect(() => {
        if (activeTab === 0) {
            setBomDetails(tabDetails?.billOfMaterialIds);
        }
    }, [activeTab, tabDetails]);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <BOM
                        bomDetails={tabDetails}
                        productName={workOrderDetails?.inventory?.productName}
                        isLoading={tabLoading}
                        debouncedSearch={debouncedSearch}
                        handleDebouncedSearch={handleDebouncedSearch}
                        handleSearch={handleSearch}
                        search={search}
                        isLoadingMore={isLoadingMore}
                        pagination={pagination}
                        showShortageButton={false}
                        type="workOrder"
                        plannedQuantity={workOrderDetails?.quantity?.amount}
                    />
                );
            case 1:
                return (
                    <LinkedPurchaseOrder
                        poDetails={tabDetailsTwo}
                        productName={workOrderDetails?.inventory?.productName}
                        isLoading={tabTwoLoading}
                    />
                );
            case 2:
                return (
                    <CostBreakdown
                        directCost={workOrderDetails?.directCosts}
                        inDirectCost={workOrderDetails?.indirectCosts}
                        productName={workOrderDetails?.inventory?.productName}
                        rawMaterialsSum={rawMaterialsSum}
                        isLoading={tabLoading}
                    />
                );
            case 3:
                return (
                    <ProductionWorkflow
                        steps={tabDetailsTwo}
                        productName={workOrderDetails?.inventory?.productName}
                        isLoading={tabTwoLoading}
                        type="workOrder"
                        workOrderDetails={workOrderDetails}
                    />
                );
            case 4:
                return (
                    <AdditionalInfo
                        notes={workOrderDetails?.notes}
                        productName={workOrderDetails?.inventory?.productName}
                    />
                );
            case 5:
                return (
                    <ApprovalWorkflow
                        approvalWorkflow={tabDetails}
                        productName={workOrderDetails?.inventory?.productName}
                        type="workOrder"
                        // approversId={approversId}
                    />
                );

            default:
                return (
                    <BOM
                        bomDetails={tabDetails}
                        productName={workOrderDetails?.inventory?.productName}
                        isLoading={tabLoading}
                        debouncedSearch={debouncedSearch}
                        handleDebouncedSearch={handleDebouncedSearch}
                        handleSearch={handleSearch}
                        search={search}
                        isLoadingMore={isLoadingMore}
                        pagination={pagination}
                        showShortageButton={false}
                        type="workOrder"
                        plannedQuantity={workOrderDetails?.quantity?.amount}
                    />
                );
        }
    };

    const handleWorkOrderStatus = (status: string, workOrderId) => {
        const body = {
            status: status,
            ...(status === "cancelled" && { reason: stoppageReason }),
        };
        setNoReload(true);
        dispatch(productionActions?.updateWorkOrderStatus(workOrderId, body));
    };

    useEffect(() => {
        if (Boolean(updateWorkOrderStatusSuccess)) {
            setUpdateWorkOrderStatusModal(false);
            dispatch(productionActions?.resetUpdateWorkOrderStatus());
            dispatch(productionActions.fetchSingleWorkOrder(workOrderId));
            if (status === "in-progress") {
                setWorkOrderStarted(true);
            } else if (status === "cancelled") {
                setProductionStopped(true);
            }
            setProductionStopModal(false);
        }
    }, [updateWorkOrderStatusSuccess]);

    // const handleProductionRunSwitch = (toggle) => {
    //     const body = {
    //         toggle: toggle,
    //     };
    //     dispatch(productionActions?.updateProductionRun(workOrderId, body));
    // };

    // useEffect(() => {
    //     if (Boolean(updateProductionRunSuccess)) {
    //         dispatch(productionActions?.resetUpdateProductionRun());

    //         toggle === "start"
    //             ? setWorkOrderStarted(true)
    //             : toggle === "finish" && setWorkOrderFinished(true);
    //         dispatch(productionActions.fetchSingleWorkOrder(workOrderId));
    //     }
    // }, [updateProductionRunSuccess]);

    const handleEndWorkOrder = () => {
        dispatch(productionActions?.endWorkOrder(workOrderId));
    };

    useEffect(() => {
        if (Boolean(endWorkOrderSuccess)) {
            dispatch(productionActions?.resetEndWorkOrder());

            toggle === "start"
                ? setWorkOrderStarted(true)
                : toggle === "finish" && setWorkOrderFinished(true);
            dispatch(productionActions.fetchSingleWorkOrder(workOrderId));
        }
    }, [endWorkOrderSuccess]);

    const FinishedGoodsModalTableHeader = [
        {
            title: "Product",
            widthClass: ` ${
                finishedGoodsAdded ? "w-[25%]" : "w-[50%]"
            } !font-rocGroteskMedium !border-slate-200`,
        },
        {
            title: "Added",
            widthClass: ` ${
                finishedGoodsAdded ? "w-[20%]" : "w-[13%]"
            } !font-rocGroteskMedium !border-slate-200`,
        },
        {
            title: "New stock ",
            widthClass: ` ${
                finishedGoodsAdded ? "w-[20%]" : "w-[13%]"
            } !font-rocGroteskMedium !border-slate-200`,
        },
        {
            title: "LOT Number",
            widthClass: ` ${
                finishedGoodsAdded ? "w-[15%]" : "w-[13%]"
            } !font-rocGroteskMedium !border-slate-200`,
        },
    ];

    const FinishedGoodsModalTableBody = inventory?.map((product, idx) => {
        return [
            {
                content: (
                    <div key={idx} className={`w-full  `}>
                        <div className="flex items-center gap-2 py-2 px-2.5">
                            <div className="text-sm">
                                <p className="text-g-75">
                                    {truncate(product?.productName, {
                                        length: 20,
                                    })}
                                </p>
                                <p className="text-[#64748B]">{product?.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200  text-sm text-[#00A643] flex items-center  font-rocGroteskMedium `}
                    >
                        <p className="px-4">
                            +{formatMoney()?.format(product?.quantityAdded)}
                            {` `}
                            {product?.productStockDetails?.unitOfMeasurement}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: `!border-slate-200 ${finishedGoodsAdded ? "" : "bg-[#E6FFF0]"} `,
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-900 text-sm text-slate-500 font-rocGroteskMedium px-4`}
                    >
                        <p className="flex items-center justify-start">
                            {formatMoney()?.format(product?.productStockDetails?.stockLevel)}
                            {` `}
                            {product?.productStockDetails?.unitOfMeasurement}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-900 text-sm text-slate-500 font-rocGroteskMedium px-4`}
                    >
                        <p className="flex items-center justify-start">{product?.batchId}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    return (
        <div>
            <PageFrame isLoading={!noReload && isLoading}>
                <div className="bg-white rounded-lg border border-slate-100 font-rocGroteskMedium pb-12">
                    <div className="flex items-center justify-between px-8 py-6 border-b border-n-20">
                        <div
                            className="flex items-center space-x-1.5 w-fit cursor-pointer"
                            onClick={() => navigate(-1)}
                        >
                            <i className="ri-arrow-left-line text-[20px]"></i>
                            <p className="text-sm text-g-75 font-rocGroteskMedium underline">
                                Back
                            </p>
                        </div>

                        <div className="flex items-center  gap-3">
                            <div className="flex items-center gap-2">
                                <div
                                    className={`w-2 h-2 text-sm rounded-full shrink-0 flex items-center justify-center captialize
                                    ${
                                        workOrderDetails?.status === "pending"
                                            ? "bg-[#6366F1]"
                                            : workOrderDetails?.status === "completed"
                                            ? "bg-[#22C55E]"
                                            : workOrderDetails?.status === "in-progress"
                                            ? "bg-[#3B82F6]"
                                            : workOrderDetails?.status === "cancelled"
                                            ? "bg-[#EF4444]"
                                            : workOrderDetails?.status === "on-hold"
                                            ? "bg-[#EAB308]"
                                            : workOrderDetails?.status === "rescheduled"
                                            ? "bg-[#8B5CF6]"
                                            : workOrderDetails?.status === "planned"
                                            ? "bg-[#14B8A6]"
                                            : workOrderDetails?.status === "delayed"
                                            ? "bg-[#F97316]"
                                            : workOrderDetails?.status === "partial-completed"
                                            ? "bg-[#0EA5E9]"
                                            : workOrderDetails?.status && "bg-[#6B7280]"
                                    }
                                    `}
                                ></div>

                                <span className="capitalize text-sm">
                                    {workOrderDetails?.status === "in-progress"
                                        ? "In progress"
                                        : workOrderDetails?.status === "on-hold"
                                        ? "On hold"
                                        : workOrderDetails?.status === "completed" &&
                                          !workOrderDetails?.yieldLossComplete
                                        ? "Completed - Awaiting Yield & Loss"
                                        : workOrderDetails?.status}
                                </span>
                            </div>
                            {workOrderDetails?.status === "completed" &&
                                !workOrderDetails?.yieldLossComplete && (
                                    <div className="">
                                        <Button
                                            btnText="Calculate yield & loss"
                                            type="button"
                                            onClick={() => {
                                                setProductData({
                                                    product: workOrderDetails?.inventory,
                                                    billOfMaterial: bomDetails,
                                                    productUnitOfMeasurement:
                                                        workOrderDetails?.quantity
                                                            ?.unitOfMeasurement,
                                                    plannedQuantity:
                                                        workOrderDetails?.quantity?.amount,
                                                });
                                                pendingQC?.length >= 1
                                                    ? setQualityControlModal(true)
                                                    : setCalculateModal(true);
                                            }}
                                            btnType="textFirst"
                                            btnClassname={`bg-[#FFB903] !px-4 !py-2  !w-fit text-[13px] !text-g-75`}
                                            icon={
                                                <a id="yield-loss">
                                                    <i className="ri-information-fill text-g-75 text-base"></i>
                                                </a>
                                            }
                                        />
                                        <div>
                                            <Tooltip
                                                anchorSelect="#yield-loss"
                                                place={"top"}
                                                content="Analyze the output efficiency and material losses during production."
                                                style={{ width: "250px", zIndex: 9999 }}
                                            />
                                        </div>
                                    </div>
                                )}

                            {workOrderDetails?.status !== "completed" &&
                                workOrderDetails?.status !== "planned" &&
                                workOrderDetails?.status !== "pending" && (
                                    <Button
                                        btnText="Update Production Status"
                                        type="button"
                                        onClick={() => setUpdateWorkOrderStatusModal(true)}
                                        btnClassname="!px-4 !py-2 !text-g-75 !w-fit text-[13px] !bg-transparent !border !border-slate-200"
                                    />
                                )}

                            {workOrderDetails?.status !== "completed" &&
                            workOrderDetails?.status !== "pending" ? (
                                <Button
                                    btnText={
                                        workOrderDetails?.status === "planned" ||
                                        workOrderDetails?.status === "cancelled" ||
                                        workOrderDetails?.status === "on-hold"
                                            ? "Start Production"
                                            : "Stop Production"
                                    }
                                    type="button"
                                    onClick={() => {
                                        if (workOrderDetails?.status === "planned") {
                                            // handleProductionRunSwitch(true);
                                            setRawMaterialModal(true);
                                            setProductName(
                                                workOrderDetails?.inventory?.productName
                                            );
                                            setPlannedQuantity(workOrderDetails?.quantity?.amount);
                                            setStatus("in-progress");
                                            setToggle("start");
                                        } else if (
                                            workOrderDetails?.status === "cancelled" ||
                                            workOrderDetails?.status === "on-hold"
                                        ) {
                                            handleWorkOrderStatus("in-progress", workOrderId);
                                            setStatus("in-progress");
                                        } else {
                                            setConfirmProductionStopModal(true);
                                        }
                                        setNoReload(true);
                                        setSecButton(false);
                                    }}
                                    isLoading={
                                        toggle === "start" && startingWorkOrder
                                            ? true
                                            : (workOrderDetails?.status === "cancelled" ||
                                                  workOrderDetails?.status === "on-hold") &&
                                              updatingWorkOrderStatus &&
                                              true
                                    }
                                    disabled={
                                        toggle === "start" && startingWorkOrder
                                            ? true
                                            : (workOrderDetails?.status === "cancelled" ||
                                                  workOrderDetails?.status === "on-hold") &&
                                              updatingWorkOrderStatus &&
                                              true
                                    }
                                    btnClassname="!px-4 !py-2 !text-g-75 !w-fit text-[13px]"
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>

                    <div className="px-6">
                        <div className="py-6 border-b border-slate-200">
                            <p className="text-gm-50 text-sm mb-1">Work Order ID</p>
                            <h6 className="text-g-75 text-2xl">
                                {workOrderDetails?.taId && `WO-${workOrderDetails?.taId}`}
                            </h6>
                            <div className="flex gap-2 mt-3">
                                {workOrderDetails?.status !== "completed" &&
                                workOrderDetails?.status !== "pending" ? (
                                    <Button
                                        btnText={
                                            workOrderDetails?.status === "planned" ||
                                            workOrderDetails?.status === "cancelled" ||
                                            workOrderDetails?.status === "on-hold"
                                                ? "Start Production"
                                                : "Stop Production"
                                        }
                                        type="button"
                                        onClick={() => {
                                            if (workOrderDetails?.status === "planned") {
                                                setRawMaterialModal(true);
                                                setStatus("in-progress");
                                                setToggle("start");
                                            } else if (
                                                workOrderDetails?.status === "cancelled" ||
                                                workOrderDetails?.status === "on-hold"
                                            ) {
                                                handleWorkOrderStatus("in-progress", workOrderId);
                                                setStatus("in-progress");
                                            } else {
                                                setConfirmProductionStopModal(true);
                                            }
                                            setNoReload(true);
                                            setSecButton(true);
                                        }}
                                        isLoading={
                                            secButton && toggle === "start" && startingWorkOrder
                                                ? true
                                                : secButton &&
                                                  (workOrderDetails?.status === "cancelled" ||
                                                      workOrderDetails?.status === "on-hold") &&
                                                  updatingWorkOrderStatus &&
                                                  true
                                        }
                                        disabled={
                                            toggle === "start" && startingWorkOrder
                                                ? true
                                                : (workOrderDetails?.status === "cancelled" ||
                                                      workOrderDetails?.status === "on-hold") &&
                                                  updatingWorkOrderStatus &&
                                                  true
                                        }
                                        btnClassname={` ${
                                            workOrderDetails?.status === "planned" ||
                                            workOrderDetails?.status === "cancelled" ||
                                            workOrderDetails?.status === "on-hold"
                                                ? "!bg-[#09C655]"
                                                : "!bg-[#E4281D]"
                                        } !px-4 !py-2  !w-fit text-[13px] !text-[#fff]`}
                                    />
                                ) : (
                                    ""
                                )}

                                {workOrderDetails?.status !== "planned" &&
                                workOrderDetails?.status !== "completed" &&
                                workOrderDetails?.status !== "pending" &&
                                workOrderDetails?.status !== "cancelled" ? (
                                    <Button
                                        btnText="Mark as complete"
                                        type="button"
                                        onClick={() => {
                                            handleEndWorkOrder();
                                            setToggle("finish");
                                            setNoReload(true);
                                        }}
                                        isLoading={toggle === "finish" && endingWorkOrder}
                                        disabled={toggle === "finish" && endingWorkOrder}
                                        btnClassname={`!bg-transparent !border-[#FFB903] !border !px-4 !py-2  !w-fit text-[13px] !text-g-75`}
                                    />
                                ) : (
                                    ""
                                )}

                                {workOrderDetails?.status === "completed" ? (
                                    <div className="flex gap-3">
                                        <p className="flex gap-2 items-center text-[13px] text-[#09C655] rounded bg-[rgba(9,198,85,0.15)] px-4 py-2">
                                            <span className="">
                                                <i className="ri-check-double-line text-[#09C655]"></i>
                                            </span>
                                            Production Completed
                                        </p>
                                        {!workOrderDetails?.yieldLossComplete && (
                                            <p className="flex gap-2 items-center text-[13px] text-g-75 rounded bg-slate-100 px-4 py-2">
                                                Awaiting Yield & Loss Review
                                            </p>
                                        )}

                                        {workOrderDetails?.status === "completed" &&
                                            workOrderDetails?.yieldLossComplete &&
                                            !workOrderDetails?.stockLevelUpdated && (
                                                <div className="flex gap-2 ">
                                                    <Button
                                                        btnText={`Add ${
                                                            workOrderDetails?.inventory
                                                                ?.inventoryCategory ===
                                                            "Finished Goods"
                                                                ? "finished goods"
                                                                : "work in progress"
                                                        } to Inventory`}
                                                        type="button"
                                                        onClick={() => setWarehouse(true)}
                                                        btnClassname={`!bg-[#09C655] !px-4 !py-2  !w-fit text-[13px] !text-[#fff]`}
                                                    />
                                                </div>
                                            )}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                        <div className="">
                            <div className="grid grid-cols-5 py-6 border-b border-slate-200">
                                <div className="">
                                    <p className="text-slate-500 text-sm">Product</p>
                                    <p className="text-gm-50 text-sm">
                                        {workOrderDetails?.inventory?.productName}
                                    </p>
                                </div>
                                <div className="">
                                    <p className="text-slate-500 text-sm">Production Quantity</p>
                                    <p className="text-gm-50 text-sm">
                                        {workOrderDetails?.quantity?.amount !== undefined &&
                                            formatMoney()?.format(
                                                workOrderDetails?.quantity?.amount
                                            )}{" "}
                                        {workOrderDetails?.quantity?.amount !== undefined
                                            ? workOrderDetails?.quantity?.unitOfMeasurement
                                            : "N/A"}
                                    </p>
                                </div>
                                <div className="">
                                    <p className="text-slate-500 text-sm">Production Start Date:</p>
                                    <p className="text-gm-50 text-sm">
                                        {workOrderDetails?.startDate
                                            ? moment(workOrderDetails?.startDate)?.format(
                                                  "MMM DD, YYYY"
                                              )
                                            : "N/A"}
                                    </p>
                                </div>
                                <div className="">
                                    <p className="text-slate-500 text-sm">
                                        Estimated Completion Date
                                    </p>
                                    <p className="text-gm-50 text-sm">
                                        {workOrderDetails?.endDate
                                            ? moment(workOrderDetails?.endDate)?.format(
                                                  "MMM DD, YYYY"
                                              )
                                            : "N/A"}
                                    </p>
                                </div>
                                <div className="">
                                    <p className="text-slate-500 text-sm">
                                        Quality Control Supervisor
                                    </p>
                                    <p className="text-gm-50 text-sm">
                                        {workOrderDetails?.qualityController?.email || "N/A"}
                                    </p>
                                </div>
                            </div>
                            <div className="grid grid-cols-5 py-6 ">
                                <div className="">
                                    <p className="text-slate-500 text-sm">Due Date</p>
                                    <p className="text-gm-50 text-sm">
                                        {workOrderDetails?.dueDate
                                            ? moment(workOrderDetails?.dueDate)?.format(
                                                  "MMM DD, YYYY"
                                              )
                                            : "N/A"}
                                    </p>
                                </div>
                                <div className="">
                                    <p className="text-slate-500 text-sm">Created by</p>
                                    <p className="text-gm-50 text-sm">
                                        {workOrderDetails?.createdBy?.fullName ||
                                            workOrderDetails?.createdBy?.email}
                                    </p>
                                </div>
                                <div className="">
                                    <p className="text-slate-500 text-sm">Production Manager</p>
                                    <p className="text-gm-50 text-sm">
                                        {workOrderDetails?.manager?.email ||
                                            workOrderDetails?.manager?.name}
                                    </p>
                                </div>
                                <div className="">
                                    <p className="text-slate-500 text-sm">Batch Number</p>
                                    <p className="text-gm-50 text-sm">
                                        {workOrderDetails?.batchId}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <TabsContainer
                            tabs={tabs}
                            activeTab={activeTab}
                            setActiveTab={(idx) => {
                                setActiveTab(idx);
                            }}
                            component={displayActiveTab()}
                            className="!px-0"
                            showButtonClassName
                            itemClassName="!pb-2 !whitespace-nowrap !border-y-10"
                            borderLineClase={"!text-slate-100 w-full mb-6"}
                        />
                    </div>

                    <ModalContainer
                        open={approvePlan}
                        modalClassName={"py-10 px-10"}
                        tailwindClassName="w-[600px]"
                        showCloseIcon={false}
                        closeModal={() => {
                            setApprovePlan(true);
                        }}
                    >
                        <div className="bg-white rounded-[8px] relative">
                            <i
                                className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                                onClick={() => {
                                    setApprovePlan(false);
                                }}
                            ></i>
                            <div className="mt-4 ">
                                <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                                    <div className="mb-1">
                                        <i className="ri-chat-check-line text-gm-50 text-[40px]"></i>
                                    </div>
                                    <div className="px-4 font-rocGroteskMedium">
                                        <span className="block mb-2 text-center text-[22px] text-gm-50">
                                            Approve Production Plan?
                                        </span>
                                        <p className="mt-1 text-base text-slate-500 text-center ">
                                            Your approval will move this plan to the next level for
                                            review. The next approver will be notified.
                                        </p>
                                    </div>
                                </div>

                                <div className="px-6 pb-6">
                                    <TextareaInput
                                        name={"reason"}
                                        value={reason}
                                        onChange={(e) => setReason(e?.target.value)}
                                        rows={5}
                                        placeholder={"Comment"}
                                    />
                                </div>
                                <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                                    <Button
                                        btnText={"Cancel"}
                                        btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                        onClick={() => {
                                            setApprovePlan(false);
                                        }}
                                    />
                                    <Button
                                        btnText={"Approve Production Plan"}
                                        btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                        onClick={() => {
                                            // handleUpdateStatus();
                                        }}
                                        // isLoading={updatingApprovalStatus}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalContainer>
                    <ModalContainer
                        open={rejectPlan}
                        modalClassName={"py-10 px-10"}
                        tailwindClassName="w-[600px]"
                        showCloseIcon={false}
                        closeModal={() => {
                            setRejectPlan(true);
                        }}
                    >
                        <div className="bg-white rounded-[8px] relative">
                            <i
                                className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                                onClick={() => {
                                    setRejectPlan(false);
                                }}
                            ></i>
                            <div className="mt-4 ">
                                <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                                    <div className="mb-1">
                                        <i className="ri-feedback-line text-gm-50 text-[40px]"></i>
                                    </div>
                                    <div className="px-4 font-rocGroteskMedium">
                                        <span className="block mb-2 text-center text-[22px] text-gm-50">
                                            Production Plan Rejected
                                        </span>
                                        <p className="mt-1 text-base text-slate-500 text-center ">
                                            The production plan for Kōloa Rum has been rejected.
                                            Please review the feedback provided to understand the
                                            reasons.
                                        </p>
                                    </div>
                                </div>

                                <div className="px-6 pb-6">
                                    <TextareaInput
                                        name={"reason"}
                                        value={reason}
                                        onChange={(e) => setReason(e?.target.value)}
                                        rows={5}
                                        placeholder={"Comment"}
                                        required={true}
                                    />
                                </div>
                                <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                                    <Button
                                        btnText={"Cancel"}
                                        btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                        onClick={() => {
                                            setRejectPlan(false);
                                        }}
                                    />
                                    <Button
                                        btnText={"Reject Production Plan"}
                                        btnClassname="!py-3 cursor-pointer !text-[14px] !text-white !border border-n-20 !font-rocGroteskMedium !px-4 !bg-[#FF3D1D]"
                                        onClick={() => {
                                            // handleUpdateStatus();
                                        }}
                                        // isLoading={updatingApprovalStatus}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalContainer>
                </div>
            </PageFrame>

            <ModalContainer
                open={workOrderFinished}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setWorkOrderFinished(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            setWorkOrderFinished(false);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 w-12 h-12 bottom-0 right-[-13px] rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-[24px] text-white"></i>
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] text-gm-50">
                                    {workOrderDetails?.inventory?.productName || ""} Production
                                    Completed: Please Begin QC Check for Yield and Loss
                                </span>
                                <p className={`px-8 mt-1 text-base text-slate-500 text-center `}>
                                    The production process has been marked as complete. It's now
                                    time to review the actual yield and any material losses. Please
                                    enter the actual quantities used and unsellable units to
                                    calculate yield and discrepancies.
                                </p>
                            </div>
                        </div>

                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Not now"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setWorkOrderFinished(false);
                                }}
                            />
                            <Button
                                btnText="Start Review"
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setWorkOrderFinished(false);
                                    setProductData({
                                        product: workOrderDetails?.inventory,
                                        billOfMaterial: bomDetails,
                                        productUnitOfMeasurement:
                                            workOrderDetails?.quantity?.unitOfMeasurement,
                                        plannedQuantity: workOrderDetails?.quantity?.amount,
                                    });
                                    pendingQC?.length >= 1
                                        ? setQualityControlModal(true)
                                        : setCalculateModal(true);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={updateWorkOrderStatusModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[700px] "
                showCloseIcon={false}
                closeModal={() => {
                    setUpdateWorkOrderStatusModal(false);
                }}
            >
                <form
                    className="bg-white rounded-[8px] relative font-rocGroteskMedium"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleWorkOrderStatus(status, workOrderId);
                    }}
                >
                    <div className="mt-4 ">
                        <div className="px-6 font-rocGroteskMedium border-b border-b-slate-200 flex items-center justify-between py-3">
                            <p className="mt-1 text-base text-gm-50 ">Update Production Status</p>
                            <i
                                className={` cursor-pointer ri-close-fill text-gm-50 `}
                                onClick={() => {
                                    setUpdateWorkOrderStatusModal(false);
                                }}
                            ></i>
                        </div>
                        <div className="px-6 py-6  pb-12 space-y-4">
                            <SelectInput
                                value={status}
                                name={status}
                                placeholder={"Status of Work Orders "}
                                handleChange={(name, value) => {
                                    setStatus(value);
                                }}
                                isRequired={true}
                                dropdownOptions={[
                                    {
                                        label: "On Hold",
                                        value: "on-hold",
                                    },
                                    // {
                                    //     label: "Cancelled",
                                    //     value: "cancelled",
                                    // },
                                    { label: "In Progress", value: "in-progress" },
                                    { label: "Rescheduled", value: "rescheduled" },
                                    { label: "Delayed", value: "delayed" },
                                    { label: "Partially Completed", value: "partial-completed" },
                                ]}
                                dropdownClassName="!max-h-24"
                            />

                            {/* <SelectInput
                                    // value={status}
                                    name={status}
                                    placeholder={"Receive automated reminders to update production statuses"}
                                    handleChange={(name, value) => {
                                        // handleStepData({ target: { name, value } });
                                    }}
                                    isRequired={true}
                                    dropdownOptions={[]}
                                    dropdownClassName="!max-h-24"
                                /> */}
                        </div>

                        <div className="flex items-center  justify-end gap-4  border-t border-t-slate-200 py-4 px-6 space-x-4">
                            <Button
                                btnText={"Cancel"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                onClick={() => {
                                    setUpdateWorkOrderStatusModal(false);
                                }}
                                disabled={updatingWorkOrderStatus}
                            />
                            <Button
                                type="submit"
                                btnText="Update status"
                                isLoading={updatingWorkOrderStatus}
                                disabled={updatingWorkOrderStatus}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                            />
                        </div>
                    </div>
                </form>
            </ModalContainer>

            <ModalContainer
                open={warehouse}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setWarehouse(false);
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleAddToInventory();
                    }}
                    className="bg-white rounded-[8px] relative"
                >
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 flex items-center justify-center">
                                <i className="ri-building-line text-[40px] text-[#142837]"></i>
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] text-gm-50">
                                    Select warehouse for finished goods
                                </span>
                                <p className={` mt-1 text-base text-slate-500 text-center mb-4`}>
                                    You're about to add the
                                    {workOrderDetails?.inventory?.inventoryCategory ===
                                    "Finished Goods"
                                        ? "finished goods"
                                        : "work in progress"}
                                    {`for ${workOrderDetails?.inventory?.productName}`}
                                    {` `}
                                    to inventory. Please select the warehouse where these goods will
                                    be stored.
                                </p>

                                <div className="mb-4">
                                    <SelectInput
                                        value={warehouseId || ""}
                                        name="warehouseId"
                                        placeholder={"Warehouse Location"}
                                        handleChange={(name, value) => {
                                            // handleProductData({ target: { name, value } });
                                            setWarehouseId(value);
                                            if (value) {
                                                const selectedWarehouse = warehouseList?.find(
                                                    (item) => {
                                                        return item?._id === value;
                                                    }
                                                );
                                                setWarehouseDetails(selectedWarehouse);
                                            }
                                        }}
                                        handleInputChange={debounce((evt) => {
                                            handleDebouncedChange(evt);
                                        }, 800)}
                                        searchLoading={isFetching}
                                        clearValue={true}
                                        isRequired={true}
                                        dropdownOptions={warehouseList?.map((warehouse) => ({
                                            label: warehouse.warehouseName,
                                            value: warehouse._id,
                                        }))}
                                        dropdownClassName="!max-h-24"
                                    />
                                </div>

                                {warehouseId && (
                                    <div className="mt-6">
                                        <div className="py-3 text-sm flex items-center justify-between border-b border-slate-200">
                                            <p className="text-slate-500">Warehouse Name</p>
                                            <p className="text-g-75">
                                                {warehouseDetails?.warehouseName}
                                            </p>
                                        </div>
                                        <div className="py-3 text-sm flex items-center justify-between border-b border-slate-200">
                                            <p className="text-slate-500">Location</p>
                                            <p className="text-g-75">
                                                {warehouseDetails?.address?.address ||
                                                warehouseDetails?.address?.country
                                                    ? `${
                                                          warehouseDetails?.address?.address || ""
                                                      } ${warehouseDetails?.address?.country || ""}`
                                                    : "N/A"}
                                            </p>
                                        </div>
                                        {/* <div className="py-3 text-sm flex items-center justify-between border-b border-slate-200">
                                            <p className="text-slate-500">Current Capacity</p>
                                            <p className="text-g-75"></p>
                                        </div>
                                        <div className="py-3 text-sm flex items-center justify-between border-b border-slate-200">
                                            <p className="text-slate-500">Total Capacity</p>
                                            <p className="text-g-75"></p>
                                        </div>
                                        <div className="py-3 text-sm flex items-center justify-between border-b border-slate-200">
                                            <p className="text-slate-500">Last Inventory Date</p>
                                            <p className="text-g-75"></p>
                                        </div> */}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setWarehouse(false);
                                }}
                            />
                            <Button
                                btnText={"Select warehouse"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                type="submit"
                                isLoading={addingToInventory}
                                disabled={addingToInventory}
                            />
                        </div>
                    </div>
                </form>
            </ModalContainer>

            <ModalContainer
                open={finishedGoodsAdded}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[800px]"
                showCloseIcon={false}
                closeModal={() => {
                    setFinishedGoodsAdded(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative font-rocGroteskMedium">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            setFinishedGoodsAdded(false);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 w-12 h-12 bottom-0 right-[-13px] rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-[24px] text-white"></i>
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] text-gm-50">
                                    Finished goods successfully added
                                </span>
                                <p className={` mt-1 text-base text-slate-500 text-center `}>
                                    Your production{" "}
                                    {`of ${workOrderDetails?.inventory?.productName}`} has been
                                    successfully added to your inventory.
                                </p>
                            </div>
                        </div>

                        <div className="pb-5 px-8 grid grid-cols-2 gap-3">
                            <div className="border border-slate-200 items-center py-3 px-4  rounded-[6px] shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                <p className="text-sm text-g-75">Production Summary</p>
                                <p className="text-sm text-slate-500">
                                    Total quantity produced:{" "}
                                    {formatMoney().format(workOrderDetails?.finalQuantity?.amount)}
                                    {` `}
                                    {workOrderDetails?.finalQuantity?.unitOfMeasurement}
                                </p>
                                <p className="text-sm text-slate-500">
                                    Production date:{" "}
                                    {workOrderDetails?.actualEnd
                                        ? moment(workOrderDetails?.actualEnd)?.format(
                                              "MMM DD, YYYY"
                                          )
                                        : "N/A"}
                                </p>
                            </div>
                            <div className="border border-slate-200 items-center py-3 px-4  rounded-[6px] shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                <p className="text-sm text-g-75">Warehouse name</p>
                                <p className="text-sm text-slate-500">
                                    {warehouseDetails?.warehouseName}
                                </p>
                            </div>
                        </div>

                        <div className="px-8 mb-8">
                            <p className="text-sm text-[#000] mb-1">Inventory update</p>

                            <CustomTable
                                tableBody={FinishedGoodsModalTableBody}
                                tableHeader={FinishedGoodsModalTableHeader}
                                isScrollable={false}
                                isCellBordered={true}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                scrollWithin={false}
                                headerContainerClass="!bg-slate-50 !border-slate-200 "
                                headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                tableClass="!border-slate-200"
                                bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                            />
                        </div>

                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center  justify-end space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                onClick={() => {
                                    setFinishedGoodsAdded(false);
                                }}
                            />
                            <Button
                                btnText={"View updated inventory"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                onClick={() => {
                                    // setFinishedGoodsAdded(false);
                                    navigate(
                                        `/dashboard/inventory/stock-control/stock-entry/${inventory[0]?.stockId}`
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <WorkOrderModal
                workOrderStarted={workOrderStarted}
                setWorkOrderStarted={setWorkOrderStarted}
                rejected={rejected}
                setRejected={setRejected}
                workOrderRejected={workOrderRejected}
                setWorkOrderRejected={setWorkOrderRejected}
                productionStopped={productionStopped}
                setProductionStopped={setProductionStopped}
                confirmProductionStopModal={confirmProductionStopModal}
                setConfirmProductionStopModal={setConfirmProductionStopModal}
                productionStopModal={productionStopModal}
                setProductionStopModal={setProductionStopModal}
                stoppageReason={stoppageReason}
                setStoppageReason={setStoppageReason}
                workorderId={workOrderId}
                handleWorkOrderStatus={handleWorkOrderStatus}
                updatingWorkOrderStatus={updatingWorkOrderStatus}
                productName={productName}
                rawMaterialModal={rawMaterialModal}
                setRawMaterialModal={setRawMaterialModal}
                bomList={bomList}
                setBomList={setBomList}
                plannedQuantity={plannedQuantity}
                handleStartWorkOrder={handleStartWorkOrder}
                startingWorkOrder={startingWorkOrder}
                id={workOrderId}
            />

            <YieldAndLosses
                productData={productData}
                calculateModal={calculateModal}
                setCalculateModal={setCalculateModal}
                setNoReload={setNoReload}
                qualityControlModal={qualityControlModal}
                setQualityControlModal={setQualityControlModal}
                pendingQC={pendingQC}
                fetchingPendingQC={fetchingPendingQC}
                fetchingPendingQCLoadMore={fetchingPendingQCLoadMore}
            />
        </div>
    );
};

export default WorkOrderDetails;
