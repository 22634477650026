import config from "config/config";
import { createUrlSearchParams, getFromStorage, removeFromStorage, saveToStorage } from "helpers";
import jwtDecode from "jwt-decode";

export const authService = {
    apiGate,
    saveAccount,
    logout,
    getProfile,
    register,
    verifyByMail,
    resendVerifyCode,
    loginUser,
    forgetPassword,
    resetPassword,
    changeVerificationEmail,
    fetchTeamMembers,
    loggedIn,
    deleteLdbProfile,
    addTeamMember,
    deleteTeamMember,
    getLdbProfile,
    updateLdbProfile,
    addVendor,
    updateTeamMember,
    changePassword,
    updateUserProfile,
    getRoles,
    createRoles,
};

function saveAccount(user: Record<string, any>, remember?: boolean) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    saveToStorage("ally-user", user, remember as boolean);
    window.dispatchEvent(new Event("storage"));
}

function logout() {
    // remove user from local storage to log user out
    removeFromStorage("ally-user");
    window.dispatchEvent(new Event("storage"));
}

function getProfile() {
    // Using jwt-decode npm package to decode the token
    const token = getToken();

    if (!token) return false;

    return jwtDecode(getToken());
}

async function apiGate(url: string, options: Record<string, any>, noKey?: boolean) {
    // performs api calls sending the required authentication headers
    let headers: Record<string, string> = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    if (!noKey) {
        headers["x-access-key"] = `Bearer ${config.API_KEY}`;
    }

    if (loggedIn()) {
        headers.Authorization = "Bearer " + getToken();
    }

    if (options?.headers) {
        headers = { ...headers, ...options.headers };
    }
    const { headers: optionHeaders, ...rest } = options;

    try {
        const res = await fetch(url, {
            headers,
            ...rest,
        });

        const response_1 = handleResponse(res);

        return response_1;
    } catch (error) {
        return console.error(error);
    }
}

function getToken() {
    // Retrieves the user token from localStorage
    const user = getFromStorage("ally-user");

    return user?.token;
}

function isTokenExpired(token: string) {
    if (!token) return false;

    try {
        const decoded: Record<string, any> = jwtDecode(token);
        if (decoded.exp < Date.now() / 1000) {
            // Checking if token is expired. N
            return true;
        } else return false;
    } catch (err) {
        return false;
    }
}

function loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = getToken(); // Getting token from store

    return !!token && !isTokenExpired(token);
}

function handleResponse(response: Response) {
    return response.text().then((text) => {
        let data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                //    Error alert goes here
                console.log('"Permission denied!"');
            }

            data.status = response.status;
            const error = (data && data) || response.statusText;

            if (
                error.message === "SUBSCRIPTION_NOT_ACTIVE" ||
                error.message === "SUBCRIPTION_LIMIT_EXCEEDED"
            ) {
                const errorEvent = new CustomEvent("errorOccurred", {
                    detail: { errorMessage: "subscriptionRestriction" },
                });

                error.message = "nil";
                // Dispatch the custom event
                document.dispatchEvent(errorEvent);
            }

            return Promise.reject(error);
        }

        return data;
    });
}

async function register(registerObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(registerObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/auth/signup`,
        requestOptions
    );

    return res;
}

async function verifyByMail(codeObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(codeObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/auth/code/signup/verify`,
        requestOptions
    );

    return res;
}

async function resendVerifyCode(token: string) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/auth/code/signup/resend`,
        requestOptions
    );

    return res;
}

async function changeVerificationEmail(
    reqObj: Record<string, any> | Array<Record<string, any>>,
    token: string
) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/auth/signup/email-change`,
        requestOptions
    );

    return res;
}
async function loginUser(loginObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(loginObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/auth/login`,
        requestOptions
    );

    return res;
}

async function getLdbProfile(token: string) {
    const requestOptions: { method: string; headers?: Record<string, string> } = {
        method: "GET",
    };

    if (token) {
        requestOptions.headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        };
    }

    const res = await authService.apiGate(`${config.API_URL}/api/v1/ldb/profile`, requestOptions);

    return res;
}

async function updateLdbProfile(
    data: Record<string, any> | Array<Record<string, any>>,
    token: string
) {
    const requestOptions = {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(`${config.API_URL}/api/v1/ldb/profile`, requestOptions);

    return res;
}

async function updateUserProfile(
    data: Record<string, any> | Array<Record<string, any>>,
    token: string
) {
    const requestOptions = {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(`${config.API_URL}/api/v1/ldb/update`, requestOptions);

    return res;
}
async function forgetPassword(reqObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/auth/password/forget`,
        requestOptions
    );

    return res;
}

async function resetPassword(
    reqObj: Record<string, any> | Array<Record<string, any>>,
    token: string
) {
    const requestOptions = {
        method: "POST",
        header: {
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/auth/password/reset`,
        requestOptions
    );

    return res;
}
async function changePassword(
    reqObj: Record<string, any> | Array<Record<string, any>>,
    token: string
) {
    const requestOptions = {
        method: "PUT",
        header: {
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/auth/password`,
        requestOptions
    );

    return res;
}

async function addVendor(reqObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/invite`,
        requestOptions
    );

    return res;
}

async function fetchTeamMembers(
    teamLimit: number | string,
    search?: string,
    page?: number,
    token?: string
) {
    const requestOptions: { [key: string]: any } = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        page,
        search: search as string,
    });
    if (token) {
        requestOptions.headers = {
            Authorization: `Bearer ${token}`,
        };
    }

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/team?limit=${teamLimit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function deleteLdbProfile(token: string) {
    const requestOptions = {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const res = await authService.apiGate(`${config.API_URL}/api/v1/ldb/account`, requestOptions);

    return res;
}

async function addTeamMember(
    data: Record<string, any> | Array<Record<string, any>>,
    token: string
) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(`${config.API_URL}/api/v1/ldb/team`, requestOptions);

    return res;
}

async function updateTeamMember(
    data: Record<string, any> | Array<Record<string, any>>,
    token?: string
) {
    const requestOptions: { [key: string]: any } = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    if (token) {
        requestOptions.headers = {
            Authorization: `Bearer ${token}`,
        };
    }

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/team-profile`,
        requestOptions
    );

    return res;
}

async function deleteTeamMember(id: string, token: string) {
    const requestOptions = {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/team?ldbId=${id}`,
        requestOptions
    );

    return res;
}

async function getRoles(limit?: string | number, token?: string, search?: string) {
    const requestOptions: { method: string; headers?: Record<string, string> } = {
        method: "GET",
    };

    if (token) {
        requestOptions.headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        };
    }

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/roles?limit=${limit}${
            Boolean(search) ? "&search=" + search : ""
        }`,
        requestOptions
    );

    return res;
}

async function createRoles(data: Record<string, any> | Array<Record<string, any>>, token?: string) {
    const requestOptions: { method: string; body: string; headers?: Record<string, string> } = {
        method: "POST",
        body: JSON.stringify(data),
    };
    if (token) {
        requestOptions.headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        };
    }

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/custom-role`,
        requestOptions
    );

    return res;
}
