import moment from "moment";

export const getCurrentStartAndEndDate = (value: string | number, dateRange?: Date[]) => {

    switch (value) {
        case "weekly": {
            const startOfWeek = moment().startOf("week"); // Start of the current week
            const endOfWeek = moment().endOf("week"); // End of the current week

            return {
                start: startOfWeek.format("YYYY-MM-DD"), // Format as needed
                end: endOfWeek.format("YYYY-MM-DD"), // Format as needed
            };
        }
        case "monthly": {
            const startOfMonth = moment().startOf("month"); // Start of the current month
            const endOfMonth = moment().endOf("month"); // End of the current month

            return {
                start: startOfMonth.format("YYYY-MM-DD"), // Format as needed
                end: endOfMonth.format("YYYY-MM-DD"), // Format as needed
            };
        }
        case "yearly": {
            const startOfYear = moment().startOf("year"); // Start of the current year (January 1st)
            const endOfYear = moment().endOf("year"); // End of the current year (December 31st)

            return {
                start: startOfYear.format("YYYY-MM-DD"), // Format as needed
                end: endOfYear.format("YYYY-MM-DD"), // Format as needed
            };
        }

        case "custom": {
            const startOfMonth = moment(dateRange?.[0]); // Start of the custom rnage
            const endOfMonth = moment(dateRange?.[1]); // End of the custom range

            return {
                start: startOfMonth.format("YYYY-MM-DD"), // Format as needed
                end: endOfMonth.format("YYYY-MM-DD"), // Format as needed
            };
        }
        // default:
        //     const startOfMonth = moment().startOf("month"); // Start of the current month
        //     const endOfMonth = moment().endOf("month"); // End of the current month

        //     return {
        //         start: startOfMonth.format("YYYY-MM-DD"), // Format as needed
        //         end: endOfMonth.format("YYYY-MM-DD"), // Format as needed
        //     };

        default: {
            const startOfYear = moment(value, "YYYY").startOf("year");
            const endOfYear = moment(value, "YYYY").endOf("year");

            return {
                start: startOfYear.format("YYYY-MM-DD"), 
                end: endOfYear.format("YYYY-MM-DD"),
            };
        }
    }
};
