import React, { useEffect, useState } from "react";
import PageTitleSection from "components/common/PageTitleSection";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import useGetAsignedBrands from "hooks/multibrandHooks/useGetAssignedBrands";
import useGetBrandList from "hooks/multibrandHooks/useGetBrandList";
import useGetUserProfile from "hooks/useGetUserProfile";
import TabsContainer from "components/common/TabsContainer";
import PortfolioMetrics from "./PortfolioMetrics";
import FinancialMetrics from "./FinancialMetrics";
import { yearDropdown } from "helpers/yearDropdown";
import moment from "moment";

const MultiBrandDashboard = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [dateRange, setDateRange] = useState<{ label: string; value: string }>({
        label: "",
        value: "yearly",
    });
    const [brand, setBrand] = useState<{ label: string; value: string }>({
        label: "",
        value: "",
    });
    const [startAndEndDate, setStartAndEndDate] = useState<string[]>([]);

    const [years, setYears] = useState<number[]>([]);

    useEffect(() => {
        setYears(yearDropdown());
    }, []);

    const {
        data: allBrandList,
        brandListSearch: brandList,
        search,
        handleDebouncedChange,
        handleSearch,
        handleDebouncedChangeTwo,
        handleSearchTwo,
        searchTwo,
        setDebouncedSearchTwo,
        setSearchTwo,
    } = useGetBrandList();

    const dateOptions = [
        {
            label: (
                <div
                    className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                    border-b border-b-slate-100 !w-[150px] px-4 py-[10px]`}
                >
                    This week
                </div>
            ),
            value: "This week-weekly",
        },
        {
            label: (
                <div
                    className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                    border-b border-b-slate-100 !w-[150px] px-4 py-[10px]`}
                >
                    This month
                </div>
            ),
            value: "This month-monthly",
        },
        ...(activeTab === 0 && years && Array.isArray(years)
            ? years.map((item) => ({
                  label: (
                      <div
                          className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                    border-b border-b-slate-100 !w-[150px] px-4 py-[10px]`}
                      >
                          {item === new Date().getFullYear()
                              ? `This year (${item})`
                              : item?.toString()}
                      </div>
                  ),
                  value: item,
              }))
            : []),
    ];

    const handleDurationChange = (value: { label: string; value: any }) => {
        const range =
            typeof value?.value === "number"
                ? { label: value?.label, value: "custom" }
                : { label: value?.label, value: value?.value };
        setDateRange(range);

        if (typeof value?.value === "number") {
            setStartAndEndDate([
                moment(value?.value, "YYYY").startOf("year")?.toISOString(),
                moment(value?.value, "YYYY").endOf("year")?.toISOString(),
            ]);
        } else {
            setStartAndEndDate([]);
        }
    };
    const handleBrandChangeFilter = (value: { label: string; value: string }) => {
        setBrand(value);
    };

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <PortfolioMetrics
                        generalBrand={brand?.value}
                        generalDuration={dateRange?.value}
                        startAndEndDate={startAndEndDate}
                    />
                );

            case 1:
                return (
                    <FinancialMetrics
                        generalBrand={brand?.value}
                        generalDuration={dateRange?.value}
                    />
                );
            default:
                return <div></div>;
        }
    };

    return (
        <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
            <div className="mb-6">
                <PageTitleSection
                    leftComp={
                        <div className="flex items-center space-x-3">
                            <h3 className="text-xl font-rocGroteskMedium">Portfolio summary</h3>
                        </div>
                    }
                    rightComp={
                        // activeTab === 0 ? (
                        <div className="flex items-center space-x-6">
                            <Dropdown
                                handleChange={(name, value) => {
                                    handleBrandChangeFilter({
                                        label: value?.split("-")?.[0],
                                        value: value?.split("-")?.[1],
                                    });
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-2.5 px-3 bg-white !h-8 !border !border-slate-100 gap-2">
                                        <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                                        >
                                            {brand?.label || "All brands"}
                                        </div>
                                    </div>
                                }
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[286px] !w-[200px] !py-0`}
                                name="valueRange"
                                search={
                                    <div>
                                        <TextInput
                                            value={searchTwo}
                                            name={"search"}
                                            type={"text"}
                                            required={false}
                                            onChange={(e) => handleSearchTwo(e)}
                                            onInput={handleDebouncedChangeTwo}
                                            inputPlaceholder="Search brands"
                                            // containerClassname=""
                                            inputContainerClassname={"!h-[40px]"}
                                            inputClassName="w-full !h-[40px]"
                                        />
                                    </div>
                                }
                                onClose={() => {
                                    setSearchTwo("");
                                    setDebouncedSearchTwo("");
                                }}
                                dropdownOptions={[
                                    {
                                        label: (
                                            <div
                                                className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                px-4 py-[10px]`}
                                            >
                                                All brands
                                            </div>
                                        ),
                                        value: ``,
                                    },
                                    ...brandList?.map((brand) => {
                                        return {
                                            label: (
                                                <div
                                                    className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                    border-b border-b-slate-100 px-4 py-[10px]`}
                                                >
                                                    {brand?.brand?.brandName}
                                                </div>
                                            ),
                                            value: `${brand?.brand?.brandName}-${brand?.brand?._id}`,
                                        };
                                    }),
                                ]}
                            />

                            <Dropdown
                                handleChange={(name, value) => {
                                    handleDurationChange({
                                        label:
                                            typeof value === "number"
                                                ? value
                                                : value?.split("-")?.[0],
                                        value:
                                            typeof value === "number"
                                                ? value
                                                : value?.split("-")?.[1],
                                    });
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-2.5 px-3 bg-white !h-8 !border !border-slate-100 gap-2">
                                        <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                                        >
                                            {dateRange?.label || "This year"}
                                        </div>
                                    </div>
                                }
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                name="valueRange"
                                dropdownOptions={dateOptions}
                            />
                        </div>
                        // ) : (
                        //     ""
                        // )
                    }
                />
            </div>

            <div>
                <TabsContainer
                    tabs={["Portfolio metrics", "Financial metrics"]}
                    activeTab={activeTab}
                    setActiveTab={(idx) => {
                        setActiveTab(idx);
                    }}
                    component={displayActiveTab()}
                    className="!px-0"
                    itemClassName="!pb-2"
                    borderLineClase={"!text-slate-100 w-full max-lg:w-full mb-6"}
                    tabRowClassname="!overflow-visible"
                />
            </div>
        </div>
    );
};

export default MultiBrandDashboard;
