import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import TabsContainer from "components/common/TabsContainer";
import VendorAvatar from "components/common/VendorAvatar";
import { formatMoney, getCurrencyFromCurrencyCode, onScroll } from "helpers";
import { downloadUrl, fbDownload } from "helpers/fileDownload";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { debounce, truncate, uniqBy } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { orderActions } from "redux/Ldb/actions";
import { AnalyticsCard } from "../BusinessAnalytics/Card";
import CustomTableLoader from "components/common/CustomTableLoader";

interface Prop {
    totalApproved: number;
    totalIntransit: number;
    totalPending: number;
    totalPurchaseOrders: number;
    totalRejected: number;
}
const PurchaseOrder = () => {
    const tabTwo = ["All", "Fulfilled", "Pending"];
    const values = ["1 year", "2 years", "3 years"];
    const limit = 20;
    const [activeTabTwo, setActiveTabTwo] = useState(0);
    const [loadMorePurchaseOrder, setLoadMorePurchaseOrder] = useState(false);
    const [status, setStatus] = useState("");
    const [purchaseOrderNotRequestList, setPurchaseOrderNotRequestList] = useState([]);
    const [purchaseOrderStatistics, setPurchaseOrderStatistics] = useState<{
        totalFulfilled: {
            count: number;
            percentage: number;
        };
        totalPurchaseOrders: {
            count: number;
            percentage: number;
        };
    }>();

    const [purchasePendingOrderNotRequestList, setPurchasePendingOrderNotRequestList] = useState(
        []
    );
    const [purchaseFulfilledOrderNotRequestList, setPurchaseFulfilledOrderNotRequestList] =
        useState([]);

    const [purchasePendingOrderNotRequestPagination, setPurchasePendingOrderNotRequestPagination] =
        useState<{
            current: string | number;
            number_of_pages: string | number;
        }>({
            current: "",
            number_of_pages: "",
        });
    const [
        purchaseFulfilledOrderNotRequestPagination,
        setPurchaseFulfilledOrderNotRequestPagination,
    ] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [purchaseOrderNotRequestPagination, setPurchaseOrderNotRequestPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [pendingPage, setPendingPage] = useState<number>(1);
    const [fulfilledPage, setFulfilledPage] = useState<number>(1);
    const [poPage, setPoPage] = useState<number>(1);
    const dashboardContent = document.getElementById("tableContent");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {
        fetchingPurchaseOrderNotRequest,
        fetchedPurchaseOrderNotRequestSuccess,
        fetchedPOStatisticsSuccess,
        fetchingPOStatistics,
    } = useAppSelector((state) => state.order);

    useEffect(() => {
        setPoPage(1);
        dispatch(orderActions.resetPurchaseOrderNotRequest());
        if (activeTabTwo === 0) {
            setStatus("");
        } else if (activeTabTwo === 1) {
            setStatus("completed");
        } else if (activeTabTwo === 2) {
            setStatus("in-transit");
        }
    }, [activeTabTwo]);

    const onPurchaseOrderNotRequestTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                activeTabTwo === 0
                    ? (purchaseOrderNotRequestPagination?.current as number)
                    : activeTabTwo === 1
                    ? (purchaseFulfilledOrderNotRequestPagination?.current as number)
                    : (purchasePendingOrderNotRequestPagination?.current as number),
                activeTabTwo === 0
                    ? (purchaseOrderNotRequestPagination?.number_of_pages as number)
                    : activeTabTwo === 1
                    ? (purchaseFulfilledOrderNotRequestPagination?.current as number)
                    : (purchasePendingOrderNotRequestPagination?.number_of_pages as number),
                () => {
                    setPoPage(() => poPage + 1);
                    setLoadMorePurchaseOrder(true);
                }
            ),
        [
            dashboardContent,
            purchasePendingOrderNotRequestPagination,
            purchaseFulfilledOrderNotRequestPagination,
            purchasePendingOrderNotRequestPagination,
            activeTabTwo,
        ]
    );

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onPurchaseOrderNotRequestTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onPurchaseOrderNotRequestTableScroll);
        };
    }, [dashboardContent, onPurchaseOrderNotRequestTableScroll]);

    const handleDebouncedChange = debounce((e) => {
        setPoPage(1);
        const { name, value } = e.target;
        setDebouncedSearch(value);
    }, 1000);

    const handleSearch = (e) => {
        setPoPage(1);
        const { name, value } = e.target;
        setSearch(value);
    };
    useEffect(() => {
        dispatch(orderActions.getPOStatistics());
    }, [dispatch]);
    useEffect(() => {
        dispatch(orderActions.getPurchaseOrder(limit, poPage, debouncedSearch, "", status));
    }, [dispatch, debouncedSearch, poPage, limit, status]);

    useEffect(() => {
        if (
            Boolean(fetchedPurchaseOrderNotRequestSuccess) &&
            status?.toLowerCase() === "in-transit"
        ) {
            setPurchasePendingOrderNotRequestList((prev) =>
                poPage > 1
                    ? uniqBy([...prev, ...fetchedPurchaseOrderNotRequestSuccess?.po], "_id")
                    : [...fetchedPurchaseOrderNotRequestSuccess?.po]
            );
            setPurchasePendingOrderNotRequestPagination({
                current: fetchedPurchaseOrderNotRequestSuccess?.pagination.current,
                number_of_pages: fetchedPurchaseOrderNotRequestSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedPurchaseOrderNotRequestSuccess, status]);

    useEffect(() => {
        if (
            Boolean(fetchedPurchaseOrderNotRequestSuccess) &&
            status?.toLowerCase() === "completed"
        ) {
            setPurchaseFulfilledOrderNotRequestList((prev) =>
                poPage > 1
                    ? uniqBy([...prev, ...fetchedPurchaseOrderNotRequestSuccess?.po], "_id")
                    : [...fetchedPurchaseOrderNotRequestSuccess?.po]
            );
            setPurchaseFulfilledOrderNotRequestPagination({
                current: fetchedPurchaseOrderNotRequestSuccess?.pagination.current,
                number_of_pages: fetchedPurchaseOrderNotRequestSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedPurchaseOrderNotRequestSuccess, status]);

    useEffect(() => {
        if (Boolean(fetchedPurchaseOrderNotRequestSuccess) && status?.toLowerCase() === "") {
            setPurchaseOrderNotRequestList((prev) =>
                poPage > 1
                    ? uniqBy([...prev, ...fetchedPurchaseOrderNotRequestSuccess?.po], "_id")
                    : [...fetchedPurchaseOrderNotRequestSuccess?.po]
            );
            setPurchaseOrderNotRequestPagination({
                current: fetchedPurchaseOrderNotRequestSuccess?.pagination.current,
                number_of_pages: fetchedPurchaseOrderNotRequestSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedPurchaseOrderNotRequestSuccess, status]);

    useEffect(() => {
        if (Boolean(fetchedPOStatisticsSuccess)) {
            setPurchaseOrderStatistics(fetchedPOStatisticsSuccess);
        }
    }, [fetchedPOStatisticsSuccess, status]);

    const tableHeaderTwo = [
        { title: "PO ID", widthClass: "w-[6.7%]" },
        { title: "Supplier", widthClass: "w-[10.8%]" },
        { title: "Created by", widthClass: "w-[10.6%]" },
        { title: "Product name", widthClass: "w-[9.8%]" },
        { title: "PO Value", widthClass: "w-[6.9%]" },
        { title: "Quantity", widthClass: "w-[6.9%]" },
        { title: "No. of shipments", widthClass: "w-[9.8%]" },
        { title: "Status", widthClass: "w-[9.8%]" },
        // { title: "", widthClass: "w-[3.1%]" },
    ];

    const tableBodyTwo =
        status === "in-transit"
            ? purchasePendingOrderNotRequestList?.map((po, idx) => [
                  {
                      content: (
                          <div
                              key={idx}
                              className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-500 `}
                          >
                              <p>{po?.taId}</p>
                          </div>
                      ),
                      cellClickAction: () => navigate(`/dashboard/purchase/shipment/${po?._id}`),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                          >
                              <div className="flex items-center space-x-3">
                                  <VendorAvatar
                                      imageSrc={po?.vendor?.profile?.avatar}
                                      size={32}
                                      name={po?.vendor?.email}
                                  />
                                  <span>{po?.vendor?.profile?.businessName}</span>
                              </div>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <div className="flex items-center space-x-2">
                                  <VendorAvatar
                                      imageSrc={po?.createdBy?.profile?.avatar}
                                      size={24}
                                      name={po?.createdBy?.fullName}
                                  />
                                  <span>{po?.createdBy?.fullName}</span>
                              </div>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <span>{po?.inventory?.productName}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <span>
                                  {getCurrencyFromCurrencyCode(po?.totalAmount?.currency)}
                                  {formatMoney().format(po?.totalAmount?.amount)}
                              </span>
                          </div>
                      ),
                  },

                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <span>{formatMoney().format(Number(po?.totalNumberOfItems))}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500`}
                          >
                              <span>{formatMoney().format(Number(po?.numberOfShipments))}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 space-x-1  flex items-center h-full border-slate-100  `}
                          >
                              <div
                                  className={`  bg-light-yellow-status  text-[#E5993E] rounded-[16px] py-[2px] px-[8px] `}
                              >
                                  <p className="text-sm font-rocGroteskMedium">
                                      Pending fulfilment
                                  </p>
                              </div>
                          </div>
                      ),
                  },
                //   {
                //       content: (
                //           <div
                //               className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                //           >
                //               <Dropdown
                //                   dropdown={
                //                       <i className="ri-more-2-fill text-lg text-slate-500"></i>
                //                   }
                //                   dropdownClassName={"!w-full !mb-10 !-mt-7"}
                //                   dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-140px] `}
                //                   dropdownItemsClasses={``}
                //                   name={"faq-dropdown"}
                //               />
                //           </div>
                //       ),
                //   },
              ])
            : status === "completed"
            ? purchaseFulfilledOrderNotRequestList?.map((po, idx) => [
                  {
                      content: (
                          <div
                              key={idx}
                              className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-500 `}
                          >
                              <p>{po?.taId}</p>
                          </div>
                      ),
                      cellClickAction: () => navigate(`/dashboard/purchase/shipment/${po?._id}`),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                          >
                              <div className="flex items-center space-x-3">
                                  <VendorAvatar
                                      imageSrc={po?.vendor?.profile?.avatar}
                                      size={32}
                                      name={po?.vendor?.email}
                                  />
                                  <span>{po?.vendor?.profile?.businessName}</span>
                              </div>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <div className="flex items-center space-x-2">
                                  <VendorAvatar
                                      imageSrc={po?.createdBy?.profile?.avatar}
                                      size={24}
                                      name={po?.createdBy?.fullName}
                                  />
                                  <span>{po?.createdBy?.fullName}</span>
                              </div>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <span>{po?.inventory?.productName}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <span>
                                  {getCurrencyFromCurrencyCode(po?.totalAmount?.currency)}
                                  {formatMoney().format(po?.totalAmount?.amount)}
                              </span>
                          </div>
                      ),
                  },

                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <span>{formatMoney().format(Number(po?.totalNumberOfItems))}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500`}
                          >
                              <span>{formatMoney().format(Number(po?.numberOfShipments))}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 space-x-1  flex items-center h-full border-slate-100  `}
                          >
                              <div
                                  className={`  bg-g-25  text-g-60 rounded-[16px] py-[2px] px-[8px] `}
                              >
                                  <p className="text-sm font-rocGroteskMedium">Fulfilled</p>
                              </div>
                          </div>
                      ),
                  },
                //   {
                //       content: (
                //           <div
                //               className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                //           >
                //               <Dropdown
                //                   dropdown={
                //                       <i className="ri-more-2-fill text-lg text-slate-500"></i>
                //                   }
                //                   dropdownClassName={"!w-full !mb-10 !-mt-7"}
                //                   dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-140px] `}
                //                   dropdownItemsClasses={``}
                //                   name={"faq-dropdown"}
                //               />
                //           </div>
                //       ),
                //   },
              ])
            : purchaseOrderNotRequestList?.map((po, idx) => [
                  {
                      content: (
                          <div
                              key={idx}
                              className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-500 `}
                          >
                              <p>PO-{po?.taId}</p>
                          </div>
                      ),
                      cellClickAction: () => navigate(`/dashboard/purchase/shipment/${po?._id}`),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                          >
                              <div className="flex items-center space-x-3">
                                  <VendorAvatar
                                      imageSrc={po?.vendor?.profile?.avatar}
                                      size={32}
                                      name={po?.vendor?.email}
                                  />
                                  <span>{po?.vendor?.profile?.businessName}</span>
                              </div>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <div className="flex items-center space-x-2">
                                  <VendorAvatar
                                      imageSrc={po?.createdBy?.profile?.avatar}
                                      size={24}
                                      name={po?.createdBy?.fullName}
                                  />
                                  <span>{po?.createdBy?.fullName}</span>
                              </div>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <span>{po?.inventory?.productName}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <span>
                                  {getCurrencyFromCurrencyCode(po?.totalAmount?.currency)}
                                  {formatMoney().format(po?.totalAmount?.amount)}
                              </span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                          >
                              <span>{formatMoney().format(Number(po?.totalNumberOfItems))}</span>
                          </div>
                      ),
                  },
                  {
                      content: (
                          <div
                              className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500`}
                          >
                              <span>{formatMoney().format(Number(po?.numberOfShipments))}</span>
                          </div>
                      ),
                  },

                  {
                      content: (
                          <div
                              className={`py-3 pr-3 space-x-1   flex items-center h-full border-slate-100  `}
                          >
                              <div
                                  className={` rounded-[16px] py-[2px] px-[8px]  ${
                                      po?.currentStatus?.toLowerCase() === "completed" &&
                                      "bg-g-25  text-g-60 "
                                  } ${
                                      po?.currentStatus?.toLowerCase() === "in-transit" &&
                                      "bg-light-yellow-status  text-[#E5993E] "
                                  } 
                                ${
                                    po?.currentStatus?.toLowerCase() === "rejected" &&
                                    "bg-r-25  text-r-55 "
                                }
                                ${
                                    po?.currentStatus?.toLowerCase() === "pending" &&
                                    "bg-light-purple-2  text-[#6562E5] "
                                }
                                 `}
                              >
                                  <p className={`text-sm font-rocGroteskMedium`}>
                                      {po?.currentStatus?.toLowerCase() === "rejected" &&
                                          "Cancelled"}
                                      {po?.currentStatus?.toLowerCase() === "in-transit" &&
                                          "Pending fulfilment"}
                                      {po?.currentStatus?.toLowerCase() === "completed" &&
                                          "Fulfilled"}
                                      {po?.currentStatus?.toLowerCase() === "pending" && "Pending"}
                                  </p>
                              </div>
                          </div>
                      ),
                  },
                //   {
                //       content: (
                //           <div
                //               className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                //           >
                //               <Dropdown
                //                   dropdown={
                //                       <i className="ri-more-2-fill text-lg text-slate-500"></i>
                //                   }
                //                   dropdownClassName={"!w-full !mb-10 !-mt-7"}
                //                   dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-140px] `}
                //                   dropdownItemsClasses={``}
                //                   name={"faq-dropdown"}
                //               />
                //           </div>
                //       ),
                //   },
              ]);

    const displayActiveTabTwo = () => {
        switch (activeTabTwo) {
            case 0:
                return fetchingPurchaseOrderNotRequest &&
                    purchaseOrderNotRequestList?.length === 0 &&
                    !debouncedSearch ? (
                    <CustomTableLoader tableHeader={tableHeaderTwo} />
                ) : purchaseOrderNotRequestList?.length > 0 ? (
                    <div className="">
                        <div className="max-lg:hidden block">
                            <CustomTable
                                tableBody={tableBodyTwo}
                                tableHeader={tableHeaderTwo}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="max-lg:block hidden">
                            {status !== "completed" &&
                                status !== "in-transit" &&
                                purchaseOrderNotRequestList?.map((po, idx) => (
                                    <div className="w-full mt-4 " key={idx}>
                                        <div className="flex justify-between items-center border px-2 py-4 sm:px-4 sm:py-4 rounded">
                                            <div className="">
                                                <div>
                                                    <div className="flex space-x-3">
                                                        <p className="text-[#142837] text-sm font-rocGroteskMedium">
                                                            PO {po?.taId}
                                                        </p>
                                                        <div className="border-r"></div>
                                                        <p className="text-sm font-rocGroteskMedium text-[#142837]">
                                                            {truncate(
                                                                po?.vendor?.profile?.businessName,
                                                                { length: 20 }
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="flex mt-2 items-center space-x-2">
                                                        <div className="flex items-center space-x-2">
                                                            <i className="ri-user-line text-[#64748B] "></i>
                                                            <p className="text-[#64748B] text-xs">
                                                                {po?.createdBy?.fullName}
                                                            </p>
                                                        </div>
                                                        <div className="border-r border-[#E2E8F0]"></div>
                                                        <div className="flex items-center space-x-2">
                                                            <i className="ri-calendar-line text-[#64748B] "></i>
                                                            <p className="text-[#64748B] text-xs">
                                                                {moment(po?.issuedDate).format(
                                                                    "MMM DD, YYYY"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="3md:hidden flex mt-2 items-center space-x-2">
                                                    <div
                                                        className={` ${
                                                            po?.currentStatus?.toLowerCase() ===
                                                                "completed" && "bg-g-25  text-g-60 "
                                                        } ${
                                                            po?.currentStatus?.toLowerCase() ===
                                                                "in-transit" &&
                                                            "bg-light-yellow-status  text-[#E5993E] "
                                                        } 
                                                      ${
                                                          po?.currentStatus?.toLowerCase() ===
                                                              "rejected" && "bg-r-25  text-r-55 "
                                                      }
                                                      ${
                                                          po?.currentStatus?.toLowerCase() ===
                                                              "pending" &&
                                                          "bg-light-purple-2  text-[#6562E5] "
                                                      } rounded-[16px] py-[2px] px-[8px] `}
                                                    >
                                                        <div
                                                            className={` rounded-[16px] py-[2px] px-[8px]  ${
                                                                po?.currentStatus?.toLowerCase() ===
                                                                    "completed" &&
                                                                "bg-g-25  text-g-60 "
                                                            } ${
                                                                po?.currentStatus?.toLowerCase() ===
                                                                    "in-transit" &&
                                                                "bg-light-yellow-status  text-[#E5993E] "
                                                            }   ${
                                                                po?.currentStatus?.toLowerCase() ===
                                                                    "rejected" &&
                                                                "bg-r-25  text-r-55 "
                                                            }   ${
                                                                po?.currentStatus?.toLowerCase() ===
                                                                    "pending" &&
                                                                "bg-light-purple-2  text-[#6562E5] "
                                                            }
                                 `}
                                                        >
                                                            <p
                                                                className={`text-sm  font-rocGroteskMedium`}
                                                            >
                                                                {po?.currentStatus?.toLowerCase() ===
                                                                    "rejected" &&
                                                                    truncate("Cancelled", {
                                                                        length: 35,
                                                                    })}
                                                                {po?.currentStatus?.toLowerCase() ===
                                                                    "in-transit" &&
                                                                    truncate("Pending fulfilment", {
                                                                        length: 35,
                                                                    })}
                                                                {po?.currentStatus?.toLowerCase() ===
                                                                    "completed" &&
                                                                    truncate("Fulfilled", {
                                                                        length: 35,
                                                                    })}
                                                                {po?.currentStatus?.toLowerCase() ===
                                                                    "pending" &&
                                                                    truncate("Pending", {
                                                                        length: 35,
                                                                    })}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="3md:flex hidden mt-2 items-center space-x-2">
                                                <div
                                                    className={` ${
                                                        po?.currentStatus?.toLowerCase() ===
                                                            "completed" && "bg-g-25  text-g-60 "
                                                    } ${
                                                        po?.currentStatus?.toLowerCase() ===
                                                            "in-transit" &&
                                                        "bg-light-yellow-status  text-[#E5993E] "
                                                    } 
                                                      ${
                                                          po?.currentStatus?.toLowerCase() ===
                                                              "rejected" && "bg-r-25  text-r-55 "
                                                      }
                                                      ${
                                                          po?.currentStatus?.toLowerCase() ===
                                                              "pending" &&
                                                          "bg-light-purple-2  text-[#6562E5] "
                                                      } rounded-[16px] py-[2px] px-[8px] `}
                                                >
                                                    <div
                                                        className={` rounded-[16px] py-[2px] px-[8px]  ${
                                                            po?.currentStatus?.toLowerCase() ===
                                                                "completed" && "bg-g-25  text-g-60 "
                                                        } ${
                                                            po?.currentStatus?.toLowerCase() ===
                                                                "in-transit" &&
                                                            "bg-light-yellow-status  text-[#E5993E] "
                                                        }   ${
                                                            po?.currentStatus?.toLowerCase() ===
                                                                "rejected" && "bg-r-25  text-r-55 "
                                                        }   ${
                                                            po?.currentStatus?.toLowerCase() ===
                                                                "pending" &&
                                                            "bg-light-purple-2  text-[#6562E5] "
                                                        }
                                 `}
                                                    >
                                                        <p
                                                            className={`text-sm font-rocGroteskMedium`}
                                                        >
                                                            {po?.currentStatus?.toLowerCase() ===
                                                                "rejected" &&
                                                                truncate("Cancelled", {
                                                                    length: 20,
                                                                })}
                                                            {po?.currentStatus?.toLowerCase() ===
                                                                "in-transit" &&
                                                                truncate("Pending fulfilment", {
                                                                    length: 20,
                                                                })}
                                                            {po?.currentStatus?.toLowerCase() ===
                                                                "completed" &&
                                                                truncate("Fulfilled", {
                                                                    length: 20,
                                                                })}
                                                            {po?.currentStatus?.toLowerCase() ===
                                                                "pending" &&
                                                                truncate("Pending", { length: 20 })}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <i
                                                    className="ri-more-2-fill cursor-pointer text-[#64748B] text-2xl"
                                                    onClick={() =>
                                                        navigate(
                                                            `/dashboard/purchase/shipment/${po?._id}`
                                                        )
                                                    }
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        {loadMorePurchaseOrder && fetchingPurchaseOrderNotRequest && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {purchaseOrderNotRequestPagination?.current ===
                            purchaseOrderNotRequestPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex-col flex  h-[100%]  justify-center my-[8%]">
                        {search ? (
                            <div className="space-y-3 w-[452px]  justify-center items-center flex flex-col  text-center">
                                <div className="p-[5px] flex items-center justify-center w-[34px] h-[34px] rounded-lg bg-slate-100 mb-2.5">
                                    <i className="ri-search-line text-2xl text-[#142837] opacity-50"></i>
                                </div>

                                <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                    Sorry, no results match your search. Refine your criteria and
                                    try again.
                                </p>
                            </div>
                        ) : (
                            <div className="space-y-3 max-w-[452px]  justify-center items-center flex flex-col  text-center">
                                <div className="p-[5px] flex items-center justify-center w-[34px] h-[34px] rounded-lg bg-slate-100 mb-2.5">
                                    <i className="ri-guide-fill text-2xl text-light-black opacity-50"></i>
                                </div>
                                <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                    Looks like there are no purchase orders at the moment. Start by
                                    creating your first purchase order to keep of your transaction
                                    and manage your procurement efficiently
                                </p>
                                <Button
                                    btnText={"Create purchase request"}
                                    type={"button"}
                                    btnClassname="!py-2 !px-4 !w-fit !whitespace-nowrap max-sm:w-full"
                                    onClick={() =>
                                        navigate("/dashboard/purchase/create-purchase-request")
                                    }
                                />
                            </div>
                        )}
                    </div>
                );
            case 1:
                return fetchingPurchaseOrderNotRequest &&
                    purchaseFulfilledOrderNotRequestList?.length === 0 &&
                    !debouncedSearch ? (
                    <CustomTableLoader tableHeader={tableHeaderTwo} />
                ) : purchaseFulfilledOrderNotRequestList?.length > 0 ? (
                    <div className="">
                        <div className="max-lg:hidden">
                            <CustomTable
                                tableBody={tableBodyTwo}
                                tableHeader={tableHeaderTwo}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="max-lg:block hidden">
                            {status === "completed" &&
                                purchaseFulfilledOrderNotRequestList?.map((po, idx) => (
                                    <div className="w-full mt-4 " key={idx}>
                                        <div className="flex justify-between items-center border px-2 py-4 sm:px-4 sm:py-4 rounded">
                                            <div className="flex md:flex-row flex-col">
                                                <div>
                                                    <div className="flex space-x-3">
                                                        <p className="text-[#142837] text-sm font-rocGroteskMedium">
                                                            PO {po?.taId}
                                                        </p>
                                                        <div className="border-r"></div>
                                                        <p className="text-sm font-rocGroteskMedium text-[#142837]">
                                                            {truncate(
                                                                po?.vendor?.profile?.businessName,
                                                                { length: 20 }
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="flex mt-2 items-center space-x-2">
                                                        <div className="flex items-center space-x-2">
                                                            <i className="ri-user-line text-[#64748B] "></i>
                                                            <p className="text-[#64748B] text-xs">
                                                                {po?.createdBy?.fullName}
                                                            </p>
                                                        </div>
                                                        <div className="border-r border-[#E2E8F0]"></div>
                                                        <div className="flex items-center space-x-2">
                                                            <i className="ri-calendar-line text-[#64748B] "></i>
                                                            <p className="text-[#64748B] text-xs">
                                                                {moment(po?.issuedDate).format(
                                                                    "MMM DD, YYYY"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="3md:hidden flex mt-2 items-center space-x-2">
                                                    <div
                                                        className={`  bg-g-25  text-g-60  rounded-[16px] py-[2px] px-[8px] `}
                                                    >
                                                        <p className="text-sm font-rocGroteskMedium">
                                                            {truncate("Completed", {
                                                                length: 35,
                                                            })}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="3md:flex hidden mt-2 xs:hidden items-center space-x-2">
                                                <div
                                                    className={` bg-g-25  text-g-60  rounded-[16px] py-[2px] px-[8px] `}
                                                >
                                                    <p className="text-sm font-rocGroteskMedium">
                                                        {truncate("Completed", {
                                                            length: 20,
                                                        })}
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <i
                                                    className="ri-more-2-fill cursor-pointer text-[#64748B] text-2xl"
                                                    onClick={() =>
                                                        navigate(`/dashboard/purchase/${po?._id}`)
                                                    }
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        {loadMorePurchaseOrder && fetchingPurchaseOrderNotRequest && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {purchaseFulfilledOrderNotRequestPagination?.current ===
                            purchaseFulfilledOrderNotRequestPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex-col flex  h-[100%]  justify-center my-[8%]">
                        {search ? (
                            <div className="space-y-3 w-[452px]  justify-center items-center flex flex-col  text-center">
                                <div className="p-[5px] flex items-center justify-center w-[34px] h-[34px] rounded-lg bg-slate-100 mb-2.5">
                                    <i className="ri-search-line text-2xl text-[#142837] opacity-50"></i>
                                </div>

                                <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                    Sorry, no results match your search. Refine your criteria and
                                    try again.
                                </p>
                            </div>
                        ) : (
                            <div className="space-y-3 w-[452px]  justify-center items-center flex flex-col  text-center">
                                <div className="p-[5px] flex items-center justify-center w-[34px] h-[34px] rounded-lg bg-slate-100 mb-2.5">
                                    <i className="ri-guide-fill text-2xl text-light-black opacity-50"></i>
                                </div>
                                <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                    No fulfilled orders at the moment
                                </p>
                            </div>
                        )}
                    </div>
                );
            case 2:
                return fetchingPurchaseOrderNotRequest &&
                    purchasePendingOrderNotRequestList?.length === 0 &&
                    !debouncedSearch ? (
                    <CustomTableLoader tableHeader={tableHeaderTwo} />
                ) : purchasePendingOrderNotRequestList?.length > 0 ? (
                    <div className="">
                        <div className="max-lg:hidden">
                            <CustomTable
                                tableBody={tableBodyTwo}
                                tableHeader={tableHeaderTwo}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="max-lg:block hidden">
                            {status === "in-transit" &&
                                purchasePendingOrderNotRequestList?.map((po, idx) => (
                                    <div className="w-full mt-4 " key={idx}>
                                        <div className="flex justify-between items-center border px-2 py-4 sm:px-4 sm:py-4 rounded">
                                            <div className="flex md:flex-row flex-col">
                                                <div>
                                                    <div className="flex space-x-3">
                                                        <p className="text-[#142837] text-sm font-rocGroteskMedium">
                                                            PO {po?.taId}
                                                        </p>
                                                        <div className="border-r"></div>
                                                        <p className="text-sm font-rocGroteskMedium text-[#142837]">
                                                            {truncate(
                                                                po?.vendor?.profile?.businessName,
                                                                { length: 20 }
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="flex mt-2 items-center space-x-2">
                                                        <div className="flex items-center space-x-2">
                                                            <i className="ri-user-line text-[#64748B] "></i>
                                                            <p className="text-[#64748B] text-xs">
                                                                {po?.createdBy?.fullName}
                                                            </p>
                                                        </div>
                                                        <div className="border-r border-[#E2E8F0]"></div>
                                                        <div className="flex items-center space-x-2">
                                                            <i className="ri-calendar-line text-[#64748B] "></i>
                                                            <p className="text-[#64748B] text-xs">
                                                                {moment(po?.issuedDate).format(
                                                                    "MMM DD, YYYY"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="3md:hidden flex mt-2 items-center space-x-2">
                                                    <div
                                                        className={`  bg-light-yellow-status  text-[#E5993E] rounded-[16px] py-[2px] px-[8px] `}
                                                    >
                                                        <p className="text-sm font-rocGroteskMedium">
                                                            {truncate("Pending fulfilment", {
                                                                length: 35,
                                                            })}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="3md:flex hidden mt-2 xs:hidden items-center space-x-2">
                                                <div
                                                    className={` bg-light-yellow-status  text-[#E5993E] rounded-[16px] py-[2px] px-[8px] `}
                                                >
                                                    <p className="text-sm font-rocGroteskMedium">
                                                        {truncate("Pending fulfilment", {
                                                            length: 20,
                                                        })}
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <i
                                                    className="ri-more-2-fill cursor-pointer text-[#64748B] text-2xl"
                                                    onClick={() =>
                                                        navigate(`/dashboard/purchase/${po?._id}`)
                                                    }
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        {loadMorePurchaseOrder && fetchingPurchaseOrderNotRequest && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {purchasePendingOrderNotRequestPagination?.current ===
                            purchasePendingOrderNotRequestPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex-col flex  h-[100%]  justify-center my-[8%]">
                        {search ? (
                            <div className="space-y-3 w-[452px]  justify-center items-center flex flex-col  text-center">
                                <div className="p-[5px] flex items-center justify-center w-[34px] h-[34px] rounded-lg bg-slate-100 mb-2.5">
                                    <i className="ri-search-line text-2xl text-[#142837] opacity-50"></i>
                                </div>

                                <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                    Sorry, no results match your search. Refine your criteria and
                                    try again.
                                </p>
                            </div>
                        ) : (
                            <div className="space-y-3 w-[452px]  justify-center items-center flex flex-col  text-center">
                                <div className="p-[5px] flex items-center justify-center w-[34px] h-[34px] rounded-lg bg-slate-100 mb-2.5">
                                    <i className="ri-guide-fill text-2xl text-light-black opacity-50"></i>
                                </div>
                                <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                    No pending orders at the moment
                                </p>
                            </div>
                        )}
                    </div>
                );

            default:
                return fetchingPurchaseOrderNotRequest &&
                    purchaseOrderNotRequestList?.length === 0 &&
                    !debouncedSearch ? (
                    <CustomTableLoader tableHeader={tableHeaderTwo} />
                ) : purchaseOrderNotRequestList?.length > 0 ? (
                    <div className="">
                        <div className="max-lg:hidden">
                            <CustomTable
                                tableBody={tableBodyTwo}
                                tableHeader={tableHeaderTwo}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="max-lg:block hidden">
                            {status !== "completed" &&
                                status !== "in-transit" &&
                                purchaseOrderNotRequestList?.map((po, idx) => (
                                    <div className="w-full mt-4 " key={idx}>
                                        <div className="flex justify-between items-center border px-2 py-4 sm:px-4 sm:py-4 rounded">
                                            <div className="">
                                                <div>
                                                    <div className="flex space-x-3">
                                                        <p className="text-[#142837] text-sm font-rocGroteskMedium">
                                                            PO {po?.taId}
                                                        </p>
                                                        <div className="border-r"></div>
                                                        <p className="text-sm font-rocGroteskMedium text-[#142837]">
                                                            {truncate(
                                                                po?.vendor?.profile?.businessName,
                                                                { length: 20 }
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="flex mt-2 items-center space-x-2">
                                                        <div className="flex items-center space-x-2">
                                                            <i className="ri-user-line text-[#64748B] "></i>
                                                            <p className="text-[#64748B] text-xs">
                                                                {po?.createdBy?.fullName}
                                                            </p>
                                                        </div>
                                                        <div className="border-r border-[#E2E8F0]"></div>
                                                        <div className="flex items-center space-x-2">
                                                            <i className="ri-calendar-line text-[#64748B] "></i>
                                                            <p className="text-[#64748B] text-xs">
                                                                {moment(po?.issuedDate).format(
                                                                    "MMM DD, YYYY"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="xs:hidden flex mt-2 items-center space-x-2">
                                                    <div
                                                        className={` ${
                                                            po?.currentStatus?.toLowerCase() ===
                                                                "completed" && "bg-g-25  text-g-60 "
                                                        } ${
                                                            po?.currentStatus?.toLowerCase() ===
                                                                "in-transit" &&
                                                            "bg-light-yellow-status  text-[#E5993E] "
                                                        } 
                                                      ${
                                                          po?.currentStatus?.toLowerCase() ===
                                                              "rejected" && "bg-r-25  text-r-55 "
                                                      }
                                                      ${
                                                          po?.currentStatus?.toLowerCase() ===
                                                              "pending" &&
                                                          "bg-light-purple-2  text-[#6562E5] "
                                                      } rounded-[16px] py-[2px] px-[8px] `}
                                                    >
                                                        <div
                                                            className={` rounded-[16px] py-[2px] px-[8px]  ${
                                                                po?.currentStatus?.toLowerCase() ===
                                                                    "completed" &&
                                                                "bg-g-25  text-g-60 "
                                                            } ${
                                                                po?.currentStatus?.toLowerCase() ===
                                                                    "in-transit" &&
                                                                "bg-light-yellow-status  text-[#E5993E] "
                                                            }   ${
                                                                po?.currentStatus?.toLowerCase() ===
                                                                    "rejected" &&
                                                                "bg-r-25  text-r-55 "
                                                            }   ${
                                                                po?.currentStatus?.toLowerCase() ===
                                                                    "pending" &&
                                                                "bg-light-purple-2  text-[#6562E5] "
                                                            }
                                 `}
                                                        >
                                                            <p
                                                                className={`text-sm font-rocGroteskMedium`}
                                                            >
                                                                {po?.currentStatus?.toLowerCase() ===
                                                                    "rejected" &&
                                                                    truncate("Cancelled", {
                                                                        length: 20,
                                                                    })}
                                                                {po?.currentStatus?.toLowerCase() ===
                                                                    "in-transit" &&
                                                                    truncate("Pending fulfilment", {
                                                                        length: 20,
                                                                    })}
                                                                {po?.currentStatus?.toLowerCase() ===
                                                                    "completed" &&
                                                                    truncate("Fulfilled", {
                                                                        length: 20,
                                                                    })}
                                                                {po?.currentStatus?.toLowerCase() ===
                                                                    "pending" &&
                                                                    truncate("Pending", {
                                                                        length: 20,
                                                                    })}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="xs:flex hidden mt-2 items-center space-x-2">
                                                <div
                                                    className={` ${
                                                        po?.currentStatus?.toLowerCase() ===
                                                            "completed" && "bg-g-25  text-g-60 "
                                                    } ${
                                                        po?.currentStatus?.toLowerCase() ===
                                                            "in-transit" &&
                                                        "bg-light-yellow-status  text-[#E5993E] "
                                                    } 
                                                      ${
                                                          po?.currentStatus?.toLowerCase() ===
                                                              "rejected" && "bg-r-25  text-r-55 "
                                                      }
                                                      ${
                                                          po?.currentStatus?.toLowerCase() ===
                                                              "pending" &&
                                                          "bg-light-purple-2  text-[#6562E5] "
                                                      } rounded-[16px] py-[2px] px-[8px] `}
                                                >
                                                    <div
                                                        className={` rounded-[16px] py-[2px] px-[8px]  ${
                                                            po?.currentStatus?.toLowerCase() ===
                                                                "completed" && "bg-g-25  text-g-60 "
                                                        } ${
                                                            po?.currentStatus?.toLowerCase() ===
                                                                "in-transit" &&
                                                            "bg-light-yellow-status  text-[#E5993E] "
                                                        }   ${
                                                            po?.currentStatus?.toLowerCase() ===
                                                                "rejected" && "bg-r-25  text-r-55 "
                                                        }   ${
                                                            po?.currentStatus?.toLowerCase() ===
                                                                "pending" &&
                                                            "bg-light-purple-2  text-[#6562E5] "
                                                        }
                                 `}
                                                    >
                                                        <p
                                                            className={`text-sm font-rocGroteskMedium`}
                                                        >
                                                            {po?.currentStatus?.toLowerCase() ===
                                                                "rejected" &&
                                                                truncate("Cancelled", {
                                                                    length: 20,
                                                                })}
                                                            {po?.currentStatus?.toLowerCase() ===
                                                                "in-transit" &&
                                                                truncate("Pending fulfilment", {
                                                                    length: 20,
                                                                })}
                                                            {po?.currentStatus?.toLowerCase() ===
                                                                "completed" &&
                                                                truncate("Fulfilled", {
                                                                    length: 20,
                                                                })}
                                                            {po?.currentStatus?.toLowerCase() ===
                                                                "pending" &&
                                                                truncate("Pending", { length: 20 })}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <i
                                                    className="ri-more-2-fill cursor-pointer text-[#64748B] text-2xl"
                                                    onClick={() =>
                                                        navigate(`/dashboard/purchase/${po?._id}`)
                                                    }
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        {loadMorePurchaseOrder && fetchingPurchaseOrderNotRequest && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {purchaseOrderNotRequestPagination?.current ===
                            purchaseOrderNotRequestPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex-col flex  h-[100%]  justify-center my-[8%]">
                        {search ? (
                            <div className="space-y-3 w-[452px]  justify-center items-center flex flex-col  text-center">
                                <div className="p-[5px] flex items-center justify-center w-[34px] h-[34px] rounded-lg bg-slate-100 mb-2.5">
                                    <i className="ri-search-line text-2xl text-[#142837] opacity-50"></i>
                                </div>

                                <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                    Sorry, no results match your search. Refine your criteria and
                                    try again.
                                </p>
                            </div>
                        ) : (
                            <div className="space-y-3 max-w-[452px]  justify-center items-center flex flex-col  text-center">
                                <div className="p-[5px] flex items-center justify-center w-[34px] h-[34px] rounded-lg bg-slate-100 mb-2.5">
                                    <i className="ri-guide-fill text-2xl text-light-black opacity-50"></i>
                                </div>
                                <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                    Looks like there are no purchase orders at the moment. Start by
                                    creating your first purchase order to keep of your transaction
                                    and manage your procurement efficiently
                                </p>
                            </div>
                        )}
                    </div>
                );
        }
    };

    return (
        <>
            <div className="md:p-4">
                {fetchingPOStatistics ? (
                    <Loader />
                ) : (
                    <div className="mb-6 border-b border-dashed pb-4 sm:flex-row flex-col flex sm:space-x-2">
                        <AnalyticsCard
                            title={"Total Issued PO"}
                            value={Number(purchaseOrderStatistics?.totalPurchaseOrders?.count)}
                            filterDuration={`this month`}
                            percentageValue={Math.abs(
                                purchaseOrderStatistics?.totalPurchaseOrders?.percentage
                            )}
                            increase={
                                Number(purchaseOrderStatistics?.totalPurchaseOrders?.percentage) >=
                                0
                            }
                            increaseData
                            imgClassName="w-[30%] !h-fit"
                            loader={false}
                            iconTwo
                            className="!shadow-none  sm:border-b-0 border-b-[1px]  sm:border-r !border-r-slate-200 pr-[10px] !md:pr-[45px] !py-4 !md:pl-[10px] pl-1 !rounded-none"
                            titleClassName="!text-slate-950 !text-[13px]"
                            innerClassName="mb-4"
                            showImg={true}
                            // customDropdown={
                            //     <div className="relative ">
                            //         <Dropdown
                            //             handleChange={(name, value) =>
                            //                 console.log({ target: { name, value } })
                            //             }
                            //             dropdown={
                            //                 <div className="flex items-center rounded-[5px] border border-slate-200 py-1 px-2 gap-1 ">
                            //                     <div
                            //                         className={`text-slate-600 font-rocGroteskMedium text-xs inline-block`}
                            //                     >
                            //                         All Suppliers
                            //                     </div>
                            //                     <i className="ri-arrow-down-s-line text-lg text-slate-600"></i>
                            //                 </div>
                            //             }
                            //             dropdownItemsClasses="!px-0 !py-0"
                            //             dropdownClassName="!w-full !mb-10 !-mt-7"
                            //             dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100  !right-0 rounded-[6px] !max-h-fit !w-auto
                            //     `}
                            //             name="valueRange"
                            //             dropdownOptions={[
                            //                 ...values?.map((item, idx) => ({
                            //                     label: (
                            //                         <div
                            //                             key={idx}
                            //                             className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                            //                         border-b border-b-slate-100 !w-[140px] px-4 py-[10px]"
                            //                         >
                            //                             {item}

                            //                             {idx === values?.length - 1 && (
                            //                                     <i className="ri-arrow-right-s-line text-gm-50 text-xs"></i>
                            //                                 )}
                            //                         </div>
                            //                     ),
                            //                     value: item,
                            //                 })),
                            //             ]}
                            //         />
                            //     </div>
                            // }
                        />
                        <AnalyticsCard
                            title={"Fulfilled PO"}
                            value={Number(purchaseOrderStatistics?.totalFulfilled?.count)}
                            filterDuration={`this month`}
                            percentageValue={Math.abs(
                                purchaseOrderStatistics?.totalFulfilled?.percentage
                            )}
                            increase={
                                Number(purchaseOrderStatistics?.totalFulfilled?.percentage) >= 0
                            }
                            loader={false}
                            iconTwo
                            className="!shadow-none !border-y-0 !border-l-0 !md:border-r  !border-r-slate-200 pr-[10px] !md:pr-[45px] !py-4 !md:pl-[10px] pl-1 !rounded-none"
                            titleClassName="!text-slate-950"
                            showImg={true}
                            innerClassName="mb-4"
                            imgClassName="w-[30%] !h-fit"
                            increaseData
                            // customDropdown={
                            //     <div className="relative ">
                            //         <Dropdown
                            //             handleChange={(name, value) =>
                            //                 console.log({ target: { name, value } })
                            //             }
                            //             dropdown={
                            //                 <div className="flex items-center rounded-[5px] border border-slate-200 py-1 px-2 gap-1 ">
                            //                     <div
                            //                         className={`text-slate-600 font-rocGroteskMedium text-xs inline-block`}
                            //                     >
                            //                         All Suppliers
                            //                     </div>
                            //                     <i className="ri-arrow-down-s-line text-lg text-slate-600"></i>
                            //                 </div>
                            //             }
                            //             // disabled={}
                            //             dropdownItemsClasses="!px-0 !py-0"
                            //             dropdownClassName="!w-full !mb-10 !-mt-7"
                            //             dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100  !right-0 rounded-[6px] !max-h-fit !w-auto
                            //     `}
                            //             name="valueRange"
                            //             dropdownOptions={[
                            //                 ...values?.map((item, idx) => ({
                            //                     label: (
                            //                         <div
                            //                             key={idx}
                            //                             className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                            //                         border-b border-b-slate-100 !w-[140px] px-4 py-[10px]"
                            //                         >
                            //                             {item}

                            //                             {idx === values?.length - 1 && (
                            //                                     <i className="ri-arrow-right-s-line text-gm-50 text-xs"></i>
                            //                                 )}
                            //                         </div>
                            //                     ),
                            //                     value: item,
                            //                 })),
                            //             ]}
                            //         />
                            //     </div>
                            // }
                        />
                        {/* <AnalyticsCard
                title={"Invoices"}
                value={5000}
                filterDuration={`this month`}
                percentageValue={Math.abs(20)}
                increase={true}
                loader={false}
                iconTwo
                increaseData
                className="!shadow-none !border-y-0 !border-l-0 !border-r !border-r-slate-200 !pr-[45px] !py-4 !pl-[10px] !rounded-none"
                titleClassName="!text-slate-950"
                showImg={true}
                innerClassName="mb-4"
                customDropdown={
                    <div className="relative ">
                        <Dropdown
                            handleChange={(name, value) =>
                                console.log({ target: { name, value } })
                            }
                            dropdown={
                                <div className="flex items-center rounded-[5px] border border-slate-200 py-1 px-2 gap-1 ">
                                    <div
                                        className={`text-slate-600 font-rocGroteskMedium text-xs inline-block`}
                                    >
                                        All Suppliers
                                    </div>
                                    <i className="ri-arrow-down-s-line text-lg text-slate-600"></i>
                                </div>
                            }
                            // disabled={}
                            dropdownItemsClasses="!px-0 !py-0"
                            dropdownClassName="!w-full !mb-10 !-mt-7"
                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100  !right-0 rounded-[6px] !max-h-fit !w-auto
                    `}
                            name="valueRange"
                            dropdownOptions={[
                                ...values?.map((item, idx) => ({
                                    label: (
                                        <div
                                            key={idx}
                                            className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                        border-b border-b-slate-100 !w-[140px] px-4 py-[10px]"
                                        >
                                            {item}

                                            {idx === values?.length - 1 && (
                                                    <i className="ri-arrow-right-s-line text-gm-50 text-xs"></i>
                                                )}
                                        </div>
                                    ),
                                    value: item,
                                })),
                            ]}
                        />
                    </div>
                }
            />
            <AnalyticsCard
                title={"Re-order levels"}
                value={1000}
                filterDuration={`this month`}
                percentageValue={Math.abs(10)}
                increase={true}
                loader={false}
                iconTwo
                increaseData
                className="!shadow-none !border-none !rounded-none"
                titleClassName="!text-slate-950"
                showImg={true}
                innerClassName="mb-4"
                customDropdown={
                    <div className="relative ">
                        <Dropdown
                            handleChange={(name, value) =>
                                console.log({ target: { name, value } })
                            }
                            dropdown={
                                <div className="flex items-center rounded-[5px] border border-slate-200 py-1 px-2 gap-1 ">
                                    <div
                                        className={`text-slate-600 font-rocGroteskMedium text-xs inline-block`}
                                    >
                                        All Suppliers
                                    </div>
                                    <i className="ri-arrow-down-s-line text-lg text-slate-600"></i>
                                </div>
                            }
                            dropdownItemsClasses="!px-0 !py-0"
                            dropdownClassName="!w-full !mb-10 !-mt-7"
                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100  !right-0 rounded-[6px] !max-h-fit !w-auto
                    `}
                            name="valueRange"
                            dropdownOptions={[
                                ...values?.map((item, idx) => ({
                                    label: (
                                        <div
                                            key={idx}
                                            className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                        border-b border-b-slate-100 !w-[140px] px-4 py-[10px]"
                                        >
                                            {item}

                                            {idx === values?.length - 1 && (
                                                    <i className="ri-arrow-right-s-line text-gm-50 text-xs"></i>
                                                )}
                                        </div>
                                    ),
                                    value: item,
                                })),
                            ]}
                        />
                    </div>
                }
            /> */}
                    </div>
                )}
                <div className="pt-4">
                    <TabsContainer
                        tabs={tabTwo}
                        activeTab={activeTabTwo}
                        setActiveTab={setActiveTabTwo}
                        component={displayActiveTabTwo()}
                        className="!px-0"
                        itemClassName="!pb-2"
                        borderLineClase={"!text-[#F1F5F9] w-[20%] max-sm:w-full !h-fit  mb-4"}
                        showButton={
                            <>
                                <div className="max-lg:hidden flex w-[830px]  items-center space-x-3">
                                    <TextInput
                                        name={"search"}
                                        type={"text"}
                                        value={search}
                                        inputPlaceholder={"Search supplier, outlet, ID..."}
                                        inputClassName={"!h-[32px] pl-[0px] !bg-white text-sm "}
                                        containerClassname={""}
                                        leftIcon={
                                            <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                        }
                                        inputContainerClassname={
                                            "!rounded-[4px] !h-[40px]  !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                                        }
                                        onChange={handleSearch}
                                        onInput={handleDebouncedChange}
                                    />
                                    <Button
                                        btnText={"Settings"}
                                        type={"button"}
                                        btnClassname="!py-2 !px-4 !w-fit max-sm:w-full !whitespace-nowrap !bg-white !border !border-[#F4F5F7]"
                                        icon={<i className="ri-settings-3-line text-base"></i>}
                                        onClick={() => navigate("/dashboard/purchase/settings")}
                                    />
                                    {/* <Button
                                    btnText={"Initiate return"}
                                    type={"button"}
                                    btnClassname="!py-2 !px-4 !w-fit max-sm:w-full !whitespace-nowrap !bg-slate-100"
                                    icon={<i className="ri-arrow-turn-back-fill text-base"></i>}
                                    onClick={() => navigate("/dashboard/purchase")}
                                /> */}

                                    <Button
                                        btnText={"Create purchase request"}
                                        type={"button"}
                                        btnClassname="!py-2 !px-4 !w-fit !whitespace-nowrap max-sm:w-full"
                                        onClick={() =>
                                            navigate("/dashboard/purchase/create-purchase-request")
                                        }
                                    />
                                </div>
                                <div className=" max-lg:flex hidden  space-x-3 items-center">
                                    <TextInput
                                        name={"search"}
                                        type={"text"}
                                        value={search}
                                        inputPlaceholder={"Search supplier, outlet, ID..."}
                                        inputClassName={
                                            "!h-[32px] pl-[0px] !bg-white text-sm  !w-full"
                                        }
                                        leftIcon={
                                            <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                        }
                                        inputContainerClassname={
                                            "!rounded-[4px] !h-[40px]  !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                                        }
                                        onChange={handleSearch}
                                        onInput={handleDebouncedChange}
                                    />
                                    {/* </div> */}

                                    <Button
                                        // btnText={"Settings"}
                                        type={"button"}
                                        btnClassname="!py-2 !px-4 !w-fit max-sm:w-full !whitespace-nowrap !bg-white !border !border-[#F4F5F7]"
                                        icon={<i className="ri-settings-3-line text-base"></i>}
                                        onClick={() => navigate("/dashboard/purchase/settings")}
                                    />
                                </div>
                            </>
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default PurchaseOrder;
