import React, { useState, useEffect } from "react";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions, vendorActions } from "redux/Ldb/actions";
import Loader from "components/common/Loader";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import DateInput from "components/common/DateInput";
import { shipmentActions } from "redux/Ldb/actions/shipmentActions";
import { debounce, truncate } from "lodash";
import { displayFileIcon, getCurrencyFromCurrencyCode } from "helpers";
import CustomTable from "components/common/CustomTable";
import VendorAvatar from "components/common/VendorAvatar";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";

const Shipment = ({
    stockData,
    attachment,
    uploadingAttachment,
    handleUpload,
    deleteAttachment,
    setProductViabilityDetailsTwo,
    productViabilityDetailsTwo,
    checkedItems,
    setCheckedItems,
    setStockShipmentData,
    stockShipmentData,
}: any): JSX.Element => {
    const dispatch = useAppDispatch();
    const [inventory, setInventory] = useState<any>();
    const [warehouses, setWarehouses] = useState<any>();
    const [suppliers, setSuppliers] = useState<any>();
    const [shipment, setShipment] = useState<any>();
    const [debounceWarehouseSearch, setDebounceWarehouseSearch] = useState<string>();
    const [debounceShipmentSearch, setDebounceShipmentSearch] = useState<string>();
    const { fetchedOnboardedVendorsSuccess } = useAppSelector((state) => state?.vendor);
    const { fetchingShipments, fetchShipmentsSuccess } = useAppSelector((state) => state?.shipment);
    const { fetchedWarehousesSuccess, fetchingWarehouses } = useAppSelector(
        (state) => state?.inventory
    );
    // const [productName, setProductName] = useState<string>("");

    const handleStockData = (e) => {
        const { name, value } = e.target;
        setStockShipmentData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    useEffect(() => {
        if (Boolean(fetchShipmentsSuccess)) {
            setShipment(fetchShipmentsSuccess?.data);
        }
    }, [fetchShipmentsSuccess]);

    useEffect(() => {
        dispatch(shipmentActions?.getShipments(40, debounceShipmentSearch));
    }, [dispatch, debounceShipmentSearch]);

    useEffect(() => {
        if (Boolean(fetchedWarehousesSuccess)) {
            setWarehouses(fetchedWarehousesSuccess?.warehouses.data);
        }
    }, [fetchedWarehousesSuccess]);

    useEffect(() => {
        if (Boolean(fetchedOnboardedVendorsSuccess)) {
            setSuppliers(fetchedOnboardedVendorsSuccess?.vendors);
        }
    }, [fetchedOnboardedVendorsSuccess]);

    useEffect(() => {
        dispatch(vendorActions.getOnboardedVendors());
    }, [dispatch]);

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouses(20, debounceWarehouseSearch));
    }, [dispatch, debounceWarehouseSearch]);

    useEffect(() => {
        const initialDetails = checkedItems?.map((item) => ({
            inventoryId: item.inventoryId,
            manufacturingDate: "",
            expiryDate: "",
        }));
        setProductViabilityDetailsTwo(initialDetails);
    }, [inventory]);
    const handleQuantityChange = (e, idx) => {
        const { value, name } = e.target;
        const updatedCheckedItems = [...checkedItems];
        updatedCheckedItems[idx] = {
            ...updatedCheckedItems[idx],
            [name]: value,
        };
        setCheckedItems(updatedCheckedItems);
    };

    const handleWarehouseChange = (itemId, warehouseId) => {
        setCheckedItems((prevItems) => {
            return prevItems?.map((item) => {
                if (item._id === itemId) {
                    return {
                        ...item,
                        warehouseId,
                    };
                }

                return item;
            });
        });
    };

    const handleDebouncedCheck = debounce((e, productName) => {
        const { value } = e.target;
        const check = checkedItems?.find((item) => item?.batchId === value);
        if (value && check?.batchId) {
            toast.custom((t) => (
                <CustomToast
                    t={t}
                    message={`Batch Id for ${productName} must be unique `}
                    type="error"
                />
            ));
        } else if (value && !check?.batchId) {
            validateBatchNo(value, productName);
        }
    }, 3000);
    const validateBatchNo = async (batchId, productName) => {
        try {
            const res = await dispatch(inventoryActions.validateBatchId(batchId));
            if (!res?.success) {
                toast.custom((t) => <CustomToast t={t} message={res.message} type="error" />);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleManufacturingDateChange = (itemId, value) => {
        const updatedDetails = productViabilityDetailsTwo?.map((detail) => {
            if (detail.inventoryId === itemId) {
                return { ...detail, manufacturingDate: value };
            }
            return detail;
        });
        setProductViabilityDetailsTwo(updatedDetails);
    };

    const handleBestBeforeChange = (itemId, value) => {
        const updatedDetails = productViabilityDetailsTwo?.map((detail) => {
            if (detail.inventoryId === itemId) {
                return { ...detail, bestBefore: value };
            }
            return detail;
        });
        setProductViabilityDetailsTwo(updatedDetails);
    };

    const handleExpiryDateChange = (itemId, id, value) => {
        const updatedDetails = productViabilityDetailsTwo?.map((detail) => {
            if (detail.inventoryId === itemId) {
                return { ...detail, expiryDate: value };
            }
            return detail;
        });
        setProductViabilityDetailsTwo(updatedDetails);
    };

    const handleRemovalTimeChange = (itemId, value) => {
        const updatedDetails = productViabilityDetailsTwo?.map((detail) => {
            if (detail.inventoryId === itemId) {
                return { ...detail, removalTime: value };
            }
            return detail;
        });
        setProductViabilityDetailsTwo(updatedDetails);
    };
    useEffect(() => {
        setCheckedItems(
            inventory?.[0]?.purchaseOrder?.itemsOrServices?.map((val, idx) => {
                return {
                    ...val,
                    vendorId: inventory?.[0]?.purchaseOrder?.vendor?._id,
                    warehouseId: inventory?.[0]?.destinationId,
                    quantity: inventory?.[0]?.productDetails?.[idx]?.quantity,
                };
            }) || []
        );
    }, [inventory]);

    useEffect(() => {
        if (stockShipmentData?.shipmentId) {
            setInventory(
                shipment?.filter(
                    (shipmentItem) => shipmentItem._id === stockShipmentData?.shipmentId
                )
            );
        }
    }, [stockShipmentData?.shipmentId, shipment]);

    const tableHeader = [
        { title: "Product name", widthClass: "w-[25%] !text-slate-700" },
        { title: "Batch Id", widthClass: "w-[18%] !text-slate-700" },
        { title: "Vendor/Supplier", widthClass: "w-[18%] !text-slate-700" },
        { title: "Unit Cost", widthClass: "w-[10%] !text-slate-700" },
        { title: "Quantity", widthClass: "w-[18%] !text-slate-700" },
        { title: "Warehouse", widthClass: "w-[18%] !text-slate-700" },
    ];

    const tableBody = inventory?.[0]?.purchaseOrder?.itemsOrServices?.map((item, idx) => {
        const handleQtyChange = (action, item) => {
            const updatedCheckedItems = [...checkedItems];
            const index = updatedCheckedItems?.findIndex(
                (checkedItem) => checkedItem._id === item._id
            );

            if (index !== -1) {
                const currentItem = updatedCheckedItems[index];
                let newQty = parseInt(currentItem.quantity);

                if (action === "increment") {
                    newQty += 1;
                } else if (action === "decrement") {
                    if (newQty > 1) {
                        newQty -= 1;
                    }
                }

                updatedCheckedItems[index] = {
                    ...currentItem,
                    quantity: newQty,
                };

                setCheckedItems(updatedCheckedItems);
            }
        };
        return [
            {
                content: (
                    <div className={`w-full px-3 border-slate-100 text-sm text-slate-700 `}>
                        <div
                            className={` w-full flex space-x-2 items-center border-slate-100 text-sm font-rocGroteskMedium`}
                        >
                            <VendorAvatar
                                size={32}
                                imageSrc={item?.inventory?.productImageDetails?.productAvatar}
                                name={item?.name}
                                containerClassname="!bg-none border !rounded"
                            />
                            <div>
                                <p className="text-slate-900 ">{item?.inventory?.sku}</p>
                                <p className="text-slate-500 ">{item?.name}</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={` w-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50`}
                    >
                        <div className="text-center">
                            <TextInput
                                name="batchId"
                                type="text"
                                value={checkedItems[idx]?.batchId}
                                onChange={(e) => {
                                    handleQuantityChange(e, idx);
                                }}
                                onInput={(e) => handleDebouncedCheck(e, item?.name)}
                                inputClassName={"!text-center "}
                                inputContainerClassname={"!border-0 !rounded-none !bg-transparent"}
                            />
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={` w-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50`}
                    >
                        <p>{inventory?.[0]?.purchaseOrder?.vendor?.profile?.businessName}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={` flex border-slate-100 text-sm font-rocGroteskMedium text-gm-50`}
                    >
                        <div className="text-center">
                            <p>
                                {getCurrencyFromCurrencyCode(item?.price?.currency)}
                                {item?.price?.amount % 1 === 0
                                    ? item?.price?.amount
                                    : item?.price?.amount.toFixed(2)}
                            </p>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div className={`w-full px-3  border-slate-100 text-sm text-slate-700 `}>
                        <div
                            className={` flex justify-center w-full items-center border-slate-100 text-sm font-rocGroteskMedium text-gm-50`}
                        >
                            <span
                                className="bg-[#166534] px-[5px]  rounded-l text-center py-1"
                                onClick={() => handleQtyChange("decrement", item)}
                            >
                                <i className="ri-subtract-line text-white text-sm font-rocGroteskMedium"></i>
                            </span>
                            <div className="text-center">
                                <TextInput
                                    name="quantity"
                                    type="number"
                                    value={
                                        checkedItems.find(
                                            (checkedItem) => checkedItem._id === item._id
                                        )?.quantity || ""
                                    }
                                    onChange={(e) => handleQuantityChange(e, idx)}
                                    inputClassName={"!text-center "}
                                    inputContainerClassname={
                                        "!rounded-none text-sm text-gun-metal px-2 py-1 uppercase !bg-[#F0FDF4] !min-w-[84px] !w-[84px]  !text-center !px-0 !py-0 !w-auto !h-[30px] !border-0"
                                    }
                                    required
                                />
                            </div>

                            <span
                                className="bg-[#166534] px-[5px]  rounded-r text-center py-1"
                                onClick={() => handleQtyChange("increment", item)}
                            >
                                <i className="ri-add-line text-white text-sm"></i>
                            </span>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },

            {
                content: (
                    <div className={`w-full pl-0 border-slate-100 text-sm text-slate-700 `}>
                        <SelectInput
                            value={
                                checkedItems.find((checkedItem) => checkedItem._id === item._id)
                                    ?.warehouseId || ""
                            }
                            name={`warehouseId-${item._id}`}
                            inputPlaceholder={"Select Warehouse"}
                            handleChange={(name, value) => {
                                handleWarehouseChange(item._id, value);
                            }}
                            handleInputChange={debounce((evt) => {
                                setDebounceWarehouseSearch(evt.target.value);
                            }, 800)}
                            isRequired={true}
                            className="!border-0 !rounded-none"
                            inputContainerClassName="!mb-0"
                            searchLoading={fetchingWarehouses}
                            hideSelectedIcon={false}
                            clearValue={true}
                            dropdownOptions={
                                warehouses
                                    ? warehouses?.map((item) => ({
                                          label: item?.warehouseName,
                                          value: item?._id,
                                      }))
                                    : []
                            }
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
        ];
    });

    const tableProductHeader = [
        { title: "Product name", widthClass: "w-[30%] !py-2 !text-slate-700" },
        { title: "Manufacture date", widthClass: "w-[36%] !py-2 !text-slate-700" },
        { title: "Expiration Time", widthClass: "w-[36%] !text-slate-700" },
    ];
    const tableProductBody = checkedItems?.map((item, idx) => {
        const productDetails = productViabilityDetailsTwo.find(
            (detail) => detail.inventoryId === item.inventoryId
        );

        return [
            {
                content: (
                    <div
                        className={` w-full flex space-x-2 items-center border-slate-100 text-sm font-rocGroteskMedium`}
                    >
                        <VendorAvatar
                            size={32}
                            imageSrc={item?.inventory?.productImageDetails?.productAvatar}
                            name={item?.name}
                            containerClassname="!bg-none border !rounded"
                        />
                        <div>
                            <p className="text-slate-900 ">{item?.inventory?.sku}</p>
                            <p className="text-slate-500 ">{item?.name}</p>
                        </div>
                    </div>
                ),
                widthClass: "!border-t",
            },
            {
                content: (
                    <div
                        key={idx}
                        className={`pr-3  w-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50`}
                    >
                        <TextInput
                            name={`manufacturingDate-${item._id}`}
                            type={"date"}
                            value={productDetails?.manufacturingDate || ""}
                            onChange={(e) =>
                                handleManufacturingDateChange(item.inventoryId, e.target.value)
                            }
                            inputContainerClassname="!border-none"
                            placeholder={"Manufacturing date"}
                            required={true}
                        />
                    </div>
                ),

                tableDataDivClass: "!pl-0",
            },

            // {
            //     content: (
            //         <div
            //             className={` pr-3  w-full  border-slate-100 text-sm font-rocGroteskMedium text-gm-50`}
            //         >
            //             <TextInput
            //                 name={`bestBefore-${item._id}`}
            //                 type={"date"}
            //                 value={productDetails?.bestBefore || ""}
            //                 onChange={(e) =>
            //                     handleBestBeforeChange(item.inventoryId, e.target.value)
            //                 }
            //                 placeholder={"Best before"}
            //                 inputContainerClassname="!border-none"
            //                 required={true}
            //             />
            //         </div>
            //     ),
            //     tableDataDivClass: "!pl-0",
            // },
            {
                content: (
                    <div
                        className={`pr-3 flex w-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50`}
                    >
                        <div className=" w-full">
                            <TextInput
                                name={`-${item._id}`}
                                type={"date"}
                                value={productDetails?.expiryDate || ""}
                                onChange={(e) =>
                                    handleExpiryDateChange(item.inventoryId, idx, e.target.value)
                                }
                                inputContainerClassname="!border-none"
                                placeholder={"Expiry date"}
                                required={true}
                            />
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            // {
            //     content: (
            //         <div
            //             className={`pr-3 flex w-full  border-slate-100 text-sm font-rocGroteskMedium text-gm-50`}
            //         >
            //             <div className=" w-full">
            //                 <TextInput
            //                     name={`removalTime-${item._id}`}
            //                     type={"date"}
            //                     value={productDetails?.removalTime || ""}
            //                     onChange={(e) =>
            //                         handleRemovalTimeChange(item.inventoryId, e.target.value)
            //                     }
            //                     inputContainerClassname="!border-none"
            //                     placeholder={"Removal time"}
            //                     required={true}
            //                 />
            //             </div>
            //         </div>
            //     ),
            //     tableDataDivClass: "!pl-0",
            // },
        ];
    });

    return (
        <div className="w-full">
            <div className="">
                <div className="">
                    <p className="pb-4 font-rocGroteskMedium text-gun-metal text-base">
                        Select Shipment
                    </p>
                </div>
                <div className="md:w-[65%] mb-4">
                    <SelectInput
                        value={stockShipmentData?.shipmentId}
                        name="shipmentId"
                        placeholder={"Select Shipment"}
                        handleChange={(name, value) => {
                            handleStockData({ target: { name, value } });
                        }}
                        searchLoading={fetchingShipments}
                        isRequired={true}
                        handleInputChange={debounce((evt) => {
                            setDebounceShipmentSearch(evt.target.value);
                        }, 800)}
                        dropdownOptions={shipment?.map((item) => ({
                            label: (
                                <div>
                                    <p>
                                        {item?.shipmentType?.toLowerCase() === "freight"
                                            ? item?.freight?.goodsDetails
                                                  ?.map((good) => good.description)
                                                  .join(", ")
                                            : item?.supplier?.ItemDetails?.map(
                                                  (item) => item?.description
                                              ).join(", ")}
                                    </p>
                                    <p>{item?.taId}</p>
                                </div>
                            ),
                            value: item?._id,
                        }))}
                        className={`${
                            stockData?.shipmentId && "!border-g-60 !border-[0.5px] !border-b-[1px]"
                        }`}
                    />
                </div>
            </div>

            <div>
                <div className="mb-6">
                    <p className="pt-2 pb-2 font-rocGroteskMedium text-gun-metal text-base">
                        Stock details
                    </p>
                    <div className="mt-4">
                        <CustomTable
                            tableBody={tableBody}
                            tableHeader={tableHeader}
                            isAllSelectable={false}
                            isScrollable={false}
                            isCellBordered={true}
                            headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                            headerContainerClass="!bg-slate-50"
                        />
                    </div>
                </div>

                <div className="space-y-6">
                    <div>
                        <label className="text-base font-rocGroteskMedium">Product lifecycle</label>
                        <div className="mt-4">
                            <CustomTable
                                tableBody={tableProductBody}
                                tableHeader={tableProductHeader}
                                isAllSelectable={false}
                                isCellBordered={true}
                                headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                headerContainerClass="!bg-slate-50"
                                isScrollable={false}
                            />
                        </div>
                    </div>

                    <div className="mt-8 md:w-[65%]">
                        <label className="text-base font-rocGroteskMedium">Attachments</label>
                        <input
                            type="file"
                            className="hidden"
                            id="attachment"
                            accept="image/*, .pdf, .xlsx"
                            disabled={uploadingAttachment}
                            onChange={(e) => {
                                if (e.target.files?.[0]) {
                                    handleUpload(
                                        e.target.files?.[0]?.name as string,
                                        e.target.files?.[0]?.size as number,
                                        "shipment"
                                        // e.target.files?.[0] as File,
                                    );
                                }
                            }}
                        />
                        <div className="mt-4 ">
                            {attachment?.map((item, idx) => {
                                return (
                                    <div className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4">
                                        <div className="flex items-center gap-2">
                                            {displayFileIcon(getFileTypeFromUrl(item?.path), false)}
                                            <div className="font-rocGroteskMedium">
                                                <p className="text-sm">{item?.filename}</p>
                                                <p className="text-xs text-slate-500"></p>
                                            </div>
                                        </div>
                                        <i
                                            className="ri-delete-bin-line text-xl text-r-50 cursor-pointer"
                                            onClick={() => deleteAttachment(idx)}
                                        ></i>
                                    </div>
                                );
                            })}

                            <label
                                className={`flex items-center gap-4 py-3 px-6 rounded border border-dashed border-slate-200  ${
                                    !uploadingAttachment && "cursor-pointer"
                                }`}
                                htmlFor="attachment"
                            >
                                <i className="ri-file-add-line text-2xl text-carribean-green"></i>
                                {uploadingAttachment ? (
                                    <div className="">
                                        <Loader size={4} />
                                    </div>
                                ) : (
                                    <p className="text-sm underline text-carribean-green font-rocGroteskMedium">
                                        Click to upload document
                                    </p>
                                )}
                            </label>
                        </div>
                    </div>

                    <div className=" my-8 md:w-[65%]">
                        <div className="mt-8">
                            <label className="text-base font-rocGroteskMedium mb-6">
                                Place this batch in quarantine?
                            </label>
                            <div className="flex gap-[52px] font-rocGroteskMedium text-base items-center">
                                <div
                                    className="flex items-center gap-4"
                                    onClick={() =>
                                        setStockShipmentData((prev) => ({
                                            ...prev,
                                            quarantine: true,
                                        }))
                                    }
                                >
                                    {stockShipmentData?.quarantine ? (
                                        <i
                                            className={`ri-radio-button-fill text-xl cursor-pointer ${
                                                stockShipmentData?.quarantine && "text-y-10"
                                            }`}
                                        ></i>
                                    ) : (
                                        <i className="ri-circle-line text-n-50 text-xl"></i>
                                    )}
                                    Yes
                                </div>
                                <div
                                    className="flex items-center gap-4"
                                    onClick={() =>
                                        setStockShipmentData((prev) => ({
                                            ...prev,
                                            quarantine: false,
                                        }))
                                    }
                                >
                                    {!stockShipmentData?.quarantine ? (
                                        <i
                                            className={`ri-radio-button-fill text-xl cursor-pointer ${
                                                !stockShipmentData?.quarantine && "text-y-10"
                                            }`}
                                        ></i>
                                    ) : (
                                        <i className="ri-circle-line text-n-50 text-xl"></i>
                                    )}
                                    No
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="flex flex-col gap-4 mt-8">
                                <details open={false} className="mt-4">
                                    <summary className="flex items-center justify-between text-base font-rocGroteskMedium border-b border-slate-200 cursor-pointer">
                                        <h6 className={`text-base font-rocGroteskMedium `}>
                                            Additional info
                                        </h6>
                                        <div>
                                            <i
                                                className={`cursor-pointer text-slate-500 ri-arrow-down-s-line`}
                                            ></i>
                                        </div>
                                    </summary>

                                    <div className="mt-4">
                                        <TextareaInput
                                            name={"notes"}
                                            value={stockShipmentData?.notes || ""}
                                            onChange={handleStockData}
                                            rows={5}
                                            placeholder={"Add note"}
                                            required={false}
                                            inputContainerClassname={`${
                                                stockShipmentData?.notes &&
                                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                            }`}
                                        />
                                    </div>
                                </details>

                                {/* <div className="mt-4">
                                    <label className="text-base font-rocGroteskMedium">
                                        Additional info
                                    </label>
                                    <div className="mt-4">
                                        <TextareaInput
                                            name={"notes"}
                                            value={stockShipmentData?.notes || ""}
                                            onChange={handleStockData}
                                            rows={5}
                                            placeholder={"Add note"}
                                            required={false}
                                            inputContainerClassname={`${
                                                stockShipmentData?.notes &&
                                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                            }`}
                                        />
                                    </div>
                                </div> */}

                                <details open={false} className="mt-4">
                                    <summary className="flex items-center justify-between text-base font-rocGroteskMedium border-b border-slate-200 cursor-pointer">
                                        <h6 className={`text-base font-rocGroteskMedium `}>
                                            Inspection note
                                        </h6>
                                        <div>
                                            <i
                                                className={`cursor-pointer text-slate-500 ri-arrow-down-s-line`}
                                            ></i>
                                        </div>
                                    </summary>

                                    <div className="mt-4">
                                        <TextareaInput
                                            name={"inspectionNote"}
                                            value={stockShipmentData?.inspectionNote || ""}
                                            onChange={handleStockData}
                                            rows={5}
                                            placeholder={"Add note"}
                                            required={false}
                                            inputContainerClassname={`${
                                                stockShipmentData?.inspectionNote &&
                                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                            }`}
                                        />
                                    </div>
                                </details>

                                {/* <div className="mt-4">
                                    <label className="text-base font-rocGroteskMedium">
                                        Inspection note
                                    </label>
                                    <div className="mt-4">
                                        <TextareaInput
                                            name={"inspectionNote"}
                                            value={stockShipmentData?.inspectionNote || ""}
                                            onChange={handleStockData}
                                            rows={5}
                                            placeholder={"Add note"}
                                            required={false}
                                            inputContainerClassname={`${
                                                stockShipmentData?.inspectionNote &&
                                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                            }`}
                                        />
                                    </div>
                                </div> */}

                                <details open={false} className="mt-4">
                                    <summary className="flex items-center justify-between text-base font-rocGroteskMedium border-b border-slate-200 cursor-pointer">
                                        <h6 className={`text-base font-rocGroteskMedium `}>
                                            Condition
                                        </h6>
                                        <div>
                                            <i
                                                className={`cursor-pointer text-slate-500 ri-arrow-down-s-line`}
                                            ></i>
                                        </div>
                                    </summary>

                                    <div className="mt-4">
                                        <TextareaInput
                                            name={"conditionNote"}
                                            value={stockShipmentData?.conditionNote || ""}
                                            onChange={handleStockData}
                                            rows={5}
                                            placeholder={"Add note"}
                                            required={false}
                                            inputContainerClassname={`${
                                                stockShipmentData?.conditionNote &&
                                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                            }`}
                                        />
                                    </div>
                                </details>

                                {/* <div className="mt-4">
                                    <label className="text-base font-rocGroteskMedium">
                                        Condition
                                    </label>
                                    <div className="mt-4">
                                        <TextareaInput
                                            name={"conditionNote"}
                                            value={stockShipmentData?.conditionNote || ""}
                                            onChange={handleStockData}
                                            rows={5}
                                            placeholder={"Add note"}
                                            required={false}
                                            inputContainerClassname={`${
                                                stockShipmentData?.conditionNote &&
                                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                            }`}
                                        />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Shipment;
