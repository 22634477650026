import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { dashboardActions } from "redux/Ldb/actions";

const useGetDashboardAnalytics = (analyticsDuration?: string) => {
    const dispatch = useAppDispatch();
    const [analyticsData, setAnalyticsData] = useState<{ [key: string]: any }>([]);
    const [analyticsStartAndEndDate, setAnalyticsStartAndEndDate] = useState<string[]>([]);

    const {
        fetchingDashboardAnalytics,
        fetchedDashboardAnalyticsSuccess,
        fetchedDashboardAnalyticsFailure,
    } = useAppSelector((state) => state.dashboard);

    useEffect(() => {
        if (Boolean(fetchedDashboardAnalyticsSuccess)) {
            setAnalyticsData(fetchedDashboardAnalyticsSuccess);
        }
    }, [fetchedDashboardAnalyticsSuccess]);

    

    useEffect(() => {
        dispatch(
            dashboardActions?.getDashboardAnalytics(
                analyticsDuration,
                analyticsStartAndEndDate[0],
                analyticsStartAndEndDate[1]
            )
        );
    }, [dispatch, analyticsDuration, analyticsStartAndEndDate]);

    return {
        data: analyticsData,
        isFetching: fetchingDashboardAnalytics,
        error: fetchedDashboardAnalyticsFailure,
        setAnalyticsStartAndEndDate,
    };
};

export default useGetDashboardAnalytics;
