import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { workforceActions } from "../../redux/Ldb/actions/workforceActions";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";

const useGetTaskList = (selectedTeammate?: string) => {
    const dispatch = useAppDispatch();

    const { fetchingTaskList, fetchTaskListSuccess, fetchTaskListFailure } = useAppSelector(
        (state) => state.workforce
    );
    const [taskList, setTaskList] = useState<{ [key: string]: any }>({});
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [taskStatus, setTaskStatus] = useState<string>("");
    const [taskType, setTaskType] = useState<string>("");

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            {
                current: pagination.currentPage,
                number_of_pages: pagination.noOfPages,
            },
            14
        );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setDebouncedSearch(e.target.value);
    }, 800);

    const handleTaskStatusChange = (value: string) => {
        setUpdatedPage(1);
        setTaskStatus(value);
    };

    const handleClearTaskStatusFilter = () => {
        setUpdatedPage(1);
        setTaskStatus("");
    };

    const handleTaskTypeChange = (value: string) => {
        setUpdatedPage(1);
        setTaskType(value);
    };

    const handleClearTaskTypeFilter = () => {
        setUpdatedPage(1);
        setTaskType("");
    };

    useEffect(() => {
        dispatch(
            workforceActions.listTasks(
                updatedPage,
                debouncedSearch,
                taskStatus,
                selectedTeammate,
                taskType
            )
        );
    }, [dispatch, debouncedSearch, updatedPage, taskStatus, selectedTeammate, taskType]);

    useEffect(() => {
        if (Boolean(fetchTaskListSuccess)) {
            setTaskList((prev) => {
                return updatedPage === 1
                    ? { ...fetchTaskListSuccess?.tasks }
                    : {
                          ...prev,
                          activeTasks: uniqBy(
                              [
                                  ...(prev?.activeTasks || []),
                                  ...fetchTaskListSuccess?.tasks?.activeTasks,
                              ],
                              "_id"
                          ),
                          completedTasks: uniqBy(
                              [
                                  ...(prev?.completedTasks || []),
                                  ...fetchTaskListSuccess?.tasks?.completedTasks,
                              ],
                              "_id"
                          ),
                          pendingTasks: uniqBy(
                              [
                                  ...(prev?.pendingTasks || []),
                                  ...fetchTaskListSuccess?.tasks?.pendingTasks,
                              ],
                              "_id"
                          ),
                      };
            });
            setPagination({
                currentPage: fetchTaskListSuccess.pagination?.current,
                noOfPages: fetchTaskListSuccess.pagination?.number_of_pages,
            });

            setLoadMore(false);
        }
    }, [fetchTaskListSuccess]);

    return {
        isFetching: fetchingTaskList,
        error: fetchTaskListFailure,
        data: taskList,
        setTaskList,
        search,
        handleSearch,
        handleDebouncedChange,
        handleTaskStatusChange,
        handleClearTaskStatusFilter,
        handleTaskTypeChange,
        handleClearTaskTypeFilter,
        taskStatus,
        taskType,
        isLoadingMore,
        pagination,
        setUpdatedPage,
        setLoadMore,
    };
};

export default useGetTaskList;
