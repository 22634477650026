import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";

const useGetBrandAnalytics = (
    generalBrand?: string,
    generalDuration?: string,
    startAndEndDate?: string[]
) => {
    const dispatch = useAppDispatch();
    const [brandAnalytics, setBrandAnalytics] = useState<{ [key: string]: any }>();
    const [dateRange, setDateRange] = useState<{ label: string; value: string }>({
        label: "",
        value: "yearly",
    });

    const { fetchingBrandAnalytics, fetchBrandAnalyticsSuccess, fetchBrandAnalyticsFailure } =
        useAppSelector((state) => state.multibrand);

    const handleDurationChange = (value: { label: string; value: string }) => {
        setDateRange(value);
    };

    useEffect(() => {
        if (Boolean(fetchBrandAnalyticsSuccess)) {
            setBrandAnalytics(fetchBrandAnalyticsSuccess?.metrics);
        }
    }, [fetchBrandAnalyticsSuccess]);

    useEffect(() => {
        dispatch(
            multibrandActions?.getBrandAnalytics(
                generalDuration === "custom"
                    ? "custom"
                    : dateRange?.value !== generalDuration
                    ? dateRange?.value
                    : generalDuration,
                generalBrand,
                startAndEndDate[0],
                startAndEndDate[1]
            )
        );
    }, [dispatch, dateRange?.value, generalBrand, generalDuration, startAndEndDate]);

    return {
        data: brandAnalytics,
        isFetching: fetchingBrandAnalytics,
        error: fetchBrandAnalyticsFailure,
        handleDurationChange,
        dateRange,
    };
};

export default useGetBrandAnalytics;
