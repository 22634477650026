import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const multibrandService = {
    listBrands,
    listNavBrands,
    createBrand,
    updateBrand,
    exportBrandList,
    getSingleBrand,
    getAssignedBrands,
    getBrandToken,
    getProfitAnalytics,
    getCostSummary,
    getChannelSalesAnalytics,
    getIncidentsAnalytics,
    getRevenueGraph,
    getRevenueTable,
    getBrandAnalytics,
    getBrandComparism,
    getBrandInventory,
    getBrandStockDetails,
    getBrandWarehouseStockBreakdown,
    getBrandWarehouses,
    exportBrandInventoryList,
    getInventoryInStockValue,
    getInventoryTotalStockValue,
    getInventoryOutOfStockValue,
    getInventoryRestockValue,
    exportBrandInventoryOutOfStock,
    exportBrandInventoryTotalStock,
    exportBrandInventoryInStock,
};

async function listBrands(page?: string | number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/list?page=${page}${search ? `&search=${search}` : ""}`,
        requestOptions
    );
    return res;
}

async function listNavBrands(page?: string | number, search?: string, token?: string) {
    let requestOptions: Record<string, any> = {
        method: "GET",
    };

    if (Boolean(token)) {
        requestOptions = {
            ...requestOptions,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
    }

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/all-brands/list?page=${page}${
            search ? `&search=${search}` : ""
        }`,
        requestOptions
    );
    return res;
}

async function createBrand(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/create/single/brand`,
        requestOptions
    );
    return res;
}

async function updateBrand(
    data: Record<string, any> | Array<Record<string, any>>,
    brandId: string
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/update/single/brand/${brandId}`,
        requestOptions
    );
    return res;
}

async function exportBrandList() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/export/list`,
        requestOptions
    );
    return res;
}

async function getSingleBrand(brandId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/single/${brandId}`,
        requestOptions
    );

    return res;
}

async function getAssignedBrands(page?: string | number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/assigned/brand/list?page=${page}${
            search ? `&search=${search}` : ""
        }`,
        requestOptions
    );

    return res;
}

async function getBrandToken(brandId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/auth/${brandId}`,
        requestOptions
    );

    return res;
}

async function getProfitAnalytics(
    dateRange?: string,
    brandId?: string,
    startDate?: string,
    endDate?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/dashboard/profit-analytics?${
            dateRange ? `dateRange=${dateRange}` : ""
        }${brandId ? `&brands=${brandId}` : ""}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );

    return res;
}

async function getCostSummary(
    dateRange?: string,
    brandId?: string,
    costType?: string,
    startDate?: string,
    endDate?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/dashboard/cost-summary-analytics?${
            dateRange ? `dateRange=${dateRange}` : ""
        }${brandId ? `&brands=${brandId}` : ""}${costType ? `&costType=${costType}` : ""}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );

    return res;
}

async function getChannelSalesAnalytics(
    dateRange?: string,
    brandId?: string,
    startDate?: string,
    endDate?: string
) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/dashboard/channel-sales-analytics?${
            dateRange ? `dateRange=${dateRange}` : ""
        }${brandId ? `&brands=${brandId}` : ""}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );

    return res;
}

async function getIncidentsAnalytics(
    dateRange?: string,
    brandId?: string,
    startDate?: string,
    endDate?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/dashboard/incidents-analytics?${
            dateRange ? `dateRange=${dateRange}` : ""
        }${brandId ? `&brands=${brandId}` : ""}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );

    return res;
}

async function getRevenueGraph(
    dateRange?: string,
    brandId?: string,
    startDate?: string,
    endDate?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/dashboard/graph/revenue-metrics?${
            dateRange ? `dateRange=${dateRange}` : ""
        }${brandId ? `&brands=${brandId}` : ""}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );

    return res;
}

async function getRevenueTable(
    dateRange?: string,
    brandId?: string,
    startDate?: string,
    endDate?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/dashboard/graph-table/revenue-metrics?${
            dateRange ? `dateRange=${dateRange}` : ""
        }${brandId ? `&brands=${brandId}` : ""}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );

    return res;
}

async function getBrandAnalytics(
    dateRange?: string,
    brandId?: string,
    startDate?: string,
    endDate?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/dashboard/brand-analytics?${
            dateRange ? `dateRange=${dateRange}` : ""
        }${brandId ? `&brands=${brandId}` : ""}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );

    return res;
}

async function getBrandComparism(brandOneId: string, brandTwoId: string, dateRange?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/brand/dashboard/brand-comparison-analytics?${
            dateRange ? `dateRange=${dateRange}` : ""
        }${brandOneId && brandTwoId ? `&brands=${brandOneId},${brandTwoId}` : ""}`,
        requestOptions
    );

    // const res = await authService.apiGate(
    //     `${config.API_URL}/api/v1/ldb/brand/dashboard/brand-comparison-analytics?dateRange=custom&startDate=2020-01-01&endDate=2024-12-30`,
    //     requestOptions
    // );

    return res;
}

async function getBrandInventory(page?: string | number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/brand/inventory/listing?page=${page}${
            search ? `&search=${search}` : ""
        }`,
        requestOptions
    );

    return res;
}

async function getBrandStockDetails() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/brand/inventory/stock-Level-details`,
        requestOptions
    );

    return res;
}

async function getBrandWarehouseStockBreakdown(
    brandId?: string,
    warehouseId?: string,
    channels?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/brand/inventory/warehouses/stock-breakdown?dateRange=yearly${
            brandId ? `&brands=${brandId}` : ""
        }${warehouseId ? `&warehouseIds=${warehouseId}` : ""}${
            channels ? `&channels=${channels}` : ""
        }`,
        requestOptions
    );

    return res;
}

async function getBrandWarehouses(search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/brand/inventory/warehouses?${search ? `&search=${search}` : ""}`,
        requestOptions
    );

    return res;
}

async function exportBrandInventoryList() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/brand/inventory/export/listing`,
        requestOptions
    );

    return res;
}

async function getInventoryInStockValue(
    warehouseId?: string,
    channel?: string,
    limit?: number,
    page?: number,
    startDate?: string,
    endDate?: string,
    search?: string,
    sortParams?: any
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        warehouseId: warehouseId as string,
        search: search as string,
        page: page as number,
        channel: channel as string,
        startDate: startDate as string,
        endDate: endDate as string,
    });

    const key = typeof sortParams === "object" && sortParams !== null && Object.keys(sortParams)[0];
    const value = key ? sortParams[key] : null;
    const sortParamsFormat = key && value !== undefined ? `${key}=${value}` : "";

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/brand/inventory/in-stock-value?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }${key ? "&" + sortParamsFormat?.toString() : ""}`,
        requestOptions
    );

    return res;
}

async function getInventoryTotalStockValue(
    warehouseId?: string,
    channel?: string,
    limit?: number,
    page?: number,
    startDate?: string,
    endDate?: string,
    search?: string,
    sortParams?: any
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        warehouseId: warehouseId as string,
        search: search as string,
        page: page as number,
        channel: channel as string,
        startDate: startDate as string,
        endDate: endDate as string,
    });

    const key = typeof sortParams === "object" && sortParams !== null && Object.keys(sortParams)[0];
    const value = key ? sortParams[key] : null;
    const sortParamsFormat = key && value !== undefined ? `${key}=${value}` : "";

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/brand/inventory/total-stock-value?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }${key ? "&" + sortParamsFormat?.toString() : ""}`,
        requestOptions
    );

    return res;
}

async function getInventoryOutOfStockValue(
    warehouseId?: string,
    channel?: string,
    limit?: number,
    page?: number,
    startDate?: string,
    endDate?: string,
    search?: string,
    sortParams?: any
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        warehouseId: warehouseId as string,
        search: search as string,
        page: page as number,
        channel: channel as string,
        startDate: startDate as string,
        endDate: endDate as string,
    });

    const key = typeof sortParams === "object" && sortParams !== null && Object.keys(sortParams)[0];
    const value = key ? sortParams[key] : null;
    const sortParamsFormat = key && value !== undefined ? `${key}=${value}` : "";

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/brand/inventory/out-stock-value?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }${key ? "&" + sortParamsFormat?.toString() : ""}`,
        requestOptions
    );

    return res;
}

async function getInventoryRestockValue(
    warehouseId?: string,
    channel?: string,
    limit?: number,
    page?: number,
    startDate?: string,
    endDate?: string,
    search?: string,
    sortParams?: any
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        warehouseId: warehouseId as string,
        search: search as string,
        page: page as number,
        channel: channel as string,
        startDate: startDate as string,
        endDate: endDate as string,
    });

    const key = typeof sortParams === "object" && sortParams !== null && Object.keys(sortParams)[0];
    const value = key ? sortParams[key] : null;
    const sortParamsFormat = key && value !== undefined ? `${key}=${value}` : "";

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/brand/inventory/restock-value?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }${key ? "&" + sortParamsFormat?.toString() : ""}`,
        requestOptions
    );

    return res;
}

async function exportBrandInventoryOutOfStock() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/brand/inventory/export/out-stock-value`,
        requestOptions
    );

    return res;
}

async function exportBrandInventoryTotalStock() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/brand/inventory/export/total-stock-value`,
        requestOptions
    );

    return res;
}

async function exportBrandInventoryInStock() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/brand/inventory/export/in-stock-value`,
        requestOptions
    );

    return res;
}
