
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { productionActions } from "redux/Ldb/actions";

const useGetPendingWorkOrderQC = () => {
    const dispatch = useAppDispatch();

    const { workOrderId } = useParams();
    const [pendingQCList, setPendingQCList] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { fetchingPendingQC, fetchPendingQCSuccess } = useAppSelector(
        (state) => state.production
    );

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    useEffect(() => {
        dispatch(productionActions?.fetchPendingQC("pending", workOrderId, 10, updatedPage));
    }, [dispatch, updatedPage]);


    useEffect(() => {
        if (Boolean(fetchPendingQCSuccess)) {
            setPendingQCList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchPendingQCSuccess?.tasks], "_id")
                    : uniqBy([...prev, ...fetchPendingQCSuccess?.tasks], "_id")
            );
            setPagination({
                currentPage: fetchPendingQCSuccess.pagination?.current,
                noOfPages: fetchPendingQCSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
            dispatch(productionActions.resetFetchPendingQC());
        }
    }, [dispatch, fetchPendingQCSuccess, updatedPage]);

    return {
        data: pendingQCList,
        isLoading: fetchingPendingQC,
        isLoadingMore,
        pagination,
    };
};

export default useGetPendingWorkOrderQC;
