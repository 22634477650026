import { useEffect, useMemo, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { cloneDeep } from "lodash";
import moment from "moment";

const useGetSalesBreakdown = (warehouseId?: string, inventoryId?: string, dateRange?: string) => {
    const dispatch = useAppDispatch();
    const [dates, setDates] = useState<{ [key: string]: any }>({
        startDate: "",
        endDate: "",
    });
    const [resetDateRange, setResetDateRange] = useState<string>("all");

    const { fetchingSalesBreakdown, fetchedSalesBreakdownSuccess, fetchedSalesBreakdownFailure } =
        useAppSelector((state) => state.inventory);

    // const [breakdownData, setBreakdownData] = useState<{ [key: string]: any }>({});
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const breakdownData = useMemo(() => {
        if (!fetchedSalesBreakdownSuccess) {
            return { channelData: [], totalSales: 0 };
        }
        return {
            channelData: fetchedSalesBreakdownSuccess.channels,
            totalStock: fetchedSalesBreakdownSuccess.totalStock,
        };
    }, [fetchedSalesBreakdownSuccess]);

    useEffect(() => {
        if (typeof dateRange === "number") {
            setDates({
                startDate: moment(dateRange, "YYYY").startOf("year")?.format("YYYY-MM-DD"),
                endDate: moment(dateRange, "YYYY").endOf("year")?.format("YYYY-MM-DD"),
            });
        } else {
            setDates({
                startDate: "",
                endDate: "",
            });
        }
        setResetDateRange(typeof dateRange === "number" ? "custom" : dateRange);
    }, [dateRange]);

    useEffect(() => {
        dispatch(
            inventoryActions.fetchSalesBreakdown(
                warehouseId,
                inventoryId,
                resetDateRange,
                dates?.startDate,
                dates?.endDate
            )
        );
    }, [dispatch, warehouseId, inventoryId, resetDateRange, dates]);

    return {
        isFetching: fetchingSalesBreakdown,
        error: fetchedSalesBreakdownFailure,
        data: cloneDeep(breakdownData),
        pagination,
        setPagination,
    };
};

export default useGetSalesBreakdown;
