import React, { useEffect, useRef, useState } from "react";
import SnapshotsAnalytics from "./SnapshotsAnalytics";
import Button from "./../../../../../../components/common/Button";
import { useNavigate } from "react-router-dom";
import TextInput from "./../../../../../../components/common/InputField/TextInput";
import { debounce } from "lodash";
import TabsContainer from "./../../../../../../components/common/TabsContainer";
import Timeline from "./Timeline";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import Dropdown from "components/common/Dropdown";
import moment from "moment";
import useGetInventorySnapshotList from "hooks/inventoryHooks/useGetInventorySnapshotList";
import useGetAllWarehouses from "hooks/useGetAllWarehouses";
import { Tooltip } from "react-tooltip";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import * as XLSX from "xlsx";
import { yearDropdown } from "helpers/yearDropdown";

const Snapshots = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const calendarRef = useRef(null);

    const tabs = ["Timeline"];
    const [showCustomDate, setShowCustomDate] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState(0);
    const [search, setSearch] = useState<string>("");
    const [customValue, customOnChange] = useState("");
    const [warehouse, setWarehouse] = useState<string>("");
    const [durationFilter, setDurationFilter] = useState<string>("Last month");
    const [groupDate, setGroupDate] = useState<string>("");
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [warehouseSearch, setWarehouseSearch] = useState<string>("");
    const [years, setYears] = useState<number[]>([]);

    useEffect(() => {
        setYears(yearDropdown());
    }, []);

    const {
        data: snapshotsList,
        snapshotStats,
        setDebouncedSearch,
        debouncedSearch,
        setWarehouseId,
        warehouseId,
        setDates,
        dates,
        isLoading,
        isLoadingMore,
        pagination,
    } = useGetInventorySnapshotList();
    const { data: warehouseList, isFetching, handleDebouncedChange } = useGetAllWarehouses();
    const [selected, setSelected] = useState<any>([]);

    const handleDebouncedSearch = debounce((e) => {
        setDebouncedSearch(e?.target?.value);
    }, 800);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                // setShowCustomDate(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [calendarRef]);

    useEffect(() => {
        if (customValue[0] !== customValue[1])
            setDates({
                startDate: moment(customValue[0])?.format("YYYY-MM-DD"),
                endDate: moment(customValue[1])?.format("YYYY-MM-DD"),
            });
    }, [customValue]);

    const fetchExportData = async (groupDate) => {
        setExportLoading(true);

        try {
            const res = await dispatch(inventoryActions?.fetchGroupSnapshotExport(groupDate));
            if (res) {
                handleExport(res);
            }
        } catch (err) {
            setGroupDate("");
            setExportLoading(false);
        }
    };

    const handleExport = (data) => {
        const workSheetData = data?.inventorySnapShots?.map((product) => {
            return {
                Product: product?.products?.productName,
                SKU: product?.products?.sku,
                "Sales Channels": product?.products?.channels?.map((channel) => channel).join(", "),
                Categories: product?.products?.inventoryCategory,
                "End-of-month stock level":
                    product?.products?.lastStockDetails?.stockLevel !== undefined
                        ? `${formatMoney()?.format(
                              product?.products?.lastStockDetails?.stockLevel
                          )} ${product?.products?.lastStockDetails?.unitOfMeasurement}`
                        : "",
                "End-of-month SKU cost":
                    product?.products?.lastSkuCost?.amount !== undefined
                        ? `${getCurrencyFromCurrencyCode(
                              product?.products?.lastSkuCost?.currency
                          )}${formatMoney()?.format(product?.products?.lastSkuCost?.amount)}`
                        : "",
                Warehouse: product?.products?.warehouseName,
            };
        });
        const ws = XLSX.utils.json_to_sheet(workSheetData);
        const summary = XLSX.utils.json_to_sheet([
            {
                "Ending stock Level":
                    data?.groupStats?.totalInventoryStock !== undefined
                        ? formatMoney()?.format(data?.groupStats?.totalInventoryStock)
                        : "",
                "Average inventory per warehouse":
                    data?.groupStats?.averageInventoryPerWarehouse !== undefined &&
                    formatMoney()?.format(data?.groupStats?.averageInventoryPerWarehouse),
                "Ending inventory value":
                    data?.groupStats?.totalInventoryValue !== undefined
                        ? `${getCurrencyFromCurrencyCode(
                              data?.groupStats?.currency
                          )}${formatMoney()?.format(data?.groupStats?.totalInventoryValue)}`
                        : "",
            },
        ]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, `${groupDate}_snapshots`);
        XLSX.utils.book_append_sheet(wb, summary, `${groupDate}_summary`);
        XLSX.writeFile(wb, `inventory_snapshot_${groupDate}.xlsx`);
        setGroupDate("");
        setExportLoading(false);
        dispatch(inventoryActions?.resetFetchGroupSnapshotExport());
    };

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <Timeline
                        data={snapshotsList}
                        isLoading={isLoading}
                        isLoadingMore={isLoadingMore}
                        pagination={pagination}
                        dates={dates}
                        search={debouncedSearch}
                        warehouseId={warehouseId}
                        selectedSnapshot={selected}
                        setSelectedSnapshot={setSelected}
                        groupDate={groupDate}
                        setGroupDate={setGroupDate}
                        handleExport={fetchExportData}
                        exportLoading={exportLoading}
                    />
                );

            default:
                return (
                    <Timeline
                        data={snapshotsList}
                        isLoading={isLoading}
                        isLoadingMore={isLoadingMore}
                        pagination={pagination}
                        dates={dates}
                        search={debouncedSearch}
                        warehouseId={warehouseId}
                        selectedSnapshot={selected}
                        setSelectedSnapshot={setSelected}
                        groupDate={groupDate}
                        setGroupDate={setGroupDate}
                        handleExport={fetchExportData}
                        exportLoading={exportLoading}
                    />
                );
        }
    };

    return (
        <div>
            <SnapshotsAnalytics
                snapshotStats={snapshotStats}
                isFetchingAnalytics={isLoading}
                data={snapshotsList}
                dates={dates}
            />

            <div className="relative mt-4">
                <TabsContainer
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={(idx) => {
                        setActiveTab(idx);
                        navigate(`/dashboard/production?currTab=${idx}`, { replace: true });
                    }}
                    component={displayActiveTab()}
                    className="!px-0"
                    showButtonClassName
                    itemClassName="!pb-2 !whitespace-nowrap !border-y-10"
                    borderLineClase={"!text-slate-100 w-[30%] max-lg:w-full mb-6"}
                    showButton={
                        <div className="flex gap-2 w-fit">
                            <TextInput
                                name="search"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e?.target?.value)}
                                onInput={handleDebouncedSearch}
                                inputPlaceholder={"Search products"}
                                inputClassName={
                                    "!h-[38px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                                }
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-200 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px]  !h-[40px] !w-[300px] !pl-[20px] !bg-white !border !border-slate-200"
                                }
                            />

                            <div className="relative">
                                <Dropdown
                                    handleChange={(name, value) => {
                                        setDurationFilter(value);
                                    }}
                                    dropdown={
                                        <div className="bg-white flex items-center max-sm:justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                            <i className="ri-calendar-line text-sm"></i>
                                            <div
                                                className={`${
                                                    dates?.startDate && dates?.endDate
                                                        ? "w-[150px]"
                                                        : "w-[80px]"
                                                } text-g-75 font-rocGroteskMedium text-[13px]`}
                                            >
                                                {typeof durationFilter === "number"
                                                    ? durationFilter
                                                    : dates?.startDate && dates?.endDate
                                                    ? `${moment(dates.startDate).format(
                                                          "MMM DD, YY"
                                                      )} - ${moment(dates.endDate).format(
                                                          "MMM DD, YY"
                                                      )}`
                                                    : durationFilter}
                                            </div>
                                            <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                                        </div>
                                    }
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownClassName="!w-full !mb-10 !-mt-7"
                                    dropdownContainerClasses={`z-[1400] shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[200px] !w-auto`}
                                    name="valueRange"
                                    dropdownOptions={[
                                        {
                                            label: "Last month",
                                            value: "Last month",
                                            action: () => {
                                                setDates({
                                                    startDate: "",
                                                    endDate: "",
                                                });
                                                customOnChange("");
                                            },
                                        },
                                        ...(years && Array.isArray(years)
                                            ? years.map((item) => ({
                                                  label:
                                                      item === new Date().getFullYear()
                                                          ? `This year (${item})`
                                                          : item?.toString(),
                                                  value: item,
                                                  action: () => {
                                                      setDates({
                                                          startDate: moment(item, "YYYY")
                                                              .startOf("year")
                                                              ?.format("YYYY-MM-DD"),
                                                          endDate: moment(item, "YYYY")
                                                              .endOf("year")
                                                              ?.format("YYYY-MM-DD"),
                                                      });
                                                      customOnChange("");
                                                  },
                                              }))
                                            : []),
                                        {
                                            label: "Custom",
                                            value: "Custom",
                                            action: () => {
                                                setShowCustomDate(true);
                                            },
                                        },
                                    ]}
                                />

                                <div className="relative">
                                    {showCustomDate && (
                                        <div
                                            ref={calendarRef}
                                            className="w-[630px] z-[14000] absolute  right-0 top-0 px-[28px] pt-8 pb-8 bg-white
                                                    rounded-2xl shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_32px_rgba(0,0,0,0.06)]"
                                        >
                                            <CustomCalendar
                                                showDoubleView={true}
                                                selectRange={true}
                                                value={customValue}
                                                onChange={customOnChange}
                                            />
                                            <div className="flex justify-end">
                                                <Button
                                                    btnText={"Close"}
                                                    btnClassname={"!w-fit !py-2"}
                                                    onClick={() => setShowCustomDate(false)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <Dropdown
                                name="filter"
                                dropdownTriggerClassName={" "}
                                handleChange={(name, value) => {
                                    setWarehouseId(value?.split("-")[0]);
                                    setWarehouse(value);
                                }}
                                dropdown={
                                    <div className="bg-white flex items-center max-sm:justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                        <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                            {warehouse?.split("-")[1]
                                                ? warehouse?.split("-")[1]
                                                : "All warehouses"}
                                        </span>
                                        {warehouse?.split("-")[1] && (
                                            <i
                                                onClick={() => setWarehouse("")}
                                                className="ri-close-circle-line text-slate-500"
                                            ></i>
                                        )}

                                        <div className="flex items-center space-x-2">
                                            <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                        </div>
                                    </div>
                                }
                                search={
                                    <div>
                                        <TextInput
                                            value={warehouseSearch}
                                            name={"search"}
                                            type={"text"}
                                            required={false}
                                            onChange={(e) => setWarehouseSearch(e?.target?.value)}
                                            onInput={handleDebouncedChange}
                                            inputPlaceholder="Search warehouse"
                                            containerClassname=""
                                            // inputContainerClassname={"!border-none !rounded-none "}
                                            inputClassName="w-full"
                                        />
                                    </div>
                                }
                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                dropdownContainerClasses={`shadow-cardShadow-4 z-[1400]  !mt-0  !w-[159px] max-sm:!w-full  rounded !max-h-[200px] !p-0 !top-[105%]`}
                                wholeContainerClass={`max-sm:!w-full`}
                                dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100 "
                                dropdownOptions={warehouseList?.map((warehouse) => ({
                                    label: warehouse?.warehouseName,
                                    value: warehouse?._id + "-" + warehouse?.warehouseName,
                                }))}
                            />
                            {/* <Button
                                btnText={"Settings"}
                                type={"button"}
                                btnClassname="!text-[13px] !bg-white !py-2 !px-4 !w-fit !whitespace-nowrap border border-slate-200"
                                // onClick={() =>}
                                icon={<i className="ri-settings-2-line"></i>}
                            /> */}

                            <div className="">
                                <div className="" id={`export`}>
                                    <Button
                                        btnText={`Export  ${
                                            groupDate !== "" ? `selected (1)` : ""
                                        }`}
                                        type={"button"}
                                        btnClassname="!py-2 !px-4 !w-fit !whitespace-nowrap max-sm:w-full"
                                        icon={
                                            <i className="ri-arrow-go-forward-fill text-slate-800"></i>
                                        }
                                        disabled={groupDate === ""}
                                        isLoading={exportLoading}
                                        onClick={() =>
                                            !exportLoading &&
                                            groupDate &&
                                            fetchExportData(groupDate)
                                        }
                                    />
                                </div>
                                {groupDate === "" && (
                                    <Tooltip
                                        anchorSelect={`#export`}
                                        place={"bottom"}
                                        content={"Select the snapshot (1) you want to export."}
                                        style={{
                                            maxWidth: "195px",
                                            zIndex: 9999,
                                            background: "#000",
                                            borderRadius: "6px",
                                            border: "1px solid #000",
                                            color: "#fff",
                                            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default Snapshots;
