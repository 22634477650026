import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import EmptyStateComp from "components/common/EmptyStateComp";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import PageFrame from "components/layout/PageFrame";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useGetAllWarehouses from "hooks/useGetAllWarehouses";
import { truncate } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

const Warehouse = () => {
    const dispatch = useAppDispatch();
    const [deleteWarehouseShow, setDeleteWarehouseShow] = useState(false);
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [warehouseDetails, setWarehouseDetails] = useState<{ [key: string]: any }>({});
    const [viewWarehouse, setViewWarehouse] = useState(false);

    const navigate = useNavigate();
    const {
        isFetching,
        data,
        isDeletingWarehouse,
        deleteWarehouseFunc,
        isLoadingMore,
        pagination,
    } = useGetAllWarehouses(warehouseId, setDeleteWarehouseShow);

    const { fetchingSingleWarehouseList, fetchedSingleWarehouseListSuccess } = useAppSelector(
        (state) => state.inventory
    );

    useEffect(() => {
        if (Boolean(fetchedSingleWarehouseListSuccess)) {
            setWarehouseDetails(fetchedSingleWarehouseListSuccess?.warehouse);
        }
    }, [fetchedSingleWarehouseListSuccess]);

    const fetchWarehouse = (id) => {
        dispatch(inventoryActions.fetchSingleWarehouseList(id));
    };

    const tableHeader = [
        { title: "Product name", widthClass: "w-[70%] !px-3" },
        { title: "Quantity ", widthClass: "w-[30%] !px-3", titleClass: "!justify-end" },
    ];

    const tableBody = warehouseDetails?.products?.map((product, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`!py-2 !px-3 font-rocGroteskMedium border-slate-200 text-sm text-g-75 `}
                >
                    <div className={"flex items-center gap-3"}>
                        {product?.inventory?.productImageDetails?.productAvatar ? (
                            <div className="w-[29px] h-[29px]">
                                <img
                                    src={product?.inventory?.productImageDetails?.productAvatar}
                                    alt=""
                                    className="max-h-[29px] max-w-[29px]"
                                />
                            </div>
                        ) : (
                            <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>
                        )}
                        <p>{product?.inventory?.productName}</p>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 !pl-3 !pr-6 font-rocGroteskMedium border-slate-200 text-sm text-slate-950 !flex !justify-end w-full`}
                >
                    <p>
                        {`${product?.totalStockLevel?.toLocaleString() ?? ""} ${
                            product?.totalStockLevel &&
                            product?.inventory?.productStockDetails?.unitOfMeasurement
                                ? product?.inventory?.productStockDetails?.unitOfMeasurement
                                : ""
                        }`}
                    </p>
                </div>
            ),
        },
    ]);

    return (
        <PageFrame isLoading={isFetching && data?.length === 0}>
            {data?.length > 0 ? (
                <>
                    <div className="grid grid-cols-4 gap-4 max-lg:grid-cols-2 max-sm:grid-cols-1">
                        {data?.map((item, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className={`p-4 border border-[#E2E8F080] rounded-md shadow-[0px_8px_8px_-4px_#10182808] cursor-pointer`}
                                    onClick={() =>
                                        navigate(`/dashboard/inventory/warehouse/${item._id}`)
                                    }
                                >
                                    <div className="flex items-center justify-between mb-4">
                                        <div className="flex items-center space-x-3">
                                            <div className="w-10 h-10 rounded bg-[#F3E8FF] flex items-center justify-center">
                                                <i className="ri-store-2-line text-[#9333EA] text-[22px]"></i>
                                            </div>
                                            {item?.incidents?.[0]?.incidentType && (
                                                <div className="border border-[#FF3D1D] py-[2px] px-2 rounded-[3px]">
                                                    <p className="text-[12px] font-rocGroteskMedium text-[#FF3D1D]">
                                                        {item?.incidents?.[0]?.incidentType
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            item?.incidents?.[0]?.incidentType.slice(
                                                                1
                                                            )}{" "}
                                                        detected
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        <div onClick={(e) => e.stopPropagation()}>
                                            <Dropdown
                                                dropdown={
                                                    <i className="ri-more-2-fill text-slate-500 text-base"></i>
                                                }
                                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 left-[-140px] rounded !max-h-fit
                        `}
                                                dropdownOptions={[
                                                    {
                                                        label: "View details",
                                                        value: "",
                                                        labelClassName: "!text-gm-50",
                                                        action: () => {
                                                            navigate(
                                                                `/dashboard/inventory/warehouse/${item._id}`
                                                            );
                                                        },
                                                    },
                                                    {
                                                        label: "Edit details",
                                                        value: "",
                                                        labelClassName: "!text-gm-50",
                                                        action: () => {
                                                            navigate(
                                                                `/dashboard/inventory/edit-warehouse/${item._id}`
                                                            );
                                                        },
                                                    },

                                                    {
                                                        label: "Delete warehouse",
                                                        value: "delete-warehouse",
                                                        labelClassName: "!text-gm-50",
                                                        action: () => {
                                                            setDeleteWarehouseShow(true);
                                                            setWarehouseId(item._id);
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="block text-g-75 text-sm font-rocGroteskMedium mb-[2px]">
                                            {truncate(item?.warehouseName, { length: 25 })}
                                        </span>
                                        <span className="block text-slate-500 text-sm font-rocGroteskMedium">
                                            {truncate(item?.address?.address, { length: 25 })}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {isLoadingMore && (
                        <div className="w-full flex justify-center my-3">
                            <Loader size={4} />
                        </div>
                    )}

                    {pagination?.currentPage === pagination?.noOfPages && (
                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </>
            ) : (
                <EmptyStateComp
                    title="No warehouses found"
                    description="There are currently no warehouses set up in your system. Start by adding a new warehouse to manage your inventory."
                    icon={<i className="ri-building-line text-slate-500 text-[40px]"></i>}
                    hasButton
                    btnText="Add new warehouse"
                    btnAction={() => {
                        navigate("/dashboard/inventory/add-warehouse");
                    }}
                />
            )}

            <ModalContainer
                open={deleteWarehouseShow}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                closeModal={() => {
                    setDeleteWarehouseShow(false);
                }}
            >
                <div className="bg-white py-7 px-6  shadow-cardShadow relative rounded-[8px]">
                    <div className="border-[2px] h-[50px] bg-[#FEF2F2] w-[50px] flex items-center justify-center rounded-full border-[#DC2626]">
                        <i className="ri-delete-bin-line text-[#DC2626] text-[26px]"></i>
                    </div>
                    <div className="mb-5 mt-4">
                        <h1 className="text-lg mb-2  font-rocGroteskMedium">Delete warehouse?</h1>
                        <p className="text-slate-500 font-rocGroteskMedium text-base">
                            Are you sure you want to delete this warehouse? Please make sure all
                            products are transferred first before proceeding.
                        </p>
                    </div>
                    <div className="flex justify-end items-center ">
                        <div className="flex items-center !whitespace-nowrap space-x-2 ">
                            <Button
                                btnText={"No, cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !w-fit !text-gm-50"}
                                disabled={isDeletingWarehouse}
                                onClick={() => setDeleteWarehouseShow(false)}
                            />
                            <Button
                                btnClassname={"!py-3 !w-fit"}
                                onClick={deleteWarehouseFunc}
                                btnText={"Delete warehouse"}
                                type={"button"}
                                isLoading={isDeletingWarehouse}
                                disabled={isDeletingWarehouse}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            {/* <ModalContainer
                open={viewWarehouse}
                modalClassName={"p-6 !rounded-[8px]"}
                tailwindClassName="w-[651px]"
                showCloseIcon={false}
                closeModal={() => {
                    setViewWarehouse(false);
                    setWarehouseDetails({});
                }}
            >
                {fetchingSingleWarehouseList ? (
                    <Loader size={8} />
                ) : (
                    !fetchingSingleWarehouseList && (
                        <div className="bg-white !p-6 rounded-[12px] max-h-[70vh] overflow-y-scroll">
                            
                            <div className="font-rocGroteskMedium flex justify-between border-b border-b-slate-200 pb-[14px]">
                                <div className="flex gap-4 items-start">
                                    <div className="flex items-center space-x-3">
                                        <div className="w-10 h-10 rounded bg-[#F3E8FF] flex items-center justify-center">
                                            <i className="ri-store-2-line text-[#9333EA] text-[22px]"></i>
                                        </div>
                                    </div>

                                    <div className="">
                                        <p className="text-[#333333] text-xl">
                                            {warehouseDetails?.warehouseName}
                                        </p>
                                        <p className="text-sm text-slate-500">
                                            {warehouseDetails?.address?.address}
                                        </p>
                                        {warehouseDetails?.channel !== "Synth" && (
                                            <div className="bg-[rgba(255,185,2,0.09)] rounded-[4px] w-fit px-2 py-1">
                                                <span className="text-[#FD700B] text-xs pr-2">
                                                    You cannot edit this warehouse name
                                                </span>

                                                <i className="ri-information-fill text-[#FD700B]"></i>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex items-start gap-4">
                                    <Button
                                        btnText={"Edit details"}
                                        type={"button"}
                                        btnClassname="!p-2.5 !text-xs !text-p-55  !w-[120px] !bg-p-25"
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/inventory/edit-warehouse/${warehouseDetails?._id}`
                                            )
                                        }
                                        icon={<i className="ri-edit-fill"></i>}
                                    />
                                    <span
                                        className="material-icons text-[#3B4C58] text-[20px] cursor-pointer font-medium"
                                        onClick={() => {
                                            setViewWarehouse(false);
                                            setWarehouseDetails({});
                                        }}
                                    >
                                        close
                                    </span>
                                </div>
                            </div>
                            <div className="grid grid-cols-2  font-rocGroteskMedium py-[14px] gap-y-3.5 border-b border-slate-200">
                                <div className="text-sm">
                                    <p className=" text-[#333333] ">Contact person's full name</p>
                                    <p className="text-slate-500">
                                        {warehouseDetails?.contactPersonName ?? "N/A"}
                                    </p>
                                </div>
                                <div className="text-sm">
                                    <p className=" text-[#333333] ">
                                        Contact person's email address
                                    </p>
                                    <p className="text-slate-500">
                                        {warehouseDetails?.contactPersonEmail ?? "N/A"}{" "}
                                    </p>
                                </div>
                                <div className="text-sm">
                                    <p className=" text-[#333333] ">Notes</p>
                                    <p className="text-slate-500">
                                        {warehouseDetails?.additionalNotes ?? "No notes available"}{" "}
                                    </p>
                                </div>
                            </div>
                            {warehouseDetails?.products?.length >= 1 && (
                                <div className=" font-rocGroteskMedium">
                                    <p className="text-[#333333] text-lg my-5">
                                        Products in warehouse
                                    </p>
                                    <div className="mt-3">
                                        <CustomTable
                                            tableBody={tableBody}
                                            tableHeader={tableHeader}
                                            isScrollable={false}
                                            isScrolled={isScrolled}
                                            isCellBordered={false}
                                            isCellSelectable={false}
                                            isAllSelectable={false}
                                            headerContainerClass="!bg-slate-50 !text-slate-500 !text-xs"
                                            headerItemClass="font-rocGroteskMedium "
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                )}
            </ModalContainer> */}
        </PageFrame>
    );
};

export default Warehouse;
