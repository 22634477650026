import { multibrandTypes } from "../types";

const initialState = {
    fetchingBrandList: false,
    fetchBrandListSuccess: null,
    fetchBrandListFailure: null,
    fetchingBrandListSearch: false,
    fetchBrandListSearchSuccess: null,
    fetchBrandListSearchFailure: null,
    creatingBrand: false,
    createBrandSuccess: null,
    createBrandFailure: null,
    updatingBrand: false,
    updateBrandSuccess: null,
    updateBrandFailure: null,
    exportingBrandList: false,
    exportBrandListSuccess: null,
    exportBrandListFailure: null,
    fetchingSingleBrand: false,
    fetchSingleBrandSuccess: null,
    fetchSingleBrandFailure: null,
    fetchingAssignedBrands: false,
    fetchAssignedBrandsSuccess: null,
    fetchAssignedBrandsFailure: null,
    fetchingAssignedBrandsSearch: false,
    fetchAssignedBrandsSearchSuccess: null,
    fetchAssignedBrandsSearchFailure: null,
    fetchingBrandToken: false,
    fetchBrandTokenSuccess: null,
    fetchBrandTokenFailure: null,
    fetchingProfitAnalytics: false,
    fetchProfitAnalyticsSuccess: null,
    fetchProfitAnalyticsFailure: null,
    fetchingCostSummary: false,
    fetchCostSummarySuccess: null,
    fetchCostSummaryFailure: null,
    fetchingChannelSalesAnalytics: false,
    fetchChannelSalesAnalyticsSuccess: null,
    fetchChannelSalesAnalyticsFailure: null,
    fetchingIncidentsAnalytics: false,
    fetchIncidentsAnalyticsSuccess: null,
    fetchIncidentsAnalyticsFailure: null,
    fetchingRevenueGraph: false,
    fetchRevenueGraphSuccess: null,
    fetchRevenueGraphFailure: null,
    fetchingRevenueTable: false,
    fetchRevenueTableSuccess: null,
    fetchRevenueTableFailure: null,
    fetchingBrandAnalytics: false,
    fetchBrandAnalyticsSuccess: null,
    fetchBrandAnalyticsFailure: null,
    fetchingBrandComparism: false,
    fetchBrandComparismSuccess: null,
    fetchBrandComparismFailure: null,
    fetchingBrandInventory: false,
    fetchBrandInventorySuccess: null,
    fetchBrandInventoryFailure: null,
    fetchingBrandStockDetails: false,
    fetchBrandStockDetailsSuccess: null,
    fetchBrandStockDetailsFailure: null,
    fetchingBrandWarehouseStockBreakdown: false,
    fetchBrandWarehouseStockBreakdownSuccess: null,
    fetchBrandWarehouseStockBreakdownFailure: null,
    fetchingBrandWarehouseList: false,
    fetchBrandWarehouseListSuccess: null,
    fetchBrandWarehouseListFailure: null,
    exportingBrandInventoryList: false,
    exportBrandInventoryListSuccess: null,
    exportBrandInventoryListFailure: null,
    fetchingInventoryInStock: false,
    fetchInventoryInStockSuccess: null,
    fetchInventoryInStockFailure: null,
    fetchingInventoryTotalStock: false,
    fetchInventoryTotalStockSuccess: null,
    fetchInventoryTotalStockFailure: null,
    fetchingInventoryOutOfStock: false,
    fetchInventoryOutOfStockSuccess: null,
    fetchInventoryOutOfStockFailure: null,
    fetchingInventoryRestock: false,
    fetchInventoryRestockSuccess: null,
    fetchInventoryRestockFailure: null,
    exportingBrandInventoryOutOfStock: false,
    exportBrandInventoryOutOfStockSuccess: null,
    exportBrandInventoryOutOfStockFailure: null,
    exportingBrandInventoryTotalStock: false,
    exportBrandInventoryTotalStockSuccess: null,
    exportBrandInventoryTotalStockFailure: null,
    exportingBrandInventoryInStock: false,
    exportBrandInventoryInStockSuccess: null,
    exportBrandInventoryInStockFailure: null,
    fetchingNavBrandList: false,
    fetchNavBrandListSuccess: null,
    fetchNavBrandListFailure: null,
};

export const multibrand = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case multibrandTypes.FETCH_NAV_BRAND_LIST_REQUEST:
            return {
                ...state,
                fetchingNavBrandList: true,
                fetchNavBrandListSuccess: null,
                fetchNavBrandListFailure: null,
            };
        case multibrandTypes.FETCH_NAV_BRAND_LIST_SUCCESS:
            return {
                ...state,
                fetchingNavBrandList: false,
                fetchNavBrandListSuccess: action.payload,
                fetchNavBrandListFailure: null,
            };
        case multibrandTypes.FETCH_NAV_BRAND_LIST_FAILURE:
            return {
                ...state,
                fetchingNavBrandList: false,
                fetchNavBrandListSuccess: null,
                fetchNavBrandListFailure: action.payload,
            };

        case multibrandTypes.EXPORT_INVENTORY_IN_STOCK_REQUEST:
            return {
                ...state,
                exportingBrandInventoryInStock: true,
                exportBrandInventoryInStockSuccess: null,
                exportBrandInventoryInStockFailure: null,
            };
        case multibrandTypes.EXPORT_INVENTORY_IN_STOCK_SUCCESS:
            return {
                ...state,
                exportingBrandInventoryInStock: false,
                exportBrandInventoryInStockSuccess: action.payload,
                exportBrandInventoryInStockFailure: null,
            };
        case multibrandTypes.EXPORT_INVENTORY_IN_STOCK_FAILURE:
            return {
                ...state,
                exportingBrandInventoryInStock: false,
                exportBrandInventoryInStockSuccess: null,
                exportBrandInventoryInStockFailure: action.payload,
            };

        case multibrandTypes.EXPORT_INVENTORY_TOTAL_STOCK_REQUEST:
            return {
                ...state,
                exportingBrandInventoryTotalStock: true,
                exportBrandInventoryTotalStockSuccess: null,
                exportBrandInventoryTotalStockFailure: null,
            };
        case multibrandTypes.EXPORT_INVENTORY_TOTAL_STOCK_SUCCESS:
            return {
                ...state,
                exportingBrandInventoryTotalStock: false,
                exportBrandInventoryTotalStockSuccess: action.payload,
                exportBrandInventoryTotalStockFailure: null,
            };
        case multibrandTypes.EXPORT_INVENTORY_TOTAL_STOCK_FAILURE:
            return {
                ...state,
                exportingBrandInventoryTotalStock: false,
                exportBrandInventoryTotalStockSuccess: null,
                exportBrandInventoryTotalStockFailure: action.payload,
            };

        case multibrandTypes.EXPORT_INVENTORY_OUT_OF_STOCK_REQUEST:
            return {
                ...state,
                exportingBrandInventoryOutOfStock: true,
                exportBrandInventoryOutOfStockSuccess: null,
                exportBrandInventoryOutOfStockFailure: null,
            };
        case multibrandTypes.EXPORT_INVENTORY_OUT_OF_STOCK_SUCCESS:
            return {
                ...state,
                exportingBrandInventoryOutOfStock: false,
                exportBrandInventoryOutOfStockSuccess: action.payload,
                exportBrandInventoryOutOfStockFailure: null,
            };
        case multibrandTypes.EXPORT_INVENTORY_OUT_OF_STOCK_FAILURE:
            return {
                ...state,
                exportingBrandInventoryOutOfStock: false,
                exportBrandInventoryOutOfStockSuccess: null,
                exportBrandInventoryOutOfStockFailure: action.payload,
            };

        case multibrandTypes.FETCH_INVENTORY_RESTOCK_REQUEST:
            return {
                ...state,
                fetchingInventoryRestock: true,
                fetchInventoryRestockSuccess: null,
                fetchInventoryRestockFailure: null,
            };
        case multibrandTypes.FETCH_INVENTORY_RESTOCK_SUCCESS:
            return {
                ...state,
                fetchingInventoryRestock: false,
                fetchInventoryRestockSuccess: action.payload,
                fetchInventoryRestockFailure: null,
            };
        case multibrandTypes.FETCH_INVENTORY_RESTOCK_FAILURE:
            return {
                ...state,
                fetchingInventoryRestock: false,
                fetchInventoryRestockSuccess: null,
                fetchInventoryRestockFailure: action.payload,
            };

        case multibrandTypes.FETCH_INVENTORY_OUT_OF_STOCK_REQUEST:
            return {
                ...state,
                fetchingInventoryOutOfStock: true,
                fetchInventoryOutOfStockSuccess: null,
                fetchInventoryOutOfStockFailure: null,
            };
        case multibrandTypes.FETCH_INVENTORY_OUT_OF_STOCK_SUCCESS:
            return {
                ...state,
                fetchingInventoryOutOfStock: false,
                fetchInventoryOutOfStockSuccess: action.payload,
                fetchInventoryOutOfStockFailure: null,
            };
        case multibrandTypes.FETCH_INVENTORY_OUT_OF_STOCK_FAILURE:
            return {
                ...state,
                fetchingInventoryOutOfStock: false,
                fetchInventoryOutOfStockSuccess: null,
                fetchInventoryOutOfStockFailure: action.payload,
            };

        case multibrandTypes.FETCH_INVENTORY_TOTAL_STOCK_REQUEST:
            return {
                ...state,
                fetchingInventoryTotalStock: true,
                fetchInventoryTotalStockSuccess: null,
                fetchInventoryTotalStockFailure: null,
            };
        case multibrandTypes.FETCH_INVENTORY_TOTAL_STOCK_SUCCESS:
            return {
                ...state,
                fetchingInventoryTotalStock: false,
                fetchInventoryTotalStockSuccess: action.payload,
                fetchInventoryTotalStockFailure: null,
            };
        case multibrandTypes.FETCH_INVENTORY_TOTAL_STOCK_FAILURE:
            return {
                ...state,
                fetchingInventoryTotalStock: false,
                fetchInventoryTotalStockSuccess: null,
                fetchInventoryTotalStockFailure: action.payload,
            };

        case multibrandTypes.FETCH_INVENTORY_IN_STOCK_REQUEST:
            return {
                ...state,
                fetchingInventoryInStock: true,
                fetchInventoryInStockSuccess: null,
                fetchInventoryInStockFailure: null,
            };
        case multibrandTypes.FETCH_INVENTORY_IN_STOCK_SUCCESS:
            return {
                ...state,
                fetchingInventoryInStock: false,
                fetchInventoryInStockSuccess: action.payload,
                fetchInventoryInStockFailure: null,
            };
        case multibrandTypes.FETCH_INVENTORY_IN_STOCK_FAILURE:
            return {
                ...state,
                fetchingInventoryInStock: false,
                fetchInventoryInStockSuccess: null,
                fetchInventoryInStockFailure: action.payload,
            };

        case multibrandTypes.EXPORT_BRAND_INVENTORY_LIST_REQUEST:
            return {
                ...state,
                exportingBrandInventoryList: true,
                exportBrandInventoryListSuccess: null,
                exportBrandInventoryListFailure: null,
            };
        case multibrandTypes.EXPORT_BRAND_INVENTORY_LIST_SUCCESS:
            return {
                ...state,
                exportingBrandInventoryList: false,
                exportBrandInventoryListSuccess: action.payload,
                exportBrandInventoryListFailure: null,
            };
        case multibrandTypes.EXPORT_BRAND_INVENTORY_LIST_FAILURE:
            return {
                ...state,
                exportingBrandInventoryList: false,
                exportBrandInventoryListSuccess: null,
                exportBrandInventoryListFailure: action.payload,
            };

        case multibrandTypes.FETCH_BRAND_WAREHOUSE_LIST_REQUEST:
            return {
                ...state,
                fetchingBrandWarehouseList: true,
                fetchBrandWarehouseListSuccess: null,
                fetchBrandWarehouseListFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_WAREHOUSE_LIST_SUCCESS:
            return {
                ...state,
                fetchingBrandWarehouseList: false,
                fetchBrandWarehouseListSuccess: action.payload,
                fetchBrandWarehouseListFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_WAREHOUSE_LIST_FAILURE:
            return {
                ...state,
                fetchingBrandWarehouseList: false,
                fetchBrandWarehouseListSuccess: null,
                fetchBrandWarehouseListFailure: action.payload,
            };

        case multibrandTypes.FETCH_BRAND_WAREHOUSE_STOCK_BREAKDOWN_REQUEST:
            return {
                ...state,
                fetchingBrandWarehouseStockBreakdown: true,
                fetchBrandWarehouseStockBreakdownSuccess: null,
                fetchBrandWarehouseStockBreakdownFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_WAREHOUSE_STOCK_BREAKDOWN_SUCCESS:
            return {
                ...state,
                fetchingBrandWarehouseStockBreakdown: false,
                fetchBrandWarehouseStockBreakdownSuccess: action.payload,
                fetchBrandWarehouseStockBreakdownFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_WAREHOUSE_STOCK_BREAKDOWN_FAILURE:
            return {
                ...state,
                fetchingBrandWarehouseStockBreakdown: false,
                fetchBrandWarehouseStockBreakdownSuccess: null,
                fetchBrandWarehouseStockBreakdownFailure: action.payload,
            };

        case multibrandTypes.FETCH_BRAND_STOCK_DETAILS_REQUEST:
            return {
                ...state,
                fetchingBrandStockDetails: true,
                fetchBrandStockDetailsSuccess: null,
                fetchBrandStockDetailsFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_STOCK_DETAILS_SUCCESS:
            return {
                ...state,
                fetchingBrandStockDetails: false,
                fetchBrandStockDetailsSuccess: action.payload,
                fetchBrandStockDetailsFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_STOCK_DETAILS_FAILURE:
            return {
                ...state,
                fetchingBrandStockDetails: false,
                fetchBrandStockDetailsSuccess: null,
                fetchBrandStockDetailsFailure: action.payload,
            };

        case multibrandTypes.FETCH_BRAND_INVENTORY_REQUEST:
            return {
                ...state,
                fetchingBrandInventory: true,
                fetchBrandInventorySuccess: null,
                fetchBrandInventoryFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_INVENTORY_SUCCESS:
            return {
                ...state,
                fetchingBrandInventory: false,
                fetchBrandInventorySuccess: action.payload,
                fetchBrandInventoryFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_INVENTORY_FAILURE:
            return {
                ...state,
                fetchingBrandInventory: false,
                fetchBrandInventorySuccess: null,
                fetchBrandInventoryFailure: action.payload,
            };

        case multibrandTypes.FETCH_BRAND_COMPARISM_REQUEST:
            return {
                ...state,
                fetchingBrandComparism: true,
                fetchBrandComparismSuccess: null,
                fetchBrandComparismFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_COMPARISM_SUCCESS:
            return {
                ...state,
                fetchingBrandComparism: false,
                fetchBrandComparismSuccess: action.payload,
                fetchBrandComparismFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_COMPARISM_FAILURE:
            return {
                ...state,
                fetchingBrandComparism: false,
                fetchBrandComparismSuccess: null,
                fetchBrandComparismFailure: action.payload,
            };

        case multibrandTypes.FETCH_BRAND_ANALYTICS_REQUEST:
            return {
                ...state,
                fetchingBrandAnalytics: true,
                fetchBrandAnalyticsSuccess: null,
                fetchBrandAnalyticsFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_ANALYTICS_SUCCESS:
            return {
                ...state,
                fetchingBrandAnalytics: false,
                fetchBrandAnalyticsSuccess: action.payload,
                fetchBrandAnalyticsFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_ANALYTICS_FAILURE:
            return {
                ...state,
                fetchingBrandAnalytics: false,
                fetchBrandAnalyticsSuccess: null,
                fetchBrandAnalyticsFailure: action.payload,
            };

        case multibrandTypes.FETCH_REVENUE_TABLE_REQUEST:
            return {
                ...state,
                fetchingRevenueTable: true,
                fetchRevenueTableSuccess: null,
                fetchRevenueTableFailure: null,
            };
        case multibrandTypes.FETCH_REVENUE_TABLE_SUCCESS:
            return {
                ...state,
                fetchingRevenueTable: false,
                fetchRevenueTableSuccess: action.payload,
                fetchRevenueTableFailure: null,
            };
        case multibrandTypes.FETCH_REVENUE_TABLE_FAILURE:
            return {
                ...state,
                fetchingRevenueTable: false,
                fetchRevenueTableSuccess: null,
                fetchRevenueTableFailure: action.payload,
            };

        case multibrandTypes.FETCH_REVENUE_GRAPH_REQUEST:
            return {
                ...state,
                fetchingRevenueGraph: true,
                fetchRevenueGraphSuccess: null,
                fetchRevenueGraphFailure: null,
            };
        case multibrandTypes.FETCH_REVENUE_GRAPH_SUCCESS:
            return {
                ...state,
                fetchingRevenueGraph: false,
                fetchRevenueGraphSuccess: action.payload,
                fetchRevenueGraphFailure: null,
            };
        case multibrandTypes.FETCH_REVENUE_GRAPH_FAILURE:
            return {
                ...state,
                fetchingRevenueGraph: false,
                fetchRevenueGraphSuccess: null,
                fetchRevenueGraphFailure: action.payload,
            };

        case multibrandTypes.FETCH_INCIDENTS_ANALYTICS_REQUEST:
            return {
                ...state,
                fetchingIncidentsAnalytics: true,
                fetchIncidentsAnalyticsSuccess: null,
                fetchIncidentsAnalyticsFailure: null,
            };
        case multibrandTypes.FETCH_INCIDENTS_ANALYTICS_SUCCESS:
            return {
                ...state,
                fetchingIncidentsAnalytics: false,
                fetchIncidentsAnalyticsSuccess: action.payload,
                fetchIncidentsAnalyticsFailure: null,
            };
        case multibrandTypes.FETCH_INCIDENTS_ANALYTICS_FAILURE:
            return {
                ...state,
                fetchingIncidentsAnalytics: false,
                fetchIncidentsAnalyticsSuccess: null,
                fetchIncidentsAnalyticsFailure: action.payload,
            };

        case multibrandTypes.FETCH_CHANNEL_SALES_ANALYTICS_REQUEST:
            return {
                ...state,
                fetchingChannelSalesAnalytics: true,
                fetchChannelSalesAnalyticsSuccess: null,
                fetchChannelSalesAnalyticsFailure: null,
            };
        case multibrandTypes.FETCH_CHANNEL_SALES_ANALYTICS_SUCCESS:
            return {
                ...state,
                fetchingChannelSalesAnalytics: false,
                fetchChannelSalesAnalyticsSuccess: action.payload,
                fetchChannelSalesAnalyticsFailure: null,
            };
        case multibrandTypes.FETCH_CHANNEL_SALES_ANALYTICS_FAILURE:
            return {
                ...state,
                fetchingChannelSalesAnalytics: false,
                fetchChannelSalesAnalyticsSuccess: null,
                fetchChannelSalesAnalyticsFailure: action.payload,
            };

        case multibrandTypes.FETCH_COST_SUMMARY_REQUEST:
            return {
                ...state,
                fetchingCostSummary: true,
                fetchCostSummarySuccess: null,
                fetchCostSummaryFailure: null,
            };
        case multibrandTypes.FETCH_COST_SUMMARY_SUCCESS:
            return {
                ...state,
                fetchingCostSummary: false,
                fetchCostSummarySuccess: action.payload,
                fetchCostSummaryFailure: null,
            };
        case multibrandTypes.FETCH_COST_SUMMARY_FAILURE:
            return {
                ...state,
                fetchingCostSummary: false,
                fetchCostSummarySuccess: null,
                fetchCostSummaryFailure: action.payload,
            };

        case multibrandTypes.FETCH_PROFIT_ANALYTICS_REQUEST:
            return {
                ...state,
                fetchingProfitAnalytics: true,
                fetchProfitAnalyticsSuccess: null,
                fetchProfitAnalyticsFailure: null,
            };
        case multibrandTypes.FETCH_PROFIT_ANALYTICS_SUCCESS:
            return {
                ...state,
                fetchingProfitAnalytics: false,
                fetchProfitAnalyticsSuccess: action.payload,
                fetchProfitAnalyticsFailure: null,
            };
        case multibrandTypes.FETCH_PROFIT_ANALYTICS_FAILURE:
            return {
                ...state,
                fetchingProfitAnalytics: false,
                fetchProfitAnalyticsSuccess: null,
                fetchProfitAnalyticsFailure: action.payload,
            };

        case multibrandTypes.FETCH_BRAND_TOKEN_REQUEST:
            return {
                ...state,
                fetchingBrandToken: true,
                fetchBrandTokenSuccess: null,
                fetchBrandTokenFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_TOKEN_SUCCESS:
            return {
                ...state,
                fetchingBrandToken: false,
                fetchBrandTokenSuccess: action.payload,
                fetchBrandTokenFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_TOKEN_FAILURE:
            return {
                ...state,
                fetchingBrandToken: false,
                fetchBrandTokenSuccess: null,
                fetchBrandTokenFailure: action.payload,
            };

        case multibrandTypes.FETCH_ASSIGNED_BRAND_LIST_REQUEST:
            return {
                ...state,
                fetchingAssignedBrands: true,
                fetchAssignedBrandsSuccess: null,
                fetchAssignedBrandsFailure: null,
            };
        case multibrandTypes.FETCH_ASSIGNED_BRAND_LIST_SUCCESS:
            return {
                ...state,
                fetchingAssignedBrands: false,
                fetchAssignedBrandsSuccess: action.payload,
                fetchAssignedBrandsFailure: null,
            };
        case multibrandTypes.FETCH_ASSIGNED_BRAND_LIST_FAILURE:
            return {
                ...state,
                fetchingAssignedBrands: false,
                fetchAssignedBrandsSuccess: null,
                fetchAssignedBrandsFailure: action.payload,
            };

        case multibrandTypes.FETCH_ASSIGNED_BRAND_LIST_SEARCH_REQUEST:
            return {
                ...state,
                fetchingAssignedBrandsSearch: true,
                fetchAssignedBrandsSearchSuccess: null,
                fetchAssignedBrandsSearchFailure: null,
            };
        case multibrandTypes.FETCH_ASSIGNED_BRAND_LIST_SEARCH_SUCCESS:
            return {
                ...state,
                fetchingAssignedBrandsSearch: false,
                fetchAssignedBrandsSearchSuccess: action.payload,
                fetchAssignedBrandsSearchFailure: null,
            };
        case multibrandTypes.FETCH_ASSIGNED_BRAND_LIST_SEARCH_FAILURE:
            return {
                ...state,
                fetchingAssignedBrandsSearch: false,
                fetchAssignedBrandsSearchSuccess: null,
                fetchAssignedBrandsSearchFailure: action.payload,
            };

        case multibrandTypes.FETCH_SINGLE_BRAND_REQUEST:
            return {
                ...state,
                fetchingSingleBrand: true,
                fetchSingleBrandSuccess: null,
                fetchSingleBrandFailure: null,
            };
        case multibrandTypes.FETCH_SINGLE_BRAND_SUCCESS:
            return {
                ...state,
                fetchingSingleBrand: false,
                fetchSingleBrandSuccess: action.payload,
                fetchSingleBrandFailure: null,
            };
        case multibrandTypes.FETCH_SINGLE_BRAND_FAILURE:
            return {
                ...state,
                fetchingSingleBrand: false,
                fetchSingleBrandSuccess: null,
                fetchSingleBrandFailure: action.payload,
            };

        case multibrandTypes.EXPORT_BRAND_LIST_REQUEST:
            return {
                ...state,
                exportingBrandList: true,
                exportBrandListSuccess: null,
                exportBrandListFailure: null,
            };
        case multibrandTypes.EXPORT_BRAND_LIST_SUCCESS:
            return {
                ...state,
                exportingBrandList: false,
                exportBrandListSuccess: action.payload,
                exportBrandListFailure: null,
            };
        case multibrandTypes.EXPORT_BRAND_LIST_FAILURE:
            return {
                ...state,
                exportingBrandList: false,
                exportBrandListSuccess: null,
                exportBrandListFailure: action.payload,
            };

        case multibrandTypes.FETCH_BRAND_LIST_SEARCH_REQUEST:
            return {
                ...state,
                fetchingBrandListSearch: true,
                fetchBrandListSearchSuccess: null,
                fetchBrandListSearchFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_LIST_SEARCH_SUCCESS:
            return {
                ...state,
                fetchingBrandListSearch: false,
                fetchBrandListSearchSuccess: action.payload,
                fetchBrandListSearchFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_LIST_SEARCH_FAILURE:
            return {
                ...state,
                fetchingBrandListSearch: false,
                fetchBrandListSearchSuccess: null,
                fetchBrandListSearchFailure: action.payload,
            };

        case multibrandTypes.FETCH_BRAND_LIST_REQUEST:
            return {
                ...state,
                fetchingBrandList: true,
                fetchBrandListSuccess: null,
                fetchBrandListFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_LIST_SUCCESS:
            return {
                ...state,
                fetchingBrandList: false,
                fetchBrandListSuccess: action.payload,
                fetchBrandListFailure: null,
            };
        case multibrandTypes.FETCH_BRAND_LIST_FAILURE:
            return {
                ...state,
                fetchingBrandList: false,
                fetchBrandListSuccess: null,
                fetchBrandListFailure: action.payload,
            };

        case multibrandTypes.CREATE_BRAND_REQUEST:
            return {
                ...state,
                creatingBrand: true,
                createBrandSuccess: null,
                createBrandFailure: null,
            };
        case multibrandTypes.CREATE_BRAND_SUCCESS:
            return {
                ...state,
                creatingBrand: false,
                createBrandSuccess: action.payload,
                createBrandFailure: null,
            };
        case multibrandTypes.CREATE_BRAND_FAILURE:
            return {
                ...state,
                creatingBrand: false,
                createBrandSuccess: null,
                createBrandFailure: action.payload,
            };

        case multibrandTypes.UPDATE_BRAND_REQUEST:
            return {
                ...state,
                updatingBrand: true,
                updateBrandSuccess: null,
                updateBrandFailure: null,
            };
        case multibrandTypes.UPDATE_BRAND_SUCCESS:
            return {
                ...state,
                updatingBrand: false,
                updateBrandSuccess: action.payload,
                updateBrandFailure: null,
            };
        case multibrandTypes.UPDATE_BRAND_FAILURE:
            return {
                ...state,
                updatingBrand: false,
                updateBrandSuccess: null,
                updateBrandFailure: action.payload,
            };

        default:
            return state;
    }
};
