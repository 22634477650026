import React, { useEffect, useState } from "react";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/storeHooks";
import Loader from "components/common/Loader";

type ListViewProps = {
    data?: { [key: string]: any };
    allHumanTasks?: { [key: string]: any }[];
    pagination?: { currentPage: string; noOfPages: string };
    type?: string;
};

const ListView = ({ data, pagination, type, allHumanTasks }: ListViewProps) => {
    const navigate = useNavigate();
    const [allData, setAllData] = useState<{ [key: string]: any }[]>([{}]);

    useEffect(() => {
        if (type !== "humanTasks") {
            setAllData([...data?.activeTasks, ...data?.pendingTasks, ...data?.completedTasks]);
        } else {
            setAllData(allHumanTasks);
        }
    }, [type, data, allHumanTasks]);
    // const allData = [...data?.activeTasks, ...data?.pendingTasks, ...data?.completedTasks];

    const { fetchingTaskList } = useAppSelector((state) => state.workforce);

    const taskTableHeader = [
        { title: "Task name", widthClass: "w-[46%]" },
        { title: "Assigned AI teammate", widthClass: "w-[17.7%]" },
        { title: "Start Date", widthClass: "w-[16.5%]" },
        { title: "Status", widthClass: "w-[20%]" },
        { title: "", widthClass: "w-[5.1%]" },
    ];

    const taskTableBody = allData?.map((task, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full w-full border-slate-100 text-sm text-slate-500 `}
                >
                    <div className="flex items-center justify-between w-full">
                        <span>
                            {camelCaseToRegularCase(task?.taskType)} - {task?.taId}
                        </span>
                        {task?.status?.toLowerCase()?.includes("attention") && (
                            <i className="ri-information-line text-r-50 text-2xl"></i>
                        )}
                    </div>
                </div>
            ),
            cellClickAction: () => navigate(`/dashboard/task/${task?._id}`),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <div className="flex items-center space-x-2">
                        <div className="w-6 h-6 rounded-full bg-slate-100 flex items-center justify-center">
                            <i className="ri-user-follow-line"></i>
                        </div>
                        <span>{task?.agent?.teamMateName}</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <span>{moment(task?.createdAt)?.format("MMM DD, YYYY")}</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <div className="flex items-center space-x-2">
                        {/* <span className="w-2 h-2 rounded-full bg-[#EAB308]"></span> */}
                        <span>{task?.status}</span>
                    </div>
                </div>
            ),
        },

        {
            content: (
                <div
                    className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-90px] `}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "View details",
                                value: "view",
                                action: () => navigate(`/dashboard/task/${task?._id}`),
                            },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    const humanTaskTableHeader = [
        { title: "Task name", widthClass: "w-[35%]" },
        { title: "Created by", widthClass: "w-[20%]" },
        { title: "Due Date", widthClass: "w-[20%]" },
        { title: "Status", widthClass: "w-[20%]" },
        { title: "", widthClass: "w-[5%]" },
    ];

    const humanTaskTableBody = allData?.map((task, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full w-full border-slate-100 text-sm text-g-75 `}
                >
                    <p className="block text-sm">
                        {" "}
                        QC task for <span className="underline">WO-{task?.workOrderTaId}</span>{" "}
                    </p>
                </div>
            ),
            cellClickAction: () => navigate(`/dashboard/task/workOrder/${task?._id}`),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                >
                    <div className="flex items-center space-x-2">
                        <div className="w-6 h-6 rounded-full bg-slate-100 flex items-center justify-center">
                            <i className="ri-user-follow-line"></i>
                        </div>
                        <span> {task?.createdBy?.fullName || task?.createdBy?.email}</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <span>
                        {task?.startDate ? moment(task?.endDate)?.format("MMM DD, YYYY") : "N/A"}
                    </span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                >
                    <div className="flex items-center space-x-2">
                        <span
                            className={`${
                                task?.status === "pending"
                                    ? "bg-[#EAB308]"
                                    : task?.status === "passed"
                                    ? "bg-[#117FFF]"
                                    : task?.status === "failed" && "bg-[#E4281D]"
                            } w-2 h-2 rounded-full `}
                        ></span>
                        <span>
                            {task?.status === "pending"
                                ? "Pending"
                                : task?.status === "passed"
                                ? "Completed/Approved"
                                : task?.status === "failed" && "Rejected/Failed"}
                        </span>
                    </div>
                </div>
            ),
        },

        {
            content: (
                <div
                    className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-90px] `}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "View details",
                                value: "view",
                                action: () => navigate(`/dashboard/task/workOrder/${task?._id}`),
                            },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    return (
        <div>
            <CustomTable
                tableBody={type === "humanTasks" ? humanTaskTableBody : taskTableBody}
                tableHeader={type === "humanTasks" ? humanTaskTableHeader : taskTableHeader}
                isScrollable={false}
                // isScrolled={isScrolled}
                isCellBordered={false}
                isCellSelectable={false}
                isAllSelectable={false}
                headerContainerClass="!bg-slate-50"
                headerItemClass="font-rocGroteskMedium "
                tableClass="mb-12"
            />

            {fetchingTaskList && (
                <div className="flex justify-center my-4">
                    <Loader color="gm-25" size={4} />
                </div>
            )}
            {pagination?.currentPage === pagination?.noOfPages && (
                <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                    End of list
                </div>
            )}
        </div>
    );
};

export default ListView;
