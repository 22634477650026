import React from "react";
import CustomToast from "components/common/CustomToast";
import {toast} from "react-hot-toast";
import {taskTypes} from "redux/Ldb/types";
import {taskService} from "services/Ldb";
import {Dispatch} from "redux";

export const taskActions = {
    createTask,
    resetCreateTask,
    updateTask,
    resetUpdateTask,
    fetchTaskList,
    fetchSingleTask,
    fetchOverdueTaskList,
    fetchPendingTaskList,
    fetchCompletedTaskList,
    fetchActiveTaskList,
    fetchAllTaskList,
    fetchMyTaskList,
    fetchAllTaskTwoList,
    fetchMyTaskTwoList,
    fetchActiveTaskTwoList,
    fetchCompletedTaskTwoList,
    fetchOverdueTaskTwoList,
    fetchPendingTaskTwoList,
    fetchPendingHumanTaskList,
    fetchAllHumanTaskList,
    fetchCompletedHumanTaskList,
    fetchFailedHumanTaskList,
    fetchSingleHumanTask,
    createHumanTask,
    resetCreateHumanTask,
    updateHumanTask,
    completeHumanTask,
    resetUpdateHumanTask,
    resetCompleteHumanTask

};

function createTask(body: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.CREATE_TASK_REQUEST));
        taskService.createTask(body).then(
            (res) => {
                dispatch(success(taskTypes.CREATE_TASK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.CREATE_TASK_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateTask(data: Record<string, any> | Array<Record<string, any>>, taskId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.UPDATE_TASK_REQUEST));
        taskService.updateTask(data, taskId).then(
            (res) => {
                dispatch(success(taskTypes.UPDATE_TASK_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Task updated successfully"} type="success" />
                ));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.UPDATE_TASK_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchTaskList(
    limit?: string | number,
    status?: string,
    keyword?: string,
    search?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.FETCH_TASK_LIST_REQUEST));
        taskService.getTaskList(limit, status, keyword, search).then(
            (res) => {
                dispatch(success(taskTypes.FETCH_TASK_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.FETCH_TASK_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchPendingTaskList(
    limit?: string | number,
    status?: string,
    keyword?: string,
    search?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.FETCH__PENDING_TASK_LIST_REQUEST));
        taskService.getTaskList(limit, status, "", search).then(
            (res) => {
                dispatch(success(taskTypes.FETCH__PENDING_TASK_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.FETCH__PENDING_TASK_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchPendingTaskTwoList(
    limit?: string | number,
    status?: string,
    keyword?: string,
    search?: string
) {
    return (dispatch) => {
        dispatch(request(taskTypes.FETCH__PENDING_TASK_TWO_LIST_REQUEST));
        taskService.getTaskList(limit, status, "", search).then(
            (res) => {
                dispatch(success(taskTypes.FETCH__PENDING_TASK_TWO_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(taskTypes.FETCH__PENDING_TASK_TWO_LIST_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchCompletedTaskList(
    limit?: string | number,
    status?: string,
    keyword?: string,
    search?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.FETCH__COMPLETED_TASK_LIST_REQUEST));
        taskService.getTaskList(limit, status, "", search).then(
            (res) => {
                dispatch(success(taskTypes.FETCH__COMPLETED_TASK_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.FETCH__COMPLETED_TASK_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchCompletedTaskTwoList(
    limit?: string | number,
    status?: string,
    keyword?: string,
    search?: string
) {
    return (dispatch) => {
        dispatch(request(taskTypes.FETCH__COMPLETED_TASK_TWO_LIST_REQUEST));
        taskService.getTaskList(limit, status, "", search).then(
            (res) => {
                dispatch(success(taskTypes.FETCH__COMPLETED_TASK_TWO_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(taskTypes.FETCH__COMPLETED_TASK_TWO_LIST_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchOverdueTaskList(
    limit?: string | number,
    status?: string,
    keyword?: string,
    search?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.FETCH__OVERDUE_TASK_LIST_REQUEST));
        taskService.getTaskList(limit, status, "", search).then(
            (res) => {
                dispatch(success(taskTypes.FETCH__OVERDUE_TASK_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.FETCH__OVERDUE_TASK_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchOverdueTaskTwoList(
    limit?: string | number,
    status?: string,
    keyword?: string,
    search?: string
) {
    return (dispatch) => {
        dispatch(request(taskTypes.FETCH__OVERDUE_TASK_TWO_LIST_REQUEST));
        taskService.getTaskList(limit, status, "", search).then(
            (res) => {
                dispatch(success(taskTypes.FETCH__OVERDUE_TASK_TWO_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(taskTypes.FETCH__OVERDUE_TASK_TWO_LIST_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchSingleTask(taskId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.FETCH_SINGLE_TASK_REQUEST));
        taskService.getSingleTask(taskId).then(
            (res) => {
                dispatch(success(taskTypes.FETCH_SINGLE_TASK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.FETCH_SINGLE_TASK_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchActiveTaskList(
    limit?: string | number,
    status?: string,
    keyword?: string,
    search?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.FETCH__ACTIVE_TASK_LIST_REQUEST));
        taskService.getTaskList(limit, status, "", search).then(
            (res) => {
                dispatch(success(taskTypes.FETCH__ACTIVE_TASK_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.FETCH__ACTIVE_TASK_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchActiveTaskTwoList(
    limit?: string | number,
    status?: string,
    keyword?: string,
    search?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.FETCH__ACTIVE_TASK_TWO_LIST_REQUEST));
        taskService.getTaskList(limit, status, "", search).then(
            (res) => {
                dispatch(success(taskTypes.FETCH__ACTIVE_TASK_TWO_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(taskTypes.FETCH__ACTIVE_TASK_TWO_LIST_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchAllTaskList(
    limit?: string | number,
    status?: string,
    keyword?: string,
    search?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.FETCH__ALL_TASK_LIST_REQUEST));
        taskService.getTaskList(limit, status, keyword, search).then(
            (res) => {
                dispatch(success(taskTypes.FETCH__ALL_TASK_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.FETCH__ALL_TASK_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchAllTaskTwoList(
    limit?: string | number,
    status?: string,
    keyword?: string,
    search?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.FETCH__ALL_TASK_TWO_LIST_REQUEST));
        taskService.getTaskList(limit, status, keyword, search).then(
            (res) => {
                dispatch(success(taskTypes.FETCH__ALL_TASK_TWO_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.FETCH__ALL_TASK_TWO_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchMyTaskList(limit?: string | number, status?: string, keyword?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.FETCH__MY_TASK_LIST_REQUEST));
        taskService.getTaskList(limit, status, keyword).then(
            (res) => {
                dispatch(success(taskTypes.FETCH__MY_TASK_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.FETCH__MY_TASK_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchMyTaskTwoList(limit, status, keyword) {
    return (dispatch) => {
        dispatch(request(taskTypes.FETCH__MY_TASK_TWO_LIST_REQUEST));
        taskService.getTaskList(limit, status, keyword).then(
            (res) => {
                dispatch(success(taskTypes.FETCH__MY_TASK_TWO_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.FETCH__MY_TASK_TWO_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchPendingHumanTaskList(limit:number ,page:number, status:string, search?:string, taskType?:string) {
    return (dispatch) => {
        dispatch(request(taskTypes.FETCH_PENDING_HUMAN_TASK_REQUEST));
        taskService.getHumanTaskList(limit, page, status, search, taskType).then(
            (res) => {
                dispatch(success(taskTypes.FETCH_PENDING_HUMAN_TASK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.FETCH_PENDING_HUMAN_TASK_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchAllHumanTaskList(limit:number ,page:number, status?:string, search?:string, taskType?:string) {
    return (dispatch) => {
        dispatch(request(taskTypes.FETCH_ALL_HUMAN_TASK_REQUEST));
        taskService.getHumanTaskList(limit, page, status, search, taskType).then(
            (res) => {
                dispatch(success(taskTypes.FETCH_ALL_HUMAN_TASK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.FETCH_ALL_HUMAN_TASK_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchCompletedHumanTaskList(limit:number ,page:number, status:string, search?:string, taskType?:string) {
    return (dispatch) => {
        dispatch(request(taskTypes.FETCH_COMPLETED_HUMAN_TASK_REQUEST));
        taskService.getHumanTaskList(limit, page, status, search, taskType).then(
            (res) => {
                dispatch(success(taskTypes.FETCH_COMPLETED_HUMAN_TASK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.FETCH_COMPLETED_HUMAN_TASK_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchFailedHumanTaskList(limit:number ,page:number, status:string, search?:string, taskType?:string) {
    return (dispatch) => {
        dispatch(request(taskTypes.FETCH_FAILED_HUMAN_TASK_REQUEST));
        taskService.getHumanTaskList(limit, page, status, search, taskType).then(
            (res) => {
                dispatch(success(taskTypes.FETCH_FAILED_HUMAN_TASK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.FETCH_FAILED_HUMAN_TASK_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchSingleHumanTask(taskId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.FETCH_SINGLE_HUMAN_TASK_REQUEST));
        taskService.getSingleHumanTask(taskId).then(
            (res) => {
                dispatch(success(taskTypes.FETCH_SINGLE_HUMAN_TASK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.FETCH_SINGLE_HUMAN_TASK_FAILURE, error?.message));
                }
            }
        );
    };
}
function createHumanTask(body: Record<string, any> | Array<Record<string, any>>, workOrderId) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.CREATE_HUMAN_TASK_REQUEST));
        taskService.createHumanTask(body, workOrderId).then(
            (res) => {
                dispatch(success(taskTypes.CREATE_HUMAN_TASK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.CREATE_HUMAN_TASK_FAILURE, error?.message));
                }
            }
        );
    };
}
function updateHumanTask(body: Record<string, any> | Array<Record<string, any>>, taskId) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.UPDATE_HUMAN_TASK_REQUEST));
        taskService.updateHumanTask(body, taskId).then(
            (res) => {
                dispatch(success(taskTypes.UPDATE_HUMAN_TASK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.UPDATE_HUMAN_TASK_FAILURE, error?.message));
                }
            }
        );
    };
}
function completeHumanTask(taskId) {
    return (dispatch: Dispatch) => {
        dispatch(request(taskTypes.COMPLETE_HUMAN_TASK_REQUEST));
        taskService.completeHumanTask(taskId).then(
            (res) => {
                dispatch(success(taskTypes.COMPLETE_HUMAN_TASK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(taskTypes.COMPLETE_HUMAN_TASK_FAILURE, error?.message));
                }
            }
        );
    };
}
function resetCreateTask() {
    return (dispatch: Dispatch) => {
        dispatch(success(taskTypes.CREATE_TASK_SUCCESS, null));
    };
}

function resetUpdateTask() {
    return (dispatch: Dispatch) => {
        dispatch(success(taskTypes.UPDATE_TASK_SUCCESS, null));
    };
}

function resetCreateHumanTask() {
    return (dispatch: Dispatch) => {
        dispatch(success(taskTypes.CREATE_HUMAN_TASK_SUCCESS, null));
    };
}
function resetUpdateHumanTask() {
    return (dispatch: Dispatch) => {
        dispatch(success(taskTypes.UPDATE_HUMAN_TASK_SUCCESS, null));
    };
}
function resetCompleteHumanTask() {
    return (dispatch: Dispatch) => {
        dispatch(success(taskTypes.COMPLETE_HUMAN_TASK_SUCCESS, null));
    };
}

function request(type: string) {
    return {type: type};
}

function success(type: string, data: any) {
    return {type: type, payload: data};
}
function failure(type: string, error: any) {
    return {type: type, payload: error ?? ""};
}
