/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Loader from "../Loader";
import { Tooltip } from "react-tooltip";

type SelectInputProps = {
    label?: string;
    handleChange?: (name: string, value: string, rawData?: any) => void;
    name?: string;
    value?: string | `${string}`;
    disabled?: boolean;
    placeholder?: string;
    inputPlaceholder?: string;
    dropdownOptions?: Array<{
        label: string | JSX.Element;
        value: string;
        icon?: JSX.Element;
        subText?: string;
        rawData?: any;
    }>;
    isRequired?: boolean;
    className?: string;
    id?: string;
    labelClassName?: string;
    inputContainerClassName?: string;
    dropdownClassName?: string;
    optionItemClassName?: string;
    optionItemContainerClassName?: string;
    activeOptionItemContainerClassName?: string;
    activeOptionItemClassName?: string;
    searchLoading?: boolean;
    handleInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    customDropdownContainer?: React.ReactNode;
    customRightDropdownContainer?: React.ReactNode;
    inputClassName?: string;
    iconClassName?: string;
    floatingPlaceholderClass?: string;
    hideSelectedIcon?: boolean;
    clearValue?: boolean;
    rightDropdownContainerClassName?: string;
    idx?: number;
    onClose?: () => void;
};

const SelectInput = ({
    label,
    handleChange,
    name,
    value,
    disabled,
    placeholder,
    inputPlaceholder,
    dropdownOptions,
    isRequired,
    className,
    id,
    labelClassName,
    dropdownClassName,
    inputContainerClassName,
    optionItemClassName,
    optionItemContainerClassName,
    activeOptionItemContainerClassName,
    activeOptionItemClassName,
    searchLoading,
    handleInputChange,
    customDropdownContainer,
    customRightDropdownContainer,
    inputClassName,
    iconClassName,
    floatingPlaceholderClass,
    hideSelectedIcon,
    clearValue,
    rightDropdownContainerClassName,
    idx,
    onClose,
}: SelectInputProps) => {
    const [open, setOpen] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [selectedIcon, setSelectedIcon] = useState<string | JSX.Element | null>(null);
    const [filter, setfilter] = useState("");
    const [selectedValue, setSelectedValue] = useState<string>("");
    const customDropdown = document.getElementById("custom-select");
    let lastScrollPosition = 0;
    let [lastScrollPositions, setLastScrollPositions] = useState<number>(0);

    const selectedOption = dropdownOptions?.find((item) => {
        if (typeof item.label === "string") {
            if (item?.value.includes("split")) {
                const splitValue = item?.value?.split("split")[0];
                return splitValue === value;
            } else {
                return item?.value?.toLowerCase() === value?.toLowerCase();
            }
        } else if (typeof item.label === "object" && item.label?.props) {
            const labelElement = item.label;
            const labelText = extractTextFromJSX(labelElement?.props?.children);

            if (typeof labelText === "string") {
                return item?.value === value;
            }
        }
        return false;
    });

    const ref = useRef<HTMLDivElement>(null);
    const inputRef = useRef(null);
    const toggleRef = useRef(null);

    function extractTextFromJSX(jsxElement: string | JSX.Element): string {
        if (typeof jsxElement === "string") {
            return jsxElement;
        } else if (Array.isArray(jsxElement)) {
            return jsxElement.map(extractTextFromJSX).join(" ");
        } else if (jsxElement?.props && jsxElement?.props?.children) {
            return extractTextFromJSX(jsxElement.props.children);
        } else {
            return "";
        }
    }

    const filteredOptions = isTyping
        ? dropdownOptions?.filter((item) => {
              const labelElement = item?.label;
              if (typeof labelElement === "object" && labelElement.props) {
                  const labelText = extractTextFromJSX(labelElement);
                  return (
                      labelText.toLowerCase().includes(filter.toLowerCase()) ||
                      item?.value?.toLowerCase().includes(filter.toLowerCase())
                  );
              }
              const valueSearch =
                  typeof item?.value === "object"
                      ? JSON.stringify(item?.value)?.toLowerCase().includes(filter.toLowerCase())
                      : item?.value?.toLowerCase().includes(filter.toLowerCase());

              return (
                  (item?.label as string)?.toLowerCase().includes(filter.toLowerCase()) ||
                  valueSearch
              );
          })
        : dropdownOptions;

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsTyping(true);
        setOpen(true);
        // handleChange?.(name as string, event.target.value);
        setSelectedIcon(null);
        setfilter(event.target.value);
    };

    const handleSelect = useCallback(
        (label: string | JSX.Element, value: string, icon: React.ReactNode, rawData?: any) => {
            handleChange?.(name as string, value, rawData);
            const selected = dropdownOptions?.find(
                (item) => item?.value === value && item?.label === label
            );
            if (selected) {
                const labelText = extractTextFromJSX(selected?.label);
                const firstSpaceIndex = labelText.indexOf(" ");
                setSelectedIcon(selected?.icon || null);
                setfilter(labelText);
                setSelectedValue(value);
            }
            handleInputChange?.({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
            setIsTyping(false);
            setOpen(false);
            onClose?.();
        },

        [dropdownOptions, handleChange, name, setfilter, setIsTyping, setOpen, filter]
    );

    useEffect(() => {
        if (selectedOption) {
            setfilter(extractTextFromJSX(selectedOption?.label) ?? "");
        }

        if (selectedOption?.icon) {
            setSelectedIcon(selectedOption?.icon);
        }
        if (clearValue && !value) {
            setfilter("");
        }
        // if (value) {
        //     setfilter(value);
        // }
    }, [value]);

    useEffect(() => {
        if (selectedOption) {
            setfilter(extractTextFromJSX(selectedOption?.label) ?? "");
        }
    }, [selectedOption]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && event.target !== toggleRef.current) {
                if (!ref.current.contains(event.target as Node)) {
                    setOpen(false);
                    onClose?.();

                    if (!value) {
                        setfilter("");
                    }
                    // if (selectedValue !== value ||  !selectedValue) {
                    //     setfilter("");
                    //     handleChange?.(name as string, "");
                    //     handleInputChange?.({
                    //         target: { value: "" },
                    //     } as React.ChangeEvent<HTMLInputElement>);
                    // }
                }
            }

            if (!event.target) setOpen(false);
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, open, value, selectedValue]);

    useEffect(() => {
        const scrollToSelectedItem = () => {
            if (ref.current) {
                ref.current.scrollTop = lastScrollPositions;
            }
        };
        document.addEventListener("click", scrollToSelectedItem);

        return () => {
            document.removeEventListener("click", scrollToSelectedItem);
        };
    }, [open]);

    useEffect(() => {
        const handleScroll = () => {
            if (ref.current) {
                setLastScrollPositions(ref.current.scrollTop);
            }
        };

        if (ref.current && open) {
            ref.current.addEventListener("click", handleScroll);
            return () => {
                if (ref.current) {
                    ref.current.removeEventListener("click", handleScroll);
                }
            };
        }
    }, [open, ref]);

    return (
        <div className="w-full" ref={ref}>
            {Boolean(label) && (
                <div className={`text-sm mb-1 font-rocGroteskMedium ${labelClassName}`}>
                    {label}
                </div>
            )}
            <div className="relative">
                <div
                    onClick={() => setOpen(!open)}
                    className={`flex justify-between cursor-pointer rounded items-center h-[50px] border-[0.5px] ${
                        isFocused ? "!border-g-60 !border-b-[1px]" : "border-gm-25"
                    } ${
                        selectedIcon && filter && "pl-4 max-475:pl-3"
                    } pr-2 475:pr-4 outline-0 w-full h-[50px] ${
                        disabled && "!bg-slate-50"
                    } py-3 text-sm font-rocGrotesk font-medium ${className}`}
                >
                    {selectedIcon && filter && !hideSelectedIcon && (
                        <span className={`mb-[-8px]`}>{selectedIcon}</span>
                    )}
                    <div className={`w-full mb-[-8px] ${inputContainerClassName}`}>
                        {idx >= 0 ? (
                            <div>
                                <a id={`product-name-column-${idx}`}>
                                    <input
                                        className={`inputText w-full outline-0 h-full max-475:pl-3 ${inputClassName} ${
                                            selectedIcon && !hideSelectedIcon
                                                ? "pl-2"
                                                : selectedIcon && hideSelectedIcon
                                                ? "pl-0"
                                                : "pl-4"
                                        } bg-transparent rounded font-rocGroteskRegular outline-none text-gm-50 focus:border-0 placeholder:text-sm placeholder:!font-rocGroteskRegular`}
                                        placeholder={inputPlaceholder}
                                        type={"text"}
                                        value={filter || value}
                                        id={id}
                                        ref={inputRef}
                                        disabled={disabled}
                                        onChange={handleFilterChange}
                                        onInput={handleInputChange}
                                        required={isRequired}
                                        onFocus={() => setIsFocused(true)}
                                        onBlur={() => setIsFocused(false)}
                                    />
                                    {placeholder && (
                                        <span
                                            className={`floating-label text-sm text-gm-35 font-rocGroteskRegular ${floatingPlaceholderClass}`}
                                        >
                                            {placeholder}
                                            {isRequired && !isFocused && (
                                                <span className="required text-r-50 text-sm mt-[-2px] font-rocGroteskMedium">
                                                    *
                                                </span>
                                            )}
                                        </span>
                                    )}
                                </a>

                                <Tooltip
                                    anchorSelect={`#product-name-column-${idx}`}
                                    place={"top"}
                                    content={filter || value}
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#1E293B",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>
                        ) : (
                            <>
                                <input
                                    className={`inputText w-full outline-0 h-full max-475:pl-3 ${inputClassName} ${
                                        selectedIcon && !hideSelectedIcon
                                            ? "pl-2"
                                            : selectedIcon && hideSelectedIcon
                                            ? "pl-0"
                                            : "pl-4"
                                    } bg-transparent rounded font-rocGroteskRegular outline-none text-gm-50 focus:border-0 placeholder:text-sm placeholder:!font-rocGroteskRegular`}
                                    placeholder={inputPlaceholder}
                                    type={"text"}
                                    value={filter || value}
                                    id={id}
                                    ref={inputRef}
                                    disabled={disabled}
                                    onChange={handleFilterChange}
                                    onInput={handleInputChange}
                                    required={isRequired}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                />
                                {placeholder && (
                                    <span
                                        className={`floating-label text-sm text-gm-35 font-rocGroteskRegular ${floatingPlaceholderClass}`}
                                    >
                                        {placeholder}
                                        {isRequired && !isFocused && (
                                            <span className="required text-r-50 text-sm mt-[-2px] font-rocGroteskMedium">
                                                *
                                            </span>
                                        )}
                                    </span>
                                )}
                            </>
                        )}
                    </div>
                    {searchLoading ? (
                        <Loader size={5} color={"gm-50"} />
                    ) : (
                        <span
                            className={`material-icons text-2xl ${iconClassName} ${
                                isFocused ? "text-g-75" : "text-gm-25"
                            }`}
                            ref={toggleRef}
                            onClick={() => setOpen(!open)}
                        >
                            keyboard_arrow_down
                        </span>
                    )}
                </div>
                {open &&
                    !disabled &&
                    (!searchLoading && filteredOptions && filteredOptions?.length > 0 ? (
                        <div
                            id="custom-select"
                            className={`py-2 bg-white overflow-y-scroll absolute top-[101%] w-full h-auto max-h-64 z-[1200]
                             overflow-auto border ${dropdownClassName}`}
                        >
                            {customDropdownContainer && <div>{customDropdownContainer}</div>}
                            {filteredOptions.map((option, idx) => {
                                const isOptionSelected =
                                    selectedOption &&
                                    selectedOption.value === option.value &&
                                    selectedOption.label === option.label;

                                const optionLabel = option.label || "";

                                return (
                                    <div
                                        key={idx}
                                        onClick={() =>
                                            handleSelect(
                                                optionLabel,
                                                option.value,
                                                option.icon,
                                                option.rawData
                                            )
                                        }
                                        className={`flex items-center px-4 justify-between hover:tradeally-blue ${
                                            isOptionSelected &&
                                            "tradeally-blue " + activeOptionItemContainerClassName
                                        } cursor-pointer ${optionItemContainerClassName}`}
                                    >
                                        <div
                                            className={`text-sm flex gap-3 py-3 items-center font-rocGroteskRegular ${optionItemClassName}`}
                                        >
                                            {option.icon && <span>{option.icon}</span>}{" "}
                                            <div>
                                                <div>{optionLabel}</div>
                                                {option?.subText && (
                                                    <div className="text-xs font-rocGroteskMedium text-slate-500">
                                                        {option?.subText}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {isOptionSelected && (
                                            <span
                                                className={`material-icons text-tradeally-black text-base ${activeOptionItemClassName}`}
                                            >
                                                done
                                            </span>
                                        )}
                                    </div>
                                );
                            })}
                            {customRightDropdownContainer && (
                                <div className={`${rightDropdownContainerClassName}`}>
                                    {customRightDropdownContainer}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div
                            className={`py-2 bg-white absolute top-[101%] w-full h-auto max-h-64 z-[999] overflow-auto border ${dropdownClassName}`}
                            ref={ref}
                        >
                            {customDropdownContainer && <div>{customDropdownContainer}</div>}
                            <div className="flex px-4 justify-between hover:tradeally-blue cursor-pointer">
                                <p className="text-sm flex gap-3 py-3 items-center font-rocGroteskRegular">
                                    <span>{searchLoading ? "Searching..." : "No Data"}</span>
                                </p>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default SelectInput;
