export const formatMoney = (isNotDecimal?: boolean) => {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: isNotDecimal ? 0 : 2,
        maximumFractionDigits: isNotDecimal ? 0 : 2,
    });

    const formatterWithDecimals = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formatterWithoutDecimals = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return {
        format: (value) => {
            if (isNotDecimal) {
                return formatter.format(value || 0);
            }
            if (Number.isInteger(Number(value))) {
                return formatterWithoutDecimals.format(value || 0);
            } else {
                return formatterWithDecimals.format(value || 0);
            }
        },
    };
};
