import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { productionActions } from "redux/Ldb/actions";

const useGetProductionPlanTabTwo = (scrollContent?: HTMLElement) => {
    const { productionId } = useParams();
    const [param] = useSearchParams();
    const duplicateId = param.get("duplicateId");
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [planDetails, setPlanDetails] = useState<any>();
    const [query, setQuery] = useState<string>("");
    const [pagination, setPagination] = useState({
        current: "",
        number_of_pages: "",
    });

    const { fetchingProductionPlanTabTwo, fetchedProductionPlanTabTwoSuccess } = useAppSelector(
        (state) => state.production
    );

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.current, number_of_pages: pagination.number_of_pages },
            10,
            scrollContent
        );

    const handleSearch = (e) => {
        setSearch(e?.target?.value);
    };

    const handleDebouncedSearch = debounce((e) => {
        setUpdatedPage(1);
        setDebouncedSearch(e?.target?.value);
    }, 800);

    useEffect(() => {
        setUpdatedPage(1);
        setPlanDetails([]);
    }, [query]);

    useEffect(() => {
        if (Boolean(fetchedProductionPlanTabTwoSuccess)) {
            if (query === "purchase_orders") {
                setPlanDetails((prev) =>
                    updatedPage === 1
                        ? uniqBy(
                              [...fetchedProductionPlanTabTwoSuccess?.plan?.purchaseOrders],
                              "_id"
                          )
                        : uniqBy(
                              [
                                  ...prev,
                                  ...fetchedProductionPlanTabTwoSuccess?.plan?.purchaseOrders,
                              ],
                              "_id"
                          )
                );
            } else if (query === "steps") {
                setPlanDetails((prev) =>
                    updatedPage === 1
                        ? uniqBy([...fetchedProductionPlanTabTwoSuccess?.plan?.steps], "_id")
                        : uniqBy(
                              [...prev, ...fetchedProductionPlanTabTwoSuccess?.plan?.steps],
                              "_id"
                          )
                );
            }

            setPagination({
                current: fetchedProductionPlanTabTwoSuccess.pagination?.current,
                number_of_pages: fetchedProductionPlanTabTwoSuccess.pagination?.number_of_pages,
            });

            dispatch(productionActions.resetFetchProductionPlanTabTwo());
        }
    }, [
        dispatch,
        fetchedProductionPlanTabTwoSuccess,
        productionId,
        duplicateId,
        query,
        debouncedSearch,
        updatedPage,
    ]);

    useEffect(() => {
        if ((productionId || duplicateId) && (query === "purchase_orders" || query === "steps")) {
            dispatch(
                productionActions.fetchProductionPlanTabTwo(
                    productionId || duplicateId,
                    query,
                    10,
                    updatedPage
                )
            );
        }
        return () => {
            dispatch(productionActions.resetFetchProductionPlanTabTwo());
        };
    }, [dispatch, productionId, duplicateId, query, debouncedSearch, updatedPage]);

    return {
        data: planDetails,
        setPlanDetails,
        isLoading: fetchingProductionPlanTabTwo,
        setQuery,
        debouncedSearch,
        setDebouncedSearch,
        handleDebouncedSearch,
        search,
        setSearch,
        handleSearch,
        isLoadingMore,
        pagination,
    };
};

export default useGetProductionPlanTabTwo;
