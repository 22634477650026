import React, { useEffect, useState } from "react";
import Button from "components/common/Button";
import { useNavigate, useParams } from "react-router-dom";
import useGetSingleTask from "hooks/workforceHooks/useGetSingleTask";
import PageFrame from "components/layout/PageFrame";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import moment from "moment";
import { displayFileIcon, getFromStorage, hasHTMLTag, sanitizeHtml } from "helpers";
import { truncate } from "lodash";
import ModalContainer from "components/common/ModalContainer";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { workforceActions } from "redux/Ldb/actions";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import TextareaInput from "components/common/InputField/TextareaInput";
import useGetUserProfile from "hooks/useGetUserProfile";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import { useChannel } from "ably/react";

const TaskDetail = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { data: taskInfo, isFetching } = useGetSingleTask();
    const {
        approvingTaskStep,
        approveTaskStepSuccess,
        retryingTaskStep,
        retryTaskStepSuccess,
        givingTaskStepFeedback,
        giveTaskStepFeedbackSuccess,
    } = useAppSelector((state) => state.workforce);
    const { taskId } = useParams();
    const [confirmApproval, setConfirmApproval] = useState<boolean>(false);
    const [confirmRetry, setConfirmRetry] = useState<boolean>(false);
    const [feedbackModal, setFeedbackModal] = useState<boolean>(false);
    const [feedbackSuccessModal, setFeedbackSuccessModal] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<string>("");
    const [singleTask, setSingleTask] = useState<{ [key: string]: any }>({});

    const [selectedStep, setSelectedStep] = useState<{ [key: string]: any }>({});
    const profile = getFromStorage("ally-user");

    const currentDay = moment();
    const runningDuration = moment(singleTask?.endDate || currentDay)?.diff(
        singleTask?.createdAt,
        "hours"
    );
    const runningDurationinHrs = moment(singleTask?.endDate || currentDay)?.diff(
        singleTask?.createdAt,
        "hours"
    );
    const runningDurationinMins = moment(singleTask?.endDate || currentDay)?.diff(
        singleTask?.createdAt,
        "minutes"
    );

    const stepsSuccessfullyRan = singleTask?.stepsRan?.filter(
        (step) => step?.status?.toLowerCase() === "completed"
    );

    const handleApprove = () => {
        let body: { [key: string]: any } = {
            stepId: selectedStep?._id,
            state: {},
        };

        if (selectedStep?.key === "order_assistant") {
            body = {
                ...body,
                state: {
                    answer: true,
                },
            };
        }

        if (selectedStep?.key === "order_create") {
            body = {
                ...body,
                state: {
                    order_creation_approved: true,
                },
            };
        }

        if (selectedStep?.key === "order_email_send") {
            body = {
                ...body,
                state: {
                    order_email_draft_approved: true,
                },
            };
        }

        dispatch(workforceActions.approveTaskStep(body));
    };

    const handleRetry = () => {
        const body = {
            stepId: selectedStep?._id,
        };

        dispatch(workforceActions.retryTaskStep(body));
    };

    const handleSubmitFeedback = () => {
        const body = {
            stepId: selectedStep?._id,
            feedback: feedback,
        };

        dispatch(workforceActions.giveTaskStepFeedback(body));
    };

    const {} = useChannel(
        `agent_tasks_${profile?.user?.parentId || profile?.user?._id}`,
        (taskData) => {
            const isStep = taskData?.name?.split("_")?.length === 3;

            if (isStep && taskData?.data?.task?._id === taskId) {
                const singleTaskCopy = { ...singleTask };
                let singleTaskStepRanCopy = [...singleTaskCopy?.stepsRan];
                const updatedStepIndex = singleTaskStepRanCopy?.findIndex(
                    (stepRan) => stepRan?.key === taskData?.data?.step?.key
                );
                singleTaskCopy.state = taskData?.data?.task?.state;

                if (updatedStepIndex !== -1) {
                    singleTaskStepRanCopy[updatedStepIndex] = taskData?.data?.step;
                } else {
                    singleTaskStepRanCopy = [...singleTaskStepRanCopy, taskData?.data?.step];
                }
                singleTaskCopy.stepsRan = singleTaskStepRanCopy;
                setSingleTask({
                    ...singleTaskCopy,
                    stepsRan:
                        updatedStepIndex !== -1
                            ? [...singleTaskStepRanCopy?.slice(0, updatedStepIndex + 1)]
                            : singleTaskStepRanCopy,
                });
            }
        }
    );

    useEffect(() => {
        if (taskInfo?._id) {
            setSingleTask(taskInfo);
        }
    }, [taskInfo?._id]);

    useEffect(() => {
        if (Boolean(approveTaskStepSuccess)) {
            setConfirmApproval(false);
            toast.custom((t) => (
                <CustomToast t={t} message={"Step approved successfully"} type={"success"} />
            ));
            dispatch(workforceActions.resetApproveTaskStepSuccess());
        }
    }, [dispatch, approveTaskStepSuccess]);

    useEffect(() => {
        if (Boolean(retryTaskStepSuccess)) {
            setConfirmRetry(false);
            toast.custom((t) => (
                <CustomToast t={t} message={"Re-run successful"} type={"success"} />
            ));
            dispatch(workforceActions.resetRetryTaskStepSuccess());
        }
    }, [dispatch, retryTaskStepSuccess]);

    useEffect(() => {
        if (Boolean(giveTaskStepFeedbackSuccess)) {
            setFeedback("");
            setFeedbackModal(false);
            setFeedbackSuccessModal(true);

            dispatch(workforceActions.resetGiveeTaskStepFeedbackSuccess());
        }
    }, [dispatch, giveTaskStepFeedbackSuccess]);

    return (
        <PageFrame isLoading={isFetching && !singleTask?.agent}>
            <div className="bg-white rounded-lg border border-n-20 relative font-rocGroteskMedium">
                <div className="flex items-center justify-between px-8 py-5 border-b border-n-20 sticky top-[-20px] bg-white z-[1000]">
                    <div className="flex items-center space-x-1.5">
                        <i
                            onClick={() => navigate(-1)}
                            className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                        ></i>
                        <span className="text-lg font-rocGroteskMedium">
                            {singleTask?.agent?.teamMateName} -{" "}
                            {camelCaseToRegularCase(singleTask?.taskType)} ({singleTask?.taId})
                        </span>
                    </div>
                    <div className="flex items-center space-x-3">
                        <Button
                            btnText="Close"
                            btnClassname="!px-4 !py-2 !bg-tradeally-neutral-20 !w-fit"
                            disabled={false}
                            onClick={() => navigate(-1)}
                        />
                    </div>
                </div>

                <div className="py-8 flex justify-center">
                    <div className="w-[63%]">
                        <div className="flex space-x-4 pb-6 border-b border-slate-200">
                            <div className="w-14 h-14 rounded-lg bg-p-50 flex justify-center items-center shadow-[0px_20px_24px_-4px_#10182814]">
                                <i className="ri-robot-fill text-white text-[32px]"></i>
                            </div>
                            <div>
                                <span className="block text-xs mb-[2px]">AI teammate</span>
                                <span className="block text-lg ">
                                    {singleTask?.agent?.teamMateName} -{" "}
                                    {camelCaseToRegularCase(singleTask?.taskType)} (
                                    {singleTask?.taId})
                                </span>
                            </div>
                        </div>

                        <div className="mt-10 font-rocGroteskMedium space-y-4 pb-10 border-b border-slate-200">
                            <div className="text-sm">
                                <span className="block text-slate-500">Task Name</span>
                                <span className="block">
                                    {camelCaseToRegularCase(singleTask?.taskType)} for{" "}
                                    {profile?.user?.profile?.businessName}
                                </span>
                            </div>
                            <div className="text-sm">
                                <span className="block text-slate-500">Current Step</span>
                                <span className="block">
                                    {
                                        singleTask?.stepsRan?.[singleTask?.stepsRan?.length - 1]
                                            ?.title
                                    }
                                </span>
                            </div>
                            <div className="text-sm">
                                <span className="block text-slate-500">Status</span>
                                <div className="flex w-fit items-center space-x-2">
                                    {/* <span className="w-2 h-2 rounded-full bg-[#22C55E]"></span> */}
                                    <span>{singleTask?.status}</span>
                                </div>
                            </div>
                            <div className="text-sm">
                                <span className="block text-slate-500">Task Duration</span>
                                <span className="block">
                                    {runningDurationinHrs
                                        ? `${runningDurationinHrs} hour(s)`
                                        : `${runningDurationinMins} min(s)`}
                                </span>
                            </div>
                            <div className="text-sm">
                                <span className="block text-slate-500">Channel</span>
                                <span className="block">
                                    {displayProductChannelIcon(
                                        singleTask?.source?.includes("microsoft")
                                            ? "microsoft"
                                            : singleTask?.source?.includes("gmail")
                                            ? "gmail-full"
                                            : "synth",
                                        "!w-8 !h-8"
                                    )}
                                </span>
                            </div>
                            {singleTask?.attachments?.length > 0 && (
                                <div className="text-sm">
                                    <span className="block text-slate-500">Attachments</span>
                                    <div className="flex flex-wrap gap-1.5">
                                        {singleTask?.attachments?.map((attachment) => {
                                            const fileName = attachment?.path
                                                ?.split("/")
                                                ?.slice(-1);

                                            return (
                                                <div
                                                    className="flex items-center p-3 rounded-[6px] space-x-4 border border-[#F1F5F9]"
                                                    key={attachment?._id}
                                                >
                                                    <div className="flex items-center space-x-3">
                                                        {displayFileIcon(
                                                            attachment?.ext?.slice(1),
                                                            true,
                                                            "w-[25px] h-[25px]"
                                                        )}
                                                        <div>
                                                            <p className="text-sm font-rocGroteskMedium text-slate-700">
                                                                {truncate(fileName?.[0], {
                                                                    length: 25,
                                                                })}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <p
                                                        onClick={() => {
                                                            const a = document.createElement("a");
                                                            a.href = attachment?.url;
                                                            a.target = "_blank";
                                                            document.body.appendChild(a);
                                                            a.click();
                                                            document.body.removeChild(a);
                                                        }}
                                                        className="cursor-pointer text-xs underline font-rocGroteskMedium text-tradeally-neutral-200"
                                                    >
                                                        View
                                                    </p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                            <div className="text-sm">
                                <span className="block text-slate-500">User Attention Needed:</span>
                                <span className="block">
                                    {singleTask?.status?.toLowerCase()?.includes("attention")
                                        ? "Yes"
                                        : "No"}
                                </span>
                            </div>
                        </div>

                        <div className="mt-10">
                            <div className="flex items-center justify-between mb-6">
                                <div className="w-fit font-rocGroteskMedium">
                                    <span className="block text-base">All activities</span>
                                    <span className="block text-slate-500 text-sm">
                                        Created on{" "}
                                        {moment(singleTask?.createdAt)
                                            .utc()
                                            .format("D MMM YYYY [at] hh:mma [UTC]")}
                                    </span>
                                </div>
                            </div>

                            <div>
                                {singleTask?.steps?.map((step, idx) => {
                                    return (
                                        <div key={step?._id}>
                                            <div className="flex items-center justify-between">
                                                {singleTask?.stepsRan?.[
                                                    idx
                                                ]?.status?.toLowerCase() === "completed" ? (
                                                    <div className="w-6 h-6 rounded-full bg-carribean-green flex items-center justify-center">
                                                        <i className="ri-checkbox-circle-fill text-white text-[13px]"></i>
                                                    </div>
                                                ) : (
                                                    <div className="w-6 h-6 rounded-full bg-y-10 flex items-center justify-center">
                                                        {stepsSuccessfullyRan?.length === idx ? (
                                                            <div className="relative flex items-center justify-center">
                                                                <i className="ri-reset-right-fill step-in-progress text-black text-[13px]"></i>
                                                                <div className="h-[4px] w-[3px] ml-[1px] border-b border-l border-black absolute" />
                                                            </div>
                                                        ) : (
                                                            <i className="ri-time-line text-black text-[13px]"></i>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className={`pb-3 pl-4 ml-2.5 ${
                                                    idx < singleTask?.steps?.length - 1 &&
                                                    "border-l"
                                                } border-slate-200`}
                                            >
                                                <div className="mt-[-20px] border border-slate-200 rounded-[6px] shadow-boxShadow-12">
                                                    {singleTask?.stepsRan?.[idx] ? (
                                                        <div className="p-4 border-b border-slate-200">
                                                            <div className="flex items-center justify-between mb-2">
                                                                <span
                                                                    className={`text-xs px-1.5 flex items-center justify-center h-5 rounded border ${
                                                                        singleTask?.stepsRan?.[
                                                                            idx
                                                                        ]?.status?.toLowerCase() ===
                                                                        "completed"
                                                                            ? "border-[#16A34A] text-[#16A34A] bg-[#DCFCE7]"
                                                                            : "border-slate-200 bg-slate-100"
                                                                    }`}
                                                                >
                                                                    Step {idx + 1}
                                                                </span>

                                                                <div className="flex items-center space-x-3">
                                                                    {singleTask?.stepsRan?.[
                                                                        idx
                                                                    ]?.status?.toLowerCase() ===
                                                                    "completed" ? (
                                                                        <div className="flex w-fit items-center space-x-2">
                                                                            <span className="w-2 h-2 rounded-full bg-[#22C55E]"></span>
                                                                            <span className="text-sm ">
                                                                                {
                                                                                    singleTask
                                                                                        ?.stepsRan?.[
                                                                                        idx
                                                                                    ]?.status
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    ) : !singleTask?.stepsRan?.[
                                                                          idx
                                                                      ]?.status
                                                                          ?.toLowerCase()
                                                                          ?.includes(
                                                                              "attention"
                                                                          ) ? (
                                                                        <div className="flex w-fit items-center space-x-2">
                                                                            <span className="w-2 h-2 rounded-full bg-[#EAB308]"></span>
                                                                            <span className="text-sm ">
                                                                                {
                                                                                    singleTask
                                                                                        ?.stepsRan?.[
                                                                                        idx
                                                                                    ]?.status
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    ) : null}

                                                                    {singleTask?.stepsRan?.[
                                                                        idx
                                                                    ]?.status
                                                                        ?.toLowerCase()
                                                                        ?.includes("attention") && (
                                                                        <div
                                                                            className={`w-fit pl-1.5 pr-2 py-1 bg-[#FEE2E2] flex items-center space-x-1.5 h-[25px] rounded`}
                                                                        >
                                                                            <i
                                                                                className={`ri-information-line text-base text-[#DC2626]`}
                                                                            ></i>
                                                                            <span
                                                                                className={`text-xs text-[#DC2626]`}
                                                                            >
                                                                                User Attention
                                                                                Needed
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <span className="block text-base mb-1">
                                                                    {
                                                                        singleTask?.stepsRan?.[idx]
                                                                            ?.title
                                                                    }
                                                                </span>

                                                                {singleTask?.stepsRan?.[idx]
                                                                    ?.approved ? (
                                                                    <div className="space-y-1.5">
                                                                        {singleTask?.stepsRan?.[idx]
                                                                            ?.key ===
                                                                            "order_check" && (
                                                                            <p className="text-sm">
                                                                                <span className="">
                                                                                    Is an order:
                                                                                </span>{" "}
                                                                                <span className="text-slate-500">
                                                                                    {singleTask
                                                                                        ?.stepsRan?.[
                                                                                        idx
                                                                                    ]?.data?.answer
                                                                                        ? "Yes"
                                                                                        : "No"}
                                                                                </span>
                                                                            </p>
                                                                        )}

                                                                        {singleTask?.stepsRan?.[idx]
                                                                            ?.key ===
                                                                            "order_assistant" && (
                                                                            <div className="space-y-1">
                                                                                <p className="text-sm ">
                                                                                    <span className="">
                                                                                        Order
                                                                                        summary:
                                                                                    </span>{" "}
                                                                                    <span className="text-slate-500">
                                                                                        {
                                                                                            singleTask
                                                                                                ?.state
                                                                                                ?.order_info
                                                                                                ?.order_summary
                                                                                        }
                                                                                    </span>
                                                                                </p>
                                                                                <p className="text-sm ">
                                                                                    <span className="">
                                                                                        Name:
                                                                                    </span>{" "}
                                                                                    <span className="text-slate-500">
                                                                                        {singleTask
                                                                                            ?.state
                                                                                            ?.order_info
                                                                                            ?.company ||
                                                                                            "N/A"}
                                                                                    </span>
                                                                                </p>
                                                                                <p className="text-sm ">
                                                                                    <span className="">
                                                                                        Email:
                                                                                    </span>{" "}
                                                                                    <span className="text-slate-500">
                                                                                        {singleTask
                                                                                            ?.state
                                                                                            ?.order_info
                                                                                            ?.email ||
                                                                                            "N/A"}
                                                                                    </span>
                                                                                </p>
                                                                                <p className="text-sm ">
                                                                                    <span className="">
                                                                                        Delivery
                                                                                        date:
                                                                                    </span>{" "}
                                                                                    <span className="text-slate-500">
                                                                                        {singleTask
                                                                                            ?.state
                                                                                            ?.order_info
                                                                                            ?.delivery_date
                                                                                            ? moment(
                                                                                                  singleTask
                                                                                                      ?.state
                                                                                                      ?.order_info
                                                                                                      ?.delivery_date
                                                                                              )?.format(
                                                                                                  "MMM DD, YYYY"
                                                                                              )
                                                                                            : "N/A"}
                                                                                    </span>
                                                                                </p>
                                                                                <p className="text-sm ">
                                                                                    <span className="">
                                                                                        Delivery
                                                                                        address:
                                                                                    </span>{" "}
                                                                                    <span className="text-slate-500">
                                                                                        {singleTask
                                                                                            ?.state
                                                                                            ?.order_info
                                                                                            ?.delivery_address ||
                                                                                            "N/A"}
                                                                                    </span>
                                                                                </p>
                                                                                <p className="text-sm ">
                                                                                    <span className="">
                                                                                        Products:
                                                                                    </span>{" "}
                                                                                    <span className="text-slate-500">
                                                                                        {singleTask
                                                                                            ?.state
                                                                                            ?.order_info
                                                                                            ?.products
                                                                                            ?.length >
                                                                                        0
                                                                                            ? singleTask?.state?.order_info?.products
                                                                                                  ?.map(
                                                                                                      (
                                                                                                          product
                                                                                                      ) =>
                                                                                                          product?.name
                                                                                                  )
                                                                                                  ?.join(
                                                                                                      ", "
                                                                                                  )
                                                                                            : "N/A"}
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        )}

                                                                        {singleTask?.stepsRan?.[idx]
                                                                            ?.key ===
                                                                            "order_create" && (
                                                                            <p className="text-sm text-slate-500">
                                                                                {
                                                                                    singleTask
                                                                                        ?.stepsRan?.[
                                                                                        idx
                                                                                    ]?.data
                                                                                        ?.order_info
                                                                                        ?.order_summary
                                                                                }
                                                                            </p>
                                                                        )}

                                                                        {singleTask?.stepsRan?.[idx]
                                                                            ?.key ===
                                                                            "order_email_draft" && (
                                                                            <div className="p-4 bg-[#FFFDF7]">
                                                                                <span className="block text-sm font-rocGroteskMedium mb-2">
                                                                                    Draft Email:
                                                                                </span>
                                                                                <span className="block text-sm font-rocGroteskMedium mb-[2px]">
                                                                                    Subject:
                                                                                </span>
                                                                                <span className="block text-sm font-rocGroteskMedium mb-2">
                                                                                    {
                                                                                        singleTask
                                                                                            ?.stepsRan?.[
                                                                                            idx
                                                                                        ]?.data
                                                                                            ?.order_email_draft_subject
                                                                                    }
                                                                                </span>
                                                                                <div
                                                                                    className="text-sm text-slate-500"
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: hasHTMLTag(
                                                                                            singleTask
                                                                                                ?.stepsRan?.[
                                                                                                idx
                                                                                            ]?.data
                                                                                                ?.order_email_draft_content
                                                                                        )
                                                                                            ? sanitizeHtml(
                                                                                                  singleTask
                                                                                                      ?.stepsRan?.[
                                                                                                      idx
                                                                                                  ]
                                                                                                      ?.data
                                                                                                      ?.order_email_draft_content
                                                                                              ).sanitizedHTML?.join(
                                                                                                  ""
                                                                                              )
                                                                                            : singleTask
                                                                                                  ?.stepsRan?.[
                                                                                                  idx
                                                                                              ]
                                                                                                  ?.data
                                                                                                  ?.order_email_draft_content,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <p className="text-sm text-slate-500">
                                                                        Please approve this step to
                                                                        proceed
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="p-4 border-b border-slate-200">
                                                            <div className="flex items-center justify-between mb-2">
                                                                <span
                                                                    className={`text-xs px-1.5 flex items-center justify-center h-5 rounded border ${
                                                                        singleTask?.steps?.[
                                                                            idx
                                                                        ]?.status?.toLowerCase() ===
                                                                        "completed"
                                                                            ? "border-[#16A34A] text-[#16A34A] bg-[#DCFCE7]"
                                                                            : "border-slate-200 bg-slate-100"
                                                                    }`}
                                                                >
                                                                    Step {idx + 1}
                                                                </span>
                                                            </div>

                                                            <div>
                                                                <span className="block text-base mb-1">
                                                                    {
                                                                        singleTask?.steps?.[idx]
                                                                            ?.title
                                                                    }
                                                                </span>
                                                                <div className="space-y-1.5">
                                                                    <p className="text-sm text-slate-500">
                                                                        {
                                                                            singleTask?.steps?.[idx]
                                                                                ?.description
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {singleTask?.stepsRan?.[idx] && (
                                                        <div className="p-4 flex items-center space-x-3">
                                                            {!singleTask?.stepsRan?.[idx]
                                                                ?.approved && (
                                                                <Button
                                                                    btnText="Approve step"
                                                                    onClick={() => {
                                                                        setConfirmApproval(true);
                                                                        setSelectedStep(
                                                                            singleTask?.stepsRan?.[
                                                                                idx
                                                                            ]
                                                                        );
                                                                    }}
                                                                    btnClassname="!px-[14px] !py-2 !w-fit !h-[36px]"
                                                                />
                                                            )}
                                                            {singleTask?.stepsRan?.[idx]?.key ===
                                                                "order_assistant" && (
                                                                <Button
                                                                    btnText="Edit Order details"
                                                                    icon={
                                                                        <i className="ri-edit-2-line text-base"></i>
                                                                    }
                                                                    // disabled={
                                                                    //     !singleTask?.stepsRan?.[
                                                                    //         idx
                                                                    //     ]?.approved
                                                                    // }
                                                                    onClick={() => {
                                                                        // if (
                                                                        //     singleTask
                                                                        //         ?.stepsRan?.[
                                                                        //         idx
                                                                        //     ]?.approved
                                                                        // ) {
                                                                        navigate(
                                                                            `/dashboard/task/${taskId}/edit-order`
                                                                        );
                                                                        // }
                                                                    }}
                                                                    btnClassname="!px-[14px] !py-2 !w-fit !h-[36px] !bg-transparent !border !border-slate-200"
                                                                />
                                                            )}
                                                            <Button
                                                                btnText="Rerun this step"
                                                                icon={
                                                                    <i className="ri-restart-line text-base"></i>
                                                                }
                                                                disabled={
                                                                    !singleTask?.stepsRan?.[idx]
                                                                        ?.approved
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        singleTask?.stepsRan?.[idx]
                                                                            ?.approved
                                                                    ) {
                                                                        setSelectedStep(
                                                                            singleTask?.stepsRan?.[
                                                                                idx
                                                                            ]
                                                                        );
                                                                        setConfirmRetry(true);
                                                                    }
                                                                }}
                                                                btnClassname="!px-[14px] !py-2 !w-fit !h-[36px] !bg-transparent !border !border-slate-200"
                                                            />
                                                            <Button
                                                                btnText="Give feedback and re-run step"
                                                                icon={
                                                                    <i className="ri-chat-2-line text-base"></i>
                                                                }
                                                                disabled={
                                                                    !singleTask?.stepsRan?.[idx]
                                                                        ?.approved
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        singleTask?.stepsRan?.[idx]
                                                                            ?.approved
                                                                    ) {
                                                                        setSelectedStep(
                                                                            singleTask?.stepsRan?.[
                                                                                idx
                                                                            ]
                                                                        );
                                                                        setFeedbackModal(true);
                                                                    }
                                                                }}
                                                                btnClassname="!px-[14px] !py-2 !w-fit !h-[36px] !bg-transparent !border !border-slate-200"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalContainer
                open={confirmApproval}
                showCloseIcon={false}
                modalClassName={"py-5 px-5"}
                closeModal={() => setConfirmApproval(false)}
                tailwindClassName=" w-[42%] "
            >
                <div className="bg-white flex flex-col rounded-[8px]  ">
                    <div className="mt-5 px-6 flex justify-end ">
                        <i
                            onClick={() => setConfirmApproval(false)}
                            className="ri-close-fill text-gm-45 text-lg cursor-pointer "
                        ></i>
                    </div>
                    <div className="px-5 my-2 ">
                        <div>
                            <h6 className="text-center font-rocGroteskMedium text-[16px]">
                                Are you sure you want to approve this step?
                            </h6>
                        </div>
                        <div className="mt-5 mb-3 space-x-2 flex items-center">
                            <Button
                                btnText={"No, close"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => setConfirmApproval(false)}
                                disabled={approvingTaskStep}
                            />
                            <Button
                                btnText={"Yes, approve step"}
                                type={"button"}
                                btnClassname="!py-3 !bg-[#DCFCE7] border border-[#16A34A] !text-dark-gray-2 !px-4"
                                onClick={handleApprove}
                                isLoading={approvingTaskStep}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={confirmRetry}
                showCloseIcon={false}
                modalClassName={"py-5 px-5"}
                closeModal={() => setConfirmRetry(false)}
                tailwindClassName=" w-[42%] "
            >
                <div className="bg-white flex flex-col rounded-[8px]  ">
                    <div className="mt-5 px-6 flex justify-end ">
                        <i
                            onClick={() => setConfirmRetry(false)}
                            className="ri-close-fill text-gm-45 text-lg cursor-pointer "
                        ></i>
                    </div>
                    <div className="px-5 my-2 ">
                        <div>
                            <h6 className="text-center font-rocGroteskMedium text-[16px]">
                                You're about to rerun the agent task from this step. This will reset
                                progress from this point forward. Are you sure you want to proceed?
                            </h6>
                        </div>
                        <div className="mt-5 mb-3 space-x-2 flex items-center">
                            <Button
                                btnText={"No, close"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => setConfirmRetry(false)}
                                disabled={retryingTaskStep}
                            />
                            <Button
                                btnText={"Yes, re-run step"}
                                type={"button"}
                                btnClassname="!py-3 !bg-[#DCFCE7] border border-[#16A34A] !text-dark-gray-2 !px-4"
                                onClick={handleRetry}
                                isLoading={retryingTaskStep}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={feedbackModal}
                showCloseIcon={false}
                modalClassName={"py-5 px-5"}
                closeModal={() => setFeedbackModal(false)}
                tailwindClassName=" w-[42%] "
            >
                <div className="bg-white flex flex-col rounded-[8px]  ">
                    <div className="mt-5 px-6 flex justify-end ">
                        <i
                            onClick={() => setFeedbackModal(false)}
                            className="ri-close-fill text-gm-45 text-lg cursor-pointer "
                        ></i>
                    </div>
                    <div className="px-5 my-2 ">
                        <div className="mb-6">
                            <div className="flex justify-center">
                                <i className="ri-feedback-line text-[48px] mb-2.5"></i>
                            </div>

                            <h6 className="text-center font-rocGroteskMedium text-[22px]">
                                Provide Feedback on Completed Task
                            </h6>

                            <p className="text-base text-center font-rocGroteskMedium  text-slate-500 px-6">
                                Your AI teammate has completed the assigned task. Share your
                                feedback to help improve its future performance.
                            </p>
                        </div>

                        <div>
                            <div className="">
                                <TextareaInput
                                    name={"feedback"}
                                    value={feedback}
                                    onChange={(e) => setFeedback(e.target.value)}
                                    rows={5}
                                    placeholder={
                                        "Add or remove instructions, improvements, or suggestions here..."
                                    }
                                    containerClassname=""
                                />
                            </div>
                            <div>
                                <span className="text-xs text-slate-500 font-rocGroteskMedium">
                                    Once submitted, your feedback will be applied, and the task will
                                    re-run automatically
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="py-4 border-t border-slate-200 space-x-2 px-8 flex items-center">
                        <Button
                            btnText={"Cancel"}
                            type={"button"}
                            btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                            onClick={() => setFeedbackModal(false)}
                            disabled={givingTaskStepFeedback}
                        />
                        <Button
                            btnText={"Submit feedback and re-run step"}
                            type={"button"}
                            btnClassname="!py-3 border !text-dark-gray-2 !px-4"
                            onClick={handleSubmitFeedback}
                            disabled={!feedback}
                            isLoading={givingTaskStepFeedback}
                        />
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={feedbackSuccessModal}
                showCloseIcon={false}
                modalClassName={"py-5 px-5"}
                closeModal={() => setFeedbackSuccessModal(false)}
                tailwindClassName=" w-[42%] "
            >
                <div className="bg-white flex flex-col rounded-[8px]  ">
                    <div className="mt-5 px-6 flex justify-end ">
                        <i
                            onClick={() => setFeedbackSuccessModal(false)}
                            className="ri-close-fill text-gm-45 text-lg cursor-pointer "
                        ></i>
                    </div>
                    <div className="px-5 my-2 ">
                        <div className="mb-6">
                            <div className="flex justify-center">
                                <i className="ri-feedback-line text-[48px] mb-2.5"></i>
                            </div>

                            <h6 className="text-center font-rocGroteskMedium text-[22px]">
                                Feedback Received! Step Will Run Again
                            </h6>

                            <p className="text-base text-center font-rocGroteskMedium  text-slate-500 px-6">
                                Thank you for your feedback. Based on your input, this step will
                                automatically rerun to reflect the changes you suggested.
                            </p>
                        </div>

                        <div className="mb-8">
                            <span className="text-slate-500 font-rocGroteskMedium text-sm block mb-4">
                                What happens next:
                            </span>
                            <div className="border font-rocGroteskMedium border-slate-200 mb-4 rounded-[6px] px-2 py-3 shadow-[0px_4px_8px_-4px_#10182808]">
                                <ul className="list-disc text-sm ml-4 space-y-2">
                                    <li className="">
                                        Your AI teammate will update the task with the requested
                                        adjustments.
                                    </li>
                                    <li>
                                        The revised process will run again to ensure the task meets
                                        your expectations.
                                    </li>
                                </ul>
                            </div>

                            <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                You'll be notified once the task is completed with the new changes.
                            </span>
                        </div>
                    </div>
                    <div className="py-4 border-t border-slate-200 space-x-2 px-8 flex items-center">
                        <Button
                            btnText={"Close"}
                            type={"button"}
                            btnClassname="!py-3 border !text-dark-gray-2 !px-4"
                            onClick={() => setFeedbackSuccessModal(false)}
                        />
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default TaskDetail;
