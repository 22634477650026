import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { taskActions } from "../../redux/Ldb/actions/taskActions";

import { debounce, uniqBy } from "lodash";

const useGetFailedHumanTask = () => {
    const dispatch = useAppDispatch();

    const [failedHumanTaskList, setFailedHumanTaskList] = useState<{ [key: string]: any }[]>([]);
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [taskStatus, setTaskStatus] = useState<string>("failed");
    const [taskType, setTaskType] = useState<string>("");
    const { fetchingFailedHumanTask, fetchFailedHumanTaskSuccess, fetchFailedHumanTaskFailure } =
        useAppSelector((state) => state.task);

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            {
                current: pagination.currentPage,
                number_of_pages: pagination.noOfPages,
            },
            10
        );

    useEffect(() => {
        setUpdatedPage(1);
    }, [debouncedSearch, taskStatus, taskType]);

    useEffect(() => {
        taskStatus === "" && setFailedHumanTaskList([]);
    }, [taskStatus]);
    useEffect(() => {
        if (taskStatus) {
            dispatch(
                taskActions.fetchFailedHumanTaskList(
                    updatedLimit,
                    updatedPage,
                    taskStatus,
                    debouncedSearch,
                    taskType
                )
            );
        }
    }, [dispatch, debouncedSearch, updatedPage, taskStatus, taskType]);

    useEffect(() => {
        if (Boolean(fetchFailedHumanTaskSuccess)) {
            setFailedHumanTaskList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchFailedHumanTaskSuccess?.tasks], "_id")
                    : uniqBy([...prev, ...fetchFailedHumanTaskSuccess?.tasks], "_id")
            );

            setPagination({
                currentPage: fetchFailedHumanTaskSuccess.pagination?.current,
                noOfPages: fetchFailedHumanTaskSuccess.pagination?.number_of_pages,
            });

            setLoadMore(false);
        }
    }, [fetchFailedHumanTaskSuccess]);

    return {
        isFetching: fetchingFailedHumanTask,
        error: fetchFailedHumanTaskFailure,
        data: failedHumanTaskList,
        setTaskStatus,
        setFailedHumanTaskList,
        setTaskType,
        isLoadingMore,
        pagination,
        setUpdatedPage,
        setLoadMore,
        setDebouncedSearch,
    };
};

export default useGetFailedHumanTask;
