import React, { useEffect, useState } from "react";
import CustomTable from "components/common/CustomTable";
import EmptyStateComp from "components/common/EmptyStateComp";
import VendorAvatar from "components/common/VendorAvatar";
import { truncate } from "lodash";
import ChartView from "components/views/Synth2.0/Production/ProductionViewType/ChartView";
import ListView from "components/views/Synth2.0/Production/ProductionViewType/ListView";
import GridView from "components/views/Synth2.0/Production/ProductionViewType/GridView";
import moment from "moment";
import Dropdown from "components/common/Dropdown";
import useGetProductionPlanList from "hooks/ProductionHooks/useGetProductionPlanList";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

type productionProps = {
    search: string;
    setSearch: (data: string) => void;
    setStatus: (data: string) => void;
    setAssigneeId: (data: string) => void;
    data: any;
    assigneeId: string;
    status: string;
    isLoading: boolean;
    isLoadingMore: boolean;
    pagination: any;
    viewType: string;
    setViewType: (data: string) => void;
    chartSearch: string;
};

const ProductionPlan = ({
    search,
    setSearch,
    setStatus,
    setAssigneeId,
    data,
    assigneeId,
    status,
    isLoading,
    isLoadingMore,
    pagination,
    viewType,
    setViewType,
    chartSearch,
}: productionProps) => {
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const planTab = param.get("planTab");

    const [view, setView] = useState<string>("day");
    const period = ["day", "week", "month"];

    const [gridDate, setGridDate] = useState<any>(new Date());

    // const { data, assigneeId, status } = useGetProductionPlanList();

    useEffect(() => {
        setSearch("");
        setStatus("");
        setAssigneeId("");
    }, [viewType]);

    useEffect(() => {
        if (planTab === "1") {
            setViewType("list");
        } else if (planTab === "2") {
            setViewType("grid");
        } else {
            setViewType("list");
        }
    }, []);

    return (
        <div className="font-rocGroteskMedium relative">
            {viewType !== "grid" && (
                <div className="flex items-center justify-between p-4 border-t border-x border-slate-200 rounded-t-lg">
                    {viewType === "grid" && (
                        <div className="flex items-center justify-between p-4">
                            <div className="flex gap-2 ">
                                <button className="bg-slate-100 rounded-md p-[6px]">
                                    <i className="ri-arrow-right-line text-gm-50 text-sm"></i>
                                </button>
                                <button className="">Next</button>
                            </div>
                            {viewType === "grid" && (
                                <p className="text-base text-g-75 ">
                                    {/* Sun Aug 25, 2024  */}
                                    {moment(gridDate).format("MMM Do, YY")}
                                </p>
                            )}
                        </div>
                    )}

                    <p className="text-[#000] text-base">
                        {viewType === "chart" ? (
                            "Production Forecast"
                        ) : viewType === "list" ? (
                            <div className="flex items-center gap-1">
                                <p className="text-[#000] text-base">All production plan</p>
                                <div>
                                    <a id="plan">
                                        <i className="ri-information-fill text-slate-500 text-base"></i>
                                    </a>

                                    <Tooltip
                                        anchorSelect="#plan"
                                        place={"top"}
                                        content="The detailed schedule and resources allocated to meet production targets."
                                        style={{ width: "250px", zIndex: 9999 }}
                                    />
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </p>

                    <div className="flex gap-2 items-center">
                        <div className="flex">
                            <div className="border-slate-200 border rounded">
                                <button
                                    className={`${
                                        viewType === "list" && "bg-slate-200"
                                    } py-[6px] px-3 text-gm-50 text-[13px]`}
                                    onClick={() => setViewType("list")}
                                >
                                    <i className="ri-list-check"></i>
                                </button>
                                <button
                                    className={`${
                                        viewType === "grid" && "bg-slate-200"
                                    } py-[6px] px-3 text-gm-50 text-[13px] border-x border-slate-200`}
                                    onClick={() => setViewType("grid")}
                                >
                                    <i className="ri-layout-grid-line"></i>
                                </button>
                                <button
                                    className={`${
                                        viewType === "chart" && "bg-slate-200"
                                    } py-[6px] px-3 text-gm-50 text-[13px]  `}
                                    onClick={() => setViewType("chart")}
                                >
                                    <i className="ri-line-chart-line"></i>
                                </button>
                            </div>
                        </div>

                        {viewType === "grid" && (
                            <div className="border-slate-200 border rounded">
                                {period?.map((item, idx) => {
                                    return (
                                        <button
                                            key={idx}
                                            className={`${
                                                idx === 1 && "border-x border-x-slate-200"
                                            } ${
                                                view === item && "bg-slate-200"
                                            } py-[6px] px-3 text-gm-50 text-[13px] leading-normal`}
                                            onClick={() => setView(item)}
                                        >
                                            {item}
                                        </button>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            )}
            {viewType === "chart" ? (
                <ChartView chartSearch={chartSearch} />
            ) : viewType === "list" ? (
                <ListView
                    search={search}
                    setSearch={setSearch}
                    data={data}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    pagination={pagination}
                    assigneeId={assigneeId}
                    status={status}
                />
            ) : (
                viewType === "grid" && (
                    <div className="">
                        <GridView
                            setGridDate={setGridDate}
                            gridDate={gridDate}
                            view={view}
                            setView={setView}
                            viewType={viewType}
                            setViewType={setViewType}
                            data={data}
                        />

                        {(search || assigneeId || status) && data?.length === 0 ? (
                            <EmptyStateComp
                                title=""
                                description=" Sorry, no results match your search. Refine your criteria and
                                    try again."
                                icon={<i className="ri-building-3-line text text-[40px]"></i>}
                                contentContainerClass="flex flex-col items-center max-w-[537px] !bg-white"
                                descriptionClass="flex flex-col items-center justify-center"
                                className="absolute top-[10%] z-[1000]"
                            />
                        ) : (
                            data?.length === 0 && (
                                <div className="border-x border-b border-slate-200">
                                    <EmptyStateComp
                                        title="No production schedule plan found"
                                        description="It looks like you don't have any production schedule plans at the moment. To get started, create a new schedule plan now!"
                                        icon={
                                            <i className="ri-building-3-line text text-[40px]"></i>
                                        }
                                        hasButton={true}
                                        btnText="Create production plan"
                                        btnAction={() =>
                                            navigate("/dashboard/production/create-plan?planTab=2")
                                        }
                                        contentContainerClass="flex flex-col items-center max-w-[537px] !bg-white"
                                        descriptionClass="flex flex-col items-center"
                                        className="absolute top-[15%] z-[1000]"
                                    />
                                </div>
                            )
                        )}
                    </div>
                )
            )}
        </div>
    );
};

export default ProductionPlan;
