import React, { useState, useEffect } from "react";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import { displayFileIcon } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions, vendorActions } from "redux/Ldb/actions";
import Loader from "components/common/Loader";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import DateInput from "components/common/DateInput";
import { debounce } from "lodash";
import { productCategories } from "variables/productCategories";

const OfflinePurchase = ({
    stockData,
    handleStockData,
    attachment,
    uploadingAttachment,
    handleUpload,
    deleteAttachment,
    productViabilityDetails,
    handleDateChange,
    allWarehouse,
    addMoreWarehouse,
    handleWarehouses,
    setStockData,
}: any): JSX.Element => {
    const dispatch = useAppDispatch();
    const [showExpirationDate, setShowExpirationDate] = useState<boolean>(false);
    const [showBBDate, setShowBBDate] = useState<boolean>(false);
    const [showRemovalTimeDate, setShowRemovalTimeDate] = useState<boolean>(false);
    const [showManufactureDate, setShowManufactureDate] = useState<boolean>(false);
    const [debounceProductSearch, setDebounceProductSearch] = useState<string>();
    const [debounceWarehouseSearch, setDebounceWarehouseSearch] = useState<string>();
    const [productCategory, setProductCategory] = useState<string>("");

    const [inventory, setInventory] = useState<any>();
    const [warehouses, setWarehouses] = useState<any>();
    const [suppliers, setSuppliers] = useState<any>();

    const { fetchedOnboardedVendorsSuccess, fetchingOnboardedVendors } = useAppSelector(
        (state) => state?.vendor
    );
    const {
        fetchingWarehouses,
        fetchedWarehousesSuccess,
        fetchingInventoryList,
        fetchedInventoryListSuccess,
    } = useAppSelector((state) => state?.inventory);

    useEffect(() => {
        if (Boolean(fetchedInventoryListSuccess)) {
            setInventory(fetchedInventoryListSuccess?.inventory?.data);
        }
    }, [fetchedInventoryListSuccess]);

    useEffect(() => {
        if (Boolean(fetchedWarehousesSuccess)) {
            setWarehouses(fetchedWarehousesSuccess?.warehouses.data);
        }
    }, [fetchedWarehousesSuccess]);

    useEffect(() => {
        dispatch(
            inventoryActions.fetchInventoryList(20, 1, debounceProductSearch, productCategory)
        );
    }, [dispatch, debounceProductSearch, productCategory]);

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouses(20, debounceWarehouseSearch));
    }, [dispatch, debounceWarehouseSearch]);

    useEffect(() => {
        if (Boolean(fetchedOnboardedVendorsSuccess)) {
            setSuppliers(fetchedOnboardedVendorsSuccess?.vendors);
        }
    }, [fetchedOnboardedVendorsSuccess]);

    useEffect(() => {
        dispatch(vendorActions.getOnboardedVendors());
    }, [dispatch]);

    return (
        <div className="w-[65%]">
            <div>
                <div className="">
                    <p className="pb-4 font-rocGroteskMedium text-gun-metal text-base">
                        Stock details
                    </p>
                    <div className="flex flex-col gap-4">
                        <TextInput
                            value={stockData?.batchId || ""}
                            name={"batchId"}
                            type={"text"}
                            onChange={handleStockData}
                            placeholder={"Batch No"}
                            inputClassName={`${
                                stockData?.batchId && "!border-g-60 !border-[0.5px] !border-b-[1px]"
                            }`}
                        />

                        <SelectInput
                            value={productCategory}
                            name="inventoryCategory"
                            placeholder={"Product category"}
                            handleChange={(name, value) => {
                                setProductCategory(value);
                            }}
                            isRequired={true}
                            dropdownOptions={productCategories?.map((category) => ({
                                label: category.label,
                                value: category.value,
                            }))}
                        />

                        <div className="grid grid-cols-3 items-center gap-4 ">
                            <SelectInput
                                value={stockData?.inventoryId}
                                name="inventoryId"
                                placeholder={"Product"}
                                handleChange={(name, value) => {
                                    handleStockData({ target: { name, value } });
                                }}
                                isRequired={true}
                                disabled={!productCategory}
                                className={`${
                                    stockData?.inventoryId &&
                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                }`}
                                handleInputChange={debounce((evt) => {
                                    setDebounceProductSearch(evt.target.value);
                                }, 800)}
                                searchLoading={fetchingInventoryList}
                                dropdownOptions={inventory?.map((item) => ({
                                    label: <div className="flex gap-2">{item?.productName}</div>,
                                    value: item?._id,
                                }))}
                            />

                            <div className="">
                                <TextInput
                                    value={stockData?.totalQuantity || ""}
                                    name={"totalQuantity"}
                                    type={"number"}
                                    onChange={handleStockData}
                                    placeholder={"Quantity"}
                                    inputClassName={`${
                                        stockData?.totalQuantity &&
                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    }`}
                                    required
                                />
                            </div>

                            <div className="flex gap-1">
                                <div className="w-[160px]">
                                    <SelectInput
                                        value={stockData?.cost?.currency || ""}
                                        name="currency"
                                        placeholder="Currency"
                                        handleChange={(name, value) => {
                                            handleStockData({ target: { name, value } });
                                        }}
                                        className={`${
                                            stockData?.cost?.currency &&
                                            "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                        }`}
                                        isRequired={true}
                                        clearValue
                                        dropdownOptions={[
                                            {
                                                label: "GBP (£)",
                                                value: "GBP",
                                            },
                                            {
                                                label: "USD ($)",
                                                value: "USD",
                                            },
                                            {
                                                label: "EUR (€)",
                                                value: "EUR",
                                            },
                                            {
                                                label: "NGN (₦)",
                                                value: "NGN",
                                            },
                                        ]}
                                    />
                                </div>

                                <TextInput
                                    value={stockData?.cost?.amount || ""}
                                    name={"amount"}
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    onChange={handleStockData}
                                    placeholder={"Unit cost"}
                                    inputClassName={`${
                                        stockData?.cost?.amount &&
                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    }`}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-6">
                    <p className="pb-4 font-rocGroteskMedium text-gun-metal text-base">
                        Warehouse / Outlets
                    </p>
                    {allWarehouse?.map((item, idx) => {
                        return (
                            // <div className="flex flex-col gap-4" key={idx}>
                            <div className="grid grid-cols-2 items-center gap-4 mb-4 " key={idx}>
                                <SelectInput
                                    value={item?.warehouseId}
                                    name="warehouseId"
                                    placeholder={"Select Warehouse / Outlets"}
                                    handleChange={(name, value) => {
                                        handleWarehouses({ target: { name, value } }, idx);
                                    }}
                                    className={`${
                                        item?.warehouseId &&
                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    }`}
                                    searchLoading={fetchingWarehouses}
                                    isRequired={true}
                                    handleInputChange={debounce((evt) => {
                                        setDebounceWarehouseSearch(evt.target.value);
                                    }, 800)}
                                    dropdownOptions={
                                        warehouses
                                            ? warehouses?.map((item) => ({
                                                  label: item?.warehouseName,
                                                  value: item?._id,
                                              }))
                                            : []
                                    }
                                />
                                <TextInput
                                    value={item?.quantity || ""}
                                    name={"quantity"}
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    onChange={(e) => handleWarehouses(e, idx)}
                                    placeholder={"Quantity"}
                                    inputClassName={`${
                                        item?.quantity &&
                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    }`}
                                    required
                                />
                            </div>
                        );
                    })}

                    <div className="flex mt-1.5 items-center gap-1.5">
                        <div className="bg- rounded-full bg-[#FFF0CA] h-4 w-4 flex items-center justify-center">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1714096397/Vector_1_n1sjse.svg"
                                alt=""
                                className=""
                            />
                        </div>
                        <p className="text-slate-400 text-xs">
                            At least, one or more warehouse should be selected
                        </p>
                    </div>

                    <div
                        onClick={addMoreWarehouse}
                        className="flex items-center gap-2 text-p-50 font-rocGroteskMedium w-fit cursor-pointer mt-4"
                    >
                        <i className="ri-add-fill text-xl"></i>
                        <span className="underline text-sm">Add new warehouse</span>
                    </div>
                </div>

                <div className="mt-8">
                    <label className="text-base font-rocGroteskMedium pb-6">
                        Place this batch in quarantine?
                    </label>
                    <div className="flex gap-[52px] font-rocGroteskMedium text-base items-center">
                        <div
                            className="flex items-center gap-4"
                            onClick={() =>
                                setStockData((prev) => ({
                                    ...prev,
                                    quarantine: true,
                                }))
                            }
                        >
                            {stockData?.quarantine ? (
                                <i
                                    className={`ri-radio-button-fill text-xl cursor-pointer ${
                                        stockData?.quarantine && "text-y-10"
                                    }`}
                                ></i>
                            ) : (
                                <i className="ri-circle-line text-n-50 text-xl"></i>
                            )}
                            Yes
                        </div>
                        <div
                            className="flex items-center gap-4"
                            onClick={() =>
                                setStockData((prev) => ({
                                    ...prev,
                                    quarantine: false,
                                }))
                            }
                        >
                            {!stockData?.quarantine ? (
                                <i
                                    className={`ri-radio-button-fill text-xl cursor-pointer ${
                                        !stockData?.quarantine && "text-y-10"
                                    }`}
                                ></i>
                            ) : (
                                <i className="ri-circle-line text-n-50 text-xl"></i>
                            )}
                            No
                        </div>
                    </div>
                </div>

                <div className="mt-8">
                    <label className="text-base font-rocGroteskMedium pb-6">
                        Does the product expire?
                    </label>
                    <div className="flex gap-[52px] font-rocGroteskMedium text-base items-center">
                        <div
                            className="flex items-center gap-4"
                            onClick={() =>
                                setStockData((prev) => ({
                                    ...prev,
                                    canExpire: true,
                                }))
                            }
                        >
                            {stockData?.canExpire ? (
                                <i
                                    className={`ri-radio-button-fill text-xl cursor-pointer ${
                                        stockData?.canExpire && "text-y-10"
                                    }`}
                                ></i>
                            ) : (
                                <i className="ri-circle-line text-n-50 text-xl"></i>
                            )}
                            Yes
                        </div>
                        <div
                            className="flex items-center gap-4"
                            onClick={() =>
                                setStockData((prev) => ({
                                    ...prev,
                                    canExpire: false,
                                }))
                            }
                        >
                            {!stockData?.canExpire ? (
                                <i
                                    className={`ri-radio-button-fill text-xl cursor-pointer ${
                                        !stockData?.canExpire && "text-y-10"
                                    }`}
                                ></i>
                            ) : (
                                <i className="ri-circle-line text-n-50 text-xl"></i>
                            )}
                            No
                        </div>
                    </div>
                </div>

                <div className="mt-8">
                    <div className="mb-4">
                        <label className="text-base font-rocGroteskMedium">Supplier</label>
                    </div>
                    <SelectInput
                        value={stockData?.vendorId}
                        name="vendorId"
                        placeholder={"Select Supplier"}
                        handleChange={(name, value) => {
                            handleStockData({ target: { name, value } });
                        }}
                        searchLoading={fetchingOnboardedVendors}
                        // isRequired={true}
                        className={`${
                            stockData?.vendorId && "!border-g-60 !border-[0.5px] !border-b-[1px]"
                        }`}
                        dropdownOptions={suppliers?.map((item) => ({
                            label: item?.vendor?.profile?.businessName,
                            value: item?.vendorId,
                        }))}
                    />
                </div>
                <div className="">
                    <div className="flex flex-col gap-4 mt-8">
                        {stockData?.canExpire && (
                            <div>
                                <div className="mb-4">
                                    <label className="text-base font-rocGroteskMedium">
                                        Product lifecycle
                                    </label>
                                </div>
                                <div className="grid grid-cols-2 items-center gap-4">
                                    <DateInput
                                        label="Manufacture Date"
                                        value={productViabilityDetails?.manufacturingDate}
                                        onChange={(newDate) =>
                                            handleDateChange("manufacturingDate", newDate)
                                        }
                                        showCalendar={showManufactureDate}
                                        onCalendarToggle={setShowManufactureDate}
                                        required={true}
                                    />
                                    <DateInput
                                        label="Best before"
                                        value={productViabilityDetails?.expiryDate}
                                        onChange={(newDate) =>
                                            handleDateChange("expiryDate", newDate)
                                        }
                                        showCalendar={showBBDate}
                                        onCalendarToggle={setShowBBDate}
                                        required={true}
                                    />
                                </div>
                            </div>
                        )}

                        {/* <div className="grid grid-cols-2 items-center gap-4 ">
                            <DateInput
                                label="Expiration time"
                                value={productViabilityDetails?.expiryDate}
                                onChange={(newDate) => handleDateChange("expiryDate", newDate)}
                                showCalendar={showExpirationDate}
                                onCalendarToggle={setShowExpirationDate}
                            />
                            <DateInput
                                label="Removal time"
                                value={productViabilityDetails?.removalTime}
                                onChange={(newDate) => handleDateChange("removalTime", newDate)}
                                showCalendar={showRemovalTimeDate}
                                onCalendarToggle={setShowRemovalTimeDate}
                            />
                        </div> */}

                        <div className="mt-4">
                            <label className="text-base font-rocGroteskMedium">Documents</label>
                            <input
                                type="file"
                                className="hidden"
                                id="attachment"
                                // accept="image*, .pdf, .xlsx"
                                disabled={uploadingAttachment}
                                onChange={(e) => {
                                    if (e.target.files?.[0]) {
                                        handleUpload(
                                            e.target.files?.[0]?.name as string,
                                            e.target.files?.[0]?.size as number
                                        );
                                    }
                                }}
                            />
                            <div className="mt-4 ">
                                {attachment?.map((item, idx) => {
                                    return (
                                        <div className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4">
                                            <div className="flex items-center gap-2">
                                                {displayFileIcon(
                                                    getFileTypeFromUrl(item?.path),
                                                    false
                                                )}
                                                <div className="font-rocGroteskMedium">
                                                    <p className="text-sm">{item?.filename}</p>
                                                    <p className="text-xs text-slate-500">
                                                        {/* {item?.size} */}
                                                    </p>
                                                </div>
                                            </div>
                                            <i
                                                className="ri-delete-bin-line text-xl text-r-50 cursor-pointer"
                                                onClick={() => deleteAttachment(idx)}
                                            ></i>
                                        </div>
                                    );
                                })}

                                <label
                                    className={`flex items-center gap-4 py-3 px-6 rounded border border-dashed border-slate-200 md:w-[50%] ${
                                        !uploadingAttachment && "cursor-pointer"
                                    }`}
                                    htmlFor="attachment"
                                >
                                    <i className="ri-file-add-line text-2xl text-carribean-green "></i>
                                    {uploadingAttachment ? (
                                        <div className="">
                                            <Loader size={4} />
                                        </div>
                                    ) : (
                                        <p className="text-sm underline text-carribean-green font-rocGroteskMedium">
                                            Click to upload document
                                        </p>
                                    )}
                                </label>
                            </div>
                        </div>

                        <details open={false} className="mt-4">
                            <summary className="flex items-center justify-between text-base font-rocGroteskMedium border-b border-slate-200 cursor-pointer">
                                <h6 className={`text-base font-rocGroteskMedium `}>
                                    Additional info
                                </h6>
                                <div>
                                    <i
                                        className={`cursor-pointer text-slate-500 ri-arrow-down-s-line`}
                                    ></i>
                                </div>
                            </summary>

                            <div className="mt-4">
                                <TextareaInput
                                    name={"notes"}
                                    value={stockData?.notes || ""}
                                    onChange={handleStockData}
                                    rows={5}
                                    placeholder={"Add note"}
                                    required={false}
                                    inputContainerClassname={`${
                                        stockData?.notes &&
                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    }`}
                                />
                            </div>
                        </details>

                        {/* <div className="mt-4">
                            <label className="text-base font-rocGroteskMedium">
                                Additional info
                            </label>
                            <div className="mt-4">
                                <TextareaInput
                                    name={"notes"}
                                    value={stockData?.notes || ""}
                                    onChange={handleStockData}
                                    rows={5}
                                    placeholder={"Add note"}
                                    required={false}
                                    inputContainerClassname={`${
                                        stockData?.notes &&
                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    }`}
                                />
                            </div>
                        </div> */}

                        <details open={false} className="mt-4">
                            <summary className="flex items-center justify-between text-base font-rocGroteskMedium border-b border-slate-200 cursor-pointer">
                                <h6 className={`text-base font-rocGroteskMedium `}>
                                    Inspection note
                                </h6>
                                <div>
                                    <i
                                        className={`cursor-pointer text-slate-500 ri-arrow-down-s-line`}
                                    ></i>
                                </div>
                            </summary>

                            <div className="mt-4">
                                <TextareaInput
                                    name={"inspectionNote"}
                                    value={stockData?.inspectionNote || ""}
                                    onChange={handleStockData}
                                    rows={5}
                                    placeholder={"Add note"}
                                    required={false}
                                    inputContainerClassname={`${
                                        stockData?.inspectionNote &&
                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    }`}
                                />
                            </div>
                        </details>

                        {/* <div className="mt-4">
                            <label className="text-base font-rocGroteskMedium">
                                Inspection note
                            </label>
                            <div className="mt-4">
                                <TextareaInput
                                    name={"inspectionNote"}
                                    value={stockData?.inspectionNote || ""}
                                    onChange={handleStockData}
                                    rows={5}
                                    placeholder={"Add note"}
                                    required={false}
                                    inputContainerClassname={`${
                                        stockData?.inspectionNote &&
                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    }`}
                                />
                            </div>
                        </div> */}

                        <details open={false} className="mt-4">
                            <summary className="flex items-center justify-between text-base font-rocGroteskMedium border-b border-slate-200 cursor-pointer">
                                <h6 className={`text-base font-rocGroteskMedium `}>Condition</h6>
                                <div>
                                    <i
                                        className={`cursor-pointer text-slate-500 ri-arrow-down-s-line`}
                                    ></i>
                                </div>
                            </summary>

                            <div className="mt-4">
                                <TextareaInput
                                    name={"conditionNote"}
                                    value={stockData?.conditionNote || ""}
                                    onChange={handleStockData}
                                    rows={5}
                                    placeholder={"Add note"}
                                    required={false}
                                    inputContainerClassname={`${
                                        stockData?.conditionNote &&
                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    }`}
                                />
                            </div>
                        </details>

                        {/* <div className="mt-4">
                            <label className="text-base font-rocGroteskMedium">Condition</label>
                            <div className="mt-4">
                                <TextareaInput
                                    name={"conditionNote"}
                                    value={stockData?.conditionNote || ""}
                                    onChange={handleStockData}
                                    rows={5}
                                    placeholder={"Add note"}
                                    required={false}
                                    inputContainerClassname={`${
                                        stockData?.conditionNote &&
                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    }`}
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OfflinePurchase;
