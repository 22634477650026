import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { productionActions } from "redux/Ldb/actions";

const useGetSingleReport = () => {
    const { workOrderId } = useParams();
    const dispatch = useAppDispatch();
    const [reportDetails, setReportDetails] = useState<{ [key: string]: any }>({});
    const { fetchingSingleReport, fetchSingleReportSuccess } = useAppSelector(
        (state) => state.production
    );

    useEffect(() => {
        if (Boolean(fetchSingleReportSuccess)) {
            setReportDetails(fetchSingleReportSuccess);
        }
    }, [fetchSingleReportSuccess]);

    useEffect(() => {
        if (workOrderId) {
            dispatch(productionActions.fetchSingleReport(workOrderId));
        }
    }, [dispatch, workOrderId]);

    return {
        data: reportDetails,
        isLoading: fetchingSingleReport,
    };
};

export default useGetSingleReport;
