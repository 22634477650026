import moment from "moment";

export function calculateDateDifference(date1, date2) {
    const startDate = moment(date1);
    const endDate = moment(date2);

    const monthsDifference = endDate.diff(startDate, "months");
    const weeksDifference = endDate.diff(startDate, "weeks");
    const daysDifference = endDate.diff(startDate, "days");
    const hoursDifference = endDate.diff(startDate, "hours");
    // Decide which unit to return based on the difference
    if (Math.abs(monthsDifference) > 0) {
        return {
            time: monthsDifference,
            duration: `month${monthsDifference > 1 ? "s" : ""}`,
        };
    } else if (Math.abs(weeksDifference) > 0) {
        return {
            time: weeksDifference,
            duration: `week${weeksDifference > 1 ? "s" : ""}`,
        };
    } else if (Math.abs(daysDifference) > 0) {
        return {
            time: daysDifference,
            duration: `day${daysDifference > 1 ? "s" : ""}`,
        };
    } else {
        return {
            time: hoursDifference,
            duration: `hour${hoursDifference > 1 ? "s" : ""}`,
        };
    }
}
