import { BlobProvider } from "@react-pdf/renderer";
import Button from "components/common/Button";
import {
    displayFileIcon,
    formatMoney,
    getCurrencyFromCurrencyCode,
    hasHTMLTag,
    sanitizeHtml,
} from "helpers";
import React from "react";
import PurchaseOrdersPDF from "./PDFPurchase";
import CustomTable from "components/common/CustomTable";
import VendorAvatar from "components/common/VendorAvatar";
import moment from "moment";
import { truncate } from "lodash";

type PurchaseOrderDetailsProps = {
    successPreview: boolean;
    containers: { [key: string]: any }[];
    taxAmount: number;
    totalAmount: number;
    title: string;
    btnTextOne: string;
    btnTextTwo?: string;
    onClickOne?: () => void;
    onClickTwo?: () => void;
    poNumber?: string;
    imageDetails: string;
    disabled: boolean;
    cancelDisabled?: boolean;
    isLoading: boolean;
    imageUrl?: string;
    vendorEmail?: string;
    vendorAddress?: string;
    vendorName?: string;
    vendorPhone?: string;
    ldbEmail?: string;
    ldbAddress?: string;
    ldbPhone?: string;
    ldbName?: string;
    saveItem?: string;
    setSaveBlob?: React.Dispatch<React.SetStateAction<File | undefined | any>>;
    showBtn?: boolean;
    btnTextThree?: string;
    onClickThree?: () => void;
    uploading?: boolean;
    customerDetails?: { [key: string]: any };
    filesAttached?: { [key: string]: any };
    approvers?: { [key: string]: any };
    selectedRFQLabel?: string;
    userProfile?: { [key: string]: any };
    remainders?: number;
    grandTotal?: number;
    totalQuantity?: number;

    setShowSendNow?: React.Dispatch<React.SetStateAction<boolean>>;
    handleRemoveImage?: () => void;
};

const PurchaseOrderDetails = ({
    successPreview,
    title,
    btnTextOne,
    btnTextTwo,
    onClickOne,
    onClickTwo,
    imageDetails,
    disabled,
    isLoading,
    vendorEmail,
    vendorAddress,
    vendorName,
    ldbEmail,
    ldbAddress,
    ldbName,
    customerDetails,
    containers,
    grandTotal,
    totalQuantity,
    filesAttached,
    btnTextThree,
    onClickThree,
    totalAmount,
    taxAmount,
    setSaveBlob,
    ldbPhone,
    vendorPhone,
}: PurchaseOrderDetailsProps) => {
    const tableHeader = [
        { title: "No", widthClass: "w-[6%]" },
        { title: "Product name", widthClass: "w-[35%]" },
        { title: "SKU", widthClass: "w-[11%]" },
        { title: "Qty", widthClass: "w-[20%]" },
        { title: "Currency", widthClass: "w-[12%]" },
        { title: "Unit cost", widthClass: "w-[16%]" },
        { title: "Sub total", widthClass: "w-[20%]" },
        { title: "Delivery Date", widthClass: "w-[15%]" },
        { title: "Lead time", widthClass: "w-[12%]" },
    ];

    const tableBody = containers?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full border-slate-100 text-sm text-gm-50 font-rocGroteskMedium relative`}
                    >
                        <p>{idx + 1}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div key={idx} className={`py-2 pr-6 w-full border-slate-100`}>
                        <div className="font-rocGroteskMedium items-center flex space-x-2">
                            <VendorAvatar
                                imageSrc={item?.prodcutImageDetails?.avatar}
                                size={32}
                                containerClassname="!rounded-[4px]"
                                name={item?.productName}
                            />

                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-gm-50 ">{item.productName}</p>
                                <p className="text-slate-500 ">{item.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                widthClass: " ",
            },

            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gm-50 font-rocGroteskMedium font-roc relative`}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: hasHTMLTag(item.sku)
                                    ? sanitizeHtml(item.sku).sanitizedHTML?.join("")
                                    : item.sku,
                            }}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gm-50 font-rocGroteskMedium font-roc `}
                    >
                        <p>{formatMoney().format(item?.quantity)}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gm-50 font-rocGroteskMedium font-roc `}
                    >
                        <p>{item?.unitCostCurrency}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gm-50 font-rocGroteskMedium font-roc relative`}
                    >
                        <p>
                            {getCurrencyFromCurrencyCode(item?.unitCostCurrency)}
                            {formatMoney().format(item?.unitCostAmount)}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gm-50 font-rocGroteskMedium font-roc relative`}
                    >
                        <p>
                            {getCurrencyFromCurrencyCode(item?.unitCostCurrency)}
                            {formatMoney().format(item?.subtotal?.amount)}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gm-50 font-rocGroteskMedium font-roc relative`}
                    >
                        <p>{moment(item.deliveryDate).format("DD-MMM-YYYY")}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-gm-50 font-rocGroteskMedium font-roc relative`}
                    >
                        <p>{item.leadTime}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
        ];
    });

    return (
        <div className="bg-white rounded-lg pb-24 md:pb-0">
            <div className="max-lg:hidden flex items-center shadow-faintShadow border-b justify-between px-8 py-5 border-slate-100 top-[-20px] sticky  z-[50] bg-white">
                <div className="flex items-center gap-2">
                    <p className="text-[20px] leading-[33px] text-gm-50 font-rocGroteskMedium">
                        {title}
                    </p>
                </div>
                <div className="flex items-center gap-3">
                    {btnTextThree && (
                        <Button
                            btnText={btnTextThree}
                            type={"button"}
                            btnClassname="!py-2 border !whitespace-nowrap border border-n-20  cursor-pointer !bg-white !text-gm-50  !font-rocGroteskMedium !px-4"
                            onClick={onClickThree}
                            // isLoading={isLoading}
                            disabled={isLoading}
                        />
                    )}
                    {btnTextOne.toLowerCase() === "adjust po" && (
                        <Button
                            btnText={btnTextOne}
                            type={"button"}
                            btnClassname="!py-2 cursor-pointer !whitespace-nowrap !bg-[#F1F5F9] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4"
                            onClick={onClickOne}
                            disabled={disabled}
                        />
                    )}

                    {btnTextTwo && (
                        <BlobProvider
                            document={
                                <PurchaseOrdersPDF
                                    imageDetails={imageDetails}
                                    customerDetails={customerDetails}
                                    ldbName={ldbName}
                                    ldbEmail={ldbEmail}
                                    ldbAddress={ldbAddress}
                                    vendorName={vendorName}
                                    vendorEmail={vendorEmail}
                                    vendorAddress={vendorAddress}
                                    totalQuantity={totalQuantity}
                                    totalAmount={totalAmount}
                                    filesAttached={filesAttached}
                                    taxAmount={taxAmount}
                                    containers={containers}
                                    ldbPhone={ldbPhone}
                                    vendorPhone={vendorPhone}
                                />
                            }
                        >
                            {({ blob, url }) => {
                                return (
                                    <Button
                                        btnText={btnTextTwo}
                                        type={"button"}
                                        btnClassname="!py-2 cursor-pointer  !whitespace-nowrap !font-rocGroteskMedium !px-4"
                                        onClick={() => {
                                            if (blob) {
                                                const pdfUrl = new File([blob], "purchase-order", {
                                                    lastModified: new Date().getTime(),
                                                    type: blob?.type,
                                                });
                                                setSaveBlob(pdfUrl);
                                            }
                                            onClickTwo();
                                        }}
                                        disabled={disabled}
                                        isLoading={isLoading}
                                    />
                                );
                            }}
                        </BlobProvider>
                    )}
                </div>
            </div>

            <div className="md:px-6 md:border-r  md:border-l md:border-b border-slate-100  overflow-y-auto pt-10 pb-20">
                <div className="flex justify-between items-center">
                    <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-3 md:space-x-6 items-center mb-6 justify-center">
                        <div className="border  md:block hidden bg-[red] rounded-[4px]  border-slate-200 w-[128px] h-[128px] ">
                            <div className="w-full h-full">
                                {imageDetails === "" ? (
                                    <i className="ri-image-add-fill text-[35px] text-tradeally-neutral-200"></i>
                                ) : (
                                    <img
                                        src={imageDetails}
                                        alt="business-logo"
                                        // width={128}
                                        // height={128}
                                        className="shrink-0 object-cover w-full h-full"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="border border-dashed rounded-[4px] md:hidden block border-[n-40] w-[100px] h-[100px]">
                            {imageDetails === "" ? (
                                <i className="ri-image-add-fill text-[35px] text-tradeally-neutral-200"></i>
                            ) : (
                                <img
                                    src={imageDetails}
                                    alt="business-logo"
                                    width={100}
                                    height={100}
                                    className="shrink-0 object-cover w-full h-full"
                                />
                            )}
                        </div>
                    </div>
                    {successPreview ? (
                        <div className="bg-[#F0FDF4] px-2 py-1 rounded-[4px] cursor-pointer">
                            <p className="text-[#16A34A] text-sm">PO Created</p>
                        </div>
                    ) : (
                        <div className="bg-[#FFF7ED] whitespace-nowrap px-4 py-1 md:block hidden rounded-[4px]">
                            <p className="text-[#EA580C] text-sm">Not saved</p>
                        </div>
                    )}
                </div>
                <div className="w-full flex md:flex-row flex-col md:space-y-0 space-y-2 justify-between">
                    <div>
                        <p className="text-base font-rocGroteskMedium mb-2">From:</p>
                        <div className="text-sm font-rocGroteskMedium text-slate-500">
                            <p className="text-gm-50">{truncate(ldbName, { length: 60 })}</p>
                            <p>{truncate(ldbEmail, { length: 60 })}</p>
                            <p>{truncate(ldbAddress, { length: 60 })}</p>
                            <p>{truncate(ldbPhone, { length: 60 })}</p>
                        </div>
                    </div>
                    <div className="md:text-right">
                        <p className="text-base font-rocGroteskMedium mb-2">To:</p>
                        <div className="text-sm font-rocGroteskMedium text-slate-500">
                            <p className="text-gm-50">{truncate(vendorName, { length: 60 })}</p>
                            <p>{truncate(vendorAddress, { length: 60 })}</p>
                            <p>{truncate(vendorEmail, { length: 60 })}</p>
                            <p>{truncate(vendorPhone, { length: 60 })}</p>
                        </div>
                    </div>
                </div>

                <div className="mt-6 md:flex  justify-between hidden  w-full border-b border-t  py-5">
                    {/* <div>
                        <h6 className=" text-gm-50  font-rocGroteskMedium text-[14px]">
                            PO source
                        </h6>
                        <p className="text-slate-500 font-rocGroteskMedium text-[14px]">
                            {customerDetails?.source?.charAt(0)?.toUpperCase() +
                                customerDetails?.source?.slice(1)}
                        </p>
                    </div> */}
                    <div>
                        <h6 className=" text-gm-50  font-rocGroteskMedium text-[14px]">
                            Issued Date
                        </h6>
                        <p className="text-slate-500 font-rocGroteskMedium text-[14px]">
                            {customerDetails?.issuedDate
                                ? moment(customerDetails?.issuedDate).format("MMM DD, YYYY")
                                : moment().format("MMM DD, YYYY")}
                        </p>
                    </div>

                    <div>
                        <h6 className=" text-gm-50  font-rocGroteskMedium text-[14px]">Due Date</h6>
                        <p className="text-slate-500 font-rocGroteskMedium text-[14px]">
                            {moment(customerDetails?.dueDate).format("MMM DD, YYYY")}
                        </p>
                    </div>
                </div>

                {/* <div className="mt-6 pb-5 border-b md:block hidden ">
                    <p className="text-[14px] mb-2 text-gm-50 font-rocGroteskMedium">
                        Shipping information
                    </p>
                    <div className="mt-4 flex md:flex-row  justify-between flex-col  w-full">
                        <div>
                            <h6 className="  text-slate-500   font-rocGroteskMedium text-[14px]">
                                FOB
                            </h6>
                            <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                {customerDetails?.fobPoint?.charAt(0)?.toUpperCase() +
                                    customerDetails?.fobPoint?.slice(1)}
                            </p>
                        </div>
                        <div>
                            <h6 className="  text-slate-500   font-rocGroteskMedium text-[14px]">
                                Shipping via
                            </h6>
                            <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                {customerDetails?.shippingVia?.charAt(0)?.toUpperCase() +
                                    customerDetails?.shippingVia?.slice(1)}
                            </p>
                        </div>
                        <div>
                            <h6 className="  text-slate-500   font-rocGroteskMedium text-[14px]">
                                Shipping terms
                            </h6>
                            <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                {customerDetails?.shippingTerms?.charAt(0)?.toUpperCase() +
                                    customerDetails?.shippingTerms?.slice(1)}
                            </p>
                        </div>
                    </div>
                </div> */}
                <div className="md:hidden grid grid-cols-2 sm:grid-cols-3 items-center border px-3 rounded gap-3 mt-5  py-5">
                    <div>
                        <h6 className="  text-slate-500   font-rocGroteskMedium text-[14px]">
                            FOB
                        </h6>
                        <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                            {customerDetails?.fobPoint
                                ? customerDetails?.fobPoint?.charAt(0).toUpperCase() +
                                  customerDetails?.fobPoint?.slice(1)
                                : "N/A"}
                        </p>
                    </div>
                    <div>
                        <h6 className="  text-slate-500   font-rocGroteskMedium text-[14px]">
                            Shipping via
                        </h6>
                        <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                            {customerDetails?.shippingVia?.charAt(0)?.toUpperCase() +
                                customerDetails?.shippingVia?.slice(1) || "N/A"}
                        </p>
                    </div>
                    <div>
                        <h6 className="  text-slate-500   font-rocGroteskMedium text-[14px]">
                            Shipping terms
                        </h6>
                        <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                            {customerDetails?.shippingTerms?.charAt(0)?.toUpperCase() +
                                customerDetails?.shippingTerms?.slice(1) || "N/A"}
                        </p>
                    </div>
                    <div>
                        <h6 className=" text-gm-50  font-rocGroteskMedium text-[14px]">
                            PO source
                        </h6>
                        <p className="text-slate-500 font-rocGroteskMedium text-[14px]">
                            {customerDetails?.source
                                ? customerDetails?.source?.charAt(0).toUpperCase() +
                                  customerDetails?.source?.slice(1)
                                : "N/A"}
                        </p>
                    </div>
                    <div>
                        <h6 className=" text-gm-50  font-rocGroteskMedium text-[14px]">
                            Issued Date
                        </h6>
                        <p className="text-slate-500 font-rocGroteskMedium text-[14px]">
                            {customerDetails?.issuedDate
                                ? moment(customerDetails?.issuedDate).format("MMM DD, YYYY")
                                : "N/A"}
                        </p>
                    </div>

                    <div>
                        <h6 className=" text-gm-50  font-rocGroteskMedium text-[14px]">Due Date</h6>
                        <p className="text-slate-500 font-rocGroteskMedium text-[14px]">
                            {customerDetails?.dueDate
                                ? moment(customerDetails?.dueDate).format("MMM DD, YYYY")
                                : "N/A"}
                        </p>
                    </div>
                </div>
                <div className="mt-8 ">
                    <div className="block max-lg:hidden">
                        <CustomTable
                            tableBody={tableBody}
                            tableHeader={tableHeader}
                            isAllSelectable={false}
                            isCellSelectable={false}
                            headerItemClass="!py-3"
                            headerContainerClass="!rounded !font-rocGroteskMedium !text-[#334155] !border-slate-200"
                            isScrollable={false}
                            isCellBordered={true}
                            bodyItemClass={"hover:!bg-transparent !rounded"}
                        />
                        <div className="flex justify-between mt-4">
                            <div className=" text-sm text-gm-50 w-[50%] font-rocGroteskMedium">
                                <p>Total Quantity: {totalQuantity}</p>
                            </div>

                            <div className="w-[50%]">
                                <div className="text-sm text-gm-50  flex justify-between items-center font-rocGroteskMedium py-2">
                                    <p>Total</p>
                                    <p className="text-end">
                                        {" "}
                                        {getCurrencyFromCurrencyCode(
                                            containers?.[0]?.unitCostCurrency
                                        )}
                                        {formatMoney().format(totalAmount)}
                                    </p>
                                </div>
                                <div className="text-sm text-gm-50  flex justify-between items-center border-y  border-slate-200 font-rocGroteskMedium py-3">
                                    <p>Tax ({customerDetails?.tax}%)</p>
                                    <p className="text-end">
                                        {getCurrencyFromCurrencyCode(
                                            containers?.[0]?.unitCostCurrency
                                        )}
                                        {formatMoney().format(taxAmount)}
                                    </p>
                                </div>
                                <div className="text-sm text-gm-50  flex justify-between items-center border-b  border-slate-200 font-rocGroteskMedium py-3">
                                    <p>Shipping and Handling</p>
                                    <p className="text-end">
                                        {" "}
                                        {getCurrencyFromCurrencyCode(
                                            containers?.[0]?.unitCostCurrency
                                        )}
                                        {formatMoney().format(
                                            customerDetails?.shippingAndHandling || 0
                                        )}
                                    </p>
                                </div>
                                <div className="text-base text-gm-50  flex justify-between items-center font-rocGroteskMedium py-2">
                                    <p>Grand Total Amount</p>
                                    <p className="text-end">
                                        {getCurrencyFromCurrencyCode(
                                            containers?.[0]?.unitCostCurrency
                                        )}

                                        {formatMoney().format(grandTotal)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="max-lg:block hidden">
                        <p className="text-[#0F172A] text-sm font-rocGroteskMedium mb-3">
                            Product details
                        </p>
                        {containers?.map((item, idx) => {
                            return (
                                <div key={idx}>
                                    <div className="flex py-2 border-b border-[#F1F5F9] justify-between items-center mt-2">
                                        <p className="text-[#1E293B] font-rocGroteskMedium text-sm">
                                            Product name
                                        </p>
                                        <div className="flex  items-center space-x-2">
                                            <VendorAvatar
                                                imageSrc={item?.prodcutImageDetails?.avatar}
                                                name={item?.productName}
                                                containerClassname="!rounded-none "
                                                size={29}
                                            />
                                            <div>
                                                <p className="text-[#142837] xs:hidden font-rocGroteskMedium text-sm">
                                                    {truncate(item?.productName, {
                                                        length: 16,
                                                    })}
                                                </p>
                                                <p className="text-[#142837] xs:block hidden font-rocGroteskMedium text-sm">
                                                    {truncate(item?.productName, {
                                                        length: 20,
                                                    })}
                                                </p>
                                                <p className="text-[#64748B] font-rocGroteskMedium text-sm">
                                                    {item?.sku}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex py-2 border-b border-[#F1F5F9] justify-between items-center mt-2">
                                        <p className="text-[#64748B] font-rocGroteskMedium text-sm">
                                            Description
                                        </p>
                                        <div className="text-[#1E293B] font-rocGroteskMedium text-sm">
                                            {item?.inventory?.productDescription ? (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: hasHTMLTag(
                                                            truncate(
                                                                item?.inventory?.productDescription,
                                                                {
                                                                    length: 24,
                                                                }
                                                            )
                                                        )
                                                            ? truncate(
                                                                  item?.inventory
                                                                      ?.productDescription,
                                                                  {
                                                                      length: 24,
                                                                  }
                                                              )
                                                            : truncate(
                                                                  item?.inventory
                                                                      ?.productDescription,
                                                                  {
                                                                      length: 24,
                                                                  }
                                                              ),
                                                    }}
                                                />
                                            ) : (
                                                "----"
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex py-2 border-b border-[#F1F5F9] justify-between items-center mt-2">
                                        <p className="font-rocGroteskMedium text-sm text-[#64748B]">
                                            Quantity
                                        </p>
                                        <p className="text-[#1E293B] font-rocGroteskMedium text-sm">
                                            {formatMoney().format(item?.quantity)}
                                        </p>
                                    </div>
                                    <div className="flex py-2 border-b border-[#F1F5F9] justify-between items-center mt-2">
                                        <p className="text-[#64748B] font-rocGroteskMedium text-sm">
                                            Unit cost
                                        </p>
                                        <p className="text-[#1E293B] font-rocGroteskMedium text-sm">
                                            {getCurrencyFromCurrencyCode(item?.unitCostCurrency)}
                                            {formatMoney().format(item?.unitCostAmount)}
                                        </p>
                                    </div>
                                    <div className="flex py-2 border-b border-[#F1F5F9] justify-between items-center mt-2">
                                        <p className="text-[#64748B] font-rocGroteskMedium text-sm">
                                            Sub-total
                                        </p>
                                        <p className="text-[#1E293B] font-rocGroteskMedium text-sm">
                                            {getCurrencyFromCurrencyCode(item?.unitCostCurrency)}
                                            {formatMoney().format(item?.subtotal?.amount)}
                                        </p>
                                    </div>
                                    <div className="flex py-2 border-b border-[#F1F5F9] justify-between items-center mt-2">
                                        <p className="text-[#64748B] font-rocGroteskMedium text-sm">
                                            Delivery date
                                        </p>
                                        <p className="text-[#1E293B] font-rocGroteskMedium text-sm">
                                            {moment(item.deliveryDate).format("DD-MMM-YYYY")}
                                        </p>
                                    </div>
                                    <div className="flex py-2 justify-between items-center mt-2">
                                        <p className="text-[#64748B] font-rocGroteskMedium text-sm">
                                            Lead time
                                        </p>
                                        <p className="text-[#1E293B] font-rocGroteskMedium text-sm">
                                            {item?.leadTime || 0}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}

                        <div className="bg-[#F1F5F9] mt-4 space-y-3 p-4">
                            <div className="flex justify-between">
                                <p className="text-[#64748B] text-sm font-rocGroteskMedium">
                                    Total
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-[#142837]">
                                    {" "}
                                    {getCurrencyFromCurrencyCode(containers[0]?.unitCostCurrency)}
                                    {formatMoney().format(Number(totalAmount) || 0)}
                                </p>
                            </div>
                            <div className="flex justify-between">
                                <p className="text-[#64748B] text-sm font-rocGroteskMedium">
                                    Tax ({customerDetails?.tax}
                                    %)
                                </p>
                                <p className="tex-sm font-rocGroteskMedium text-sm text-[#142837]">
                                    {getCurrencyFromCurrencyCode(containers[0]?.unitCostCurrency)}
                                    {formatMoney().format(Number(taxAmount) || 0)}
                                </p>
                            </div>
                            <div className="flex justify-between">
                                <p className="text-[#64748B] text-sm font-rocGroteskMedium">
                                    Shipping and Handling
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-[#142837]">
                                    {getCurrencyFromCurrencyCode(containers[0]?.unitCostCurrency)}
                                    {formatMoney().format(
                                        customerDetails?.shippingAndHandling || 0
                                    )}
                                </p>
                            </div>
                            <div className="flex justify-between">
                                <p className="text-[#64748B] text-sm font-rocGroteskMedium">
                                    Grand total amount
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-[#142837]">
                                    {getCurrencyFromCurrencyCode(containers[0]?.unitCostCurrency)}

                                    {formatMoney().format(grandTotal)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    {customerDetails?.incoterm && (
                        <>
                            <div className="text-sm font-rocGroteskMedium pb-5 border-b border-slate-200">
                                <p className="text-gm-50">Incoterm</p>
                                <p className="text-slate-500">{customerDetails?.incoterm} </p>
                            </div>
                            <div className="my-5 space-y-4">
                                <div className="text-sm font-rocGroteskMedium">
                                    <p className="text-gm-50">Payment terms</p>
                                    <p className="text-slate-500">
                                        {customerDetails?.paymentTerms?.charAt(0)?.toUpperCase() +
                                            customerDetails?.paymentTerms?.slice(1)}
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                    {filesAttached?.length > 0 && (
                        <div className="mt-10">
                            <p className="text-gm-50 text-base font-rocGroteskMedium mb-2">
                                File Attachment
                            </p>
                            {filesAttached?.map((doc, idx) => (
                                <div
                                    className="flex items-center p-3 rounded-[6px] space-x-3 border border-[#F1F5F9]"
                                    key={idx}
                                >
                                    {displayFileIcon("pdf", true, "w-[25px] h-[25px]")}
                                    <div>
                                        <p className="text-sm font-rocGroteskMedium text-slate-700">
                                            {doc?.fileName || doc?.filename}
                                        </p>
                                        {/* <p className="text-xs font-rocGroteskMedium text-slate-500">
                                                2MB
                                            </p> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="py-10">
                    {customerDetails?.termsAndCondition && (
                        <>
                            <h6 className="flex justify-between mb-4 text-sm font-rocGroteskMedium text-gm-50">
                                Terms & Conditions
                            </h6>
                            <div className="space-y-0.5 text-sm font-rocGroteskMedium text-slate-500">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: hasHTMLTag(customerDetails?.termsAndCondition)
                                            ? sanitizeHtml(
                                                  customerDetails?.termsAndCondition
                                              ).sanitizedHTML?.join("")
                                            : customerDetails?.termsAndCondition,
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
                {customerDetails?.additionalInfo && (
                    <div className="pb-10 w-[80%]">
                        <h6 className="flex justify-between mb-4 text-sm font-rocGroteskMedium text-gm-50">
                            Additional Info
                        </h6>
                        <div className="space-y-0.5 text-sm font-rocGroteskMedium text-slate-500">
                            <p>{customerDetails?.additionalInfo}</p>
                        </div>
                    </div>
                )}
            </div>

            <div className="flex-col space-y-3 max-lg:flex  hidden items-center left-0 right-0 px-5 shadow-faintShadow border-t justify-between  py-5 border-slate-100 bottom-[0px] w-full fixed z-[50]   bg-white">
                {btnTextThree && (
                    <Button
                        btnText={btnTextThree}
                        type={"button"}
                        btnClassname="!py-2 border !whitespace-nowrap border border-n-20  cursor-pointer !bg-white !text-gm-50  !font-rocGroteskMedium !px-4"
                        onClick={onClickThree}
                        // isLoading={isLoading}
                        disabled={isLoading}
                    />
                )}
                {btnTextOne.toLowerCase() === "adjust po" && (
                    <Button
                        btnText={btnTextOne}
                        type={"button"}
                        btnClassname="!py-2 cursor-pointer !whitespace-nowrap !bg-[#F1F5F9] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4"
                        onClick={onClickOne}
                        disabled={disabled}
                    />
                )}
                {btnTextTwo && (
                    <BlobProvider
                        document={
                            <PurchaseOrdersPDF
                                imageDetails={imageDetails}
                                customerDetails={customerDetails}
                                ldbName={ldbName}
                                ldbEmail={ldbEmail}
                                ldbAddress={ldbAddress}
                                vendorName={vendorName}
                                vendorEmail={vendorEmail}
                                vendorAddress={vendorAddress}
                                totalQuantity={totalQuantity}
                                totalAmount={totalAmount}
                                filesAttached={filesAttached}
                                taxAmount={taxAmount}
                                containers={containers}
                                ldbPhone={ldbPhone}
                                vendorPhone={vendorPhone}
                            />
                        }
                    >
                        {({ blob, url }) => {
                            return (
                                <Button
                                    btnText={btnTextTwo}
                                    type={"button"}
                                    btnClassname="!py-2 cursor-pointer  !whitespace-nowrap !font-rocGroteskMedium !px-4"
                                    onClick={() => {
                                        if (blob) {
                                            const pdfUrl = new File([blob], "purchase-order", {
                                                lastModified: new Date().getTime(),
                                                type: blob?.type,
                                            });
                                            setSaveBlob(pdfUrl);
                                        }
                                        onClickTwo();
                                    }}
                                    disabled={disabled}
                                    isLoading={isLoading}
                                />
                            );
                        }}
                    </BlobProvider>
                )}
            </div>
        </div>
    );
};

export default PurchaseOrderDetails;
